import {
  ActionButton,
  DetailsList,
  DetailsListLayoutMode,
  IColumn,
  IIconProps,
  ImageFit,
  Image,
  SelectionMode,
  Label,
  Text,
  MessageBarType,
  FontIcon,
  TooltipHost,
  TextField
} from '@fluentui/react';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { AvailableMedicalService } from '../../../models/Entities/MedicalServices/AvailableMedicalService';
import { PriceDetails } from '../../../models/Entities/PriceList/PriceDetail';
import { MessageComponent } from '../../../commons/component/MessageComponent';

// functions
import * as Functions from '../../../functions/common';
import * as ComponentFunctions from './PriceListComponentFunctions';

////i18Next
import { useTranslation, Trans } from 'react-i18next';

export const PricesListComponent = (props: ComponentFunctions.Props) => {
  const [sortedItems, setSortedItems] = useState<PriceDetails[]>();
  const [columns, setColumns] = useState<IColumn[]>();
  
  //useTranslation()
  const [t] = useTranslation();

  useEffect(() => {
    if (!props.PriceList && !props.IsGettingPriceListById) {
      props.GetPriceListById(props.PriceListId);
    }
  }, [props.PriceListId]);

  useEffect(() => {
    if (props.MedicalServices.length === 0) { 
      props.GetAllMedicalServices(1, 1000, undefined);
    }
  }, [props.MedicalServices]);

  useEffect(() => {
    if (!props.PriceMassiveEditionIsActive) {
      props.PriceList?.prices.forEach((price: PriceDetails) => {
        if (price.isChanged) {
          props.SavePrice(price);
        }
      });
    }
  }, [props.PriceMassiveEditionIsActive]);

  useEffect(() => {
    const EditPriceEventHandler = (item: PriceDetails) => {
      if (props.onEditPrice) props.onEditPrice(item);
    };

    const DeletePriceEventHandler = (item: PriceDetails) => {
      if (props.DeletePrice) props.DeletePrice(item);
    };

    if (props.MedicalServices && props.PriceList) {
      setSortedItems(
        props.PriceList.prices
          .map((price: PriceDetails) =>
            price.itemReferredId
              ? {
                  ...price,
                  Description:
                    props.MedicalServices.find(
                      (service: AvailableMedicalService) =>
                        service.id === price.itemReferredId
                    )?.serviceNameReference || 'Desconocido'
                }
              : price
          )
          .sort(Functions.DynamicSort('Description'))
          .filter((price: any) =>
            props.ServiceFilterCriteria
              ? price.Description.toLowerCase().includes(
                  props.ServiceFilterCriteria?.toLowerCase()
                )
              : true
          )
      );
      setColumns(
        _buildColumns(
          props.PriceList.prices,
          EditPriceEventHandler,
          DeletePriceEventHandler
        )
      );
    }
  }, [
    props.PriceList?.prices,
    props.MedicalServices,
    props.ServiceFilterCriteria,
    props.PriceList,
    props
  ]);

  // events

  const onChangePrice = (ItemChanged: PriceDetails) => {};

  const _onColumnClick = (
    event: React.MouseEvent<HTMLElement> | undefined,
    column: IColumn | undefined
  ): void => {
    let isSortedDescending = column?.isSortedDescending;
    if (column?.isSorted) {
      isSortedDescending = !isSortedDescending;
    }

    setSortedItems(
      ComponentFunctions._copyAndSort(
        sortedItems,
        column?.fieldName!,
        isSortedDescending
      )
    );

    setColumns(
      columns!.map((col: IColumn) => {
        col.isSorted = col.key === column?.key;

        if (col.isSorted) {
          col.isSortedDescending = isSortedDescending;
        }

        return col;
      })
    );
  };

  const _buildColumns = (
    items: PriceDetails[],
    onEdit: (item: PriceDetails) => void,
    onDelete: (item: PriceDetails) => void
  ): IColumn[] => {
    const columns: IColumn[] = []; // buildColumns(items, true);

    //icons
    const editIcon: IIconProps = { iconName: 'Edit' };
    const deleteIcon: IIconProps = { iconName: 'Delete' };

    // Column Definitions

    // Medical practice column
    const MedicalPracticeColumn: IColumn = {
      key: 'MedicalPracticeColumn',
      name: t('priceList.medicalPracticeColumn.practice'),
      ariaLabel: 'Medical Practice description',
      minWidth: 100,
      onRender: (item: PriceDetails) => (
        <Text style={{ textTransform: 'uppercase' }}>
          {item.Description}
          {!item.isEnabled && (
            <TooltipHost content={t('priceList.medicalPracticeColumn.disabledService')}>
              <FontIcon
                aria-label="Warning"
                style={{
                  color: 'red',
                  marginLeft: '0.5em',
                  padding: 3,
                  cursor: 'pointer'
                }}
                iconName="Warning"
              />
            </TooltipHost>
          )}
        </Text>
      )
    };

    // Options columns
    const optionsColumn: IColumn = {
      key: 'optionColumn',
      name: '',
      className: ComponentFunctions.classNames.fileOptions,
      ariaLabel: 'Options',
      minWidth: 100,
      maxWidth: 100,
      onRender: (item: PriceDetails) => (
        <>
          <ActionButton
            iconProps={editIcon}
            allowDisabledFocus
            onClick={() => onEdit(item)}
          />
          <ActionButton
            iconProps={deleteIcon}
            allowDisabledFocus
            onClick={() => onDelete(item)}
          />
        </>
      )
    };

    const PracticePriceColumn: IColumn = {
      key: 'PriceColumn',
      name: t('priceList.priceColumn.price'),
      ariaLabel: 'Price',
      minWidth: 100,
      onRender: (item: PriceDetails, index) =>
        !props.PriceMassiveEditionIsActive ? (
          <Label>
            {item.price} <small>{item.currency}</small>
          </Label>
        ) : (
          <TextField
            name={'txt_price' + item.id}
            required
            step={0.01}
            type="currency"
            autoComplete="off"
            tabIndex={index}
            defaultValue={item.price?.toString()}
            suffix={item.currency}
            onChange={(
              event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
              newValue?: string | undefined
            ) => props.ChangePrice({ ...item, price: Number(newValue) })}
            className={item.isChanged ? 'TextFieldValueModified' : ''}
          />
        )
    };

    const PreScreeningPriceColumn: IColumn = {
      key: 'PreSrceaningPriceColumn',
      name: t('priceList.priceColumn.preScreeningPrice'),
      ariaLabel: 'PreSrceaningPrice',
      minWidth: 100,
      onRender: (item: PriceDetails, index) =>
        !props.PriceMassiveEditionIsActive ? (
          <Label>
            {item.preScreeningPrice} <small>{item.currency}</small>
          </Label>
        ) : (
          <TextField
            name={'txt_preScreeningPrice' + item.id}
            required
            step={0.01}
            type="currency"
            autoComplete="off"
            tabIndex={index}
            defaultValue={item.preScreeningPrice?.toString()}
            suffix={item.currency}
            onChange={(
              event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
              newValue?: string | undefined
            ) =>
              props.ChangePreScreaningPrice({
                ...item,
                preScreeningPrice: Number(newValue)
              })
            }
            className={item.isChanged ? 'TextFieldValueModified' : ''}
          />
        )
    };

    const PracticeRemotePriceColumn: IColumn = {
      key: 'RemotePriceColumn',
      name: t('priceList.priceColumn.remotePrice'),
      ariaLabel: 'RemotePrice',
      minWidth: 100,
      onRender: (item: PriceDetails, index) =>
        !props.PriceMassiveEditionIsActive ? (
          <Label>
            {item.remotePrice} <small>{item.currency}</small>
          </Label>
        ) : (
          <TextField
            name={'txt_remoteprice' + item.id}
            required
            step={0.01}
            type="currency"
            autoComplete="off"
            tabIndex={index}
            defaultValue={item.remotePrice?.toString()}
            suffix={item.currency}
            onChange={(
              event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
              newValue?: string | undefined
            ) => props.ChangeRemotePrice({ ...item, remotePrice: Number(newValue) })}
            className={item.isChanged ? 'TextFieldValueModified' : ''}
          />
        )
    };

    const PracticeFailPriceColumn: IColumn = {
      key: 'FailPriceColumn',
      name: t('priceList.priceColumn.failurePrice'),
      ariaLabel: 'RemotePrice',
      minWidth: 100,
      onRender: (item: PriceDetails, index) =>
        !props.PriceMassiveEditionIsActive ? (
          <Label>
            {item.failPrice} <small>{item.currency}</small>
          </Label>
        ) : (
          <TextField
            name={'txt_failprice' + item.id}
            required
            step={0.01}
            type="currency"
            autoComplete="off"
            tabIndex={index}
            defaultValue={item.failPrice?.toString()}
            suffix={item.currency}
            onChange={(
              event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
              newValue?: string | undefined
            ) => props.ChangeFailPrice({ ...item, failPrice: Number(newValue) })}
            className={item.isChanged ? 'TextFieldValueModified' : ''}
          />
        )
    };

    const PracticeRandomPriceColumn: IColumn = {
      key: 'RandomPriceColumn',
      name: t('priceList.priceColumn.randomPrice'),
      ariaLabel: 'randomPrice',
      minWidth: 100,
      onRender: (item: PriceDetails, index) =>
        !props.PriceMassiveEditionIsActive ? (
          <Label>
            {item.randomPrice} <small>{item.currency}</small>
          </Label>
        ) : (
          <TextField
            name={'txt_randomprice' + item.id}
            required
            step={0.01}
            type="currency"
            autoComplete="off"
            tabIndex={index}
            defaultValue={item.randomPrice?.toString()}
            suffix={item.currency}
            onChange={(
              event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
              newValue?: string | undefined
            ) => props.ChangeRandomPrice({ ...item, randomPrice: Number(newValue) })}
            className={item.isChanged ? 'TextFieldValueModified' : ''}
          />
        )
    };

    const PracticeAdditionalPriceColumn: IColumn = {
      key: 'additionalPaymentPriceColumn',
      name: t('priceList.priceColumn.copay'),
      ariaLabel: 'AdditionalPaymentPrice',
      minWidth: 100,
      onRender: (item: PriceDetails, index) =>
        !props.PriceMassiveEditionIsActive ? (
          <Label>
            {item.additionalPaymentPrice} <small>{item.currency}</small>
          </Label>
        ) : (
          <TextField
            name={'txt_additionalPaymentPrice' + item.id}
            required
            step={0.01}
            type="currency"
            autoComplete="off"
            tabIndex={index}
            defaultValue={item.additionalPaymentPrice?.toString()}
            suffix={item.currency}
            onChange={(
              event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
              newValue?: string | undefined
            ) => props.ChangeAdditionalPaymentPrice({ ...item, additionalPaymentPrice: Number(newValue) })}
            className={item.isChanged ? 'TextFieldValueModified' : ''}
          />
        )
    };

    // push columns
    columns.push(MedicalPracticeColumn);
    props.showPrice && columns.push(PracticePriceColumn);
    props.showRemotePrice && columns.push(PracticeRemotePriceColumn);
    props.showPreScreaningPrice && columns.push(PreScreeningPriceColumn);
    props.showRandomPrice && columns.push(PracticeRandomPriceColumn);
    props.showFailPrice && columns.push(PracticeFailPriceColumn);
    props.showAdditionalPaymentPrice && columns.push(PracticeAdditionalPriceColumn);
    columns.push(optionsColumn);

    const principalColumn = columns.filter((column) => column.name === 'id')[0];

    if (principalColumn) {
      principalColumn.isRowHeader = true;
    }

    columns.forEach((column: IColumn) => {
      column.flexGrow = 4;
    });

    return columns;
  };

  return (
    <>
      {(props.FailOnGettingAllPriceList ||
        props.FailOnGettingPriceListById ||
        props.FailOnGettingAllMedicalServices) && (
        <MessageComponent
          message={t('priceList.failMessage.errorMessage')}
          type={MessageBarType.error}
          itemList={
            (props.PriceListOperationError &&
              props.PriceListOperationError.Errors) ||
            (props.MedicalServiceOperationError &&
              props.MedicalServiceOperationError.Errors) ||
            []
          }
          subMessage={
            props.PriceListOperationError?.ErrorMessage ||
            props.MedicalServiceOperationError?.ErrorMessage ||
            ''
          }
        />
      )}
      {(props.FailOnUpdatingPrice || props.FailOnDeletingPrice) && (
        <MessageComponent
          message={t('priceList.failMessage.errorMessage')}
          type={MessageBarType.error}
          itemList={
            (props.PriceOperationError && props.PriceOperationError.Errors) ||
            []
          }
          subMessage={props.PriceOperationError?.ErrorMessage || ''}
        />
      )}
      {props.ServiceFilterCriteria && (
        <MessageComponent
          message={t('priceList.filterMessage.appliedFilter')}
          type={MessageBarType.info}
          itemList={[]}
          subMessage={
            t('priceList.filterMessage.searchCriteria') + ' "' + props.ServiceFilterCriteria + '"'
          }
        />
      )}
      <DetailsList
        items={sortedItems || []}
        setKey="set"
        columns={columns}
        onRenderItemColumn={_renderItemColumn}
        onColumnHeaderClick={_onColumnClick}
        selectionMode={SelectionMode.none}
        layoutMode={DetailsListLayoutMode.justified}
        isHeaderVisible={true}
      />
      {props.PriceList?.prices.length === 0 && (
        <div className="NoData">
          <label>No hay precios registrados</label>
        </div>
      )}
    </>
  );
};

export default connect(
  ComponentFunctions.mapStateToProps,
  ComponentFunctions.mapDispatchToProps
)(PricesListComponent as any);

function _renderItemColumn(
  item: PriceDetails | undefined,
  index: number | undefined,
  column: IColumn | undefined
) {
  const fieldContent = item![column?.fieldName as keyof PriceDetails] as string;

  switch (column!.key) {
    case 'thumbnail':
      return (
        <Image
          src={fieldContent}
          width={50}
          height={50}
          imageFit={ImageFit.cover}
        />
      );

    default:
      return <span>{fieldContent}</span>;
  }
}
