import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Label, Text, Button, DrawerBody, DrawerFooter, DrawerHeader, DrawerHeaderTitle, OverlayDrawer, Title3, Subtitle2, Subtitle1, Avatar, Field, Input, InputOnChangeData, InputProps, Switch } from '@fluentui/react-components';
import { Dismiss24Regular, InfoFilled } from '@fluentui/react-icons';
import moment from 'moment';

// component functions
import * as ComponentFunctions from './Consumption.Form.Functions';
import { Separator } from '@fluentui/react';
import { DateTimeInput } from '../../../commons/component/Inputs/DateTimeInput/DateTimeInput';
import ContractConceptSelectorComponent from '../../../commons/component/Inputs/ContractConceptSelector/ContractConceptSelector.Component';
import { ContractConcept } from '../../../models/Entities/Contracts/ContractConcept';
import ContractStagesSelectorComponent from '../../../commons/component/Inputs/ContractStagesSelector/ContractStages.Selector.Component';
import { ContractStage } from '../../../models/Entities/Contracts/ContractStage';
import { CurrencySelectorComponent } from '../../../commons/component/Inputs/CurrencySelector/CurrencySelectorComponent';
import ProtocolPatientSelectorComponent from '../../../commons/component/Inputs/ProtocolPatientSelector/ProtocolPatientSelector.Component';
import { ProtocolPatient } from '../../../models/Entities/Protocols/ProtocolPatient';
import { ProtocolConsumption } from '../../../models/Entities/Protocols/ProtocolConsumption';
import { NewProtocolConsumption } from '../../../models/Entities/Protocols/NewProtocolConsumption';



const ConsumptionFormComponent = (props: ComponentFunctions.Props) => {

    const [isAssociatedToContractConcept, setIsAssociatedToContractConcept] = useState<boolean>(false);

    useEffect(() => {
        props.Protocol?.id && props.GetMedicalProtocolById(props.Protocol?.id);
    }, [props.Protocol?.id === undefined]);

    useEffect(() => {
        if (props.Protocol?.id !== undefined) {
            props.GetAllProtocolContractAssociations(props.Protocol?.id);
        }
    }, [props.Protocol?.id !== undefined]);


    /*
    id: string;
    status: number;
    protocolId: number;
    serviceName: string;
    serviceDueDate: Date;
    contractConceptId: number;
    conceptDescription: string;
    parentInstanceId: string;
    instanceId: string;
    patientNumberInProtocol: string;
    stageId: number;
    stageName: string;
    calculatingType: number;
    currencyCode: string;
    
    // amount
    
    quantity: number;
    coefficientApplied: number;
    haveOverheadIncluded: boolean;
    contractValue: number;
    overheadPercentage: number;
    overheadAmount: number;
    haveFeeDivision: boolean;
    feeDivisionPercentage: number;
    ownerAmount: number;
    ownerAmountWithholding: number;
    ownerAmountWithholdingTotal: number;
    teamDivisionPercentage: number;
    teamAmount: number;
    teamAmountWithholding: number;
    teamAmountWithholdingTotal: number;
    unitAmount: number;
    amount: number;
    totalValues: number;
    totalAmount: number;
    totalAmountWithWithholding: number;
    payeeAmount: number;
    pendingAmount: number;
    withHolingPercentage: number;
    withHolingAmount: number;
    
    // cost
    costStatus: number;
    costAmount: number;
    costPayee: number;
    costPending: number;
    costCurrencyCode: string | undefined;
    
    providerId: string;
    providerName: string;
    producer: string;
    producerName: string;
    
    typeOfService: number;
    realizedType: number;
    condition: number;

    // medical service
    medicalServiceId: number;
    medicalServiceName: string;
    medicalServiceCode: string;
    markedToSettle: boolean;
    markedAsPayee: boolean;
    markedAsFlagged: boolean;
    markedAsItemized: boolean;
    markedAsRefundable: boolean;
    markedAsNeedMoreInfo: boolean;
    moreInfoComments: string;
    markedAsHaveWarnings: boolean;
    warningsComments: string;
    comments: string;

    // tags

    tag1: string;
    tag2: string;
    tag3: string;
    tag4: string; 
    */

    const [serviceName, setServiceName] = useState<string | undefined>();
    const [serviceDueDate, setServiceDueDate] = useState<Date>();
    const [contractConcept, setContractConcept] = useState<ContractConcept>();
    const [stageId, setStageId] = useState<number>();
    const [patientNumberInProtocol, setPatientNumberInProtocol] = useState<string>();
    const [conceptDescription, setConceptDescription] = useState<string>('');
    const [stageName, setStageName] = useState<string>('');
    const [calculatingType, setCalculatingType] = useState<number>(0);
    const [currencyCode, setCurrencyCode] = useState<string>('ARS');
    const [quantity, setQuantity] = useState<number>(1);
    const [coefficientApplied, setCoefficientApplied] = useState<number>(0);
    const [haveOverheadIncluded, setHaveOverheadIncluded] = useState<boolean>(false);
    const [contractValue, setContractValue] = useState<number>(0);
    const [overheadPercentage, setOverheadPercentage] = useState<number>(0);
    const [overheadAmount, setOverheadAmount] = useState<number>(0);
    const [haveFeeDivision, setHaveFeeDivision] = useState<boolean>(false);
    const [feeDivisionPercentage, setFeeDivisionPercentage] = useState<number>(0);
    const [ownerAmount, setOwnerAmount] = useState<number>(0);
    const [ownerAmountWithholding, setOwnerAmountWithholding] = useState<number>(0);
    const [ownerAmountWithholdingTotal, setOwnerAmountWithholdingTotal] = useState<number>(0);
    const [teamDivisionPercentage, setTeamDivisionPercentage] = useState<number>(0);
    const [teamAmount, setTeamAmount] = useState<number>(0);
    const [teamAmountWithholding, setTeamAmountWithholding] = useState<number>(0);
    const [teamAmountWithholdingTotal, setTeamAmountWithholdingTotal] = useState<number>(0);
    const [unitAmount, setUnitAmount] = useState<number>(0);
    const [amount, setAmount] = useState<number>(0);
    const [totalValues, setTotalValues] = useState<number>(0);
    const [totalAmount, setTotalAmount] = useState<number>(0);
    const [totalAmountWithWithholding, setTotalAmountWithWithholding] = useState<number>(0);
    const [payeeAmount, setPayeeAmount] = useState<number>(0);
    const [pendingAmount, setPendingAmount] = useState<number>(0);
    const [withHolingPercentage, setWithHolingPercentage] = useState<number>(0);
    const [withHolingAmount, setWithHolingAmount] = useState<number>(0);
    const [costStatus, setCostStatus] = useState<number>(0);
    const [costAmount, setCostAmount] = useState<number>(0);
    const [costPayee, setCostPayee] = useState<number>(0);
    const [costPending, setCostPending] = useState<number>(0);
    const [costCurrencyCode, setCostCurrencyCode] = useState<string>('');
    const [providerId, setProviderId] = useState<string>();
    const [providerName, setProviderName] = useState<string>('');
    const [producer, setProducer] = useState<string>();
    const [producerName, setProducerName] = useState<string>('');

    const HandleCancelForm = () => {
        props.onClose();
    };

    const ValidateForm = () => {

    }

    const onSubmmitForm = () => {
        const newConsumption: NewProtocolConsumption = {
            status: 0,
            protocolId: props.Protocol?.id ?? 0,
            serviceName: serviceName ?? '',
            serviceDueDate: serviceDueDate ?? new Date(),
            contractConceptId: contractConcept?.id ?? 0,
            conceptDescription: conceptDescription,
            parentInstanceId: '',
            instanceId: '',
            patientNumberInProtocol: patientNumberInProtocol ?? '',
            stageId: stageId ?? 0,
            stageName: stageName,
            calculatingType: calculatingType,
            currencyCode: currencyCode,
            quantity: quantity,
            coefficientApplied: coefficientApplied,
            haveOverheadIncluded: haveOverheadIncluded,
            contractValue: contractValue,
            overheadPercentage: overheadPercentage,
            overheadAmount: overheadAmount,
            haveFeeDivision: haveFeeDivision,
            feeDivisionPercentage: feeDivisionPercentage,
            ownerAmount: ownerAmount,
            ownerAmountWithholding: ownerAmountWithholding,
            ownerAmountWithholdingTotal: ownerAmountWithholdingTotal,
            teamDivisionPercentage: teamDivisionPercentage,
            teamAmount: teamAmount,
            teamAmountWithholding: teamAmountWithholding,
            teamAmountWithholdingTotal: teamAmountWithholdingTotal,
            unitAmount: unitAmount,
            amount: amount,
            totalValues: totalValues,
            totalAmount: totalAmount,
            totalAmountWithWithholding: totalAmountWithWithholding,
            payeeAmount: payeeAmount,
            pendingAmount: pendingAmount,
            withHolingPercentage: withHolingPercentage,
            withHolingAmount: withHolingAmount,
            costStatus: costStatus,
            costAmount: costAmount,
            costPayee: costPayee,
            costPending: costPending,
            costCurrencyCode: costCurrencyCode,
            typeOfService: 0,
            realizedType: 1,
            condition: 0,
            medicalServiceId: 0,
            medicalServiceName: '',
            medicalServiceCode: '',
            markedToSettle: false,
            markedAsPayee: false,
            markedAsFlagged: false,
            markedAsItemized: false,
            markedAsRefundable: false,
            markedAsNeedMoreInfo: false,
            moreInfoComments: '',
            markedAsHaveWarnings: false,
            warningsComments: '',
            comments: '',
            tag1: '',
            tag2: '',
            tag3: '',
            tag4: '',
            providerId: undefined,
            providerName: '',
            producer: undefined,
            producerName: ''
        };

        props.CreateProtocolConsumption(newConsumption);
    }

    const ResetForm = () => {
        setServiceName('');
        setServiceDueDate(new Date());
        setContractConcept(undefined);
        setStageId(undefined);
        setPatientNumberInProtocol('');
        setConceptDescription('');
        setStageName('');
        setCalculatingType(0);
        setCurrencyCode('ARS');
        setQuantity(1);
        setCoefficientApplied(0);
        setHaveOverheadIncluded(false);
        setContractValue(0);
        setOverheadPercentage(0);
        setOverheadAmount(0);
        setHaveFeeDivision(false);
        setFeeDivisionPercentage(0);
        setOwnerAmount(0);
        setOwnerAmountWithholding(0);
        setOwnerAmountWithholdingTotal(0);
        setTeamDivisionPercentage(0);
        setTeamAmount(0);
        setTeamAmountWithholding(0);
        setTeamAmountWithholdingTotal(0);
        setUnitAmount(0);
        setAmount(0);
        setTotalValues(0);
        setTotalAmount(0);
        setTotalAmountWithWithholding(0);
        setPayeeAmount(0);
        setPendingAmount(0);
        setWithHolingPercentage(0);
        setWithHolingAmount(0);
        setCostStatus(0);
        setCostAmount(0);
        setCostPayee(0);
        setCostPending(0);
        setCostCurrencyCode('');
        setProviderId(undefined);
        setProviderName('');
        setProducer(undefined);
        setProducerName('');
    }

    // form handlers
    const onServiceNameChange: InputProps["onChange"] = (ev, data) => {
        setServiceName(data.value);
    };

    const onQuantityChange: InputProps["onChange"] = (ev, data) => {
        setQuantity(parseInt(data.value));
        setAmount(parseInt(data.value) * unitAmount);
    }

    const onUnityAmountChange: InputProps["onChange"] = (ev, data) => {
        setUnitAmount(parseInt(data.value));
        setAmount(quantity * parseInt(data.value));
    }

    const FormFileds = (
        <div>
            <Field label="Description del concepto" required>
                <Input type='text' value={serviceName} onChange={onServiceNameChange} />
            </Field>
            <Field label="Fecha de servicio">
                <DateTimeInput label={undefined} placeHolder={"seleccione de realización"} selectedDate={serviceDueDate} isSubmitted={false} isRequired={true} errorMessage={undefined} onSelectDate={(date: Date | undefined) => setServiceDueDate(date)} />
            </Field>
            <Field className='ToggleInline'
                validationState="none"
                validationMessageIcon={<InfoFilled style={{color: 'orange'}} />}
                validationMessage="Indica si este item esta asociado a un concepto del contrato">
                <Switch
                    label={'Esta asociado a un concepto de contrato'}
                    labelPosition="before"
                    checked={isAssociatedToContractConcept}
                    onChange={
                        (ev, checked) => {
                            setIsAssociatedToContractConcept(checked.checked ?? false);
                        }
                    }
                />
            </Field>
            {props.ContractId && isAssociatedToContractConcept && <Field>
                <ContractConceptSelectorComponent
                    ContractId={props.ContractId}
                    referralDate={serviceDueDate}
                    onSelectedContractConcept={(concept: ContractConcept[]) => setContractConcept(concept?.[0])}
                    ContractConceptsSelected={undefined}
                    MultiselectEnable={false} />

            </Field>}
            <Separator />
            {props.ContractId && <Field>
                <ContractStagesSelectorComponent
                    ContractId={props.ContractId}
                    onSelectedContractStage={(stages: ContractStage[]) => setStageId(stages[0]?.id)}
                    ContractStagesSelected={undefined}
                    MultiselectEnable={false} />
            </Field>}
            {props.Protocol?.id && <Field>
                <ProtocolPatientSelectorComponent
                    ProtocolId={props.Protocol?.id}
                    onSelectedProtocolPatient={(patients: ProtocolPatient[]) => setPatientNumberInProtocol(patients?.[0]?.numberInProtocol)}
                    ProtocolPatientsSelectedNumber={undefined}
                    MultiselectEnable={false}
                />
            </Field>}
            {!contractConcept?.id && <>
                <Field>
                    <CurrencySelectorComponent
                        errorMessage={undefined}
                        defaultSelected={currencyCode}
                        label={''}
                        onCurrencyChange={(currency: string) => setCurrencyCode(currency)}
                    />
                </Field>
                <Field label={'Monto unitario'}>
                    <Input type='text' contentAfter={currencyCode} value={unitAmount.toString()} onChange={onUnityAmountChange} />
                </Field>
                <Field label={'Cantidad'}>
                    <Input type='text' contentAfter={'unidades'} value={quantity.toString()} onChange={onQuantityChange} />
                </Field>
                <Separator />
                <Field label={'Monto total'}>
                    <Label><strong>{amount.toLocaleString('es-AR', { style: 'currency', currency: currencyCode?.toUpperCase() }) ?? '0.00'} {currencyCode}</strong></Label>
                </Field>
            </>}
        </div>
    )

    const Footer = (
        <DrawerFooter>
            <Button appearance='primary' onClick={onSubmmitForm} >Grabar</Button>
            <Button onClick={HandleCancelForm} >Cerrar</Button>
        </DrawerFooter>
    );

    return (
        <div>
            <OverlayDrawer
                position={'end'}
                open={props.isOpen}
                size='small'
            >
                <DrawerHeader>
                    <DrawerHeaderTitle
                        action={
                            <Button
                                appearance="subtle"
                                aria-label="Close"
                                icon={<Dismiss24Regular />}
                                onClick={() => HandleCancelForm()}
                            />
                        }
                    >
                        <div className='consumptionHeader'>
                            <Subtitle1 style={{ textTransform: 'capitalize' }}>Información del consumo</Subtitle1>
                        </div>


                    </DrawerHeaderTitle>
                </DrawerHeader>
                <DrawerBody className='ScrollShadow'>
                    {FormFileds}
                </DrawerBody>
                {Footer}
            </OverlayDrawer>
        </div>
    );
}

export default connect(
    ComponentFunctions.mapStateToProps,
    ComponentFunctions.mapDispatchToProps
)(ConsumptionFormComponent as any);

// Remove this function as it is not needed
