import React from 'react';
import { useEffect, useState } from 'react';

// own functions
import * as ownFunctions from './ProtocolPatientSelector.Functions';

// models 
import { ProtocolPatient } from '../../../../models/Entities/Protocols/ProtocolPatient';
import { connect } from 'react-redux';
import { Field, Label, Tag, TagPicker, TagPickerControl, TagPickerGroup, TagPickerInput, TagPickerList, TagPickerOption, TagPickerProps } from '@fluentui/react-components';
import { ITag } from '@fluentui/react/lib/components/pickers';


export const ProtocolPatientSelectorComponent = (
  props: ownFunctions.Props
) => {

  const onChangeIdTextFieldValue = (items?: ITag[] | undefined) => {
    let ProtocolPatientComboSelected: ProtocolPatient[] = [];
    if (items && items.length > 0) {

      items.forEach(async (item: ITag) => {
        const ProtocolPatientNumberInProtocol = String(item.key);
        const patient: ProtocolPatient = ownFunctions.GetProtocolPatientById(item.key.toString(), props.ProtocolPatients);

        ProtocolPatientComboSelected = [
          ...ProtocolPatientComboSelected,
          patient
        ];
        setInternalProtocolPatientsSelected(ProtocolPatientComboSelected);
        setProtocolPatientsOptionsSelected(items);

        console.log('paciente seleccionado', ProtocolPatientComboSelected);

        props.onSelectedProtocolPatient && props.onSelectedProtocolPatient(ProtocolPatientComboSelected);
      });
    } else {
      setInternalProtocolPatientsSelected([]);
      setProtocolPatientsOptionsSelected([]);
      props.onSelectedProtocolPatient && props.onSelectedProtocolPatient([]);
    }
  };

  const [ProtocolPatientsOptions, setProtocolPatientsOptions] = useState<ITag[]>();
  const [ProtocolPatientsOptionsSelected, setProtocolPatientsOptionsSelected] =
    useState<ITag[]>();

  const [InternalProtocolPatientsSelected, setInternalProtocolPatientsSelected] =
    useState<ProtocolPatient[]>([]);

  useEffect(() => {
    props.GetProtocolPatients(props.ProtocolId, '');
  }, []);

  useEffect(() => {
    setProtocolPatientsOptions(ownFunctions.GetProtocolPatientOptions(props.ProtocolId, props.ProtocolPatients));
  }, [props.ProtocolPatients?.length > 0]);

  useEffect(() => {
    props.onSelectedProtocolPatient(InternalProtocolPatientsSelected);
  }, [InternalProtocolPatientsSelected]);

  useEffect(() => {
    if (props.ProtocolPatientsSelectedNumber && ProtocolPatientsOptions)

      //console.log('props.ProtocolPatientsSelected 24', props.ProtocolPatientsSelectedNumber[0]);

      //var auxProtocolPatientSelected = 

      //console.log('auxProtocolPatientSelected', auxProtocolPatientSelected?.length);
      //console.log('props.ProtocolPatientsSelected', props.ProtocolPatientsSelectedNumber?.length);

      setProtocolPatientsOptionsSelected(ProtocolPatientsOptions?.filter(
        (option: ITag) =>
          option.key ===
          props.ProtocolPatientsSelectedNumber?.find((id: string) => id === option.key)
      ));

    var protocolPatient = props.ProtocolPatientsSelectedNumber?.length && ownFunctions.GetProtocolPatientById(props.ProtocolPatientsSelectedNumber[0], props.ProtocolPatients);

    setInternalProtocolPatientsSelected(protocolPatient ? [protocolPatient] : []);

    props.onSelectedProtocolPatient && protocolPatient && props.onSelectedProtocolPatient([protocolPatient]);

  }, [ProtocolPatientsOptions]);

  const filterSuggestedProtocolPatientsServices = async (
    filterText: string,
    tagList: ITag[] | undefined
  ): Promise<ITag[]> => {
    return filterText
      ? ProtocolPatientsOptions?.filter(
        (tag) =>
          tag.name.toLowerCase().includes(filterText.toLowerCase()) &&
          !ownFunctions.listContainsTagList(tag, tagList)
      ) || []
      : [];
  };

  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

  const onOptionSelect: TagPickerProps["onOptionSelect"] = (e, data) => {
    if (data.value === "no-options") {
      setSelectedOptions([]);
      setInternalProtocolPatientsSelected([]);
      return;
    }
    if (props.MultiselectEnable) {
      setSelectedOptions(data.selectedOptions);
      setInternalProtocolPatientsSelected(props.ProtocolPatients?.filter((patient: ProtocolPatient) => data.selectedOptions.includes(patient.numberInProtocol)));
    } else {
      setSelectedOptions(props.MultiselectEnable ? [data.value] : (selectedOptions.includes(data.value) ? [] : [data.value]));
      setInternalProtocolPatientsSelected([props.ProtocolPatients?.find((patient: ProtocolPatient) => patient.numberInProtocol === data.value) as ProtocolPatient]);
    }
  };

  const tagPickerOptions = props.ProtocolPatients?.map((patient) => patient.numberInProtocol)?.filter(
    (patient: string) => !selectedOptions.includes(patient)
  );

  return (
    <>
      {/* <Label htmlFor="SnomedOption">Seleccione un paciente</Label> */}
      {/* <TagPicker
        removeButtonAriaLabel="Eliminar"
        selectionAriaLabel="Seleccione un concepto"
        onResolveSuggestions={filterSuggestedProtocolPatientsServices}
        onEmptyResolveSuggestions={(selectedItems: ITag[] = []) => ProtocolPatientsOptions?.filter((tag: ITag ) => !selectedItems?.includes(tag) ) || []}
        getTextFromItem={ownFunctions.getTextFromItem}
        pickerSuggestionsProps={ownFunctions.pickerSuggestionsProps}
        itemLimit={props.MultiselectEnable ? undefined : 1}
        onChange={onChangeIdTextFieldValue}
        pickerCalloutProps={{ doNotLayer: false }}
        inputProps={{
          id: 'ConceptsOption'
        }}
        selectedItems={ProtocolPatientsOptionsSelected}
      /> */}
      <Field label="Seleccione un paciente" style={{ maxWidth: 400 }}>
        <TagPicker
          onOptionSelect={onOptionSelect}
          selectedOptions={selectedOptions}
        >
          <TagPickerControl>
            <TagPickerGroup aria-label="Pacientes Seleccionados">
              {selectedOptions.map((option) => (
                <Tag
                  key={option}
                  shape="rounded"
                  value={option}
                >
                  {option}
                </Tag>
              ))}
            </TagPickerGroup>
            <TagPickerInput aria-label="Seleccione un paciente" />
          </TagPickerControl>
          <TagPickerList>
            {tagPickerOptions?.length > 0 ? (
              tagPickerOptions?.map((option) => (
                <TagPickerOption
                  value={option}
                  key={option}
                >
                  {option}
                </TagPickerOption>
              ))
            ) : (
              <TagPickerOption value="no-options">
                No hay conceptos disponibles
              </TagPickerOption>
            )}
          </TagPickerList>
        </TagPicker>
        {props.noteText && <Label>{props.noteText}</Label>}
      </Field>
      {props.noteText && <small>{props.noteText}</small>}
    </>
  );
};

export default connect(
  ownFunctions.mapStateToProps,
  ownFunctions.mapDispatchToProps
)(ProtocolPatientSelectorComponent as any);
