// stores
import * as ContractConceptStore from '../../../../redux/middleware/ContractConceptMiddleware';

// common functions
import * as Functions from '../../../../functions/common'

//State
import { AppState } from "../../../../redux/reducers";

export interface IConsumptionStatusProps {
    id: number;
    Name: string;
    color: string;
}

export const ConsumptionStatus: IConsumptionStatusProps[] = [
    {id: 0, Name: 'Pendiente de liquidación', color: 'status-not-settled'},
    {id: 1, Name: 'Con saldo pendiente', color: 'status-pending'},
    {id: 2, Name: 'Pagado', color: 'status-payee'},
    {id: 3, Name: 'Liquidado', color: 'status-settled'},
    {id: 4, Name: 'Rechazado', color: 'status-rejected'},
    {id: 5, Name: 'Ignorado', color: 'status-ignored'},
    {id: 6, Name: 'Por liquidar', color: 'status-to-settle'}
];

interface IProps {
  ContractId: number;
  onSelectedStatus: (status: IConsumptionStatusProps[]) => void;
  statusSelected: number[] | undefined;
  MultiselectEnable: boolean;
  noteText?: string;
}

interface ConnectedProps {
  Status: IConsumptionStatusProps[]
}

interface DispatchProps {
  
}

export type Props = IProps & ConnectedProps & DispatchProps;

/*
isLoading={false} 
isUpdating={false} 
isUpdatedSuccessfully={false} 
isLoadingSuccessfully={false} 
failOnUpdating={false} 
failOnLoading={false} 
error={undefined} 
Contract={undefined} 
ContractConcepts={[]} 
GetContractConceptById={undefined} 
GetAllContractsConcept={undefined}  
*/

export const mapStateToProps = (state: AppState, ownProps: IProps) => ({
    Status: ConsumptionStatus
});

export const mapDispatchToProps = {
    ...ContractConceptStore.actionCreators 
};


export const GetStatusDescription = (status: number): {description: string, class: string} => {

    /*
        NotSettled = 0,
        Pending = 1,
        Payee = 2,
        Settled = 3,
        Rejected = 4,
        Ignored = 5,
        ToSettle = 6

     */

    switch (status) {
        case 0:
            return {description: 'Pendiente de liquidación', class: 'status-not-settled'};
        case 1:
            return {description: 'Con saldo pendiente', class: 'status-pending'};
        case 2:
            return {description: 'Pagado', class: 'status-payee'};
        case 3:
            return {description: 'Liquidado', class: 'status-settled'};
        case 4:
            return {description: 'Rechazado', class: 'status-rejected'};
        case 5:
            return {description: 'Ignorado', class: 'status-ignored'};
        case 6:
            return {description: 'Por liquidar', class: 'status-to-settle'};
        default:
            return {description: 'Unknown', class: 'status-unknown'};
    }
}

export const GetStatusClass = (status: string): {class: string} => {

    /*
        NotSettled = 0,
        Pending = 1,
        Payee = 2,
        Settled = 3,
        Rejected = 4,
        Ignored = 5,
        ToSettle = 6

     */

    switch (status) {
        case 'Pendiente de liquidación':
            return {class: 'status-not-settled'};
        case 'Con saldo pendiente':
            return {class: 'status-pending'};
        case 'Pagado':
            return {class: 'status-payee'};
        case 'Liquidado':
            return {class: 'status-settled'};
        case 'Rechazado':
            return {class: 'status-rejected'};
        case 'Ignorado':
            return {class: 'status-ignored'};
        case 'Por liquidar':
            return {class: 'status-to-settle'};
        default:
            return {class: 'status-unknown'};
    }
}