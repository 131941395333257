import { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { ContractConcept } from '../../../../models/Entities/Contracts/ContractConcept';

// own functions
import * as ownFunctions from './ContractConceptSelector.Functions';
import { Field, Label, Tag, TagPicker, TagPickerControl, TagPickerGroup, TagPickerInput, TagPickerList, TagPickerOption, TagPickerProps } from '@fluentui/react-components';

const ContractConceptSelectorComponent = (props: ownFunctions.Props) => {

  useEffect(() => {
    props.GetAllContractsConcept(props.ContractId, props.referralDate);
  }, [props.ContractId, props.referralDate]);

  
  const [InternalContractsSelected, setInternalContratsSelected] = useState<ContractConcept[]>([]);


  // useEffect(() => {
  //    setInternalContratsSelected([]);
  // }, [props.ContractConceptsSelected]);

  useEffect(() => {
      props.onSelectedContractConcept(InternalContractsSelected);    
  }, [InternalContractsSelected]);

  useEffect(() => {
    if(props.ContractConcepts?.length > 0 && (props.ContractConceptsSelected ?? []).length > 0){
      // determine if props.ContractConceptsSelected is an array of numbers or strings
      const isNumberArray: boolean | undefined = (props.ContractConceptsSelected ?? [])?.some((item:any) => typeof item === 'number');
      
      const selectedConcepts: ContractConcept[] = (isNumberArray ? props.ContractConcepts?.filter((concept: ContractConcept) => (props.ContractConceptsSelected as number[])?.includes(concept.id)) : (props.ContractConcepts?.filter((concept: ContractConcept) => (props.ContractConceptsSelected as string[])?.includes(concept.concept.conceptName)))  ?? []);
      const selectedStrings: string[] = selectedConcepts.map((concept: ContractConcept) => concept.concept.conceptName) ?? [];
      setSelectedOptions(selectedStrings);
      setInternalContratsSelected(selectedConcepts);
    }
  }, [props.ContractConcepts, props.ContractConceptsSelected !== undefined]);

  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

  const onOptionSelect: TagPickerProps["onOptionSelect"] = (e, data) => {
    if (data.value === "no-options") {
      setSelectedOptions([]);
      setInternalContratsSelected([]);
      return;
    }
    setSelectedOptions(data.selectedOptions);
    setInternalContratsSelected(props.ContractConcepts?.filter((concept: ContractConcept ) => data.selectedOptions.includes(concept.concept.conceptName)));
  };

  const tagPickerOptions = props.ContractConcepts?.map((concept) => concept.concept.conceptName)?.filter(
    (concept: string) => !selectedOptions.includes(concept)
  );

  return (
      <Field label="Seleccione un concepto" style={{ maxWidth: 400 }}>
        <TagPicker
          onOptionSelect={onOptionSelect}
          selectedOptions={selectedOptions}
        >
          <TagPickerControl>
            <TagPickerGroup aria-label="Conceptos seleccionados">
              {selectedOptions.map((option) => (
                <Tag
                  key={option}
                  shape="rounded"
                  value={option}
                >
                  {option}
                </Tag>
              ))}
            </TagPickerGroup>
            <TagPickerInput aria-label="Seleccione un concepto" />
          </TagPickerControl>
          <TagPickerList>
            {tagPickerOptions?.length > 0 ? (
              tagPickerOptions?.map((option) => (
                <TagPickerOption
                  value={option}
                  key={option}
                >
                  {option}
                </TagPickerOption>
              ))
            ) : (
              <TagPickerOption value="no-options">
                No hay conceptos disponibles
              </TagPickerOption>
            )}
          </TagPickerList>
        </TagPicker>
        {props.noteText && <Label>{props.noteText}</Label>}
      </Field>
  );
};

export default connect(
  ownFunctions.mapStateToProps,
  ownFunctions.mapDispatchToProps
)(ContractConceptSelectorComponent as any);
