import { Reducer, Action } from 'redux';
import { ProviderState, ProviderUnloadState } from '../states/ProviderState';

// functions
import * as Functions from '../../functions/common';

// Actions
import * as Actions from '../actions';
import { Provider } from '../../models/Entities/Provider/Provider';
import { ProviderAssociationsMedicalService } from '../../models/Entities/ProviderAssociations/MedicalServices/ProviderAssociationMedicalService';
import { ProviderPriceList } from '../../models/Entities/ProviderAssociations/PriceLists/ProviderPriceList';

type KnownAction =
  | Actions.Provider.Providers_GetAll_Request_Action
  | Actions.Provider.Providers_GetAll_Receive_Action
  | Actions.Provider.Providers_GetAll_Fail_Action
  | Actions.Provider.Providers_UpdateAll_Request_Action
  | Actions.Provider.Providers_UpdateAll_Receive_Action
  | Actions.Provider.Providers_UpdateAll_Fail_Action
  | Actions.Provider.Provider_GetById_Request_Action
  | Actions.Provider.Provider_GetById_Receive_Action
  | Actions.Provider.Provider_GetById_Fail_Action
  | Actions.Provider.Provider_Add_Request_Action
  | Actions.Provider.Provider_Add_Receive_Action
  | Actions.Provider.Provider_Add_Fail_Action
  | Actions.Provider.Provider_Update_Request_Action
  | Actions.Provider.Provider_Update_Receive_Action
  | Actions.Provider.Provider_Update_Fail_Action
  | Actions.Provider.Provider_Delete_Request_Action
  | Actions.Provider.Provider_Delete_Receive_Action
  | Actions.Provider.Provider_Delete_Fail_Action
  // Provider Medical Service Associations
  | Actions.ProviderMedicalServiceAssociations.ProviderMedicalService_ReceiveAll_Action
  | Actions.ProviderMedicalServiceAssociations.ProviderMedicalService_Delete_By_Medical_Service_Id_Receive_Action
  | Actions.ProviderMedicalServiceAssociations.ProviderMedicalService_Add_Receive_Action
  // provider price list associations
  | Actions.ProviderPriceLists.ProviderPriceList_ReceiveAll_Action
  | Actions.ProviderPriceLists.ProviderPriceList_Add_Receive_Action
  | Actions.ProviderPriceLists.ProviderPriceList_Clone_Receive_Action
  | Actions.ProviderPriceLists.ProviderPriceList_Delete_By_Price_List_Id_Receive_Action
  | Actions.ProviderPriceLists.ProviderPriceList_Delete_Receive_Action
  | Actions.ProviderPriceLists.ProviderPriceList_SetStartValidity_Receive_Action
  | Actions.ProviderPriceLists.ProviderPriceList_SetEndValidity_Receive_Action
  // protocol consumption
  | Actions.Provider.Provider_Consumption_GetAll_Request_Action
  | Actions.Provider.Provider_Consumption_GetAll_Receive_Action
  | Actions.Provider.Provider_Consumption_GetAll_Fail_Action;

export const ProviderReducer: Reducer<ProviderState> = (
  state: ProviderState | undefined,
  incomingAction: Action
): ProviderState => {
  if (state === undefined) {
    return ProviderUnloadState as ProviderState;
  }

  const action = incomingAction as KnownAction;

  switch (action.type) {
    case 'PROVIDERS_GETALL_REQUEST_ACTION':
      return {
        ...state,
        isLoadingAll: true,
        successLoadingAll: undefined,
        failOnLoadingAll: false,
        error: undefined
      };
    case 'PROVIDERS_GETALL_RECEIVE_ACTION':
      return {
        ...state,
        isLoadingAll: false,
        successLoadingAll: true,
        failOnLoadingAll: false,
        list: action.providers,
        error: undefined
      };
    case 'PROVIDERS_GETALL_FAIL_ACTION':
      return {
        ...state,
        isLoadingAll: false,
        successLoadingAll: false,
        failOnLoadingAll: true,
        error: action.error
      };
    case 'PROVIDER_UPDATE_ALL_REQUEST_ACTION':
      return {
        ...state,
        isUpdatingAll: true,
        UpdatingAllSuccess: undefined,
        FailUpdatingAll: false,
        error: undefined
      };
    case 'PROVIDER_UPDATE_ALL_RECEIVE_ACTION':
      return {
        ...state,
        isUpdatingAll: false,
        UpdatingAllSuccess: true,
        FailUpdatingAll: false,
        list: action.providers.map((provider: Provider) => {
          provider.PriceListAssociated = state.list.find(
            (p: Provider) => p.id === provider.id
          )?.PriceListAssociated || [];
          provider.MedicalServicesAssociated = state.list.find(
            (p: Provider) => p.id === provider.id
          )?.MedicalServicesAssociated || [];
          return provider;
        }),
        error: undefined
      };
    case 'PROVIDER_UPDATE_ALL_FAIL_ACTION':
      return {
        ...state,
        isUpdatingAll: false,
        UpdatingAllSuccess: false,
        FailUpdatingAll: true,
        error: action.error
      };
    case 'PROVIDER_GETBYID_REQUEST_ACTION':
      return {
        ...state,
        isLoadingOne: true,
        successLoadingOne: undefined,
        failOnLoadingOne: false,
        error: undefined
      };
    case 'PROVIDER_GETBYID_RECEIVE_ACTION':
      return {
        ...state,
        isLoadingOne: false,
        successLoadingOne: true,
        failOnLoadingOne: false,
        list: [
          ...state.list.filter(
            (provider: Provider) => provider.id !== action.Provider.id
          ),
          action.Provider
        ].sort(Functions.DynamicSort('fullName')),
        error: undefined
      };
    case 'PROVIDER_GETBYID_FAIL_ACTION':
      return {
        ...state,
        isLoadingOne: false,
        successLoadingOne: false,
        failOnLoadingOne: true,
        error: action.error
      };
    case 'PROVIDER_ADD_REQUEST_ACTION':
      return {
        ...state,
        isAddingNewOne: true,
        successAddingNewOne: undefined,
        failOnAddingNewOne: false,
        error: undefined
      };
    case 'PROVIDER_ADD_RECEIVE_ACTION':
      return {
        ...state,
        isAddingNewOne: false,
        successAddingNewOne: true,
        failOnAddingNewOne: false,
        list: [...state.list, action.Provider].sort(
          Functions.DynamicSort('fullName')
        ),
        error: undefined
      };
    case 'PROVIDER_ADD_FAIL_ACTION':
      return {
        ...state,
        isAddingNewOne: false,
        successAddingNewOne: false,
        failOnAddingNewOne: true,
        error: action.error
      };
    case 'PROVIDER_UPDATE_REQUEST_ACTION':
      return {
        ...state,
        isUpdatingOne: true,
        successUpdatingOne: undefined,
        failOnUpdatingOne: false,
        error: undefined
      };
    case 'PROVIDER_UPDATE_RECEIVE_ACTION':
      var provider = state.list.find(
        (provider: Provider) => provider.id === action.Provider.id
      );

      if (provider !== undefined) {
        action.Provider.PriceListAssociated = provider.PriceListAssociated;
        action.Provider.MedicalServicesAssociated = provider.MedicalServicesAssociated;
      }

      return {
        ...state,
        isUpdatingOne: false,
        successUpdatingOne: true,
        failOnUpdatingOne: false,
        list: [
          ...state.list.filter(
            (provider: Provider) => provider.id !== action.Provider.id
          ),
          action.Provider
        ].sort(Functions.DynamicSort('fullName')),
        error: undefined
      };
    case 'PROVIDER_UPDATE_FAIL_ACTION':
      return {
        ...state,
        isUpdatingOne: false,
        successUpdatingOne: false,
        failOnUpdatingOne: true,
        error: action.error
      };

    case 'PROVIDER_DELETE_REQUEST_ACTION':
      return {
        ...state,
        isDeletingOne: true,
        successDeletingOne: undefined,
        failOnDeletingOne: false,
        error: undefined
      };
    case 'PROVIDER_DELETE_RECEIVE_ACTION':
      return {
        ...state,
        isDeletingOne: false,
        successDeletingOne: true,
        failOnDeletingOne: false,
        list: [
          ...state.list.filter(
            (provider: Provider) => provider.id !== action.ProviderId
          )
        ].sort(Functions.DynamicSort('fullName')),
        error: undefined
      };
    case 'PROVIDER_DELETE_FAIL_ACTION':
      return {
        ...state,
        isDeletingOne: false,
        successDeletingOne: false,
        failOnDeletingOne: true,
        error: action.error
      };
    case 'PROVIDER_MEDICAL_SERVICE_RECEIVE_ALL_ACTION':
      var provider = state.list.find(
        (provider: Provider) => provider.id === action.ProviderId
      );
      console.log('provider', provider);
      return {
        ...state,
        list: provider
          ? [
            ...state.list.filter(
              (provider: Provider) => provider.id !== action.ProviderId
            ),
            {
              ...provider,
              MedicalServicesAssociated: action.ProviderMedicalServices
            }
          ]
          : state.list
      };
    case 'PROVIDER_MEDICAL_SERVICE_DELETE_BY_MEDICAL_SERVICE_ID_RECEIVE_ACTION':
      var provider = state.list.find(
        (provider: Provider) => provider.id === action.ProviderId
      );

      return {
        ...state,
        list: provider
          ? [
            ...state.list.filter(
              (provider: Provider) => provider.id !== action.ProviderId
            ),
            {
              ...provider,
              MedicalServicesAssociated:
                provider.MedicalServicesAssociated.filter(
                  (
                    providerMedicalService: ProviderAssociationsMedicalService
                  ) =>
                    providerMedicalService.medicalServiceId !==
                    action.MedicalServiceId
                )
            }
          ]
          : state.list
      };
    case 'PROVIDER_MEDICAL_SERVICE_ADD_RECEIVE_ACTION':
      var provider = state.list.find(
        (provider: Provider) =>
          provider.id === action.ProviderMedicalService.providerId
      );

      return {
        ...state,
        list: provider
          ? [
            ...state.list.filter(
              (provider: Provider) =>
                provider.id !== action.ProviderMedicalService.providerId
            ),
            {
              ...provider,
              MedicalServicesAssociated: [
                ...provider.MedicalServicesAssociated,
                action.ProviderMedicalService
              ]
            }
          ]
          : state.list
      };
    case 'PROVIDER_PRICE_LIST_RECEIVE_ALL_ACTION':
      var provider = state.list.find(
        (provider: Provider) => provider.id === action.ProviderId
      );

      return {
        ...state,
        list: provider
          ? [
            ...state.list.filter(
              (provider: Provider) => provider.id !== action.ProviderId
            ),
            {
              ...provider,
              PriceListAssociated: action.ProviderPriceLists
            }
          ]
          : state.list
      };
    case 'PROVIDER_PRICE_LIST_DELETE_BY_PRICE_LIST_ID_RECEIVE_ACTION':
      var provider = state.list.find(
        (provider: Provider) => provider.id === action.ProviderId
      );

      return {
        ...state,
        list: provider
          ? [
            ...state.list.filter(
              (provider: Provider) => provider.id !== action.ProviderId
            ),
            {
              ...provider,
              PriceListAssociated: [
                ...provider.PriceListAssociated.filter(
                  (priceList: ProviderPriceList) =>
                    priceList.priceListId !== action.PriceListId
                )
              ]
            }
          ]
          : state.list
      };
    case 'PROVIDER_PRICE_LIST_ADD_RECEIVE_ACTION':

      var provider = state.list.find(
        (provider: Provider) => provider.id === action.ProviderPriceList.providerId
      );

      if (provider !== undefined) {
        provider.PriceListAssociated.push(action.ProviderPriceList);
        alert(provider.PriceListAssociated.length);
      }

      return {
        ...state,
        list: provider
          ? [
            ...state.list.filter(
              (provider: Provider) =>
                provider.id !== action.ProviderPriceList.providerId
            ),
            provider
          ]
          : state.list
      };
    case 'PROVIDER_PRICE_LIST_CLONE_RECEIVE_ACTION':
      var provider = state.list.find(
        (provider: Provider) => provider.id === action.ProviderPriceList.providerId
      );

      return {
        ...state,
        list: provider
          ? [
            ...state.list.filter(
              (provider: Provider) =>
                provider.id !== action.ProviderPriceList.providerId
            ),
            {
              ...provider,
              PriceListAssociated: [
                ...provider.PriceListAssociated,
                action.ProviderPriceList
              ]
            }
          ]
          : state.list
      };
    case 'PROVIDER_PRICE_LIST_DELETE_RECEIVE_ACTION':
      var provider = state.list.find(
        (provider: Provider) => provider.id === action.ProviderId
      );

      return {
        ...state,
        list: provider
          ? [
            ...state.list.filter(
              (provider: Provider) => provider.id !== action.ProviderId
            ),
            {
              ...provider,
              PriceListAssociated: [
                ...provider.PriceListAssociated.filter(
                  (priceList: ProviderPriceList) =>
                    priceList.id !== action.ProviderPriceListId
                )
              ]
            }
          ]
          : state.list
      };
    case 'PROVIDER_PRICE_LIST_SET_START_VALIDITY_RECEIVE_ACTION':

      try {
        var providerStartDate = state.list?.find(
          (provider: Provider) => provider.id === action.ProviderPriceList.providerId
        );

        console.log('provider', providerStartDate);

        if (providerStartDate !== undefined) {
          providerStartDate.PriceListAssociated.find(
            (priceList: ProviderPriceList) => priceList.id === action.ProviderPriceList.id
          )!.effectiveDateStart = action.ProviderPriceList.effectiveDateStart;
        }

        console.log('provider', providerStartDate);

        return {
          ...state,
          list: providerStartDate
            ? [
              ...state.list.filter(
                (provider: Provider) =>
                  provider.id !== action.ProviderPriceList.providerId
              ),
              providerStartDate
            ]
            : state.list
        };
      } catch (error) {
        console.log('error', error);
        return state;
      }

    case 'PROVIDER_PRICE_LIST_SET_END_VALIDITY_RECEIVE_ACTION':
      var providerEndDate = state.list.find(
        (provider: Provider) => provider.id === action.ProviderPriceList.providerId
      );

      if (providerEndDate !== undefined) {
        providerEndDate.PriceListAssociated.find(
          (priceList: ProviderPriceList) => priceList.id === action.ProviderPriceList.id
        )!.effectiveDateEnd = action.ProviderPriceList.effectiveDateEnd;
      }

      return {
        ...state,
        list: providerEndDate
          ? [
            ...state.list.filter(
              (provider: Provider) =>
                provider.id !== action.ProviderPriceList.providerId
            ),
            providerEndDate
          ]
          : state.list
      };
    // protocol consumption
    case 'PROVIDER_CONSUMPTION_GETALL_REQUEST_ACTION':
      return {
        ...state,
        isLoadingConsumptions: true,
        LoadingConsumptionsSuccess: undefined,
        FailGettingConsumptions: false,
        ErrorLoadingConsumptions: undefined
      };
    case 'PROVIDER_CONSUMPTION_GETALL_RECEIVE_ACTION':

      var provider = state.list.find(
        (provider: Provider) => provider.id === action.ProviderId
      );

      if (provider !== undefined) {
        provider.consumptions = action.consumptions;
      }

      return {
        ...state,
        list: provider
          ? [
            ...state.list.filter(
              (provider: Provider) => provider.id !== action.ProviderId
            ),
            provider
          ]
          : state.list,
        isLoadingConsumptions: false,
        LoadingConsumptionsSuccess: true,
        FailGettingConsumptions: false,
        ErrorLoadingConsumptions: undefined
      };
    case 'PROVIDER_CONSUMPTION_GETALL_FAIL_ACTION':
      return {
        ...state,
        isLoadingConsumptions: false,
        LoadingConsumptionsSuccess: false,
        FailGettingConsumptions: true,
        ErrorLoadingConsumptions: action.error
      };
    default:
      return state;
  }
};
