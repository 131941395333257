import { IBasePickerSuggestionsProps, ITag } from '@fluentui/react';

//models
import { Contract } from "../../../../models/Entities/Contracts/Contract";
import { ContractStage } from '../../../../models/Entities/Contracts/ContractStage';
import { ErrorType } from "../../../../models/HttpError";

// stores
import * as ContractStageStore from '../../../../redux/middleware/ContractStageMiddleware';

// common functions
import * as Functions from '../../../../functions/common'

//State
import { AppState } from "../../../../redux/reducers";



interface IProps {
  ContractId: number;
  onSelectedContractStage: (stage: ContractStage[]) => void;
  ContractStagesSelected: number[] | undefined;
  MultiselectEnable: boolean;
  noteText?: string;
}

interface ConnectedProps {
  isLoading: boolean;
  isUpdating: boolean;
  isUpdatedSuccessfully: boolean;
  isLoadingSuccessfully: boolean;
  failOnUpdating: boolean;
  failOnLoading: boolean;
  error: ErrorType | undefined;
  Contract: Contract | undefined;
  Stages: ContractStage[];
}

interface DispatchProps {
    GetContractStageById: typeof ContractStageStore.actionCreators.GetContractStageById;
    GetAllContractsStage: typeof ContractStageStore.actionCreators.GetAllContractsStage;
}

export type Props = IProps & ConnectedProps & DispatchProps;

/*
isLoading={false} 
isUpdating={false} 
isUpdatedSuccessfully={false} 
isLoadingSuccessfully={false} 
failOnUpdating={false} 
failOnLoading={false} 
error={undefined} 
Contract={undefined} 
ContractConcepts={[]} 
GetContractConceptById={undefined} 
GetAllContractsConcept={undefined}  
*/

export const mapStateToProps = (state: AppState, ownProps: IProps) => ({
  isLoading: state.ContractConcept?.isLoadingAll,
  isUpdating: state.ContractConcept?.isLoadingAll,
  isUpdatedSuccessfully: state.ContractConcept?.successLoadingAll,
  isLoadingSuccessfully: state.ContractConcept?.successLoadingAll,
  failOnUpdating: state.ContractConcept?.failOnLoadingAll,
  failOnLoading: state.ContractConcept?.failOnLoadingAll,
  error: state.ContractConcept?.error,
  Contract: ownProps.ContractId ? state.Contract?.list?.find((item: Contract) => item.id === ownProps.ContractId) : undefined,
  Stages: [...state.Contract?.list?.find((item: Contract) => item.id === ownProps.ContractId)?.stages?.sort(Functions.DynamicSortNumeric('id')) || [], {
    id: 0,
    stageName: 'ExtraProtocolVisit'
  }]
});

export const mapDispatchToProps = {
    ...ContractStageStore.actionCreators 
};

export const GetContractStageOptions = (concepts: ContractStage[]): ITag[] => {
  return concepts?.map((item: ContractStage) => {
    return {
      key: item.id,
      name: item.stageName
    };
  }) as ITag[];
};

export const listContainsTagList = (tag: ITag, tagList?: ITag[]) => {
  if (!tagList?.length) {
    return false;
  }
  return tagList.some((compareTag) => compareTag.key === tag.key);
};

export const GetContractStageById = (
  contractStageId: number, stages: ContractStage[]
): ContractStage => {
  const ContractStage: ContractStage = stages.find(
    (item: ContractStage) => item.id === contractStageId
  ) as ContractStage;
  return ContractStage;
};

export function GetSelectedContractStage(
  contractConceptsIds: number[], concepts: ContractStage[]
): ITag[] {

  const contractStages: ITag[] = [];

  contractConceptsIds.forEach((id) => {
    const currentStage = concepts.find((stage: ContractStage) => stage.id === id);

    if (currentStage) {
        contractStages.push({
        key: currentStage.id,
        name: currentStage.stageName
      });
    };

  });

  return contractStages;
}

export const getTextFromItem = (item: ITag) => item.name;

export const pickerSuggestionsProps: IBasePickerSuggestionsProps = {
  suggestionsHeaderText: 'conceptos sugeridos',
  noResultsFoundText: 'No se encontro concepto'
};
