import { ProtocolConsumption } from "../models/Entities/Protocols/ProtocolConsumption";
import { IResult } from "../models/HttpResult";

// Functions
import * as Functions from '../functions/common';

// models
import { ProtocolConsumptionTotals } from "../models/Entities/Protocols/ProtocolsConsumptionTotal";
import { ProtocolConsumptionTotal } from "../models/Entities/Protocols/ProtocolConsumptionTotal";
import { NewProtocolConsumption } from "../models/Entities/Protocols/NewProtocolConsumption";

// Base URL
const BaseURL = process.env.REACT_APP_BILLER_API_GATEWAY + 'protocols/protocols/';

// add method to get all protocol consumptions
export const GetAllProtocolConsumptionByProtocol = async (
    protocolId: number,
    token: string
): Promise<ProtocolConsumption[]> => {
    let RequestURL: string = BaseURL + protocolId + '/consumptions';
    try {
        const Response: ProtocolConsumption[] = await fetch(RequestURL, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            },
            method: 'GET'
        })
        .then((response) => {
            if (!response.ok) {
            throw response;
            } else {
            return response.json() as Promise<IResult<ProtocolConsumption[]>>;
            }
        })
        .then((data: IResult<ProtocolConsumption[]>) => {
            return data.data;
        })
        .catch((error: any) =>
            error.text().then((body: any) => {
            throw Functions.HttpErrorHandler(body, error);
            })
        );

        return Response;
    } catch (error: any) {
        throw error;
    }
};

export const GetAllProtocolConsumptionByProvider = async (
    providerId: string,
    token: string
): Promise<ProtocolConsumption[]> => {

    // https://prebiller.hc.alpha-cr.com/apis/prebiller-gateway/protocols/protocols/consumptions/provider/f0c90773-eacf-40fe-9643-e7639cd9b2d5

    let RequestURL: string = BaseURL + 'consumptions/provider/' + providerId;
    try {
        const Response: ProtocolConsumption[] = await fetch(RequestURL, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            },
            method: 'GET'
        })
        .then((response) => {
            if (!response.ok) {
            throw response;
            } else {
            return response.json() as Promise<IResult<ProtocolConsumption[]>>;
            }
        })
        .then((data: IResult<ProtocolConsumption[]>) => {
            return data.data;
        })
        .catch((error: any) =>
            error.text().then((body: any) => {
            throw Functions.HttpErrorHandler(body, error);
            })
        );

        return Response;
    } catch (error: any) {
        throw error;
    }
};

// get protocol consumption by id
export const GetProtocolConsumptionById = async (
    token: string,
    id: string
): Promise<ProtocolConsumption> => {
    let RequestURL: string = BaseURL + '/consumptions/' + id;
    try {
        const Response: ProtocolConsumption = await fetch(RequestURL, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
        },
        method: 'GET'
        })
        .then((response) => {
            if (!response.ok) {
            throw response;
            } else {
            return response.json() as Promise<IResult<ProtocolConsumption>>;
            }
        })
        .then((data: IResult<ProtocolConsumption>) => {
            return data.data;
        })
        .catch((error: any) =>
            error.text().then((body: any) => {
            throw Functions.HttpErrorHandler(body, error);
            })
        );

        return Response;
    } catch (error: any) {
        throw error;
    }
};

// get totals by status
export const GetProtocolConsumptionTotals = async (
    protocolId: number,
    status: number,
    token: string
): Promise<ProtocolConsumptionTotal[]> => {
    let RequestURL: string = BaseURL + protocolId + '/consumptions/totals?status=' + status;
    try {
        const Response: ProtocolConsumptionTotal[] = await fetch(RequestURL, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
        },
        method: 'GET'
        })
        .then((response) => {
            if (!response.ok) {
            throw response;
            } else {
            return response.json() as Promise<IResult<ProtocolConsumptionTotal[]>>;
            }
        })
        .then((data: IResult<ProtocolConsumptionTotals[]>) => {
            return data.data;
        })
        .catch((error: any) =>
            error.text().then((body: any) => {
            throw Functions.HttpErrorHandler(body, error);
            })
        );

        return Response;
    } catch (error: any) {
        throw error;
    }
};

// create protocol consumption
export const CreateProtocolConsumption = async (
    token: string,
    protocolConsumption: NewProtocolConsumption
): Promise<ProtocolConsumption> => {
    let RequestURL: string = BaseURL + 'consumptions/';
    try {
        const Response: ProtocolConsumption = await fetch(RequestURL, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
        },
        method: 'POST',
        body: JSON.stringify(protocolConsumption)
        })
        .then((response) => {
            if (!response.ok) {
            throw response;
            } else {
            return response.json() as Promise<IResult<ProtocolConsumption>>;
            }
        })
        .then((data: IResult<ProtocolConsumption>) => {
            return data.data;
        })
        .catch((error: any) =>
            error.text().then((body: any) => {
            throw Functions.HttpErrorHandler(body, error);
            })
        );

        return Response;
    } catch (error: any) {
        throw error;
    }
};

// update protocol consumption
export const UpdateProtocolConsumption = async (
    token: string,
    protocolConsumption: ProtocolConsumption
): Promise<ProtocolConsumption> => {
    let RequestURL: string = BaseURL + '/consumptions/';
    try {
        const Response: ProtocolConsumption = await fetch(RequestURL, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
        },
        method: 'PUT',
        body: JSON.stringify(protocolConsumption)
        })
        .then((response) => {
            if (!response.ok) {
            throw response;
            } else {
            return response.json() as Promise<IResult<ProtocolConsumption>>;
            }
        })
        .then((data: IResult<ProtocolConsumption>) => {
            return data.data;
        })
        .catch((error: any) =>
            error.text().then((body: any) => {
            throw Functions.HttpErrorHandler(body, error);
            })
        );

        return Response;
    } catch (error: any) {
        throw error;
    }
};

// delete protocol consumption
export const DeleteProtocolConsumption = async (
    token: string,
    id: string
): Promise<ProtocolConsumption> => {
    let RequestURL: string = BaseURL + 'consumptions/' + id;
    try {
        const Response: ProtocolConsumption = await fetch(RequestURL, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
        },
        method: 'DELETE'
        })
        .then((response) => {
            if (!response.ok) {
            throw response;
            } else {
            return response.json() as Promise<IResult<ProtocolConsumption>>;
            }
        })
        .then((data: IResult<ProtocolConsumption>) => {
            return data.data;
        })
        .catch((error: any) =>
            error.text().then((body: any) => {
            throw Functions.HttpErrorHandler(body, error);
            })
        );

        return Response;
    } catch (error: any) {
        throw error;
    }
};

// change protocol consumption status
export const ChangeProtocolConsumptionStatus = async (
    token: string,
    protocolId: number,
    consumptionId: string,
    status: number,
    applyToAll: boolean
): Promise<ProtocolConsumption> => {
    let RequestURL: string = BaseURL + 'consumptions/' + 
    consumptionId + '?newStatus=' + status + '&applyToAll=' + applyToAll;
    try {
        const Response: ProtocolConsumption = await fetch(RequestURL, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
        },
        method: 'PATCH'
        })
        .then((response) => {
            if (!response.ok) {
            throw response;
            } else {
            return response.json() as Promise<IResult<ProtocolConsumption>>;
            }
        })
        .then((data: IResult<ProtocolConsumption>) => {
            return data.data;
        })
        .catch((error: any) =>
            error.text().then((body: any) => {
            throw Functions.HttpErrorHandler(body, error);
            })
        );

        return Response;
    } catch (error: any) {
        throw error;
    }
};

// change protocol consumption concept

export const SetConsumptionConcept = async (
    token: string,
    consumptionId: string,
    conceptId: number,
    changeAll: boolean
): Promise<ProtocolConsumption[]> => {

    /*
    example url
    -> consumption/3fa85f64-5717-4562-b3fc-2c963f66afa6/concept/12?ChangeAll=false
    */
    let RequestURL: string = BaseURL + 'consumptions/' + consumptionId + '/concept/' + conceptId + '?ChangeAll=' + changeAll;
    try {
        const Response: ProtocolConsumption[] = await fetch(RequestURL, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
        },
        method: 'PATCH'
        })
        .then((response) => {
            if (!response.ok) {
            throw response;
            } else {
            return response.json() as Promise<IResult<ProtocolConsumption[]>>;
            }
        })
        .then((data: IResult<ProtocolConsumption[]>) => {
            return data.data;
        })
        .catch((error: any) =>
            error.text().then((body: any) => {
            throw Functions.HttpErrorHandler(body, error);
            })
        );

        return Response;
    } catch (error: any) {
        throw error;
    }
}

// change protocol consumption producer 

export const SetConsumptionProducer = async (
    token: string,
    consumptionId: string,
    producerId: string
): Promise<ProtocolConsumption[]> => {

    /*
    example url
    -> consumption/3fa85f64-5717-4562-b3fc-2c963f66afa6/producer/12
    */
    let RequestURL: string = BaseURL + 'consumptions/' + consumptionId + '/producer/' + producerId;
    try {
        const Response: ProtocolConsumption[] = await fetch(RequestURL, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
        },
        method: 'PATCH'
        })
        .then((response) => {
            if (!response.ok) {
            throw response;
            } else {
            return response.json() as Promise<IResult<ProtocolConsumption[]>>;
            }
        })
        .then((data: IResult<ProtocolConsumption[]>) => {
            return data.data;
        })
        .catch((error: any) =>
            error.text().then((body: any) => {
            throw Functions.HttpErrorHandler(body, error);
            })
        );

        return Response;
    } catch (error: any) {
        throw error;
    }
}

// change protocol consumption provider

export const SetConsumptionProvider = async (
    token: string,
    consumptionId: string,
    providerId: string
): Promise<ProtocolConsumption[]> => {

    /*
    example url
    -> consumption/3fa85f64-5717-4562-b3fc-2c963f66afa6/provider/12
    */
    let RequestURL: string = BaseURL + 'consumptions/' + consumptionId + '/provider/' + providerId;
    try {
        const Response: ProtocolConsumption[] = await fetch(RequestURL, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
        },
        method: 'PATCH'
        })
        .then((response) => {
            if (!response.ok) {
            throw response;
            } else {
            return response.json() as Promise<IResult<ProtocolConsumption[]>>;
            }
        })
        .then((data: IResult<ProtocolConsumption[]>) => {
            return data.data;
        })
        .catch((error: any) =>
            error.text().then((body: any) => {
            throw Functions.HttpErrorHandler(body, error);
            })
        );

        return Response;
    } catch (error: any) {
        throw error;
    }
}

// change protocol consumption medical service

export const SetConsumptionMedicalService = async (
    token: string,
    consumptionId: string,
    serviceId: number
): Promise<ProtocolConsumption[]> => {

    /*
    example url
    -> consumption/3fa85f64-5717-4562-b3fc-2c963f66afa6/service/12
    */
    let RequestURL: string = BaseURL + 'consumptions/' + consumptionId + '/medicalService/' + serviceId;
    try {
        const Response: ProtocolConsumption[] = await fetch(RequestURL, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
        },
        method: 'PATCH'
        })
        .then((response) => {
            if (!response.ok) {
            throw response;
            } else {
            return response.json() as Promise<IResult<ProtocolConsumption[]>>;
            }
        })
        .then((data: IResult<ProtocolConsumption[]>) => {
            return data.data;
        })
        .catch((error: any) =>
            error.text().then((body: any) => {
            throw Functions.HttpErrorHandler(body, error);
            })
        );

        return Response;
    } catch (error: any) {
        throw error;
    }
}