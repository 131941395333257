
// stores 
import * as ProtocolsStore from '../../../redux/middleware/MedicalProtocolMiddleware';
import * as ProtocolConsumptionStore from '../../../redux/middleware/ProtocolConsumptionMiddleware';
import * as ContractsStore from '../../../redux/middleware/ContractMiddleware';
import * as PractitionersStore from "../../../redux/middleware/PractitionerMiddleware";
import * as ProvidersStore from "../../../redux/middleware/ProviderMiddleware";



// state
import { AppState } from '../../../redux/reducers';
import { ProtocolConsumption } from '../../../models/Entities/Protocols/ProtocolConsumption';
import { Protocol } from '../../../models/Entities/Protocols/Protocol';
import { Contract } from '../../../models/Entities/Contracts/Contract';
import { Practitioner } from "../../../models/Entities/Practitioners/Practitioner";
import { Provider } from '../../../models/Entities/Provider/Provider';

//#region Props

interface IProps {
    ProtocolId: number | undefined;
    ParentId: string | undefined;
    ProducerId: string | undefined;
    ProviderId: number | undefined;
}

interface ConnectedProps {
    isLoading: boolean;
    successLoading: boolean;
    error: string | null;

    haveConsumptionsToSettle: boolean;

    Consumptions: ProtocolConsumption[];
    protocol: Protocol | null;
    contract : Contract | null;
    Practitioners: Practitioner[];
    CurrentProvider: Provider | undefined;
    GetPersonByIdentifier: (identifier: string) => Practitioner | undefined;
    GetProtocolById: (protocolId: number) => Protocol | undefined;
}

interface MedicalServicesDispatchProps {
    GetMedicalProtocolById: typeof ProtocolsStore.actionCreators.GetMedicalProtocolById;
    GetAllMedicalProtocols: typeof ProtocolsStore.actionCreators.GetAllMedicalProtocols;
    GetAllProtocolConsumptionsByProtocol: typeof ProtocolConsumptionStore.ActionCreators.GetAllProtocolConsumptionsByProtocol;
    DeleteProtocolConsumption: typeof ProtocolConsumptionStore.ActionCreators.DeleteProtocolConsumption;
    GetProviderConsumptions: typeof ProvidersStore.actionCreators.GetProviderConsumptions;
    GetAllProtocolContractAssociations: typeof ProtocolsStore.actionCreators.GetAllProtocolContractAssociations;
    GetContractById: typeof ContractsStore.actionCreators.GetContractById;
    GetAllPractitioners: typeof PractitionersStore.actionCreators.GetAllPractitioners;
    GetProviderById: typeof ProvidersStore.actionCreators.GetProviderById;
}

export type Props = IProps & ConnectedProps & MedicalServicesDispatchProps;

export const mapStateToProps = (state: AppState, ownProps: IProps) => {
    
    const practitioners = state.Practitioner?.list || [];

    const AuxProtocol: Protocol | null =  state.MedicalProtocols?.list?.find((protocol: Protocol) => protocol.id === ownProps.ProtocolId) ?? null;

    const AuxProvider: Provider | undefined = state.Provider?.list?.find((provider: Provider) => provider.id === ownProps.ProviderId) ?? undefined;

    // get first contract associated from protocol
    const AuxContractId = AuxProtocol?.contractAssociation?.contractId || null;

    const AuxContract: Contract | null = state.Contract?.list?.find((contract: Contract) => contract.id === AuxContractId) ?? null;

    const AuxPractitioners = state.Practitioner?.list || [];

    let AuxConsumptions: ProtocolConsumption[] = ownProps.ProviderId ? AuxProvider?.consumptions ?? [] : AuxProtocol?.consumptions ?? [];

    console.log("consumption", AuxConsumptions.length);

    if(ownProps.ParentId !== undefined)  {
        AuxConsumptions = AuxConsumptions.filter((consumption: ProtocolConsumption) => consumption.parentInstanceId === ownProps.ParentId);
    }

    return {
        isLoading: state.MedicalProtocols?.isLoadingAllConsumptions || false,
        successLoading: state.MedicalProtocols?.LoadingAllConsumptionsSuccess || undefined,
        error: state.MedicalProtocols?.ErrorGettingAllConsumptions || null,
        
        haveConsumptionsToSettle: AuxConsumptions.some((consumption: ProtocolConsumption) => consumption.status === 6),
        
        Consumptions: AuxConsumptions ? AuxConsumptions.sort(
            (a: ProtocolConsumption, b: ProtocolConsumption) => new Date(b.serviceDueDate).getTime() - new Date(a.serviceDueDate).getTime()
        ) : [],
        protocol: AuxProtocol,
        contract: AuxContract,
        Practitioners: AuxPractitioners,
        CurrentProvider: AuxProvider,
        GetPersonByIdentifier: (identifier: string) => GetPersonByIdentifier(practitioners, identifier),
        GetProtocolById: (protocolId: number) => state.MedicalProtocols?.list?.find((protocol: Protocol) => protocol.id === protocolId) || null
    }
};

export const mapDispatchToProps = {
    ...ProtocolsStore.actionCreators,
    ...ProtocolConsumptionStore.ActionCreators,
    ...ContractsStore.actionCreators,
    ...PractitionersStore.actionCreators,
    ...ProvidersStore.actionCreators
};


export const GetStatusDescription = (status: number): {description: string, class: string} => {

    /*
        NotSettled = 0,
        Pending = 1,
        Payee = 2,
        Settled = 3,
        Rejected = 4,
        Ignored = 5,
        ToSettle = 6

     */

    switch (status) {
        case 0:
            return {description: 'Pendiente de liquidación', class: 'status-not-settled'};
        case 1:
            return {description: 'Con saldo pendiente', class: 'status-pending'};
        case 2:
            return {description: 'Pagado', class: 'status-payee'};
        case 3:
            return {description: 'Liquidado', class: 'status-settled'};
        case 4:
            return {description: 'Rechazado', class: 'status-rejected'};
        case 5:
            return {description: 'Ignorado', class: 'status-ignored'};
        case 6:
            return {description: 'Por liquidar', class: 'status-to-settle'};
        default:
            return {description: 'Unknown', class: 'status-unknown'};
    }
}

 // get person by identifier
const GetPersonByIdentifier = (persons: Practitioner[], identifier: string): Practitioner | undefined => {
  return persons.find((person: Practitioner) => person.userId === identifier);
}

export const GetRowClassByStatus = (status: number): string => {
    switch (status) {
        case 0:
            return ''; // 'status-not-settled-row';
        case 1:
            return ''; // 'status-pending-row';
        case 2:
            return 'status-payee-row';
        case 3:
            return 'status-settled-row';
        case 4:
            return ''; // 'status-rejected-row';
        case 5:
            return 'status-ignored-row';
        case 6:
            return 'status-to-settle-row';
        default:
            return ''; // 'status-unknown-row';
    }
}