import { AppThunkAction } from '../reducers';

// Actions
import * as Actions from '../actions';

// Services
import * as service from '../../services/ProtocolConsumptions.Service';

// Models
import { ProtocolConsumption } from '../../models/Entities/Protocols/ProtocolConsumption';
import { ErrorType } from '../../models/HttpError';
import { ProtocolConsumptionTotal } from '../../models/Entities/Protocols/ProtocolConsumptionTotal';
import { NewProtocolConsumption } from '../../models/Entities/Protocols/NewProtocolConsumption';

type KnownAction =
    | Actions.ProtocolConsumptions.ProtocolConsumptionGetAllByProtocolRequestAction
    | Actions.ProtocolConsumptions.ProtocolConsumptionGetAllByProtocolSuccessAction
    | Actions.ProtocolConsumptions.ProtocolConsumptionGetAllByProtocolFailureAction
    | Actions.ProtocolConsumptions.ProtocolConsumptionGetByIdRequestAction
    | Actions.ProtocolConsumptions.ProtocolConsumptionGetByIdSuccessAction
    | Actions.ProtocolConsumptions.ProtocolConsumptionGetByIdFailureAction
    | Actions.ProtocolConsumptions.ProtocolConsumptionCreateRequestAction
    | Actions.ProtocolConsumptions.ProtocolConsumptionCreateSuccessAction
    | Actions.ProtocolConsumptions.ProtocolConsumptionCreateFailureAction
    | Actions.ProtocolConsumptions.ProtocolConsumptionUpdateRequestAction
    | Actions.ProtocolConsumptions.ProtocolConsumptionUpdateSuccessAction
    | Actions.ProtocolConsumptions.ProtocolConsumptionUpdateFailureAction
    | Actions.ProtocolConsumptions.ProtocolConsumptionDeleteRequestAction
    | Actions.ProtocolConsumptions.ProtocolConsumptionDeleteSuccessAction
    | Actions.ProtocolConsumptions.ProtocolConsumptionDeleteFailureAction
    | Actions.ProtocolConsumptions.ProtocolConsumptionGetTotalByStatusRequestAction
    | Actions.ProtocolConsumptions.ProtocolConsumptionGetTotalByStatusSuccessAction
    | Actions.ProtocolConsumptions.ProtocolConsumptionGetTotalByStatusFailureAction
    | Actions.ProtocolConsumptions.ProtocolConsumptionChangeStatusRequestAction
    | Actions.ProtocolConsumptions.ProtocolConsumptionChangeStatusSuccessAction
    | Actions.ProtocolConsumptions.ProtocolConsumptionChangeStatusFailureAction
    // change consumption contract concept association
    | Actions.ProtocolConsumptions.ProtocolConsumptionChangeContractConceptRequestAction
    | Actions.ProtocolConsumptions.ProtocolConsumptionChangeContractConceptSuccessAction
    | Actions.ProtocolConsumptions.ProtocolConsumptionChangeContractConceptFailureAction
    // change producer
    | Actions.ProtocolConsumptions.ProtocolConsumptionChangeProducerRequestAction
    | Actions.ProtocolConsumptions.ProtocolConsumptionChangeProducerSuccessAction
    | Actions.ProtocolConsumptions.ProtocolConsumptionChangeProducerFailureAction
    // change provider
    | Actions.ProtocolConsumptions.ProtocolConsumptionChangeProviderRequestAction
    | Actions.ProtocolConsumptions.ProtocolConsumptionChangeProviderSuccessAction
    | Actions.ProtocolConsumptions.ProtocolConsumptionChangeProviderFailureAction
    // change medical service
    | Actions.ProtocolConsumptions.ProtocolConsumptionChangeMedicalServiceRequestAction
    | Actions.ProtocolConsumptions.ProtocolConsumptionChangeMedicalServiceSuccessAction
    | Actions.ProtocolConsumptions.ProtocolConsumptionChangeMedicalServiceFailureAction;

export const ActionCreators = {
    GetAllProtocolConsumptionsByProtocol:
        (protocolId: number): AppThunkAction<KnownAction> =>
            (dispatch, getState) => {
                const appState = getState();
                var token: string = '';

                if (appState.oidc?.user?.access_token === undefined) {
                    dispatch({
                        type: 'PROTOCOLCONSUMPTION_GETALL_BYPROTOCOL_FAILURE',
                        protocolId: protocolId,
                        error: {
                            ErrorCode: 401,
                            ErrorMessage: 'Not authorized',
                            Errors: []
                        }
                    });
                    return;
                } else {
                    token = appState.oidc.user.access_token;
                };

                if (appState) {
                    service.GetAllProtocolConsumptionByProtocol(protocolId, token)
                        .then((list: ProtocolConsumption[]) => {
                            dispatch({
                                type: 'PROTOCOLCONSUMPTION_GETALL_BYPROTOCOL_SUCCESS',
                                protocolId: protocolId,
                                consumptions: list
                                
                            });
                        })
                        .catch((error: ErrorType) => {
                            dispatch({
                                type: 'PROTOCOLCONSUMPTION_GETALL_BYPROTOCOL_FAILURE',
                                protocolId: protocolId,
                                error: error
                            });
                        });
                }
            },
    GetProtocolConsumptionById:
        (
            protocolId: number, 
            consumptionId: string
        ): AppThunkAction<KnownAction> =>
        (dispatch, getState) => {
            const appState = getState();
            var token: string = '';

            if (appState.oidc?.user?.access_token === undefined) {
                dispatch({
                    type: 'PROTOCOLCONSUMPTION_GET_BYID_FAILURE',
                    consumptionId: consumptionId,
                    protocolId: 0,

                    error: {
                        ErrorCode: 401,
                        ErrorMessage: 'Not authorized',
                        Errors: []
                    }
                });
                return;
            } else {
                token = appState.oidc.user.access_token;
            };

            if (appState) {
                dispatch({
                    type: 'PROTOCOLCONSUMPTION_GET_BYID_REQUEST',
                    protocolId: protocolId,	
                    consumptionId: consumptionId
                });

                service.GetProtocolConsumptionById(consumptionId, token)
                .then((consumption: ProtocolConsumption) => {
                    dispatch({
                        type: 'PROTOCOLCONSUMPTION_GET_BYID_SUCCESS',
                        consumption: consumption
                    });
                })
                .catch((error: ErrorType) => {
                        dispatch({
                            type: 'PROTOCOLCONSUMPTION_GET_BYID_FAILURE',
                            protocolId: protocolId,
                            consumptionId: consumptionId,
                            error: error
                        });
                    }
                );
            }
        },
    CreateProtocolConsumption:
        (
            consumption: NewProtocolConsumption
        ): AppThunkAction<KnownAction> =>
        (dispatch, getState) => {
            const appState = getState();
            var token: string = '';

            if (appState.oidc?.user?.access_token === undefined) {
                dispatch({
                    type: 'PROTOCOLCONSUMPTION_CREATE_FAILURE',
                    error: {
                        ErrorCode: 401,
                        ErrorMessage: 'Not authorized',
                        Errors: []
                    }
                });
                return;
            } else {
                token = appState.oidc.user.access_token;
            };

            if (appState) {
                dispatch({
                    type: 'PROTOCOLCONSUMPTION_CREATE_REQUEST',
                    consumption: consumption
                });

                service.CreateProtocolConsumption(token, consumption)
                .then((consumption: ProtocolConsumption) => {
                    dispatch({
                        type: 'PROTOCOLCONSUMPTION_CREATE_SUCCESS',
                        consumption: consumption
                    });
                })
                .catch((error: ErrorType) => {
                    dispatch({
                        type: 'PROTOCOLCONSUMPTION_CREATE_FAILURE',
                        error: error
                    });
                });
            }
        },
    UpdateProtocolConsumption:
        (
            consumption: ProtocolConsumption
        ): AppThunkAction<KnownAction> =>
        (dispatch, getState) => {
            const appState = getState();
            let token: string = '';

            if (appState.oidc?.user?.access_token === undefined) {
                dispatch({
                    type: 'PROTOCOLCONSUMPTION_UPDATE_FAILURE',
                    protocolId: consumption.protocolId,
                    consumptionId: consumption.id,
                    error: {
                        ErrorCode: 401,
                        ErrorMessage: 'Not authorized',
                        Errors: []
                    }
                });
                return;
            } else {
                token = appState.oidc.user.access_token;
            };

            if (appState) {
                dispatch({
                    type: 'PROTOCOLCONSUMPTION_UPDATE_REQUEST',
                    consumption: consumption
                });

                service.UpdateProtocolConsumption(token, consumption)
                .then((consumption: ProtocolConsumption) => {
                    dispatch({
                        type: 'PROTOCOLCONSUMPTION_UPDATE_SUCCESS',
                        consumption: consumption
                    });
                })
                .catch((error: ErrorType) => {
                    dispatch({
                        type: 'PROTOCOLCONSUMPTION_UPDATE_FAILURE',
                        protocolId: consumption.protocolId,
                        consumptionId: consumption.id,
                        error: error
                    });
                });
            }
        },
    DeleteProtocolConsumption:
        (
            protocolId: number,
            consumptionId: string
        ): AppThunkAction<KnownAction> =>
        (dispatch, getState) => {
            const appState = getState();
            var token: string = '';

            if (appState.oidc?.user?.access_token === undefined) {
                dispatch({
                    type: 'PROTOCOLCONSUMPTION_DELETE_FAILURE',
                    protocolId: protocolId,
                    consumptionId: consumptionId,
                    error: {
                        ErrorCode: 401,
                        ErrorMessage: 'Not authorized',
                        Errors: []
                    }
                });
                return;
            } else {
                token = appState.oidc.user.access_token;
            };

            if (appState) {
                dispatch({
                    type: 'PROTOCOLCONSUMPTION_DELETE_REQUEST',
                    protocolId: protocolId,
                    consumptionId: consumptionId
                });

                service.DeleteProtocolConsumption(token, consumptionId)
                .then(() => {
                    dispatch({
                        type: 'PROTOCOLCONSUMPTION_DELETE_SUCCESS',
                        protocolId: protocolId,
                        consumptionId: consumptionId
                    });
                })
                .catch((error: ErrorType) => {
                    dispatch({
                        type: 'PROTOCOLCONSUMPTION_DELETE_FAILURE',
                        protocolId: protocolId,
                        consumptionId: consumptionId,
                        error: error
                    });
                });
            }
    },
    GetTotalsByStatus:
        (
            protocolId: number,
            status: number
        ): AppThunkAction<KnownAction> =>
        (dispatch, getState) => {
            const appState = getState();
            var token: string = '';

            if (appState.oidc?.user?.access_token === undefined) {
                dispatch({
                    type: 'PROTOCOLCONSUMPTION_GET_TOTAL_BYSTATUS_FAILURE',
                    protocolId: protocolId,
                    status: status,
                    error: {
                        ErrorCode: 401,
                        ErrorMessage: 'Not authorized',
                        Errors: []
                    }
                });
                return;
            } else {
                token = appState.oidc.user.access_token;
            };

            if (appState) {
                dispatch({
                    type: 'PROTOCOLCONSUMPTION_GET_TOTAL_BYSTATUS_REQUEST',
                    status: status,
                    protocolId: protocolId
                });

                service.GetProtocolConsumptionTotals(protocolId, status, token)
                .then((totals: ProtocolConsumptionTotal[]) => {
                    var auxTotals = totals?.map((total: ProtocolConsumptionTotal) => {
                        return {
                            status: status,
                            currencyCode: total.currencyCode,
                            totalAmount: total.totalAmount
                        };
                    });

                    dispatch({
                        type: 'PROTOCOLCONSUMPTION_GET_TOTAL_BYSTATUS_SUCCESS',
                        protocolId: protocolId,
                        status: status,
                        total: auxTotals
                    });
                })
                .catch((error: ErrorType) => {
                    dispatch({
                        type: 'PROTOCOLCONSUMPTION_GET_TOTAL_BYSTATUS_FAILURE',
                        protocolId: protocolId,
                        status: status,
                        error: error
                    });
                });
            }
        },
    // Change status
    ChangeStatus:
        (
            protocolId: number,
            consumptionId: string,
            status: number,
            isForAll: boolean
        ): AppThunkAction<KnownAction> =>
        (dispatch, getState) => {
            const appState = getState();
            var token: string = '';

            if (appState.oidc?.user?.access_token === undefined) {
                dispatch({
                    type: 'PROTOCOLCONSUMPTION_CHANGE_STATUS_FAILURE',
                    protocolId: protocolId,
                    status: status,
                    consumptionId: consumptionId,
                    error: {
                        ErrorCode: 401,
                        ErrorMessage: 'Not authorized',
                        Errors: []
                    }
                });
                return;
            } else {
                token = appState.oidc.user.access_token;
            };

            if (appState) {
                dispatch({
                    type: 'PROTOCOLCONSUMPTION_CHANGE_STATUS_REQUEST',
                    protocolId: protocolId,
                    consumptionId: consumptionId,
                    status: status,
                    isForAll: isForAll
                });

                service.ChangeProtocolConsumptionStatus(token, protocolId, consumptionId, status, isForAll)
                .then(() => {
                    dispatch({
                        type: 'PROTOCOLCONSUMPTION_CHANGE_STATUS_SUCCESS',
                        protocolId: protocolId,
                        consumptionId: consumptionId,
                        status: status,
                        isForAll: isForAll
                    });
                })
                .catch((error: ErrorType) => {
                    dispatch({
                        type: 'PROTOCOLCONSUMPTION_CHANGE_STATUS_FAILURE',
                        protocolId: protocolId,
                        status: status,
                        consumptionId: consumptionId,
                        error: error
                    });
                });
            }
        },
    // change consumption contract concept association
    ChangeContractConcept:
        (
            protocolId: number,
            consumptionId: string,
            contractConceptId: number,
            changeAll: boolean
        ): AppThunkAction<KnownAction> =>
        (dispatch, getState) => {
            const appState = getState();
            let token: string = '';

            if (appState.oidc?.user?.access_token === undefined) {
                dispatch({
                    type: 'PROTOCOLCONSUMPTION_CHANGE_CONTRACTCONCEPT_FAILURE',
                    protocolId: protocolId,
                    consumptionId: consumptionId,
                    contractConceptId: contractConceptId,
                    error: {
                        ErrorCode: 401,
                        ErrorMessage: 'Not authorized',
                        Errors: []
                    }
                });
                return;
            } else {
                token = appState.oidc.user.access_token;
            };

            if (appState) {
                dispatch({
                    type: 'PROTOCOLCONSUMPTION_CHANGE_CONTRACTCONCEPT_REQUEST',
                    protocolId: protocolId,
                    consumptionId: consumptionId,
                    contractConceptId: contractConceptId
                });

                service.SetConsumptionConcept(token, consumptionId, contractConceptId, changeAll)
                .then((data: ProtocolConsumption[]) => {
                    dispatch({
                        type: 'PROTOCOLCONSUMPTION_CHANGE_CONTRACTCONCEPT_SUCCESS',
                        protocolId: protocolId,
                        consumptionId: consumptionId,
                        updatedConsumptions: data,
                        contractConceptId: contractConceptId
                    });
                })
                .catch((error: ErrorType) => {
                    dispatch({
                        type: 'PROTOCOLCONSUMPTION_CHANGE_CONTRACTCONCEPT_FAILURE',
                        protocolId: protocolId,
                        consumptionId: consumptionId,
                        contractConceptId: contractConceptId,
                        error: error
                    });
                });
            }
        },
    // change producer
    ChangeProducer: 
        (
            protocolId: number,
            consumptionId: string,
            producerId: string
        ): AppThunkAction<KnownAction> =>
        (dispatch, getState) => {
            const appState = getState();
            let token: string = '';

            if (appState.oidc?.user?.access_token === undefined) {
                dispatch({
                    type: 'PROTOCOLCONSUMPTION_CHANGE_PRODUCER_FAILURE',
                    protocolId: protocolId,
                    consumptionId: consumptionId,
                    producerId: producerId,
                    error: {
                        ErrorCode: 401,
                        ErrorMessage: 'Not authorized',
                        Errors: []
                    }
                });
                return;
            } else {
                token = appState.oidc.user.access_token;
            };

            if (appState) {
                dispatch({
                    type: 'PROTOCOLCONSUMPTION_CHANGE_PRODUCER_REQUEST',
                    protocolId: protocolId,
                    consumptionId: consumptionId,
                    producerId: producerId
                });

                service.SetConsumptionProducer(token, consumptionId, producerId)
                .then(() => {
                    dispatch({
                        type: 'PROTOCOLCONSUMPTION_CHANGE_PRODUCER_SUCCESS',
                        protocolId: protocolId,
                        consumptionId: consumptionId,
                        producerId: producerId,
                        
                    });
                })
                .catch((error: ErrorType) => {
                    dispatch({
                        type: 'PROTOCOLCONSUMPTION_CHANGE_PRODUCER_FAILURE',
                        protocolId: protocolId,
                        consumptionId: consumptionId,
                        producerId: producerId,
                        error: error
                    });
                });
            }
        },
    // change provider
    ChangeProvider: 
        (
            protocolId: number,
            consumptionId: string,
            providerId: string
        ): AppThunkAction<KnownAction> =>
        (dispatch, getState) => {
            const appState = getState();
            let token: string = '';

            if (appState.oidc?.user?.access_token === undefined) {
                dispatch({
                    type: 'PROTOCOLCONSUMPTION_CHANGE_PROVIDER_FAILURE',
                    protocolId: protocolId,
                    consumptionId: consumptionId,
                    providerId: providerId,
                    error: {
                        ErrorCode: 401,
                        ErrorMessage: 'Not authorized',
                        Errors: []
                    }
                });
                return;
            } else {
                token = appState.oidc.user.access_token;
            };

            if (appState) {
                dispatch({
                    type: 'PROTOCOLCONSUMPTION_CHANGE_PROVIDER_REQUEST',
                    protocolId: protocolId,
                    consumptionId: consumptionId,
                    providerId: providerId
                });

                service.SetConsumptionProvider(token, consumptionId, providerId)
                .then(() => {
                    dispatch({
                        type: 'PROTOCOLCONSUMPTION_CHANGE_PROVIDER_SUCCESS',
                        protocolId: protocolId,
                        consumptionId: consumptionId,
                        providerId: providerId
                    });
                })
                .catch((error: ErrorType) => {
                    dispatch({
                        type: 'PROTOCOLCONSUMPTION_CHANGE_PROVIDER_FAILURE',
                        protocolId: protocolId,
                        consumptionId: consumptionId,
                        providerId: providerId,
                        error: error
                    });
                });
            }
        },
    // change medical service
    ChangeMedicalService: 
        (
            protocolId: number,
            consumptionId: string,
            medicalServiceId: number
        ): AppThunkAction<KnownAction> =>
        (dispatch, getState) => {
            const appState = getState();
            let token: string = '';

            if (appState.oidc?.user?.access_token === undefined) {
                dispatch({
                    type: 'PROTOCOLCONSUMPTION_CHANGE_MEDICALSERVICE_FAILURE',
                    protocolId: protocolId,
                    consumptionId: consumptionId,
                    medicalServiceId: medicalServiceId,
                    error: {
                        ErrorCode: 401,
                        ErrorMessage: 'Not authorized',
                        Errors: []
                    }
                });
                return;
            } else {
                token = appState.oidc.user.access_token;
            };

            if (appState) {
                dispatch({
                    type: 'PROTOCOLCONSUMPTION_CHANGE_MEDICALSERVICE_REQUEST',
                    protocolId: protocolId,
                    consumptionId: consumptionId,
                    medicalServiceId: medicalServiceId
                });

                service.SetConsumptionMedicalService(token, consumptionId, medicalServiceId)
                .then(() => {
                    dispatch({
                        type: 'PROTOCOLCONSUMPTION_CHANGE_MEDICALSERVICE_SUCCESS',
                        protocolId: protocolId,
                        consumptionId: consumptionId,
                        medicalServiceId: medicalServiceId
                    });
                })
                .catch((error: ErrorType) => {
                    dispatch({
                        type: 'PROTOCOLCONSUMPTION_CHANGE_MEDICALSERVICE_FAILURE',
                        protocolId: protocolId,
                        consumptionId: consumptionId,
                        medicalServiceId: medicalServiceId,
                        error: error
                    });
                });
            }
        }

};


