import { Protocol } from "../../models/Entities/Protocols/Protocol";
import { ErrorType } from "../../models/HttpError";
import { CommonState } from "../common/state/commonState";


export interface MedicalProtocolsState extends CommonState<Protocol> {
    // updating all
    isUpdatingAll: boolean;
    isUpdatingAllSuccess: boolean|undefined;
    FailUpdatingAll: boolean;

    // Protocol contract association status
    isGettingProtocolContractAssociation: boolean;
    isGettingProtocolContractAssociationSuccess: boolean|undefined;
    FailGettingProtocolContractAssociation: boolean;

    // consumptions states

    // loading all consumptions
    isLoadingAllConsumptions: boolean;
    LoadingAllConsumptionsSuccess: boolean|undefined;
    FailGettingAllConsumptions: boolean;
    ErrorGettingAllConsumptions: ErrorType|undefined;

    // loading conumption by id
    isLoadingOneConsumption: boolean;
    LoadingOneConsumptionSuccess: boolean|undefined;
    FailGettingOneConsumption: boolean;
    ErrorGettingOneConsumption: ErrorType|undefined;

    // Creating consumption
    isCreatingConsumption: boolean;
    CreatingConsumptionSuccess: boolean|undefined;
    FailCreatingConsumption: boolean;
    ErrorCreatingConsumption: ErrorType|undefined;

    // Loading all sync process
    isLoadingAllSyncProcess: boolean;
    LoadingAllSyncProcessSuccess: boolean|undefined;
    FailGettingAllSyncProcess: boolean;
    ErrorGettingAllSyncProcess: ErrorType|undefined;

    // creating sync process
    isCreatingSyncProcess: boolean;
    CreatingSyncProcessSuccess: boolean|undefined;
    FailCreatingSyncProcess: boolean;
    ErrorCreatingSyncProcess: ErrorType|undefined;

    // Getting last sync process
    isLoadingLastSyncProcess: boolean;
    LoadingLastSyncProcessSuccess: boolean|undefined;
    FailGettingLastSyncProcess: boolean;
    ErrorGettingLastSyncProcess: ErrorType|undefined;

    // Get Totals 
    isLoadingTotals: boolean;
    LoadingTotalsSuccess: boolean|undefined;
    FailGettingTotals: boolean;
    ErrorGettingTotals: ErrorType|undefined;
}

export const MedicalProtocolsUnloadState: any = {
    // loading all
    isLoadingAll: false,
    isLoadingAllSuccess: undefined,
    FailGettingAll: false,

    // updating all
    isUpdatingAll: false,
    isUpdatingAllSuccess: undefined,
    FailUpdatingAll: false,

    // loading one
    isLoadingOne: false,
    isLoadingOneSuccess: undefined,
    FailGettingOne: false,

    // saving
    isSaving: false,
    isSavingSuccess: undefined,
    FailSaving: false,
    
    // adding
    isAdding: false,
    isAddingSuccess: undefined,
    FailAdding: false,

    // deleting
    isDeletingOne: false,
    isDeletingOneSuccess: undefined,
    FailDeletingOne: false, 

    // protocol contract association status
    isGettingProtocolContractAssociation: false,
    isGettingProtocolContractAssociationSuccess: undefined,
    FailGettingProtocolContractAssociation: false,

    // consumptions states
    isLoadingAllConsumptions: false,
    LoadingAllConsumptionsSuccess: undefined,
    FailGettingAllConsumptions: false,
    ErrorGettingAllConsumptions: undefined,

    // loading conumption by id
    isLoadingOneConsumption: false,
    LoadingOneConsumptionSuccess: undefined,
    FailGettingOneConsumption: false,
    ErrorGettingOneConsumption: undefined,

    // Creating consumption
    isCreatingConsumption: false,
    CreatingConsumptionSuccess: undefined,
    FailCreatingConsumption: false,
    ErrorCreatingConsumption: undefined,

    // Loading all sync process
    isLoadingAllSyncProcess: false,
    LoadingAllSyncProcessSuccess: undefined,
    FailGettingAllSyncProcess: false,
    ErrorGettingAllSyncProcess: undefined,

    // creating sync process
    isCreatingSyncProcess: false,
    CreatingSyncProcessSuccess: undefined,
    FailCreatingSyncProcess: false,
    ErrorCreatingSyncProcess: undefined,

    // Getting last sync process
    isLoadingLastSyncProcess: false,
    LoadingLastSyncProcessSuccess: undefined,
    FailGettingLastSyncProcess: false,
    ErrorGettingLastSyncProcess: undefined,

    // Get Totals
    isLoadingTotals: false,
    LoadingTotalsSuccess: undefined,
    FailGettingTotals: false,
    ErrorGettingTotals: undefined
};