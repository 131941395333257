import { IResult } from '../models/HttpResult';
import { ContractConcept } from '../models/Entities/Contracts/ContractConcept';
import { NewContractConcept } from '../models/Entities/Contracts/NewContractConcept';
import { NewConcept } from '../models/Entities/Contracts/NewConcept';
import { Concept } from '../models/Entities/Contracts/Concept';

//Functions
import * as Functions from '../functions/common';

// Error Tracer
import * as Sentry from '@sentry/react';
import { useSelector } from 'react-redux';


const BaseURL = process.env.REACT_APP_BILLER_API_GATEWAY + 'contracts/';
const BaseURLConcept = process.env.REACT_APP_BILLER_API_GATEWAY + 'concepts/';

// Get All Concepts of contract
export const GetContractsConcept = async (ContractId: number, referralDate: Date | undefined, token: string): Promise<ContractConcept[]> => {

    let RequestURL: string = BaseURL + ContractId + '/concepts/?referralDate=' + (referralDate ? referralDate.toISOString() : '');

    try {
        const Response: ContractConcept[] = await fetch(RequestURL, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
        }
        })
        .then((response) => {
            if (!response.ok) {
            Sentry.captureException(response);
            throw response;
            } else {
            return response.json() as Promise<IResult<ContractConcept[]>>;
            }
        })
        .then((data: IResult<ContractConcept[]>) => {
            return data.data;
        })
        .catch((error: any) =>
            error.text().then((body: any) => {
            throw Functions.HttpErrorHandler(body, error);
            })
        );

        return Response;
    } catch (error: any) {
        Sentry.captureException(error);
        throw error;
    }
};

// Get All Concepts by addendum id

export const GetContractConceptsByAddendumId = async (AddendumId: number, token: string): Promise<ContractConcept[]> => {

    let RequestURL: string = BaseURL + 'addendums/' + AddendumId + '/concepts/';

    try {
        const Response: ContractConcept[] = await fetch(RequestURL, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
        }
        })
        .then((response) => {
            if (!response.ok) {
            Sentry.captureException(response);
            throw response;
            } else {
            return response.json() as Promise<IResult<ContractConcept[]>>;
            }
        })
        .then((data: IResult<ContractConcept[]>) => {
            return data.data;
        })
        .catch((error: any) =>
            error.text().then((body: any) => {
            throw Functions.HttpErrorHandler(body, error);
            })
        );

        return Response;
    } catch (error: any) {
        Sentry.captureException(error);
        throw error;
    }
};

// Get contract By Id
export const GetContractConceptById = async (id: number, token: string): Promise<ContractConcept> => {

    let RequestURL: string = BaseURL + 'concepts/' + id;
    
    try {
        const Response: ContractConcept = await fetch(RequestURL, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
        }
        })
        .then((response) => {
            if (!response.ok) {
            Sentry.captureException(response);
            throw response;
            } else {
            return response.json() as Promise<IResult<ContractConcept>>;
            }
        })
        .then((data: IResult<ContractConcept>) => {
            return data.data;
        })
        .catch((error: any) =>
            error.text().then((body: any) => {
            throw Functions.HttpErrorHandler(body, error);
            })
        );
    
        return Response;
    } catch (error: any) {
        Sentry.captureException(error);
        throw error;
    }
    };

// Create concept
export const CreateConcept = async (newConcept: NewConcept, token: string): Promise<Concept> => {

    let RequestURL: string = BaseURLConcept;
    
    try {
        const Response: Concept = await fetch(RequestURL, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
        },
        body: JSON.stringify(newConcept)
        })
        .then((response) => {
            if (!response.ok) {
            Sentry.captureException(response);
            throw response;
            } else {
            return response.json() as Promise<IResult<Concept>>;
            }
        })
        .then((data: IResult<Concept>) => {
            return data.data;
        })
        .catch((error: any) =>
            error.text().then((body: any) => {
            throw Functions.HttpErrorHandler(body, error);
            })
        );
    
        return Response;
    } catch (error: any) {
        Sentry.captureException(error);
        throw error;
    }
};

// Update Concept
export const UpdateConcept = async (concept: Concept, token: string): Promise<Concept> => {

    let RequestURL: string = BaseURLConcept;
    
    try {
        const Response: Concept = await fetch(RequestURL, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
        },
        body: JSON.stringify(concept)
        })
        .then((response) => {
            if (!response.ok) {
            Sentry.captureException(response);
            throw response;
            } else {
            return response.json() as Promise<IResult<Concept>>;
            }
        })
        .then((data: IResult<Concept>) => {
            return data.data;
        })
        .catch((error: any) =>
            error.text().then((body: any) => {
            throw Functions.HttpErrorHandler(body, error);
            })
        );
    
        return Response;
    } catch (error: any) {
        Sentry.captureException(error);
        throw error;
    }
};


// Create Contract concept
export const CreateContractConcept = async (newContractConcept: NewContractConcept, token: string): Promise<ContractConcept> => {

    let RequestURL: string = BaseURL + 'concepts/';
    
    try {
        const Response: ContractConcept = await fetch(RequestURL, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
        },
        body: JSON.stringify(newContractConcept)
        })
        .then((response) => {
            if (!response.ok) {
            Sentry.captureException(response);
            throw response;
            } else {
            return response.json() as Promise<IResult<ContractConcept>>;
            }
        })
        .then((data: IResult<ContractConcept>) => {
            return data.data;
        })
        .catch((error: any) =>
            error.text().then((body: any) => {
            throw Functions.HttpErrorHandler(body, error);
            })
        );
    
        return Response;
    } catch (error: any) {
        Sentry.captureException(error);
        throw error;
    }
    };

// Update Contract
export const UpdateContractConcept = async (contractConcept: ContractConcept, token: string): Promise<ContractConcept> => {

    let RequestURL: string = BaseURL + 'concepts/';
    
    try {
        const Response: ContractConcept = await fetch(RequestURL, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
        },
        body: JSON.stringify(contractConcept)
        })
        .then((response) => {
            if (!response.ok) {
            Sentry.captureException(response);
            throw response;
            } else {
            return response.json() as Promise<IResult<ContractConcept>>;
            }
        })
        .then((data: IResult<ContractConcept>) => {
            return data.data;
        })
        .catch((error: any) =>
            error.text().then((body: any) => {
            throw Functions.HttpErrorHandler(body, error);
            })
        );
    
        return Response;
    } catch (error: any) {
        Sentry.captureException(error);
        throw error;
    }
};

// Delete contract concept
export const DeleteContractConcept = async (id: number, token: string): Promise<boolean> => {

    let RequestURL: string = BaseURL + 'concepts/' + id;
    
    try {
        const Response: boolean = await fetch(RequestURL, {
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
        }
        })
        .then((response) => {
            if (!response.ok) {
            Sentry.captureException(response);
            throw response;
            } else {
            return response.json() as Promise<IResult<boolean>>;
            }
        })
        .then((data: IResult<boolean>) => {
            return data.data;
        })
        .catch((error: any) =>
            error.text().then((body: any) => {
            throw Functions.HttpErrorHandler(body, error);
            })
        );
    
        return Response;
    } catch (error: any) {
        Sentry.captureException(error);
        throw error;
    }
};
