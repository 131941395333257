import React from 'react';
import { DefaultButton, FontIcon, FontSizes, Label, Separator, Text } from '@fluentui/react';
import { HeaderPersonaComponent } from '../../../commons/component/headers/HeaderPersona/HeaderPersonaComponent';
import { connect } from 'react-redux';
import { Practitioner } from '../../../models/Entities/Practitioners/Practitioner';
import { useEffect, useState } from 'react';

// Functions
import * as ComponentFuntions from './ContractAssociations.Functions';

////i18Next
import { useTranslation, Trans } from 'react-i18next';

//styles
import './ContractAssociations.Styles.css';
import PractitionerSelectorComponent from '../../../commons/component/Inputs/PractitionerSelector/PractitionerSelectorComponent';
import ProtocolSelectorComponent from '../../../commons/component/Inputs/ProtocolSelector/ProtocolSelectorComponent';
import { Protocol } from '../../../models/Entities/Protocols/Protocol';

const ContractAssociationsComponent = (props : ComponentFuntions.Props) => {

    const [enablePractitionerAssociation, setEnablePractitionerAssociation] = useState<boolean>(false);
    const [enableProtocolAssociation, setEnableProtocolAssociation] = useState<boolean>(false);
   
    //useTranslation()
    const [t] = useTranslation();

    useEffect(() => {
        props.GetContractById(props.ContractId);
    }, [props.ContractId]);

    useEffect(() => {
        props.GetAllPractitioners(1,1000, '');
        props.GetAllMedicalProtocols();
    }, []);

    useEffect(() => {
        props.Practitioner && setEnablePractitionerAssociation(false);
    }, [props.Practitioner]);

    useEffect(() => {
        props.Protocol && setEnableProtocolAssociation(false);
    }, [props.Protocol]);

    // events
    const onViewProtocol = () => {}

    const onViewPractitioner = () => {}

    const onDisassociatePractitioner = () => {
        props.Contract?.practitioners && props.DisassociateContractFromPractitioner(props.Contract.practitioners[0]);
    }

    const onDisassociateProtocol = () => {
        props.Contract?.protocol && props.DisassociateContractFromProtocol(props.Contract.protocol[0]);
    }

    const AssociateToPractitioner = (practitioner: Practitioner) => {
        practitioner && props.AssociateContractToPractitioner(props.ContractId, practitioner.userId);
    }

    const AssociateToProtocol = (protocol: Protocol) => {
        protocol && props.AssociateContractToProtocol(props.ContractId, protocol.id);
    }

    const PractitionerAssociation = (
        <>
         <Label>{t('contractAssociations.practitionerAssociation.selectPractitioners')}</Label>
         <PractitionerSelectorComponent MultiselectEnable={false} onChangeSelection={(selectedPractitioners: Practitioner[] | undefined) => selectedPractitioners && AssociateToPractitioner(selectedPractitioners[0])} SelectedPractitioners={[]} />
        </>
    );

    const ProtocolAssociation = (
        <>
            <Label>{t('contractAssociations.protocolAssociation.selectProtocol')}</Label>
            <ProtocolSelectorComponent selectionLimit={1} onChangeSelection={(selectedProtocol: Protocol[] | undefined) => selectedProtocol && AssociateToProtocol(selectedProtocol[0])} />
        </>
    )

    


    const NoAssociations = (
        <>
          <p className="NoData">{t('contractAssociations.noAssociations.contractNoAssociations')}</p>
          <Separator />
            {(!enablePractitionerAssociation && !enableProtocolAssociation) && <div style={{ marginBottom: '0.5em'}} className='ContractAssociationsControls'>
                <DefaultButton onClick={() => setEnableProtocolAssociation(true)}><FontIcon iconName='Link' style={{fontSize: FontSizes.size18, marginRight: '0.5em'}} /> <Trans i18nKey={'contractAssociations.noAssociations.associateProtocol'}/></DefaultButton>
                <DefaultButton onClick={() => setEnablePractitionerAssociation(true)}><FontIcon iconName='Link' style={{fontSize: FontSizes.size18, marginRight: '0.5em'}}/> <Trans i18nKey={'contractAssociations.noAssociations.associateMedicalProfessional'}/></DefaultButton>
            </div>}
            {enablePractitionerAssociation && PractitionerAssociation}
            {enableProtocolAssociation && ProtocolAssociation}
        </>
    );

    
    const ProtocolAssociated = (
        <>
            <div style={{ marginBottom: '0.5em'}}>
                <Text>Protocolo Asociado</Text>
                {props.Protocol && <><h3>{props.Protocol.title}<Label>{props.Protocol.status.title}</Label></h3>
                <Label className='ProtocolSpecialty'>{props.Protocol.specialty?.description}</Label>
                <small>{props.Protocol.fullTitle}</small>
                
                </>}
            </div>
            <Separator/>
            {!props.Contract?.parentContractId && <DefaultButton onClick={onDisassociateProtocol}><FontIcon iconName='RemoveLink' style={{fontSize: FontSizes.size18, marginRight: '0.5em'}} /> <Trans i18nKey={'contractAssociations.protocolAssociated.disassociate'}/></DefaultButton>}
        </>
    );

    const PractitionerAssociated = (
        <>
            <div style={{ marginBottom: '0.5em'}}>
            <Text style={{marginBottom: '5px', display: 'block'}}>{t('contractAssociations.practitionerAssociated.associatedMedical')}</Text>
            {props.Practitioner && <HeaderPersonaComponent PersonaName={props.Practitioner?.fullName} secondaryText={props.Practitioner?.speciality} size='large' />}
            </div>
            <Separator/>
            {!props.Contract?.parentContractId && <DefaultButton onClick={onDisassociatePractitioner}><FontIcon iconName='RemoveLink' style={{fontSize: FontSizes.size18, marginRight: '0.5em'}} /> <Trans i18nKey={'contractAssociations.protocolAssociated.disassociate'}/></DefaultButton>}
            
        </>
    );

    const AddendumAssociationMessage = (props.Contract?.parentContractId !== undefined && props.Contract?.parentContractId !== null) && (
        <>
            <p style={{color: 'blue'}}>Atención</p>
            <Text>
                No se pude modificar la asociación del contrato desde una adenda, por favor modifique la asociación desde el contrato original.
            </Text>
        </>
    )
    
    return (
        <div>
            {props.Protocol && <div>
                {ProtocolAssociated}
            </div>}
            {props.Practitioner && <div>
                {PractitionerAssociated}
            </div>}
            {!props.Practitioner && !props.Protocol && NoAssociations}
            {AddendumAssociationMessage}
        </div>
    )
}

export default connect(ComponentFuntions.mapStateToProps, ComponentFuntions.mapDispatchToProps)(ContractAssociationsComponent as any);