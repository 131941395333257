// stores 
import * as ProtocolConsumptionStore from '../../../redux/middleware/ProtocolConsumptionMiddleware';
import * as ProtocolStore from '../../../redux/middleware/MedicalProtocolMiddleware';

// state
import { AppState } from '../../../redux/reducers';
import { ProtocolConsumption } from '../../../models/Entities/Protocols/ProtocolConsumption';
import { Protocol } from '../../../models/Entities/Protocols/Protocol';
import { Practitioner } from '../../../models/Entities/Practitioners/Practitioner';
import { Contract } from '../../../models/Entities/Contracts/Contract';

//#region Props

interface IProps {
    ProtocolId: number;
    isOpen: boolean;
    onClose: () => void;
}

interface ConnectedProps {
    isLoading: boolean;
    successLoading: boolean;
    failLoading: boolean;
    error: string | null;
    Protocol: Protocol | null;
    ContractId: number | null;
    Consumption: ProtocolConsumption | null;
    GetPersonByIdentifier: (identifier: string) => Practitioner | undefined;
}

interface MedicalServicesDispatchProps {
    GetProtocolConsumptionById: typeof ProtocolConsumptionStore.ActionCreators.GetProtocolConsumptionById;
    CreateProtocolConsumption: typeof ProtocolConsumptionStore.ActionCreators.CreateProtocolConsumption;
    UpdateProtocolConsumption: typeof ProtocolConsumptionStore.ActionCreators.UpdateProtocolConsumption;
    DeleteProtocolConsumption: typeof ProtocolConsumptionStore.ActionCreators.DeleteProtocolConsumption;
    GetMedicalProtocolById: typeof ProtocolStore.actionCreators.GetMedicalProtocolById;
    GetAllProtocolContractAssociations: typeof ProtocolStore.actionCreators.GetAllProtocolContractAssociations;
}

export type Props = IProps & ConnectedProps & MedicalServicesDispatchProps;

export const mapStateToProps = (state: AppState, ownProps: IProps) => {

    const practitioners = state.Practitioner?.list || [];
    
    const AuxProtocol: Protocol| null =  state.MedicalProtocols?.list?.find((protocol: Protocol) => protocol.id === ownProps.ProtocolId) || null;

    const AuxContractId: number | null = AuxProtocol?.contractAssociation?.contractId || null;

    return {
        isLoading: state.MedicalProtocols?.isLoadingOneConsumption || false,
        successLoading: state.MedicalProtocols?.LoadingOneConsumptionSuccess || undefined,
        failLoading: state.MedicalProtocols?.FailGettingOneConsumption || null,
        error: state.MedicalProtocols?.ErrorGettingOneConsumption || null,
        Protocol: AuxProtocol || null,
        ContractId: AuxContractId,
        GetPersonByIdentifier: (identifier: string) => GetPersonByIdentifier(practitioners, identifier)
    }
};

export const mapDispatchToProps = {
    ...ProtocolConsumptionStore.ActionCreators,
    ...ProtocolStore.actionCreators
};

export const GetStatusDescription = (status: number) => {

    /*
        NotSettled = 0,
        Pending = 1,
        Payee = 2,
        Settled = 3,
        Rejected = 4,
        Ignored = 5,
     */

    switch (status) {
        case 0:
            return 'Pendiente de liquidación';
        case 1:
            return 'Con saldo pendiente';
        case 2:
            return 'Pagado';
        case 3:
            return 'Liquidado';
        case 4:
            return 'Rechazado';
        case 5:
            return 'Ignorado';
        default:
            return 'Unknown';
    }
};

// get person by identifier
const GetPersonByIdentifier = (persons: Practitioner[], identifier: string): Practitioner | undefined => {
  return persons.find((person: Practitioner) => person.userId === identifier);
}