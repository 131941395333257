import { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { ContractConcept } from '../../../../models/Entities/Contracts/ContractConcept';

// own functions
import * as ownFunctions from './ContractStages.Selector.Functions';
import { ContractStage } from '../../../../models/Entities/Contracts/ContractStage';
import { Avatar, Field, Label, Tag, TagPicker, TagPickerControl, TagPickerGroup, TagPickerInput, TagPickerList, TagPickerOption, TagPickerProps } from '@fluentui/react-components';
import { ITag } from '@fluentui/react/lib/components/pickers';

const ContractStagesSelectorComponent = (props: ownFunctions.Props) => {

  useEffect(() => {
    props.GetAllContractsStage(props.ContractId);
  }, []);

  const onChangeIdTextFieldValue = (items?: ITag[] | undefined) => {
    let ContractStagesComboSelected: ContractStage[] = [];
    if (items && items.length > 0) {
      items.forEach(async (item: ITag) => {
        const ContractStageId = Number(item.key);
        const stage: ContractStage =
          ownFunctions.GetContractStageById(ContractStageId, props.Stages);

        ContractStagesComboSelected = stage !== undefined ? [
          ...ContractStagesComboSelected,
          stage
        ] : ContractStagesComboSelected;
        setInternalContratsSelected(ContractStagesComboSelected);
        setContracConceptsOptionsSelected(items);
      });
    } else {
      setInternalContratsSelected([]);
      setContracConceptsOptionsSelected([]);
    }
  };



  const [ContractOptions, setContractOptions] = useState<ITag[]>();
  const [ContractsOptionsSelected, setContracConceptsOptionsSelected] =
    useState<ITag[]>();

  const [InternalContractsSelected, setInternalContratsSelected] =
    useState<ContractStage[]>([]);

  useEffect(() => {
    const options = ownFunctions.GetContractStageOptions(props.Stages);
    setContractOptions(options);
  }, [props.Stages?.length]);

  useEffect(() => {
    if (InternalContractsSelected?.[0] === undefined) {
      props.onSelectedContractStage([]);
    } else {
      props.onSelectedContractStage(InternalContractsSelected);
    }

  }, [InternalContractsSelected]);

  useEffect(() => {
    if (props.ContractStagesSelected && ContractOptions) {
      const auxContractsSelected = ContractOptions.filter(
        (option: ITag) =>
          option.key ===
          props.ContractStagesSelected?.find((id: number) => id === option.key)
      );
      setContracConceptsOptionsSelected(auxContractsSelected);
      const stage = ownFunctions.GetContractStageById(props.ContractStagesSelected[0], props.Stages);
      setInternalContratsSelected([stage]);
    }
  }, [ContractOptions]);

  const filterSuggestedContractConceptServices = async (
    filterText: string,
    tagList: ITag[] | undefined
  ): Promise<ITag[]> => {
    return filterText
      ? ContractOptions?.filter(
        (tag) =>
          tag.name.toLowerCase().includes(filterText.toLowerCase()) &&
          !ownFunctions.listContainsTagList(tag, tagList)
      ) || []
      : [];
  };

  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

  const onOptionSelect: TagPickerProps["onOptionSelect"] = (e, data) => {
    if (data.value === "no-options") {
        setSelectedOptions([]);
        setInternalContratsSelected([]);
      return;
    }
    if (props.MultiselectEnable) {
      setSelectedOptions(data.selectedOptions);
      setInternalContratsSelected(props.Stages?.filter((stage: ContractStage) => data.selectedOptions.includes(stage.stageName)));
    } else {
      setSelectedOptions(props.MultiselectEnable ? [data.value] : (selectedOptions.includes(data.value) ? [] : [data.value]));
      setInternalContratsSelected([props.Stages?.find((stage: ContractStage) => stage.stageName === data.value) as ContractStage]);
    }
  };

  const tagPickerOptions = props.Stages?.map((stage) => stage.stageName)?.filter(
    (stage: string) => !selectedOptions.includes(stage)
  );

  return (
    <>
      {/* <Label htmlFor="SnomedOption">Seleccione una visita</Label> */}
      {/* <TagPicker
        removeButtonAriaLabel="Eliminar"
        selectionAriaLabel="Seleccione un concepto"
        onResolveSuggestions={filterSuggestedContractConceptServices}
        onEmptyResolveSuggestions={(selectedItems?: ITag[] | undefined) => ContractOptions || []}
        getTextFromItem={ownFunctions.getTextFromItem}
        pickerSuggestionsProps={ownFunctions.pickerSuggestionsProps}
        itemLimit={props.MultiselectEnable ? undefined : 1}
        onChange={onChangeIdTextFieldValue}
        pickerCalloutProps={{ doNotLayer: false }}
        inputProps={{
          id: 'ConceptsOption'
        }}
        selectedItems={ContractsOptionsSelected}
      /> */}
      <Field label="Seleccione una visita" style={{ maxWidth: 400 }}>
        <TagPicker
          onOptionSelect={onOptionSelect}
          selectedOptions={selectedOptions}
        >
          <TagPickerControl>
            <TagPickerGroup aria-label="visitas seleccionadas">
              {selectedOptions.map((option) => (
                <Tag
                  key={option}
                  shape="rounded"
                  value={option}
                >
                  {option}
                </Tag>
              ))}
            </TagPickerGroup>
            <TagPickerInput aria-label="Seleccione una visita" />
          </TagPickerControl>
          <TagPickerList>
            {tagPickerOptions?.length > 0 ? (
              tagPickerOptions?.map((option) => (
                <TagPickerOption
                  value={option}
                  key={option}
                >
                  {option}
                </TagPickerOption>
              ))
            ) : (
              <TagPickerOption value="no-options">
                No hay conceptos disponibles
              </TagPickerOption>
            )}
          </TagPickerList>
        </TagPicker>
        {props.noteText && <Label>{props.noteText}</Label>}
      </Field>
      {props.noteText && <small>{props.noteText}</small>}
    </>
  );
};

export default connect(
  ownFunctions.mapStateToProps,
  ownFunctions.mapDispatchToProps
)(ContractStagesSelectorComponent as any);
