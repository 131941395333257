// stores 
import * as ProtocolsStore from '../../../redux/middleware/MedicalProtocolMiddleware';
import * as ProtocolConsumptionSyncProcessStore from '../../../redux/middleware/ProtocolConsumptionSyncProcessMiddleware';
import * as ProtocolConsumptionStore from '../../../redux/middleware/ProtocolConsumptionMiddleware';


// state
import { AppState } from '../../../redux/reducers';
import { Protocol } from '../../../models/Entities/Protocols/Protocol';
import { ProtocolConsumptionSyncProcess } from '../../../models/Entities/Protocols/ProtocolConsumptionSyncProcess';
import { ErrorType } from '../../../models/HttpError';

//#region Props

interface IProps {
    ProtocolId: number;
}

interface ConnectedProps {
    isLoading: boolean;
    successLoading: boolean;
    failureLoading: boolean;
    ErrorGetting: ErrorType | null;

    isAdding: boolean;
    successAdding: boolean;
    failureAdding: boolean;
    ErrorAdding: ErrorType | null;

    CurrentProcess: ProtocolConsumptionSyncProcess;
    protocol: Protocol | null;
}

interface MedicalServicesDispatchProps {
    GetMedicalProtocolById: typeof ProtocolsStore.actionCreators.GetMedicalProtocolById;
    GetLastProtocolConsumptionSyncProcessByProtocolId: typeof ProtocolConsumptionSyncProcessStore.ActionCreators.GetLastProtocolConsumptionSyncProcessByProtocolId;
    CreateProtocolConsumptionSyncProcess: typeof ProtocolConsumptionSyncProcessStore.ActionCreators.CreateProtocolConsumptionSyncProcess;
    UpdateProtocolConsumptionSyncProcess: typeof ProtocolConsumptionSyncProcessStore.ActionCreators.UpdateProtocolConsumptionSyncProcess;
    GetAllProtocolConsumptionsByProtocol: typeof ProtocolConsumptionStore.ActionCreators.GetAllProtocolConsumptionsByProtocol;
}

export type Props = IProps & ConnectedProps & MedicalServicesDispatchProps;

export const mapStateToProps = (state: AppState, ownProps: IProps) => {

    const AuxProtocol: Protocol | null =  state.MedicalProtocols?.list?.find((protocol: Protocol) => protocol.id === ownProps.ProtocolId) || null;

    return {
        isLoading: state.MedicalProtocols?.isLoadingLastSyncProcess || false,
        successLoading: state.MedicalProtocols?.LoadingLastSyncProcessSuccess || undefined,
        failureLoading: state.MedicalProtocols?.FailGettingLastSyncProcess || undefined,
        ErrorGetting: state.MedicalProtocols?.ErrorGettingLastSyncProcess || null,

        isAdding: state.MedicalProtocols?.isCreatingSyncProcess || false,
        successAdding: state.MedicalProtocols?.CreatingSyncProcessSuccess || undefined,
        failureAdding: state.MedicalProtocols?.FailCreatingSyncProcess || undefined,
        ErrorAdding: state.MedicalProtocols?.ErrorCreatingSyncProcess || null,

        CurrentProcess: AuxProtocol?.lastConsumptionSyncProcess || undefined,
        protocol: AuxProtocol
    }
};

export const mapDispatchToProps = {
    ...ProtocolsStore.actionCreators,
    ...ProtocolConsumptionSyncProcessStore.ActionCreators,
    ...ProtocolConsumptionStore.ActionCreators
};