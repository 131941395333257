import { connect } from 'react-redux';
import { useEffect } from 'react';
import { Button, Label, MessageBar, MessageBarBody, MessageBarTitle, Spinner } from '@fluentui/react-components';

import { ArrowSync20Filled, ArrowSync24Filled, ArrowSyncCheckmark24Regular, ArrowSyncDismiss24Filled, Checkmark24Filled, CheckmarkCircle24Filled, SyncOff16Regular } from '@fluentui/react-icons';

// Component functions
import * as ComponentFunctions from './ConsumptionSyncProcess.Functions';
import moment from 'moment';
import { Separator } from '@fluentui/react';




const ConsumptionSyncProcessComponent = (props: ComponentFunctions.Props) => {



    useEffect(() => {
        if(props.protocol?.lastConsumptionSyncProcess === undefined) {
            props.GetLastProtocolConsumptionSyncProcessByProtocolId(props.ProtocolId);
        }
    }, [props.protocol?.id]);

    // call get last process ever 5 minutes
    useEffect(() => {

        if (props.CurrentProcess?.status === 1 || props.CurrentProcess?.status === 2) {
            const interval = setInterval(() => {
                
                props.GetLastProtocolConsumptionSyncProcessByProtocolId(props.ProtocolId);
            }, 50000);
            return () => clearInterval(interval);
        }
    }, [props.CurrentProcess?.status]);

    useEffect(() => {
        if (props.successAdding === true && props.CurrentProcess?.status === 3) {
            
            props.GetAllProtocolConsumptionsByProtocol(props.ProtocolId);
        }
    }, [props.successAdding && props.CurrentProcess?.status]);



    /*
    Status
    ---------------------
    registered = 1,
    inProgress = 2,
    finished = 3,
    cancelled = 4,
    fail = 5,
    ---------------------*/

    return (
        <>
            <div>
                {!props.CurrentProcess && props.ProtocolId && <Button icon={<ArrowSync24Filled />} appearance='primary' onClick={() => props.CreateProtocolConsumptionSyncProcess(props.ProtocolId)}>Sincronizar</Button>}
                {(props.CurrentProcess?.status === 3) && props.ProtocolId && <Button disabled={props.isAdding} icon={!props.isAdding ? <CheckmarkCircle24Filled color='lightgreen' /> : <Spinner size="extra-tiny" />} appearance='primary' onClick={() => props.CreateProtocolConsumptionSyncProcess(props.ProtocolId)}>{props.CurrentProcess && !props.isAdding ? 'Última sincronización: ' + moment(props.CurrentProcess.lastSync).format("DD/MM/YYYY hh:mm a") : props.isAdding ? 'Generando sincronización...' : 'Sincronizar'}</Button>}
                {(props.CurrentProcess?.status === 1 || props.CurrentProcess?.status === 2) && props?.ProtocolId && <Button disabled icon={<Spinner size="extra-tiny" />} appearance='primary' onClick={() => props.CreateProtocolConsumptionSyncProcess(props.ProtocolId)}>{props.CurrentProcess && 'Sincronizando...'}</Button>}
                {props.CurrentProcess?.status === 5 && props.ProtocolId && <Button icon={<ArrowSyncDismiss24Filled color='#cd3740' />} appearance='primary' onClick={() => props.CreateProtocolConsumptionSyncProcess(props.ProtocolId)}>{props.CurrentProcess && !props.isAdding ? 'Error | Última sincronización: ' + moment(props.CurrentProcess.lastSync).format("DD/MM/YYYY hh:mm a") : props.isAdding ? 'Generando sincronización...' : 'Actualiza'}</Button>}
            </div>
            {(props.failureLoading || props.failureAdding) && <>
                <Separator />
                <MessageBar intent={'error'}>
                    <MessageBarBody>
                        <MessageBarTitle>Error</MessageBarTitle>
                        {props.ErrorAdding?.ErrorMessage || props.ErrorGetting?.ErrorMessage}
                    </MessageBarBody>
                </MessageBar>
            </>}
        </>
    );
};

export default connect(
    ComponentFunctions.mapStateToProps,
    ComponentFunctions.mapDispatchToProps
)(ConsumptionSyncProcessComponent as any);
