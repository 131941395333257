import { DefaultButton, FontSizes, Icon, Label, PrimaryButton, Separator, Spinner } from "@fluentui/react"
import { connect } from "react-redux";
import { useBoolean } from '@fluentui/react-hooks';

import ContractStageInitialFormComponent from "../StageInitialForm/StageInitialForm.Component";
import ContractStageFormComponent from "../StageForm/StageForm.Component";

// functions
import * as ComponentFunctions from './StageList.Functions';

////i18Next
import { useTranslation, Trans } from 'react-i18next';

// styles
import './StageList.Styles.css';
import { useEffect, useState } from "react";
import { StageItemComponent } from "./StageItem.Component";
import { ContractStage } from "../../../../models/Entities/Contracts/ContractStage";
import { ErrorMessageComponent } from "../../../Error/ErrorMessageComponent";


//image 
const emptyResultImage = '../../../../assets/img/empty_state.gif';

export const StageListComponent = (props: ComponentFunctions.Props) => {

    // initial values
    const [isOpenInitialSettings, { setTrue: openPanelInitialSettings, setFalse: dismissPanelInitialSettings }] = useBoolean(false);
    const [selectedStage, setSelectedStage] = useState<ContractStage | undefined>(undefined);

    // Add or eddit visit
    const [isOpenVisit, { setTrue: openPanelVisit, setFalse: dismissPanelVisit }] = useBoolean(false);

    //useTranslation()
    const [t] = useTranslation();

    const handleInitialSettingsClosePanel = () => {
        dismissPanelInitialSettings();
    };

    const handleVisitClosePanel = () => {
        setSelectedStage(undefined);
        dismissPanelVisit();
    };

    const handleChargeVisitInformation = () => {
        openPanelInitialSettings();
    };

    const HandleAddEvent = () => {
        openPanelVisit();
    };

    const HandleEditEvent = (stage: ContractStage) => {
        setSelectedStage(stage);
        openPanelVisit();
    }

    const HandleDeleteEvent = (stage: ContractStage) => {
        props.DeleteContractStage(props.ContractId, props.ContractAddendumId, stage.id);
    }

    useEffect(() => {
        props.GetContractById(props.ContractId);
    }, [props.ContractId !== undefined]);

    // efects   
    useEffect(() => {
        if (props.Contract) {
            props.GetAllContractStagesByAddendumId(props.ContractId, props.ContractAddendumId);
        }
    }, [props.ContractAddendumId]);

    // Loading
    const Loading = (props.isLoading) && (
        <div>
            <Spinner
                label={t('stageList.props.loadingVisits')}
                ariaLive="assertive"
                labelPosition="top"
            />
        </div>
    );

    const ComponentActions = (
        <PrimaryButton
            secondaryText={t('stageList.props.addVisits')}
            onClick={HandleAddEvent}
            text={t('stageList.props.addVisits')}
            iconProps={{ iconName: 'Add' }}
            className="actionButton"
        />
    )

    const ErrorMessage = (props.failOnLoading) && props.error && (
        <ErrorMessageComponent Error={props.error} />
    )

    const NoRegistries = (props.Stages?.length === 0 || props.Stages?.length === undefined) && (
        <div className="NoDataSection">
            <img
                src={require('../../../../assets/img/empty_state.gif')}
                alt="Ichtys"
                style={{ width: '10em', height: '10em', marginBottom: '1em' }}
            ></img>
            <Label>{t('stageList.props.registeredVisits')}</Label>
            <DefaultButton
                text={t('stageList.props.loadingVisitInformation')}
                iconProps={{ iconName: 'Add' }}
                style={{ marginTop: '1em' }}
                onClick={handleChargeVisitInformation} />
        </div>
    );

    const Forms = <>
        <ContractStageInitialFormComponent
            isOpen={isOpenInitialSettings}
            openPanel={openPanelInitialSettings}
            dismissPanel={handleInitialSettingsClosePanel}
            ContractId={props.ContractId}
            AddendumId={props.ContractAddendumId}
        />
        <ContractStageFormComponent
            isOpen={isOpenVisit}
            openPanel={openPanelVisit}
            dismissPanel={handleVisitClosePanel}
            ContractId={props.ContractId}
            ContractAddendumId={props.ContractAddendumId}
            ContractStageId={selectedStage?.id}
            CurrentCycles={props.Cycles}
        />
    </>


    return (
        <>
            {ComponentActions}
            {ErrorMessage}
            {props.isLoading ? Loading : null}
            {props.Stages?.map((stage, index) => (
                <StageItemComponent
                    key={stage.id}
                    Stage={stage} index={index}
                    onEdit={(stage: ContractStage) => HandleEditEvent(stage)}
                    onDelete={(stage: ContractStage) => props.DeleteContractStage(props.ContractId, props.ContractAddendumId, stage.id)}
                    isDeleting={props.isDeleting}
                    failOnDeleting={props.isDeletedSuccessfully}
                    error={props.error}
                />
            ))}
            {NoRegistries}
            {Forms}
        </>
    )
}

export default connect(
    ComponentFunctions.mapStateToProps,
    ComponentFunctions.mapDispatchToProps
)(StageListComponent as any);

