import { MedicalProtocolsState, MedicalProtocolsUnloadState } from '../states/MedicalProtocolsState';

// MedicalProtocols Reducer
import { Action, Reducer } from 'redux';

// actions
import * as Actions from '../actions/';

// functions
import * as Functions from '../../functions/common';
import { Protocol } from '../../models/Entities/Protocols/Protocol';
import { ProtocolConsumption } from '../../models/Entities/Protocols/ProtocolConsumption';
import { ProtocolConsumptionTotal } from '../../models/Entities/Protocols/ProtocolConsumptionTotal';


type KnownAction = 
| Actions.MedicalProtocols.MedicalProtocols_GetAll_Request_Action 
| Actions.MedicalProtocols.MedicalProtocols_GetAll_Receive_Action 
| Actions.MedicalProtocols.MedicalProtocols_GetAll_Fail_Action
| Actions.MedicalProtocols.MedicalProtocols_UpdateAll_Request_Action
| Actions.MedicalProtocols.MedicalProtocols_UpdateAll_Receive_Action
| Actions.MedicalProtocols.MedicalProtocols_UpdateAll_Fail_Action
| Actions.MedicalProtocols.MedicalProtocols_GetById_Request_Action
| Actions.MedicalProtocols.MedicalProtocols_GetById_Receive_Action
| Actions.MedicalProtocols.MedicalProtocols_GetById_Fail_Action
| Actions.MedicalProtocols.MedicalProtocols_GetAll_ContractAssociations_Request_Action
| Actions.MedicalProtocols.MedicalProtocols_GetAll_ContractAssociations_Receive_Action
| Actions.MedicalProtocols.MedicalProtocols_GetAll_ContractAssociations_Fail_Action
| Actions.Contract.Contract_Associate_To_Protocol_Receive_Action
| Actions.Contract.Contract_Disassociate_From_Protocol_Receive_Action
| Actions.ProtocolPractitioners.ProtocolPractitiones_GetAll_Receive_Action
| Actions.MedicalProtocols.MedicalProtocols_GetAll_ProtocolPatients_Receive_Action
| Actions.ProtocolPractitioners.ProtocolPractitionesStudyTask_GetAll_Receive_Action
| Actions.MedicalProtocols.MedicalProtocols_GetAll_PatientVisit_ByProtocolId_Receive_Action
| Actions.MedicalProtocols.MedicalProtocols_GetAll_Visits_ByProtocolId_Receive_Action

// protocol consumption actions
| Actions.ProtocolConsumptions.ProtocolConsumptionGetAllByProtocolRequestAction
| Actions.ProtocolConsumptions.ProtocolConsumptionGetAllByProtocolSuccessAction
| Actions.ProtocolConsumptions.ProtocolConsumptionGetAllByProtocolFailureAction
| Actions.ProtocolConsumptions.ProtocolConsumptionGetByIdRequestAction
| Actions.ProtocolConsumptions.ProtocolConsumptionGetByIdSuccessAction
| Actions.ProtocolConsumptions.ProtocolConsumptionGetByIdFailureAction
| Actions.ProtocolConsumptions.ProtocolConsumptionCreateRequestAction
| Actions.ProtocolConsumptions.ProtocolConsumptionCreateSuccessAction
| Actions.ProtocolConsumptions.ProtocolConsumptionCreateFailureAction
| Actions.ProtocolConsumptions.ProtocolConsumptionUpdateRequestAction
| Actions.ProtocolConsumptions.ProtocolConsumptionUpdateSuccessAction
| Actions.ProtocolConsumptions.ProtocolConsumptionUpdateFailureAction
| Actions.ProtocolConsumptions.ProtocolConsumptionDeleteRequestAction
| Actions.ProtocolConsumptions.ProtocolConsumptionDeleteSuccessAction
| Actions.ProtocolConsumptions.ProtocolConsumptionDeleteFailureAction
| Actions.ProtocolConsumptions.ProtocolConsumptionChangeStatusRequestAction
| Actions.ProtocolConsumptions.ProtocolConsumptionChangeStatusSuccessAction
| Actions.ProtocolConsumptions.ProtocolConsumptionChangeStatusFailureAction

// protocol consumption sync process actions
| Actions.ProtocolConsumptionSyncProcess.ProtocolConsumptionSyncProcessGetAllByProtocolIdRequestAction
| Actions.ProtocolConsumptionSyncProcess.ProtocolConsumptionSyncProcessGetAllByProtocolIdSuccessAction
| Actions.ProtocolConsumptionSyncProcess.ProtocolConsumptionSyncProcessGetAllByProtocolIdFailureAction
| Actions.ProtocolConsumptionSyncProcess.ProtocolConsumptionSyncProcessGetByIdRequestAction
| Actions.ProtocolConsumptionSyncProcess.ProtocolConsumptionSyncProcessGetByIdSuccessAction
| Actions.ProtocolConsumptionSyncProcess.ProtocolConsumptionSyncProcessGetByIdFailureAction
| Actions.ProtocolConsumptionSyncProcess.ProtocolConsumptionSyncProcessGetLastByProtocolIdRequestAction
| Actions.ProtocolConsumptionSyncProcess.ProtocolConsumptionSyncProcessGetLastByProtocolIdSuccessAction
| Actions.ProtocolConsumptionSyncProcess.ProtocolConsumptionSyncProcessGetLastByProtocolIdFailureAction
| Actions.ProtocolConsumptionSyncProcess.ProtocolConsumptionSyncProcessCreateRequestAction
| Actions.ProtocolConsumptionSyncProcess.ProtocolConsumptionSyncProcessCreateSuccessAction
| Actions.ProtocolConsumptionSyncProcess.ProtocolConsumptionSyncProcessCreateFailureAction
| Actions.ProtocolConsumptionSyncProcess.ProtocolConsumptionSyncProcessUpdateRequestAction
| Actions.ProtocolConsumptionSyncProcess.ProtocolConsumptionSyncProcessUpdateSuccessAction
| Actions.ProtocolConsumptionSyncProcess.ProtocolConsumptionSyncProcessUpdateFailureAction
| Actions.ProtocolConsumptionSyncProcess.ProtocolConsumptionSyncProcessDeleteRequestAction
| Actions.ProtocolConsumptionSyncProcess.ProtocolConsumptionSyncProcessDeleteSuccessAction
| Actions.ProtocolConsumptionSyncProcess.ProtocolConsumptionSyncProcessDeleteFailureAction

// protocols consumption Totals
| Actions.ProtocolConsumptions.ProtocolConsumptionGetTotalByStatusRequestAction
| Actions.ProtocolConsumptions.ProtocolConsumptionGetTotalByStatusSuccessAction
| Actions.ProtocolConsumptions.ProtocolConsumptionGetTotalByStatusFailureAction

// protocol consumption change concept actions
| Actions.ProtocolConsumptions.ProtocolConsumptionChangeContractConceptRequestAction
| Actions.ProtocolConsumptions.ProtocolConsumptionChangeContractConceptSuccessAction
| Actions.ProtocolConsumptions.ProtocolConsumptionChangeContractConceptFailureAction

// protocol consumption change producer actions
| Actions.ProtocolConsumptions.ProtocolConsumptionChangeProducerRequestAction
| Actions.ProtocolConsumptions.ProtocolConsumptionChangeProducerSuccessAction
| Actions.ProtocolConsumptions.ProtocolConsumptionChangeProducerFailureAction

// protocol consumption change provider actions
| Actions.ProtocolConsumptions.ProtocolConsumptionChangeProviderRequestAction
| Actions.ProtocolConsumptions.ProtocolConsumptionChangeProviderSuccessAction
| Actions.ProtocolConsumptions.ProtocolConsumptionChangeProviderFailureAction

// protocol consumption change medical service actions
| Actions.ProtocolConsumptions.ProtocolConsumptionChangeMedicalServiceRequestAction
| Actions.ProtocolConsumptions.ProtocolConsumptionChangeMedicalServiceSuccessAction
| Actions.ProtocolConsumptions.ProtocolConsumptionChangeMedicalServiceFailureAction;

export const MedicalProtocolsReducer: Reducer<MedicalProtocolsState> = (
    state: MedicalProtocolsState | undefined, incomingAction: Action): MedicalProtocolsState => {
    
    if (state === undefined) {
        return MedicalProtocolsUnloadState as MedicalProtocolsState;
    }

    const action = incomingAction as KnownAction;

    switch (action.type) {
        case 'MEDICALPROTOCOLS_GET_ALL_REQUEST_ACTION':
            return {
                ...state,
                isLoadingAll: true,
                successLoadingAll: false,
                failOnLoadingAll: false,
                error: undefined
            };
        case 'MEDICALPROTOCOLS_GET_ALL_RECEIVE_ACTION':
            return {
                ...state,
                isLoadingAll: false,
                successLoadingAll: true,
                failOnLoadingAll: false,
                error: undefined,
                list: action.MedicalProtocols.sort(Functions.DynamicSort('title'))
            };
        case 'MEDICALPROTOCOLS_GET_ALL_FAIL_ACTION':
            return {
                ...state,
                isLoadingAll: false,
                successLoadingAll: false,
                failOnLoadingAll: true,
                error: action.error
            };
        case 'MEDICALPROTOCOLS_UPDATE_ALL_REQUEST_ACTION':
            return {
                ...state,
                isUpdatingAll: true,
                isUpdatingAllSuccess: undefined,
                FailUpdatingAll: false,
                error: undefined
            };
        case 'MEDICALPROTOCOLS_UPDATE_ALL_RECEIVE_ACTION':
            return {
                ...state,
                isUpdatingAll: false,
                isUpdatingAllSuccess: true,
                FailUpdatingAll: false,
                error: undefined,
                list: action.MedicalProtocols
            };
        case 'MEDICALPROTOCOLS_UPDATE_ALL_FAIL_ACTION':
            return {
                ...state,
                isUpdatingAll: false,
                isUpdatingAllSuccess: false,
                FailUpdatingAll: true,
                error: action.error
            };
        case 'MEDICALPROTOCOLS_GET_BY_ID_REQUEST_ACTION':
            return {
                ...state,
                isLoadingOne: true,
                successLoadingOne: false,
                failOnLoadingOne: false,
                error: undefined
            };
        case 'MEDICALPROTOCOLS_GET_BY_ID_RECEIVE_ACTION':

            var CurrentProtocol = state.list?.find((item: Protocol) => item.id === action.MedicalProtocol.id);
            var UpdatedProtocol = action.MedicalProtocol;

            if(CurrentProtocol) {
                UpdatedProtocol = {...UpdatedProtocol, 
                    contractAssociation: CurrentProtocol.contractAssociation, 
                    practitioners: CurrentProtocol.practitioners, 
                    patients: CurrentProtocol.patients, 
                    visits: CurrentProtocol.visits, 
                    consumptions: CurrentProtocol.consumptions, 
                    consumptionsSyncProcess: CurrentProtocol.consumptionsSyncProcess, 
                    lastConsumptionSyncProcess: CurrentProtocol.lastConsumptionSyncProcess, 
                    AmountTotals: CurrentProtocol.AmountTotals
                };
            }

            return {
                ...state,
                isLoadingOne: false,
                successLoadingOne: true,
                failOnLoadingOne: false,
                error: undefined,
                list: (state.list ? [...state.list?.filter((item: any) => item.id !== UpdatedProtocol.id), UpdatedProtocol]: [UpdatedProtocol])
            };
        case 'MEDICALPROTOCOLS_GET_BY_ID_FAIL_ACTION':
            return {
                ...state,
                isLoadingOne: false,
                successLoadingOne: false,
                failOnLoadingOne: true,
                error: action.error
            };
        case 'CONTRACT_ASSOCIATE_TO_PROTOCOL_RECEIVE_ACTION':
            var ProtocolWithContractAssociated = state.list?.find((item: Protocol) => item.id === action.contractProtocolAssociation.protocolId);
            if(ProtocolWithContractAssociated) {
                ProtocolWithContractAssociated = {...ProtocolWithContractAssociated, contractAssociation : action.contractProtocolAssociation};
            }
            return {
                ...state,
                list: (ProtocolWithContractAssociated ? [...state.list?.filter((item: any) => item.id !== action.contractProtocolAssociation.protocolId), ProtocolWithContractAssociated] : state.list).sort(Functions.DynamicSort('title'))
            };
        case 'CONTRACT_DISASSOCIATE_FROM_PROTOCOL_RECEIVE_ACTION':
            var ProtocolWithContractDisassociated = state.list?.find((item: Protocol) => item.id === action.ProtocolId);
            if(ProtocolWithContractDisassociated) {
                ProtocolWithContractDisassociated = {...ProtocolWithContractDisassociated, contractAssociation : undefined};
            }
            return {
                ...state,
                list: (ProtocolWithContractDisassociated ? [...state.list?.filter((item: any) => item.id !== action.ProtocolId), ProtocolWithContractDisassociated] : state.list).sort(Functions.DynamicSort('title'))
            };
        case 'MEDICALPROTOCOLS_GETALL_CONTRACTASSOCIATIONS_REQUEST_ACTION':
            return {
                ...state,
                isGettingProtocolContractAssociation: true,
                isGettingProtocolContractAssociationSuccess: undefined,
                FailGettingProtocolContractAssociation: false,
                error: undefined
            };
        case 'MEDICALPROTOCOLS_GETALL_CONTRACTASSOCIATIONS_RECEIVE_ACTION':
            return {
                ...state,
                isGettingProtocolContractAssociation: false,
                isGettingProtocolContractAssociationSuccess: true,
                FailGettingProtocolContractAssociation: false,
                list: state.list?.map((protocol: Protocol) => {
                    if(protocol.id === action.ProtocolId) {
                        return {...protocol, contractAssociation : action.contractAssociation};
                    } else {
                        return protocol;
                    }
                }),
                error: undefined
            };
        case 'MEDICALPROTOCOLS_GETALL_CONTRACTASSOCIATIONS_FAIL_ACTION':
            return {
                ...state,
                isGettingProtocolContractAssociation: false,
                isGettingProtocolContractAssociationSuccess: false,
                FailGettingProtocolContractAssociation: true,
                error: action.error
            };
        case 'PROTOCOL_PRACTITIONERS_GETALL_RECEIVE_ACTION':
            return {
                ...state,
                list: state.list?.map((protocol: Protocol) => {
                    if(protocol.id === action.protocolId) {
                        return {...protocol, practitioners : action.protocolPractitioners};
                    } else {
                        return protocol;
                    }
                })
            };
        case 'PROTOCOL_PRACTITIONERS_STUDYTASK_GETALL_RECEIVE_ACTION':
            return {
                ...state,
                list: state.list?.map((protocol: Protocol) => {
                    if(protocol.id === action.protocolId) {
                        return {...protocol, practitionersStudyTask : action.protocolPractitioners};
                    } else {
                        return protocol;
                    }
                })
            };
        case 'MEDICALPROTOCOLS_GETALL_PROTOCOLPATIENTS_RECEIVE_ACTION':        
            return {
                ...state,
                list: state.list?.map((protocol: Protocol) => {
                    if(protocol.id === action.ProtocolId) {
                        return {...protocol, patients : action.ProtocolPatients};
                    } else {
                        return protocol;
                    }
                })
            };
        case 'MEDICALPROTOCOLS_GETALL_PATIENTVISIT_BYPROTOCOLID_RECEIVE_ACTION':
            return {
                ...state,
                list: state.list?.map((protocol: Protocol) => {
                    if(protocol.id === action.ProtocolId) {
                        return {...protocol, patientVisits : action.visits};
                    } else {
                        return protocol;
                    }
                })
            };
        case 'MEDICALPROTOCOLS_GETALL_VISITS_BYPROTOCOLID_RECEIVE_ACTION':
            return {
                ...state,
                list: state.list?.map((protocol: Protocol) => {
                    if(protocol.id === action.ProtocolId) {
                        return {...protocol, visits : action.visits};
                    } else {
                        return protocol;
                    }
                })
            };
        
        // protocol consumption actions
        case 'PROTOCOLCONSUMPTION_GETALL_BYPROTOCOL_REQUEST':
            return {
                ...state,
                isLoadingAllConsumptions: true,
                LoadingAllConsumptionsSuccess: false,
                FailGettingAllConsumptions: false,
                ErrorGettingAllConsumptions: undefined,
                
                // sync process status
                isCreatingSyncProcess: false,
                CreatingSyncProcessSuccess: undefined,
                FailCreatingSyncProcess: false,
                ErrorCreatingSyncProcess: undefined
            };
        case 'PROTOCOLCONSUMPTION_GETALL_BYPROTOCOL_SUCCESS':

            var CurrentProtocol = state.list?.find((item: Protocol) => item.id === action.protocolId);

            if(CurrentProtocol) {
                CurrentProtocol = {...CurrentProtocol, consumptions: action.consumptions};
            }

            return {
                ...state,
                isLoadingAllConsumptions: false,
                LoadingAllConsumptionsSuccess: true,
                FailGettingAllConsumptions: false,
                ErrorCreatingConsumption: undefined,
                list: (CurrentProtocol ? [...(state.list ?? []).filter((item: any) => item.id !== action.protocolId), CurrentProtocol] : state.list ?? [])
            };
        case 'PROTOCOLCONSUMPTION_GETALL_BYPROTOCOL_FAILURE':
            return {
                ...state,
                isLoadingAllConsumptions: false,
                LoadingAllConsumptionsSuccess: false,
                FailGettingAllConsumptions: true,
                ErrorGettingAllConsumptions: action.error
            };

        case 'PROTOCOLCONSUMPTION_GET_BYID_REQUEST':
            return {
                ...state,
                isLoadingOneConsumption: true,
                LoadingOneConsumptionSuccess: false,
                FailGettingOneConsumption: false,
                ErrorGettingOneConsumption: undefined
            }
        
        case 'PROTOCOLCONSUMPTION_GET_BYID_SUCCESS':
            var CurrentProtocol = state.list?.find((item: Protocol) => item.id === action.consumption.protocolId);

            if(CurrentProtocol) {
                CurrentProtocol = {...CurrentProtocol, consumptions: [...(CurrentProtocol.consumptions || []).filter((item: ProtocolConsumption) => item.id !== action.consumption.id), action.consumption]};
            }

            return {
                ...state,
                isLoadingOneConsumption: false,
                LoadingOneConsumptionSuccess: true,
                FailGettingOneConsumption: false,
                ErrorGettingOneConsumption: undefined,
                list: (CurrentProtocol ? [...state.list?.filter((item: any) => item.id !== action.consumption.protocolId), CurrentProtocol] : state.list)
            };

        case 'PROTOCOLCONSUMPTION_GET_BYID_FAILURE':
            return {
                ...state,
                isLoadingOneConsumption: false,
                LoadingOneConsumptionSuccess: false,
                FailGettingOneConsumption: true,
                ErrorGettingOneConsumption: action.error
            };

        
        case 'PROTOCOLCONSUMPTION_CREATE_REQUEST':
            return {
                ...state,
                isCreatingConsumption: true,
                CreatingConsumptionSuccess: false,
                FailCreatingConsumption: false,
                ErrorCreatingConsumption: undefined
            };
        case 'PROTOCOLCONSUMPTION_CREATE_SUCCESS':
            var CurrentProtocol = state.list?.find((item: Protocol) => item.id === action.consumption.protocolId);

            if(CurrentProtocol) {
                CurrentProtocol = {...CurrentProtocol, consumptions: [...(CurrentProtocol.consumptions || []), action.consumption]};
            }

            return {
                ...state,
                isCreatingConsumption: false,
                CreatingConsumptionSuccess: true,
                FailCreatingConsumption: false,
                ErrorCreatingConsumption: undefined,
                list: (CurrentProtocol ? [...state.list?.filter((item: any) => item.id !== action.consumption.protocolId), CurrentProtocol] : state.list)
            };
        
        case 'PROTOCOLCONSUMPTION_CREATE_FAILURE':
            return {
                ...state,
                isCreatingConsumption: false,
                CreatingConsumptionSuccess: false,
                FailCreatingConsumption: true,
                ErrorCreatingConsumption: action.error
            };
        
        case 'PROTOCOLCONSUMPTION_UPDATE_REQUEST':

            var CurrentProtocol = state.list?.find((item: Protocol) => item.id === action.consumption.protocolId);

            if(CurrentProtocol) {

                var CurrentConsumption = CurrentProtocol.consumptions?.find((item: any) => item.id === action.consumption.id);

                if(CurrentConsumption) {
                    
                    CurrentConsumption = {...CurrentConsumption, isUpdating: true, isUpdatedSuccessfully: false, failOnUpdating: false, error: undefined};

                    CurrentProtocol = {...CurrentProtocol, consumptions: [...(CurrentProtocol.consumptions || []).filter((item: any) => item.id !== action.consumption.id), CurrentConsumption]};
                }
            }

            return {
                ...state,
                list: (CurrentProtocol ? [...state.list?.filter((item: Protocol) => item.id !== action.consumption.protocolId), CurrentProtocol] : state.list)
            };
        
        case 'PROTOCOLCONSUMPTION_UPDATE_SUCCESS':

            var CurrentProtocol = state.list?.find((item: Protocol) => item.id === action.consumption.protocolId);

            if(CurrentProtocol) {

                var CurrentConsumptionUpdated = action.consumption;

                if(CurrentConsumptionUpdated) {
                    
                    CurrentConsumptionUpdated = {...CurrentConsumptionUpdated, isUpdating: false, isUpdatedSuccessfully: true, failOnUpdating: false, error: undefined};

                    CurrentProtocol = {...CurrentProtocol, consumptions: [...(CurrentProtocol.consumptions || []).filter((item: ProtocolConsumption) => item.id !== CurrentConsumptionUpdated.id), CurrentConsumptionUpdated]};
                }
            }

            return {
                ...state,
                list: (CurrentProtocol ? [...state.list?.filter((item: Protocol) => item.id !== action.consumption.protocolId), CurrentProtocol] : state.list)
            };

        case 'PROTOCOLCONSUMPTION_UPDATE_FAILURE':
            
            var CurrentProtocol = state.list?.find((item: Protocol) => item.id === action.protocolId);

            if(CurrentProtocol) {

                var CurrentConsumptionUpdatedFailure = CurrentProtocol.consumptions?.find((item: ProtocolConsumption) => item.id === action.consumptionId);

                if(CurrentConsumption) {
                    
                    CurrentConsumption = {...CurrentConsumption, isUpdating: false, isUpdatedSuccessfully: false, failOnUpdating: true, error: action.error};

                    CurrentProtocol = {...CurrentProtocol, consumptions: [...(CurrentProtocol.consumptions || []).filter((item: ProtocolConsumption) => item.id !== action.consumptionId), CurrentConsumption]};
                }
            }

            return {
                ...state,
                list: (CurrentProtocol ? [...(state.list ?? []).filter((item: Protocol) => item.id !== action.protocolId), CurrentProtocol] : state.list ?? [])
            };
        
        case 'PROTOCOLCONSUMPTION_DELETE_REQUEST':

            var CurrentProtocol = state.list?.find((item: Protocol) => item.id === action.protocolId);

            if(CurrentProtocol) {

                var CurrentConsumption = CurrentProtocol.consumptions?.find((item: any) => item.id === action.consumptionId);

                if(CurrentConsumption) {
                    
                    CurrentConsumption = {...CurrentConsumption, isDeleting: true, isDeletedSuccessfully: false, failOnDeleting: false, error: undefined};

                    CurrentProtocol = {...CurrentProtocol, consumptions: [...(CurrentProtocol.consumptions || []).filter((item: any) => item.id !== action.consumptionId), CurrentConsumption]};
                }
            }

            return {
                ...state,
                list: (CurrentProtocol ? [...state.list?.filter((item: Protocol) => item.id !== action.protocolId), CurrentProtocol] : state.list)
            };

        case 'PROTOCOLCONSUMPTION_DELETE_SUCCESS':

            var CurrentProtocol = state.list?.find((item: Protocol) => item.id === action.protocolId);

            if(CurrentProtocol) {

                var CurrentConsumptionDeleted = CurrentProtocol.consumptions?.find((item: any) => item.id === action.consumptionId);

                if(CurrentConsumptionDeleted) {
                    
                    CurrentConsumptionDeleted = {...CurrentConsumptionDeleted, isDeleting: false, isDeletedSuccessfully: true, failOnDeleting: false, error: undefined};

                    CurrentProtocol = {...CurrentProtocol, consumptions: [...(CurrentProtocol.consumptions || []).filter((item: any) => item.id !== action.consumptionId), CurrentConsumptionDeleted]};
                }
            }

            return {
                ...state,
                list: (CurrentProtocol ? [...state.list?.filter((item: Protocol) => item.id !== action.protocolId), CurrentProtocol] : state.list)
            };

        case 'PROTOCOLCONSUMPTION_CHANGE_STATUS_REQUEST':
            
            var CurrentProtocol = state.list?.find((item: Protocol) => item.id === action.protocolId);

            if(CurrentProtocol) {

                var CurrentConsumption = CurrentProtocol.consumptions?.find((item: any) => item.id === action.consumptionId);

                if(CurrentConsumption) {
                    
                    CurrentConsumption = {...CurrentConsumption, ischangingStatus: true, statusChanged: false, failonStatusChange: false, ErrorOnStatusChange: undefined};

                    CurrentProtocol = {...CurrentProtocol, consumptions: [...(CurrentProtocol.consumptions || []).filter((item: any) => item.id !== action.consumptionId), CurrentConsumption]};
                }
            }

            return {
                ...state,
                list: (CurrentProtocol ? [...state.list?.filter((item: Protocol) => item.id !== action.protocolId), CurrentProtocol] : state.list)
            };
        case 'PROTOCOLCONSUMPTION_CHANGE_STATUS_SUCCESS':
            	
            var CurrentProtocol = state.list?.find((item: Protocol) => item.id === action.protocolId);

            if(CurrentProtocol) {

                let CurrentConsumptionUpdated: ProtocolConsumption | undefined = CurrentProtocol.consumptions?.find((item: ProtocolConsumption) => item.id === action.consumptionId);

                if(CurrentConsumptionUpdated) {

                    if(action.isForAll) {
                        CurrentProtocol = {...CurrentProtocol, consumptions: CurrentProtocol.consumptions?.map((item: ProtocolConsumption) => {
                            if(item.serviceName === CurrentConsumptionUpdated?.serviceName) {
                                return {...item, status: action.status};
                            } else {
                                return item;
                            }
                        })};
                    } else {
                        CurrentConsumptionUpdated = {...CurrentConsumptionUpdated, status: action.status};
                        CurrentProtocol = {...CurrentProtocol, consumptions: [...(CurrentProtocol.consumptions || []).filter((item: ProtocolConsumption) => item.id !== CurrentConsumptionUpdated?.id), CurrentConsumptionUpdated]};
                    }
                    
                    CurrentConsumptionUpdated = {...CurrentConsumptionUpdated, ischangingStatus: false, statusChanged: true, failonStatusChange: false, ErrorOnStatusChange: undefined};

                    CurrentProtocol = {...CurrentProtocol, consumptions: [...(CurrentProtocol.consumptions || []).filter((item: ProtocolConsumption) => item.id !== CurrentConsumptionUpdated?.id), CurrentConsumptionUpdated]};
                }
            }

            return {
                ...state,
                list: (CurrentProtocol ? [...state.list?.filter((item: Protocol) => item.id !== action.protocolId), CurrentProtocol] : state.list)
            };

        case 'PROTOCOLCONSUMPTION_CHANGE_STATUS_FAILURE':

        // protocol consumption sync process actions

        case 'PROTOCOLCONSUMPTIONSYNCPROCESS_GETALL_BYPROTOCOLID_REQUEST':

            return {
                ...state,
                isLoadingAllSyncProcess: true,
                LoadingAllSyncProcessSuccess: false,
                FailGettingAllSyncProcess: false,
                ErrorGettingAllSyncProcess: undefined
            };

        case 'PROTOCOLCONSUMPTIONSYNCPROCESS_GETALL_BYPROTOCOLID_SUCCESS':

            var CurrentProtocol = state.list?.find((item: Protocol) => item.id === action.protocolId);

            if(CurrentProtocol) {
                CurrentProtocol = {...CurrentProtocol, consumptionsSyncProcess: action.processes};
            }

            return {
                ...state,
                isLoadingAllSyncProcess: false,
                LoadingAllSyncProcessSuccess: true,
                FailGettingAllSyncProcess: false,
                ErrorGettingAllSyncProcess: undefined,
                list: (CurrentProtocol ? [...state.list?.filter((item: any) => item.id !== action.protocolId), CurrentProtocol] : state.list)
            };
        
        case 'PROTOCOLCONSUMPTIONSYNCPROCESS_GETALL_BYPROTOCOLID_FAILURE':
            
            return {
                ...state,
                isLoadingAllSyncProcess: false,
                LoadingAllSyncProcessSuccess: false,
                FailGettingAllSyncProcess: true,
                ErrorGettingAllSyncProcess: action.error
            };

        case 'PROTOCOLCONSUMPTIONSYNCPROCESS_GET_BYID_REQUEST':

            var CurrentProtocol = state.list?.find((item: Protocol) => item.id === action.protocolId);

            if(CurrentProtocol) {

                var CurrentProcess = CurrentProtocol.consumptionsSyncProcess?.find((item: any) => item.id === action.syncProcessId);

                if(CurrentProcess) {
                    
                    CurrentProcess = {...CurrentProcess, isUpdating: true, isUpdatedSuccessfully: false, failOnUpdating: false, error: undefined};

                    CurrentProtocol = {...CurrentProtocol, consumptionsSyncProcess: [...(CurrentProtocol.consumptionsSyncProcess || []).filter((item: any) => item.id !== action.syncProcessId), CurrentProcess]};
                }
            }

            return {
                ...state,
                list: (CurrentProtocol ? [...state.list?.filter((item: Protocol) => item.id !== action.protocolId), CurrentProtocol] : state.list)
            };

        case 'PROTOCOLCONSUMPTIONSYNCPROCESS_GET_BYID_SUCCESS':
            
            var CurrentProtocol = state.list?.find((item: Protocol) => item.id === action.process.protocolId);

            if(CurrentProtocol) {

                var CurrentProcessUpdated = action.process;

                if(CurrentProcessUpdated) {
                    
                    CurrentProcessUpdated = {...CurrentProcessUpdated, isUpdating: false, isUpdatedSuccessfully: true, failOnUpdating: false, error: undefined};

                    CurrentProtocol = {...CurrentProtocol, consumptionsSyncProcess: [...(CurrentProtocol.consumptionsSyncProcess || []).filter((item: any) => item.id !== CurrentProcessUpdated.id), CurrentProcessUpdated]};
                }
            }

            return {
                ...state,
                list: (CurrentProtocol ? [...state.list?.filter((item: Protocol) => item.id !== action.process.protocolId), CurrentProtocol] : state.list)
            };

        case 'PROTOCOLCONSUMPTIONSYNCPROCESS_GET_BYID_FAILURE':
        
            var CurrentProtocol = state.list?.find((item: Protocol) => item.id === action.protocolId);

            if(CurrentProtocol) {

                var CurrentProcessUpdatedFailure = CurrentProtocol.consumptionsSyncProcess?.find((item: any) => item.id === action.syncProcessId);

                if(CurrentProcess) {
                    
                    CurrentProcess = {...CurrentProcess, isUpdating: false, isUpdatedSuccessfully: false, failOnUpdating: true, error: action.error};

                    CurrentProtocol = {...CurrentProtocol, consumptionsSyncProcess: [...(CurrentProtocol.consumptionsSyncProcess || []).filter((item: any) => item.id !== action.syncProcessId), CurrentProcess]};
                }
            }

            return {
                ...state,
                list: (CurrentProtocol ? [...state.list?.filter((item: Protocol) => item.id !== action.protocolId), CurrentProtocol] : state.list)
            };

        case 'PROTOCOLCONSUMPTIONSYNCPROCESS_GETLAST_BYPROTOCOLID_REQUEST':
            
            return {
                ...state,
                isLoadingLastSyncProcess: true,
                LoadingLastSyncProcessSuccess: false,
                FailGettingLastSyncProcess: false,
                ErrorGettingLastSyncProcess: undefined
            };

        case 'PROTOCOLCONSUMPTIONSYNCPROCESS_GETLAST_BYPROTOCOLID_SUCCESS':
            
            var CurrentProtocol = state.list?.find((item: Protocol) => item.id === action.protocolId);

            if(CurrentProtocol) {
                CurrentProtocol = {...CurrentProtocol, lastConsumptionSyncProcess: action.process};
            }

            return {
                ...state,
                isLoadingLastSyncProcess: false,
                LoadingLastSyncProcessSuccess: true,
                FailGettingLastSyncProcess: false,
                ErrorGettingLastSyncProcess: undefined,
                list: (CurrentProtocol ? [...state.list?.filter((item: any) => item.id !== action.protocolId), CurrentProtocol] : state.list)
            };

        case 'PROTOCOLCONSUMPTIONSYNCPROCESS_GETLAST_BYPROTOCOLID_FAILURE':
            
            return {
                ...state,
                isLoadingLastSyncProcess: false,
                LoadingLastSyncProcessSuccess: false,
                FailGettingLastSyncProcess: true,
                ErrorGettingLastSyncProcess: action.error
            };  
            
        case 'PROTOCOLCONSUMPTIONSYNCPROCESS_CREATE_REQUEST':
            
            return {
                ...state,
                isCreatingSyncProcess: true,
                CreatingSyncProcessSuccess: false,
                FailCreatingSyncProcess: false,
                ErrorCreatingSyncProcess: undefined
            };
            
        case 'PROTOCOLCONSUMPTIONSYNCPROCESS_CREATE_SUCCESS':
            
            var CurrentProtocol = state.list?.find((item: Protocol) => item.id === action.process.protocolId);

            if(CurrentProtocol) {
                CurrentProtocol = {...CurrentProtocol, consumptionsSyncProcess: [...(CurrentProtocol.consumptionsSyncProcess || []), action.process], lastConsumptionSyncProcess: action.process};
            }

            return {
                ...state,
                isCreatingSyncProcess: false,
                CreatingSyncProcessSuccess: true,
                FailCreatingSyncProcess: false,
                ErrorCreatingSyncProcess: undefined,
                list: (CurrentProtocol ? [...state.list?.filter((item: any) => item.id !== action.process.protocolId), CurrentProtocol] : state.list)
            };

        case 'PROTOCOLCONSUMPTIONSYNCPROCESS_CREATE_FAILURE':
            
            return {
                ...state,
                isCreatingSyncProcess: false,
                CreatingSyncProcessSuccess: false,
                FailCreatingSyncProcess: true,
                ErrorCreatingSyncProcess: action.error
            };
        // protocol consumption totals

        case 'PROTOCOLCONSUMPTION_GET_TOTAL_BYSTATUS_REQUEST':
            return {
                ...state,
                isLoadingTotals: true,
                LoadingTotalsSuccess: false,	
                FailGettingTotals: false,
                ErrorGettingTotals: undefined
            };
        
        case 'PROTOCOLCONSUMPTION_GET_TOTAL_BYSTATUS_SUCCESS':


            var CurrentProtocol = state.list?.find((item: Protocol) => item.id === action.protocolId);

            if(CurrentProtocol) {
                CurrentProtocol = {...CurrentProtocol, AmountTotals: [...(CurrentProtocol.AmountTotals || []).filter((total: ProtocolConsumptionTotal) =>  total.status != action.status), ...action.total]};
            }

            return {
                ...state,
                isLoadingTotals: false,
                LoadingTotalsSuccess: true,
                FailGettingTotals: false,
                ErrorGettingTotals: undefined,
                list: (CurrentProtocol ? [...state.list?.filter((item: any) => item.id !== action.protocolId), CurrentProtocol] : state.list)
            };

        case 'PROTOCOLCONSUMPTION_GET_TOTAL_BYSTATUS_FAILURE':
            return {
                ...state,
                isLoadingTotals: false,
                LoadingTotalsSuccess: false,
                FailGettingTotals: true,
                ErrorGettingTotals: action.error
            };

        // protocol consumption change concept actions

        case 'PROTOCOLCONSUMPTION_CHANGE_CONTRACTCONCEPT_REQUEST':
            
            var CurrentProtocol = state.list?.find((item: Protocol) => item.id === action.protocolId);

            if(CurrentProtocol) {

                var CurrentConsumption = CurrentProtocol.consumptions?.find((item: any) => item.id === action.consumptionId);

                if(CurrentConsumption) {
                    
                    CurrentConsumption = {...CurrentConsumption, isConcepAssociationChanging: true, conceptAssociationChanged: false, failonConceptAssociationChange: false, ErrorOnConceptAssociationChange: undefined};

                    CurrentProtocol = {...CurrentProtocol, consumptions: [...(CurrentProtocol.consumptions || []).filter((item: any) => item.id !== action.consumptionId), CurrentConsumption]};
                }
            }

            return {
                ...state,
                list: (CurrentProtocol ? [...state.list?.filter((item: Protocol) => item.id !== action.protocolId), CurrentProtocol] : state.list)
            };

        case 'PROTOCOLCONSUMPTION_CHANGE_CONTRACTCONCEPT_SUCCESS':
            
            var CurrentProtocol = state.list?.find((item: Protocol) => item.id === action.protocolId);

            if(CurrentProtocol) {

                let CurrentConsumptionUpdated = CurrentProtocol.consumptions?.find((consumption: ProtocolConsumption) => consumption.id === action.consumptionId);

                if(CurrentConsumptionUpdated) {
                    
                    CurrentConsumptionUpdated = {...CurrentConsumptionUpdated, isConcepAssociationChanging: false, conceptAssociationChanged: true, failonConceptAssociationChange: false, ErrorOnConceptAssociationChange: undefined};

                    const updatedConsumptionIds = action.updatedConsumptions.map((item: ProtocolConsumption) => { return item.id } ) ?? [];

                    console.log("total: ", updatedConsumptionIds.length);

                    console.log("ids: ", updatedConsumptionIds);

                    const actuallConsumptions: ProtocolConsumption[] = CurrentProtocol?.consumptions ?? [];

                    console.log("total: ", actuallConsumptions.length);

                    console.log("consumptions: ", actuallConsumptions);

                    const filtredConsumptions: ProtocolConsumption[] = actuallConsumptions.filter((item: ProtocolConsumption) => !updatedConsumptionIds.includes(item.id));

                    console.log("total: ", filtredConsumptions.length);

                    console.log("consumptions: ", filtredConsumptions);

                    const updatedConsumptions: ProtocolConsumption[] = [...filtredConsumptions, ...action.updatedConsumptions]

                    console.log("total: ", updatedConsumptions.length);

                    console.log("consumptions: ", updatedConsumptions);

                    CurrentProtocol = {...CurrentProtocol, consumptions: updatedConsumptions};
                }
            }

            return {
                ...state,
                list: (CurrentProtocol ? [...state.list?.filter((item: Protocol) => item.id !== action.protocolId), CurrentProtocol] : state.list)
            };

        case 'PROTOCOLCONSUMPTION_CHANGE_CONTRACTCONCEPT_FAILURE':
            
            var CurrentProtocol = state.list?.find((item: Protocol) => item.id === action.protocolId);

            if(CurrentProtocol) {

                var CurrentConsumptionUpdatedFailure = CurrentProtocol.consumptions?.find((item: any) => item.id === action.consumptionId);

                if(CurrentConsumption) {

                    CurrentConsumption = {...CurrentConsumption, isConcepAssociationChanging: false, conceptAssociationChanged: false, failonConceptAssociationChange: true, ErrorOnConceptAssociationChange: action.error};

                    CurrentProtocol = {...CurrentProtocol, consumptions: [...(CurrentProtocol.consumptions || []).filter((item: any) => item.id !== action.consumptionId), CurrentConsumption]};
                }
            }

            return {
                ...state,
                list: (CurrentProtocol ? [...state.list?.filter((item: Protocol) => item.id !== action.protocolId), CurrentProtocol] : state.list)
            };

        // protocol consumption change producer actions

        case 'PROTOCOLCONSUMPTION_CHANGE_PRODUCER_REQUEST':
            
            var CurrentProtocol = state.list?.find((item: Protocol) => item.id === action.protocolId);

            if(CurrentProtocol) {

                var CurrentConsumption = CurrentProtocol.consumptions?.find((item: any) => item.id === action.consumptionId);

                if(CurrentConsumption) {
                    
                    CurrentConsumption = {...CurrentConsumption, isProducerChanging: true, producerChanged: false, failonProducerChange: false, ErrorOnProducerChange: undefined};

                    CurrentProtocol = {...CurrentProtocol, consumptions: [...(CurrentProtocol.consumptions || []).filter((item: any) => item.id !== action.consumptionId), CurrentConsumption]};
                }
            }

            return {
                ...state,
                list: (CurrentProtocol ? [...state.list?.filter((item: Protocol) => item.id !== action.protocolId), CurrentProtocol] : state.list)
            };

        case 'PROTOCOLCONSUMPTION_CHANGE_PRODUCER_SUCCESS':
            
            var CurrentProtocol = state.list?.find((item: Protocol) => item.id === action.protocolId);

            if(CurrentProtocol) {

                let CurrentConsumptionUpdated = CurrentProtocol.consumptions?.find((consumption: ProtocolConsumption) => consumption.id === action.consumptionId);

                if(CurrentConsumptionUpdated) {
                    
                    CurrentConsumptionUpdated = {...CurrentConsumptionUpdated, isProducerChanging: false, producerChanged: true, failonProducerChange: false, ErrorOnProducerChange: undefined, producer: action.producerId};

                    const updatedConsumptions = [...(CurrentProtocol.consumptions ?? []).filter((item: ProtocolConsumption) => item.id !== action.consumptionId), CurrentConsumptionUpdated];

                    CurrentProtocol = {...CurrentProtocol, consumptions: updatedConsumptions};
                }
            }

            return {
                ...state,
                list: (CurrentProtocol ? [...state.list?.filter((item: Protocol) => item.id !== action.protocolId), CurrentProtocol] : state.list)
            };

        case 'PROTOCOLCONSUMPTION_CHANGE_PRODUCER_FAILURE':
            
            var CurrentProtocol = state.list?.find((item: Protocol) => item.id === action.protocolId);

            if(CurrentProtocol) {

                var CurrentConsumptionUpdatedFailure = CurrentProtocol.consumptions?.find((item: any) => item.id === action.consumptionId);

                if(CurrentConsumption) {

                    CurrentConsumption = {...CurrentConsumption, isProducerChanging: false, producerChanged: false, failonProducerChange: true, ErrorOnProducerChange: action.error};

                    CurrentProtocol = {...CurrentProtocol, consumptions: [...(CurrentProtocol.consumptions || []).filter((item: any) => item.id !== action.consumptionId), CurrentConsumption]};
                }
            }

            return {
                ...state,
                list: (CurrentProtocol ? [...state.list?.filter((item: Protocol) => item.id !== action.protocolId), CurrentProtocol] : state.list)
            };

        // protocol consumption change provider actions

        case 'PROTOCOLCONSUMPTION_CHANGE_PROVIDER_REQUEST':
            
            var CurrentProtocol = state.list?.find((item: Protocol) => item.id === action.protocolId);

            if(CurrentProtocol) {

                var CurrentConsumption = CurrentProtocol.consumptions?.find((item: any) => item.id === action.consumptionId);

                if(CurrentConsumption) {
                    
                    CurrentConsumption = {...CurrentConsumption, isProviderChanging: true, providerChanged: false, failonProviderChange: false, ErrorOnProviderChange: undefined};

                    CurrentProtocol = {...CurrentProtocol, consumptions: [...(CurrentProtocol.consumptions || []).filter((item: any) => item.id !== action.consumptionId), CurrentConsumption]};
                }
            }

            return {
                ...state,
                list: (CurrentProtocol ? [...state.list?.filter((item: Protocol) => item.id !== action.protocolId), CurrentProtocol] : state.list)
            };

        case 'PROTOCOLCONSUMPTION_CHANGE_PROVIDER_SUCCESS':
            
            var CurrentProtocol = state.list?.find((item: Protocol) => item.id === action.protocolId);

            if(CurrentProtocol) {

                let CurrentConsumptionUpdated = CurrentProtocol.consumptions?.find((consumption: ProtocolConsumption) => consumption.id === action.consumptionId);

                if(CurrentConsumptionUpdated) {
                    
                    CurrentConsumptionUpdated = {...CurrentConsumptionUpdated, isProviderChanging: false, providerChanged: true, failonProviderChange: false, ErrorOnProviderChange: undefined, providerId: action.providerId};

                    const updatedConsumptions = [...(CurrentProtocol.consumptions ?? []).filter((item: ProtocolConsumption) => item.id !== action.consumptionId), CurrentConsumptionUpdated];

                    CurrentProtocol = {...CurrentProtocol, consumptions: updatedConsumptions};
                }
            }

            return {
                ...state,
                list: (CurrentProtocol ? [...state.list?.filter((item: Protocol) => item.id !== action.protocolId), CurrentProtocol] : state.list)
            };

        case 'PROTOCOLCONSUMPTION_CHANGE_PROVIDER_FAILURE':
            
            var CurrentProtocol = state.list?.find((item: Protocol) => item.id === action.protocolId);

            if(CurrentProtocol) {

                var CurrentConsumptionUpdatedFailure = CurrentProtocol.consumptions?.find((item: any) => item.id === action.consumptionId);

                if(CurrentConsumption) {

                    CurrentConsumption = {...CurrentConsumption, isProviderChanging: false, providerChanged: false, failonProviderChange: true, ErrorOnProviderChange: action.error};

                    CurrentProtocol = {...CurrentProtocol, consumptions: [...(CurrentProtocol.consumptions || []).filter((item: any) => item.id !== action.consumptionId), CurrentConsumption]};
                }
            }

            return {
                ...state,
                list: (CurrentProtocol ? [...state.list?.filter((item: Protocol) => item.id !== action.protocolId), CurrentProtocol] : state.list)
            };

        // protocol consumption change medical service actions

        case 'PROTOCOLCONSUMPTION_CHANGE_MEDICALSERVICE_REQUEST':
            
            var CurrentProtocol = state.list?.find((item: Protocol) => item.id === action.protocolId);

            if(CurrentProtocol) {

                var CurrentConsumption = CurrentProtocol.consumptions?.find((item: any) => item.id === action.consumptionId);

                if(CurrentConsumption) {
                    
                    CurrentConsumption = {...CurrentConsumption, isMedicalServiceChanging: true, medicalServiceChanged: false, failonMedicalServiceChange: false, ErrorOnMedicalServiceChange: undefined};

                    CurrentProtocol = {...CurrentProtocol, consumptions: [...(CurrentProtocol.consumptions || []).filter((item: any) => item.id !== action.consumptionId), CurrentConsumption]};
                }
            }

            return {
                ...state,
                list: (CurrentProtocol ? [...state.list?.filter((item: Protocol) => item.id !== action.protocolId), CurrentProtocol] : state.list)
            };

        case 'PROTOCOLCONSUMPTION_CHANGE_MEDICALSERVICE_SUCCESS':
            
            var CurrentProtocol = state.list?.find((item: Protocol) => item.id === action.protocolId);

            if(CurrentProtocol) {

                let CurrentConsumptionUpdated = CurrentProtocol.consumptions?.find((consumption: ProtocolConsumption) => consumption.id === action.consumptionId);

                if(CurrentConsumptionUpdated) {
                    
                    CurrentConsumptionUpdated = {...CurrentConsumptionUpdated, isMedicalServiceChanging: false, medicalServiceChanged: true, failonMedicalServiceChange: false, ErrorOnMedicalServiceChange: undefined, medicalServiceId: action.medicalServiceId};

                    const updatedConsumptions = [...(CurrentProtocol.consumptions ?? []).filter((item: ProtocolConsumption) => item.id !== action.consumptionId), CurrentConsumptionUpdated];

                    CurrentProtocol = {...CurrentProtocol, consumptions: updatedConsumptions};
                }
            }

            return {
                ...state,
                list: (CurrentProtocol ? [...state.list?.filter((item: Protocol) => item.id !== action.protocolId), CurrentProtocol] : state.list)
            };

        case 'PROTOCOLCONSUMPTION_CHANGE_MEDICALSERVICE_FAILURE':
            
            var CurrentProtocol = state.list?.find((item: Protocol) => item.id === action.protocolId);

            if(CurrentProtocol) {

                var CurrentConsumptionUpdatedFailure = CurrentProtocol.consumptions?.find((item: any) => item.id === action.consumptionId);

                if(CurrentConsumption) {

                    CurrentConsumption = {...CurrentConsumption, isMedicalServiceChanging: false, medicalServiceChanged: false, failonMedicalServiceChange: true, ErrorOnMedicalServiceChange: action.error};

                    CurrentProtocol = {...CurrentProtocol, consumptions: [...(CurrentProtocol.consumptions || []).filter((item: any) => item.id !== action.consumptionId), CurrentConsumption]};
                }
            }

            return {
                ...state,
                list: (CurrentProtocol ? [...state.list?.filter((item: Protocol) => item.id !== action.protocolId), CurrentProtocol] : state.list)
            };
        
        default:
            return state;
    }
};