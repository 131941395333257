import React from 'react';
import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import { DateTimeInput } from '../../../commons/component/Inputs/DateTimeInput/DateTimeInput';
import { CurrencySelectorComponent } from '../../../commons/component/Inputs/CurrencySelector/CurrencySelectorComponent';
import { DefaultButton, Dropdown, MessageBar, MessageBarType, Panel, PanelType, PrimaryButton, Separator, Spinner, SpinnerSize, TextField, Toggle } from '@fluentui/react';

//function
import * as ComponentFunctions from './SettlementDetailForm.Functions';

import ContractConceptSelectorComponent from '../../../commons/component/Inputs/ContractConceptSelector/ContractConceptSelector.Component';
import { ContractConcept } from '../../../models/Entities/Contracts/ContractConcept';
import ProtocolPatientSelectorComponent from '../../../commons/component/Inputs/ProtocolPatientSelector/ProtocolPatientSelector.Component';
import { ProtocolPatient } from '../../../models/Entities/Protocols/ProtocolPatient';
import { SettlementDetail } from '../../../models/Entities/SettlementDetails/SettlementDetail';
import { NewSettlementDetail } from '../../../models/Entities/SettlementDetails/NewSettlementDetail';

////i18Next
import { useTranslation, Trans } from 'react-i18next';
import { Label } from '@fluentui/react-components';

interface FormValidation {
    success: boolean;
    errors: string[];
}

const SettlementDetailForm = (props: ComponentFunctions.Props) => {

    // form status
    const [submittedForm, setSubmittedForm] = useState<boolean>(false);
    const [FormValidationStatus, setFormValidationStatus] = useState<
        FormValidation | undefined
    >();

    /* 
        id: number;
        haveWarning: boolean;
        warningDescription: string;
        priceOrigin: number;
        originReferenceId: number;
        isExcluded: boolean;
        selectedResumeId: number;
        settlementId: number;
        conceptDescription: string;
        conceptId: number;
        referedId: number;
        refererName: string;
        amount: number;
        currency: string;
        comments: string;
        isItemisable: boolean;
        isReembolsable: boolean;
        type: number;
    */

    // form properties
    const [haveWarning, setHaveWarning] = useState<boolean>(false);
    const [warningDescription, setWarningDescription] = useState<string>();
    const [priceOrigin, setPriceOrigin] = useState<number>();
    const [originReferenceId, setOriginReferenceId] = useState<number>();
    const [isExcluded, setIsExcluded] = useState<boolean>(false);
    const [settlementId, setSettlementId] = useState<number>();
    const [conceptDescription, setConceptDescription] = useState<string>();
    const [conceptId, setConceptId] = useState<number>();
    const [dueDate, setDueDate] = useState<Date>();
    const [concept, setConcept] = useState<ContractConcept>();
    const [referedId, setReferedId] = useState<number>();
    const [refererName, setRefererName] = useState<string>();

    const [PatientNumberInProtocol, setPatientNumberInProtocol] = useState<string | null>();

    const [isAssociatedToASummary, setIsAssociatedToASummary] = useState<boolean>(false);
    const [isAssociatedToAConcept, setIsAssociatedToAConcept] = useState<boolean>(false);

    const [conceptSelected, setConceptSelected] = useState<ContractConcept>();
    const [patientSelected, setPatientSelected] = useState<ProtocolPatient>();

    const [value, setValue] = useState<number>();
    const [unitValue, setUnitValue] = useState<number>();
    const [currency, setCurrency] = useState<string>();
    const [comments, setComments] = useState<string>();
    const [isItemisable, setIsItemisable] = useState<boolean>(false);
    const [isReembolsable, setIsReembolsable] = useState<boolean>(false);
    const [type, setType] = useState<number>();
    const [settlementResumeId, setSettlementResumeId] = useState<number | undefined | null>();

    const [withhold, setWithhold] = useState<number>(0);
    const [overhead, setOverhead] = useState<number>(0);
    const [cost, setCost] = useState<number>(0);

    //useTranslation();
    const [t] = useTranslation();

    useEffect(() => {
        if (props.SettlementDetail !== undefined) {

            setHaveWarning(props.SettlementDetail.haveWarning || false);
            setWarningDescription(props.SettlementDetail.warningDescription);
            setPriceOrigin(props.SettlementDetail.priceOrigin);
            setOriginReferenceId(props.SettlementDetail.originReferenceId);
            setIsExcluded(props.SettlementDetail.isExcluded || false);
            setSettlementId(props.SettlementDetail.settlementId);
            setConceptId(props.SettlementDetail.conceptId);
            setConceptDescription(props.SettlementDetail.description);
            setReferedId(props.SettlementDetail.referredId);
            setRefererName(props.SettlementDetail?.referrerName);
            setDueDate(props.SettlementDetail.dueDate && new Date(props.SettlementDetail.dueDate));
            setValue(props.SettlementDetail.value);
            setUnitValue(props.SettlementDetail.unitValue);
            setCurrency(props.SettlementDetail.currency);
            setComments(props.SettlementDetail.comments);
            setIsItemisable(props.SettlementDetail.isItemized || false);
            setIsReembolsable(props.SettlementDetail.isRefundable || false);
            setType(props.SettlementDetail.type || 1);
            setSettlementResumeId(props.SettlementDetail.settlementResumeId);
            setIsAssociatedToAConcept(props.SettlementDetail.conceptId ? true : false);
            setPatientNumberInProtocol(props.SettlementDetail.patientNumberInProtocol);
            setIsAssociatedToASummary(props.SettlementDetail.settlementResumeId ? true : false);
            setWithhold(props.SettlementDetail.withHoldAmount || 0);
            setOverhead(props.SettlementDetail.overheadValue || 0);
            setCost(props.SettlementDetail.costValue || 0);
            setIsAssociatedToASummary(props.SettlementDetail.settlementResumeId ? true : false);
        } else {
            setSettlementResumeId(props.SettlementResumeId);
            setIsAssociatedToASummary(props.SettlementResumeId ? true : false);
        }
    }, [props.SettlementId, props.SettlementDetailId, props.SettlementDetail, props.SettlementResumeId, props.isOpen]);

    useEffect(() => {
        if (submittedForm && (props.isSuccessfullyAdded || props.isSuccessfullyUpdated)) {
            ResetForm();
            props.dismissPanel();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.isSuccessfullyAdded, props.isSuccessfullyUpdated]);


    // events
    const HandleChangeIsItemisable = (event: any, checked?: boolean) => {
        setIsItemisable(checked ?? false);
    }

    const HandleChangeIsReembolsable = (event: any, checked?: boolean) => {
        setIsReembolsable(checked ?? false);
    }
    const HandleChangeConceptDescription = (event: any) => {
        setConceptDescription(event.target.value);
    }

    const HandleChangeAmount = (event: any) => {
        setValue(event.target.value);
    }

    const HandleChangeUnitValue = (event: any) => {
        setUnitValue(event.target.value);
    }

    const HandleChangeWithhold = (event: any) => {
        setWithhold(event.target.value);
    }

    const HandleChangeOverhead = (event: any) => {
        setOverhead(event.target.value);
    }

    const HandleChangeCost = (event: any) => {
        setCost(event.target.value);
    }

    const HandleChangeComments = (event: any) => {
        setComments(event.target.value);
    }

    const HandleSaveForm = () => {
        setSubmittedForm(true);
        if (ValidateForm()) {
            if (props.SettlementDetailId) { // edit
                var settlementDetail: SettlementDetail = {
                    id: props.SettlementDetailId,
                    haveWarning: haveWarning,
                    isExcluded: props.SettlementDetail?.isExcluded || false,
                    settlementId: props.SettlementId,
                    conceptId: conceptSelected?.id || 0,
                    description: (isAssociatedToAConcept ? conceptSelected?.concept?.conceptName : conceptDescription) || 'No informado',
                    value: value!,
                    unitValue: unitValue || 0,
                    quantityToSettle: props.SettlementDetail?.quantityToSettle || 0,
                    coefficient: props.SettlementDetail?.coefficient || 0,
                    currency: currency || '',
                    comments: comments || '',
                    isItemized: isItemisable,
                    isRefundable: isReembolsable,
                    type: 2,
                    warningDescription: props.SettlementDetail?.warningDescription || '',
                    priceOrigin: props.SettlementDetail?.priceOrigin || 0,
                    originReferenceId: props.SettlementDetail?.originReferenceId || 0,
                    referredId: referedId || 0,
                    referrerName: patientSelected?.numberInProtocol || '',
                    referredId2: props.SettlementDetail?.referredId2 || 0,
                    referrerName2: props.SettlementDetail?.referrerName2 || '',
                    settlementResumeId: settlementResumeId,
                    markForReview: props.SettlementDetail?.markForReview || false,
                    markOk: props.SettlementDetail?.markOk || false,
                    settlementDate: new Date(),
                    dueDate: dueDate!,
                    conceptName: props.SettlementDetail?.conceptName || '',
                    uniqueControlId: props.SettlementDetail?.uniqueControlId || 0,
                    isSettledAlready: props.SettlementDetail?.isSettledAlready || false,
                    settlementLinked: props.SettlementDetail?.settlementLinked || 0,
                    contractStage: props.SettlementDetail?.contractStage || 0,
                    costValue: cost,
                    withHoldPercentage: props.SettlementDetail?.withHoldPercentage || 0,
                    withHoldAmount: withhold,
                    overheadValue: props.SettlementDetail?.overheadValue || 0,
                    overheadPercentage: props.SettlementDetail?.overheadPercentage || 0,
                    overheadUnitValue: props.SettlementDetail?.overheadUnitValue || 0,
                    providerId: props.SettlementDetail?.providerId || 0,
                    calculatingType: props.SettlementDetail?.calculatingType || 0,
                    unitAmount: props.SettlementDetail?.unitAmount || 0,
                    notEnoughInfo: props.SettlementDetail?.notEnoughInfo || false,
                    patientNumberInProtocol: patientSelected?.numberInProtocol,
                    internalSummaryIdentifier: props.SettlementDetail?.internalSummaryIdentifier || null,
                    contractStageName: props.SettlementDetail?.contractStageName || '',
                    contratId: props.SettlementDetail?.contratId 
                };

                props.UpdateSettlementDetail(settlementDetail)

            } else { // add

                var newSettlementDetail: NewSettlementDetail = {
                    settlementId: props.SettlementId,
                    settlementResumeId: settlementResumeId,
                    description: (isAssociatedToAConcept ? conceptSelected?.concept?.conceptName : conceptDescription) || 'No informado',
                    conceptId: conceptSelected?.id || 0,
                    value: value!,
                    unitValue: unitValue || 0,
                    quantityToSettle: props.SettlementDetail?.quantityToSettle || 0,
                    coefficient: props.SettlementDetail?.coefficient || 0,
                    currency: currency || '',
                    comments: comments || '',
                    isItemized: isItemisable,
                    isRefundable: isReembolsable,
                    referredId: props.SettlementDetail?.referredId || 0,
                    referrerName: patientSelected?.medicalRecordNumber || '',
                    referredId2: props.SettlementDetail?.referredId2 || 0,
                    referrerName2: props.SettlementDetail?.referrerName2 || '',
                    type: 2,
                    dueDate: dueDate!,
                    conceptName: props.SettlementDetail?.conceptName || '',
                    uniqueControlId: props.SettlementDetail?.uniqueControlId || 0,
                    isSettledAlready: props.SettlementDetail?.isSettledAlready || false,
                    settlementLinked: props.SettlementDetail?.settlementLinked || 0,
                    contractStage: props.SettlementDetail?.contractStage || 0,
                    haveWarning: props.SettlementDetail?.haveWarning || false,
                    warningDescription: props.SettlementDetail?.warningDescription || '',
                    priceOrigin: props.SettlementDetail?.priceOrigin || 0,
                    originReferenceId: props.SettlementDetail?.originReferenceId || 0,
                    costValue: cost,
                    withHoldPercentage: props.SettlementDetail?.withHoldPercentage || 0,
                    withHoldAmount: withhold,
                    overheadValue: overhead,
                    overheadPercentage: props.SettlementDetail?.overheadPercentage || 0,
                    overheadUnitValue: props.SettlementDetail?.overheadUnitValue || 0,
                    providerId: props.SettlementDetail?.providerId || 0,
                    isExcluded: props.SettlementDetail?.isExcluded || false,
                    markOk: props.SettlementDetail?.markOk || false,
                    markForReview: props.SettlementDetail?.markForReview || false,
                    settlementDate: props.Settlement?.created || new Date(),
                    calculatingType: props.SettlementDetail?.calculatingType || 0,
                    unitAmount: props.SettlementDetail?.unitAmount || 0,
                    notEnoughInfo: false,
                    patientNumberInProtocol: patientSelected?.numberInProtocol,
                    internalSummaryIdentifier: props.SettlementDetail?.internalSummaryIdentifier || null,
                    contractStageName: props.SettlementDetail?.contractStageName || '',
                    contratId: props.SettlementDetail?.contratId 
                };

                props.AddSettlementDetail(newSettlementDetail)
            }
        }
    };

    const HandleCancelForm = () => {
        ResetForm();
        props.dismissPanel();
    };

    const ResetForm = () => {

        setFormValidationStatus(undefined);
        setSubmittedForm(false);
        setHaveWarning(false);
        setWarningDescription(undefined);
        setPriceOrigin(undefined);
        setOriginReferenceId(undefined);
        setIsExcluded(false);
        setSettlementId(undefined);
        setConceptDescription(undefined);
        setReferedId(undefined);
        setRefererName(undefined);
        setValue(undefined);
        setUnitValue(undefined);
        setCurrency(undefined);
        setComments(undefined);
        setIsItemisable(false);
        setIsReembolsable(false);
        setType(undefined);
        setDueDate(undefined);
        setConceptId(undefined);
        setConceptSelected(undefined);
        setPatientSelected(undefined);
        setPatientNumberInProtocol(undefined);
        setSettlementResumeId(undefined);
        setIsAssociatedToAConcept(false);
        setIsAssociatedToASummary(false);
        setWithhold(0);
        setOverhead(0);
        setCost(0);
    };

    const ValidateForm = (): boolean => {
        let validationResult: boolean = true;

        let FormValidation: FormValidation = { success: true, errors: [] };
        setFormValidationStatus(FormValidation);

        //validate haveWarning
        if (isAssociatedToAConcept && conceptSelected === undefined) {
            validationResult = false;
            FormValidation.errors.push('Debe informar un concepto a liquidar');
        }

        //validate amount
        if (value === undefined) {
            validationResult = false;
            FormValidation.errors.push(t('settlementDetailForm.formValidation.errorAmount'));
        }

        if (unitValue === undefined) {
            validationResult = false;
            FormValidation.errors.push(t('settlementDetailForm.formValidation.errorUnitValue'));
        }

        FormValidation.success = validationResult;
        setFormValidationStatus(FormValidation);
        return validationResult;
    };

    const formData = (
        <>
            <Toggle
                label={t('settlementDetailForm.formData.SummaryAssociation')}
                defaultChecked={isAssociatedToASummary}
                onChange={() => setIsAssociatedToASummary(!isAssociatedToASummary)}
            />
            {isAssociatedToASummary && <Dropdown
                placeholder='Select an option'
                label='Resumen'
                options={props.Summaries}
                defaultSelectedKey={settlementResumeId}
                onChange={(event: any, option: any) => setSettlementResumeId(option.key)}
            />}
            <Toggle
                label={t('settlementDetailForm.formData.ConceptAssociation')}
                defaultChecked={isAssociatedToAConcept}
                onChange={() => setIsAssociatedToAConcept(!isAssociatedToAConcept)}
            />
            {isAssociatedToAConcept && <ContractConceptSelectorComponent
                ContractId={props.Settlement?.contractId || 0}
                referralDate={undefined}
                onSelectedContractConcept={(concept: ContractConcept[] | undefined) => {
                    setConceptSelected(concept?.[0]);
                    setConceptDescription(concept?.[0]?.concept?.conceptName);
                } }
                ContractConceptsSelected={conceptId ? [conceptId] : undefined}
                MultiselectEnable={false}
            />}
            {!isAssociatedToAConcept && <TextField
                label={t('settlementDetailForm.formData.conceptDescription')}
                name="settlement-concept-description"
                required
                type="text"
                autoComplete="off"
                onChange={HandleChangeConceptDescription}
                errorMessage={
                    submittedForm === true && conceptDescription === undefined
                        ? t('settlementDetailForm.formData.requiredField')
                        : undefined
                }
                value={conceptDescription?.toString() || ''}
            />}
            <Separator />
            <ProtocolPatientSelectorComponent
                ProtocolId={props.Settlement?.protocol[0]?.protocolId || 0}
                onSelectedProtocolPatient={(patient: ProtocolPatient[] | undefined) => {
                    console.log('Paciente seleccionado', patient);
                    setPatientSelected(patient?.[0]);
                }}
                ProtocolPatientsSelectedNumber={PatientNumberInProtocol ? [PatientNumberInProtocol] : []}
                MultiselectEnable={false}
            />
            <DateTimeInput label={'Fecha de realización'}
                isSubmitted={submittedForm}
                errorMessage={undefined}
                isRequired={true}
                placeHolder={undefined}
                selectedDate={dueDate}
                onSelectDate={(date: Date | undefined) => setDueDate(date)}
            />
            <Separator />
            <CurrencySelectorComponent
                errorMessage={undefined}
                defaultSelected={currency}
                label={t('settlementDetailForm.formData.currency')}
                isRequired={true}
                onCurrencyChange={(newCurrency: string) => setCurrency(newCurrency)}
            />
            <TextField
                label={t('settlementDetailForm.formData.UnitValue')}
                name="settlement-unitValue"
                required
                type="number"
                autoComplete="off"
                onChange={HandleChangeUnitValue}
                errorMessage={
                    submittedForm === true && unitValue === undefined
                        ? t('settlementDetailForm.formData.requiredField')
                        : undefined
                }
                value={unitValue?.toString() || ''}
                suffix={currency}
            />
            <TextField
                label={t('settlementDetailForm.formData.Value')}
                name="settlement-name"
                required
                type="number"
                autoComplete="off"
                onChange={HandleChangeAmount}
                errorMessage={
                    submittedForm === true && value === undefined
                        ? t('settlementDetailForm.formData.requiredField')
                        : undefined
                }
                value={value?.toString() || ''}
                suffix={currency}
            />
            <Separator />
            <TextField
                label={t('settlementDetailForm.formData.withhold')}
                name="settlement-whitHold"
                required
                type="number"
                autoComplete="off"
                onChange={HandleChangeWithhold}
                errorMessage={
                    submittedForm === true && withhold === undefined
                        ? t('settlementDetailForm.formData.requiredField')
                        : undefined
                }
                value={withhold?.toString() || ''}
                suffix={currency}
            />
            <TextField
                label={t('settlementDetailForm.formData.overhead')}
                name="settlement-overhead"
                required
                type="number"
                autoComplete="off"
                onChange={HandleChangeOverhead}
                errorMessage={
                    submittedForm === true && overhead === undefined
                        ? t('settlementDetailForm.formData.requiredField')
                        : undefined
                }
                value={overhead?.toString() || ''}
                suffix={currency}
            />
            <TextField
                label={t('settlementDetailForm.formData.Cost')}
                name="settlement-cost"
                required
                type="number"
                autoComplete="off"
                onChange={HandleChangeCost}
                errorMessage={
                    submittedForm === true && cost === undefined
                        ? t('settlementDetailForm.formData.requiredField')
                        : undefined
                }
                value={cost?.toString() || ''}
                suffix={currency}
            />
            <Separator />
            <TextField
                label={t('settlementDetailForm.formData.comments')}
                name="settlement-name"
                multiline={true}
                type="text"
                autoComplete="off"
                onChange={HandleChangeComments}
                value={comments}
            />
            <Toggle
                label={t('settlementDetailForm.formData.itemized')}
                defaultChecked={isItemisable}
                onChange={HandleChangeIsItemisable}
            />
            <Toggle
                label={t('settlementDetailForm.formData.refundable')}
                defaultChecked={isReembolsable}
                onChange={HandleChangeIsReembolsable}
            />
        </>
    );

    // footer
    const onRenderFooterContent = () => {
        return (
            <div className={ComponentFunctions.contentStyles.footer}>
                <div>
                    <DefaultButton onClick={HandleCancelForm} className="OtherOption">
                        <Trans i18nKey={'settlementDetailForm.footer.cancelButton'} />
                    </DefaultButton>
                    {<PrimaryButton
                        onClick={HandleSaveForm}
                        styles={ComponentFunctions.buttonStyles}
                        className="ConfirmAction"
                        disabled={props.isAdding || props.isUpdating}
                    >
                        {(props.isAdding || props.isUpdating) && <><Spinner size={SpinnerSize.small} />&nbsp;</>}
                        <Trans i18nKey={'settlementDetailForm.footer.saveButton'} />
                    </PrimaryButton>}
                </div>
            </div>
        );
    };

    return (
        <Panel
            isOpen={props.isOpen}
            onDismiss={props.dismissPanel}
            onRenderFooter={onRenderFooterContent}
            isFooterAtBottom={true}
            type={PanelType.smallFixedFar}
            headerText={t('settlementDetailForm.conceptInformation')}
        >

            {submittedForm && !FormValidationStatus?.success && (
                <MessageBar messageBarType={MessageBarType.error} isMultiline={true}>
                    {t('settlementDetailForm.message.reviewInformation')}
                    <ul>
                        {FormValidationStatus?.errors.map(
                            (Error: string, index: number) => (
                                <li key={index}>{Error}</li>
                            )
                        )}
                    </ul>
                </MessageBar>
            )}
            {formData}

        </Panel>
    );

};

export default connect(
    ComponentFunctions.mapStateToProps,
    ComponentFunctions.mapDispatchToProps
)(SettlementDetailForm as any);

