// store 
import * as ProtocolsStore from '../../../redux/middleware/MedicalProtocolMiddleware';
import * as ProtocolConsumptionStore from '../../../redux/middleware/ProtocolConsumptionMiddleware';

// state
import { AppState } from '../../../redux/reducers';

// models
import { Protocol } from '../../../models/Entities/Protocols/Protocol';
import { ProtocolConsumptionTotal } from '../../../models/Entities/Protocols/ProtocolConsumptionTotal';
import { ProtocolConsumption } from '../../../models/Entities/Protocols/ProtocolConsumption';
import { GiOverhead } from 'react-icons/gi';


//#region Props

interface IProps {
    ProtocolId: number;
    status: number;
}

interface ConnectedProps {
    isLoading: boolean;
    successLoading: boolean;
    error: string | null;
    Consumptions: ProtocolConsumption[];
    TotalByCurrency: {Total: number, Currency: string, TotalOwner: number, TotalTeam: number}[];
    Totals: {ConceptName: string, CurrencyValue: string, Total: number, TotalOwner: number, TotalTeam: number}[]
    protocol: Protocol | null;
}

interface MedicalServicesDispatchProps {
    GetMedicalProtocolById: typeof ProtocolsStore.actionCreators.GetMedicalProtocolById;
    GetTotalsByStatus: typeof ProtocolConsumptionStore.ActionCreators.GetTotalsByStatus;
}

export type Props = IProps & ConnectedProps & MedicalServicesDispatchProps;

export const mapStateToProps = (state: AppState, ownProps: IProps) => {

    const AuxProtocol: Protocol | null =  state.MedicalProtocols?.list?.find((protocol: Protocol) => protocol.id === ownProps.ProtocolId) || null;

    const AuxConsumptions: ProtocolConsumption[] = AuxProtocol?.consumptions?.filter((consumption: ProtocolConsumption) => consumption.status === 6) || [];

    // calculate total values for filter consumption by consumption.totalAmount
    const AuxConceptTotalByCurrency: {Total: number, Currency: string, TotalOwner: number, TotalTeam: number}[] = AuxConsumptions.reduce((acc: {Total: number, Currency: string, TotalOwner: number, TotalTeam: number}[], consumption: ProtocolConsumption) => {
        const existing = acc.find(item => item.Currency === consumption.currencyCode);
        if (existing) {
            existing.Total += consumption.amount;
            existing.TotalOwner += consumption.ownerAmountWithholdingTotal;
            existing.TotalTeam += consumption.teamAmountWithholdingTotal;
        } else {
            acc.push({ Total: consumption.amount, Currency: consumption.currencyCode, TotalOwner: consumption.ownerAmount, TotalTeam: consumption.teamAmount });
        }
        return acc;
    }, []);

    // calculate total values for filter consumption by consumption.conceptValues
    const AuxTotals: {ConceptName: string, CurrencyValue: string, Total: number, TotalOwner: number, TotalTeam: number}[] = AuxConsumptions.reduce((acc: {ConceptName: string, CurrencyValue: string, Total: number, TotalOwner: number, TotalTeam: number}[], consumption: ProtocolConsumption) => {
        consumption.conceptValues.forEach((conceptValue) => {
            const existing = acc.find(item => item.ConceptName === conceptValue.contractConceptName);
            if (existing) {
                existing.Total += conceptValue.value;
                existing.TotalOwner += conceptValue.ownerAmount;
                existing.TotalTeam += conceptValue.teamAmount;
            } else {
                acc.push({ 
                    ConceptName: conceptValue.contractConceptName, 
                    CurrencyValue: conceptValue.currencyCode, 
                    Total: conceptValue.value,  
                    TotalOwner: conceptValue.ownerAmount, 
                    TotalTeam: conceptValue.teamAmount 
                });
            }
        });
        return acc;
    }, []);

    

    return {
        isLoading: state.MedicalProtocols?.isLoadingTotals || false,
        successLoading: state.MedicalProtocols?.LoadingTotalsSuccess || undefined,
        error: state.MedicalProtocols?.ErrorGettingTotals || null,
        Consumptions: AuxConsumptions || [],
        Totals: AuxTotals || [],
        TotalByCurrency: AuxConceptTotalByCurrency,
        protocol: AuxProtocol
    }
};

export const mapDispatchToProps = {
    ...ProtocolsStore.actionCreators,
    ...ProtocolConsumptionStore.ActionCreators,
};


export const GetStatusDescription = (status: number) => {

    /*
        NotSettled = 0,
        Pending = 1,
        Payee = 2,
        Settled = 3,
        Rejected = 4,
        Ignored = 5,
        ToSettle = 6
     */

    switch (status) {
        case 0:
            return 'Pendiente de liquidación';
        case 1:
            return 'Con saldo pendiente';
        case 2:
            return 'Pagado';
        case 3:
            return 'Liquidado';
        case 4:
            return 'Rechazado';
        case 5:
            return 'Ignorado';
        case 6:
            return 'Para liquidar';
        default:
            return 'Unknown';
    }
}