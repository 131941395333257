import { ContractState, ContractUnloadState } from '../states/ContractState';
import { Contract } from '../../models/Entities/Contracts/Contract';
import { ContractConcept } from '../../models/Entities/Contracts/ContractConcept';
import { ContractProtocolAssociation } from '../../models/Entities/Contracts/ContractProtocolAssociation';
import { ContractPractitionerAssociation } from '../../models/Entities/Contracts/ContractPractitionerAssociation';
import { StageCycle } from '../../models/Entities/Contracts/StageCycle';

import { Action, Reducer } from 'redux';

// actions
import * as Actions from '../actions/';

// functions
import * as Functions from '../../functions/common';
import { ContractCommissionConcept } from '../../models/Entities/Contracts/ContractCommissionConcept';
import { ContractCommissionAgent } from '../../models/Entities/Contracts/ContractCommissionAgent';
import { Context } from 'react';
import { CommissionAgent } from '../../models/Entities/Contracts/CommissionAgent';
import { ContractCommissionAgentConcept } from '../../models/Entities/Contracts/ContractCommissionAgentConcept';
import { ContractAddendum } from '../../models/Entities/Contracts/ContractAddendum';
import { ContractStage } from '../../models/Entities/Contracts/ContractStage';
import { ContractConceptPaymentSchedule } from '../../models/Entities/Contracts/ContractConceptPaymentSchedule';
import { ContractConceptConcept } from '../../models/Entities/Contracts/ContractConceptConcept';
import { ContractAccess } from '../../models/Entities/Contracts/ContractAccess';

type KnownAction = 
| Actions.Contract.Contract_GetAll_Request_Action
| Actions.Contract.Contract_GetAll_Receive_Action
| Actions.Contract.Contract_GetAll_Fail_Action
| Actions.Contract.Contract_UpdateAll_Request_Action
| Actions.Contract.Contract_UpdateAll_Receive_Action
| Actions.Contract.Contract_UpdateAll_Fail_Action
| Actions.Contract.Contract_GetById_Request_Action
| Actions.Contract.Contract_GetById_Receive_Action
| Actions.Contract.Contract_GetById_Fail_Action
| Actions.Contract.Contract_Add_Request_Action
| Actions.Contract.Contract_Add_Receive_Action
| Actions.Contract.Contract_Add_Fail_Action
| Actions.Contract.Contract_Update_Request_Action
| Actions.Contract.Contract_Update_Receive_Action
| Actions.Contract.Contract_Update_Fail_Action
| Actions.Contract.Contract_Delete_Request_Action
| Actions.Contract.Contract_Delete_Receive_Action
| Actions.Contract.Contract_Delete_Fail_Action
| Actions.Contract.Clone_Contract_Request_Action
| Actions.Contract.Clone_Contract_Receive_Action
| Actions.Contract.Clone_Contract_Fail_Action
| Actions.ContractAddendum.ContractAddendumsGetByContractIdRequestAction
| Actions.ContractAddendum.ContractAddendumsGetByContractIdSuccessAction
| Actions.ContractAddendum.ContractAddendumsGetByContractIdFailureAction
| Actions.ContractAddendum.ContractAddendumGetByIdRequestAction
| Actions.ContractAddendum.ContractAddendumGetByIdSuccessAction
| Actions.ContractAddendum.ContractAddendumGetByIdFailureAction
| Actions.ContractAddendum.ContractAddendumCreateRequestAction
| Actions.ContractAddendum.ContractAddendumCreateSuccessAction
| Actions.ContractAddendum.ContractAddendumCreateFailureAction
| Actions.ContractAddendum.ContractAddendumUpdateRequestAction
| Actions.ContractAddendum.ContractAddendumUpdateSuccessAction
| Actions.ContractAddendum.ContractAddendumUpdateFailureAction
| Actions.ContractAddendum.ContractAddendumDeleteRequestAction
| Actions.ContractAddendum.ContractAddendumDeleteSuccessAction
| Actions.ContractAddendum.ContractAddendumDeleteFailureAction
| Actions.ContractAddendum.ContractAddendumCloneRequestAction
| Actions.ContractAddendum.ContractAddendumCloneSuccessAction
| Actions.ContractAddendum.ContractAddendumCloneFailureAction
| Actions.ContractStages.ContractStage_GetAllBycontractId_Receive_Action
| Actions.ContractStages.ContractStage_UpdateAllByContractId_Receive_Action
| Actions.ContractStages.ContractStage_GetById_Receive_Action
| Actions.ContractStages.ContractStage_Add_Receive_Action
| Actions.ContractStages.ContractStage_Update_Request_Action
| Actions.ContractStages.ContractStage_Update_Receive_Action
| Actions.ContractStages.ContractStage_Update_Fail_Action
| Actions.ContractStages.ContractStage_Delete_Receive_Action
| Actions.ContractStageCycles.ContractStageCycle_Add_Receive_Action
| Actions.ContractStageCycles.ContractStageCycle_Update_Request_Action
| Actions.ContractStageCycles.ContractStageCycle_Update_Receive_Action
| Actions.ContractStageCycles.ContractStageCycle_Delete_Receive_Action
| Actions.ContractStageCycles.ContractStageCycle_GetAllBycontractId_Receive_Action
| Actions.ContractStageCycles.ContractStageCycle_UpdateAllByContractId_Receive_Action
| Actions.ContractConcepts.ContractConcept_Add_Receive_Action
| Actions.ContractConcepts.ContractConcept_Update_Receive_Action
| Actions.ContractConcepts.ContractConcept_GetAllBycontractId_Receive_Action
| Actions.ContractConcepts.ContractConcept_UpdateAllByContractId_Receive_Action
| Actions.ContractConcepts.ContractConcept_Delete_Receive_Action

// Payment Schedules

| Actions.ContractConceptPaymentsSchedule.ContractConceptPaymentSchedule_Add_Request_Action
| Actions.ContractConceptPaymentsSchedule.ContractConceptPaymentSchedule_Add_Receive_Action
| Actions.ContractConceptPaymentsSchedule.ContractConceptPaymentSchedule_Update_Request_Action
| Actions.ContractConceptPaymentsSchedule.ContractConceptPaymentSchedule_Update_Receive_Action
| Actions.ContractConceptPaymentsSchedule.ContractConceptPaymentSchedule_GetAllByContractConceptId_Receive_Action
| Actions.ContractConceptPaymentsSchedule.ContractConceptPaymentSchedule_UpdateAllByContractConceptId_Receive_Action
| Actions.ContractConceptPaymentsSchedule.ContractConceptPaymentSchedule_Delete_Request_Action
| Actions.ContractConceptPaymentsSchedule.ContractConceptPaymentSchedule_Delete_Receive_Action


| Actions.ContractConceptConcept.ContractConceptConcept_Add_Receive_Action
| Actions.ContractConceptConcept.ContractConceptConcept_Update_Receive_Action
| Actions.ContractConceptConcept.ContractConceptConcept_GetAllByContractConceptId_Receive_Action
| Actions.ContractConceptConcept.ContractConceptConcept_UpdateAllByContractConceptId_Receive_Action
| Actions.ContractConceptConcept.ContractConceptConcept_Delete_Receive_Action
| Actions.ContractBranch.ContractBranch_GetAllBycontractId_Receive_Action
| Actions.ContractBranch.ContractBranch_UpdateALLByContractId_Receive_Action
| Actions.ContractBranch.ContractBranch_GetById_Receive_Action
| Actions.ContractBranch.ContractBranch_Create_Receive_Action
| Actions.ContractBranch.ContractBranch_Update_Receive_Action
| Actions.ContractBranch.ContractBranch_Delete_Receive_Action
| Actions.Contract.Contract_Disassociate_From_Practitioner_Receive_Action
| Actions.Contract.Contract_Disassociate_From_Protocol_Receive_Action
| Actions.PractitionerContracts.PractitionerContractsGetAll_Receive_Action
| Actions.PractitionerContracts.PractitionerContractsAdd_Receive_Action
| Actions.PractitionerContracts.PractitionerContractsUpdate_Receive_Action
| Actions.PractitionerContracts.PractitionerContractsDelete_Receive_Action
| Actions.Contract.Contract_Associate_To_Protocol_Receive_Action
| Actions.Contract.Contract_Change_FeeDivision_Request_Action
| Actions.Contract.Contract_Change_FeeDivision_Receive_Action
| Actions.Contract.Contract_Change_FeeDivision_Fail_Action
| Actions.ContractCommissionConcepts.ContractCommissionConcept_GetAll_Success_Action
| Actions.ContractCommissionConcepts.ContractCommissionConcept_Update_Success_Action
| Actions.ContractCommissionConcepts.ContractCommissionConcept_Create_Success_Action
| Actions.ContractCommissionConcepts.ContractCommissionConcept_Delete_Success_Action
| Actions.ContractCommissionAgent.ContractCommissionAgent_GetAll_Response_Action
| Actions.ContractCommissionAgent.ContractCommissionAgent_GetById_Response_Action
| Actions.ContractCommissionAgent.ContractCommissionAgent_Create_Response_Action
| Actions.ContractCommissionAgent.ContractCommissionAgent_Update_Response_Action
| Actions.ContractCommissionAgent.ContractCommissionAgent_GetAll_ByAssociatedContractId_Request_Action
| Actions.ContractCommissionAgent.ContractCommissionAgent_GetAll_ByAssociatedContractId_Response_Action
| Actions.ContractCommissionAgent.ContractCommissionAgent_GetAll_ByAssociatedContractId_Failure_Action
| Actions.ContractCommissionAgentConcept.ContractCommissionAgentConcept_GetAll_ByCommissionAgentId_Request_Action
| Actions.ContractCommissionAgentConcept.ContractCommissionAgentConcept_GetAll_ByCommissionAgentId_Response_Action
| Actions.ContractCommissionAgentConcept.ContractCommissionAgentConcept_GetAll_ByCommissionAgentId_Failure_Action
| Actions.ContractCommissionAgentConcept.ContractCommissionAgentConcept_GetById_Response_Action
| Actions.ContractCommissionAgentConcept.ContractCommissionAgentConcept_Create_Response_Action
| Actions.ContractCommissionAgentConcept.ContractCommissionAgentConcept_Update_Response_Action
| Actions.ContractCommissionAgentConcept.ContractCommissionAgentConcept_Delete_Response_Action

// stages

| Actions.ContractStages.ContractStage_GetAllByAddendumId_Request_Action
| Actions.ContractStages.ContractStage_GetAllByAddendumId_Receive_Action
| Actions.ContractStages.ContractStage_GetAllByAddendumId_Fail_Action

// cycles

| Actions.ContractStageCycles.ContractStageCycle_GetAllByAddendumId_Request_Action
| Actions.ContractStageCycles.ContractStageCycle_GetAllByAddendumId_Receive_Action
| Actions.ContractStageCycles.ContractStageCycle_GetAllByAddendumId_Fail_Action

// concepts

| Actions.ContractConcepts.ContractConcept_GetAllByAddendumId_Request_Action
| Actions.ContractConcepts.ContractConcept_GetAllByAddendumId_Receive_Action
| Actions.ContractConcepts.ContractConcept_GetAllByAddendumId_Fail_Action

// branches

| Actions.ContractBranch.ContractBranch_GetAllByAddendumId_Request_Action
| Actions.ContractBranch.ContractBranch_GetAllByAddendumId_Receive_Action
| Actions.ContractBranch.ContractBranch_GetAllByAddendumId_Error_Action

// contract Access

| Actions.ContractAccess.ContractAccessGetAllRequestAction
| Actions.ContractAccess.ContractAccessGetAllSuccessAction
| Actions.ContractAccess.ContractAccessGetAllFailureAction
| Actions.ContractAccess.ContractAccessCreateSuccessAction
| Actions.ContractAccess.ContractAccessDeleteRequestAction
| Actions.ContractAccess.ContractAccessDeleteSuccessAction
| Actions.ContractAccess.ContractAccessGetByIdSuccessAction
| Actions.ContractAccess.ContractAccessUpdateRequestAction
| Actions.ContractAccess.ContractAccessUpdateSuccessAction;


export const ContractReducer: Reducer<ContractState> = (
    state: ContractState | undefined, incomingAction: Action): ContractState => {

        if (state === undefined) {
            return ContractUnloadState as ContractState;
        }

        const action = incomingAction as KnownAction;

        let CurrentContract: Contract | undefined;

        switch (action.type) {
            case 'CONTRACT_GET_ALL_REQUEST_ACTION':
                return {
                    ...state,
                    isLoadingAll: true,
                    successLoadingAll: false,
                    failOnLoadingAll: false,
                    error: undefined
                };
            case 'CONTRACT_GET_ALL_RECEIVE_ACTION':
                return {
                    ...state,
                    isLoadingAll: false,
                    successLoadingAll: true,
                    failOnLoadingAll: false,
                    error: undefined,
                    list: state.list?.length > 0 ? action.contracts?.map((incomingContract: Contract) => {
                        const existingContract = state.list?.find((contract: Contract) => contract.id === incomingContract.id);
                        return existingContract ?  {...existingContract, ...incomingContract} : incomingContract;
                    }) : action.contracts
                };
            case 'CONTRACT_GET_ALL_FAIL_ACTION':
                return {
                    ...state,
                    isLoadingAll: false,
                    successLoadingAll: false,
                    failOnLoadingAll: true,
                    error: action.error
                };
            case 'CONTRACT_UPDATE_ALL_REQUEST_ACTION':
                return {
                    ...state,
                    isUpdatingAll: true,
                    isUpdatingAllSuccess: false,
                    FailUpdatingAll: false,
                    error: undefined
                };
            case 'CONTRACT_UPDATE_ALL_RECEIVE_ACTION':
                return {
                    ...state,
                    isUpdatingAll: false,
                    isUpdatingAllSuccess: true,
                    FailUpdatingAll: false,
                    error: undefined,
                    list: state.list?.length > 0 ? action.contracts?.map((incomingContract: Contract) => {
                        const existingContract = state.list?.find((contract: Contract) => contract.id === incomingContract.id);
                        return existingContract ? {...existingContract, ...incomingContract} : incomingContract;
                    }) : action.contracts
                };
            case 'CONTRACT_UPDATE_ALL_FAIL_ACTION':
                return {
                    ...state,
                    isUpdatingAll: false,
                    isUpdatingAllSuccess: false,
                    FailUpdatingAll: true,
                    error: action.error
                };
            case 'CONTRACT_GET_BY_ID_REQUEST_ACTION':
                return {
                    ...state,
                    isLoadingOne: true,
                    successLoadingOne: false,
                    failOnLoadingOne: false,
                    error: undefined
                };
            case 'CONTRACT_GET_BY_ID_RECEIVE_ACTION':
                CurrentContract = state.list ? state.list?.find((item: Contract) => item.id === action.contract.id) : undefined;

                if (CurrentContract) {
                    
                    var auxContract = {...CurrentContract, ...action.contract};

                    auxContract.addendums = CurrentContract.addendums;
                    auxContract.commissionAgents = CurrentContract.commissionAgents;
                    auxContract.commissionAgentsAssociated = CurrentContract.commissionAgentsAssociated;
                    auxContract.access = CurrentContract.access;
                    
                    CurrentContract = auxContract;
                }
                
                return {
                    ...state,
                    isLoadingOne: false,
                    successLoadingOne: true,
                    failOnLoadingOne: false,
                    error: undefined,
                    list: (CurrentContract ? state.list ? [...state.list.filter((item: Contract) => item.id !== action.contract.id), CurrentContract] : [CurrentContract] : [action.contract])
                };
            case 'CONTRACT_GET_BY_ID_FAIL_ACTION':
                return {
                    ...state,
                    isLoadingOne: false,
                    successLoadingOne: false,
                    failOnLoadingOne: true,
                    error: action.error
                };
            case 'CONTRACT_ADD_REQUEST_ACTION':
                return {
                    ...state,
                    isAddingNewOne: true,
                    successAddingNewOne: false,
                    failOnAddingNewOne: false,
                    error: undefined
                };
            case 'CONTRACT_ADD_RECEIVE_ACTION':
                return {
                    ...state,
                    isAddingNewOne: false,
                    successAddingNewOne: true,
                    failOnAddingNewOne: false,
                    error: undefined,
                    list: [...state.list, action.contract].sort(Functions.DynamicSort('contractNumber'))
                };
            case 'CONTRACT_ADD_FAIL_ACTION':
                return {
                    ...state,
                    isAddingNewOne: false,
                    successAddingNewOne: false,
                    failOnAddingNewOne: true,
                    error: action.error
                };
            case 'CONTRACT_UPDATE_REQUEST_ACTION':
                return {
                    ...state,
                    isUpdatingOne: true,
                    successUpdatingOne: false,
                    failOnUpdatingOne: false,
                    error: undefined
                };
            case 'CONTRACT_UPDATE_RECEIVE_ACTION':
                const UpdatedContract = state.list ? state.list.find((item: Contract) => item.id === action.contract.id) : undefined;
                let actionContract = action.contract;

                if ( UpdatedContract ) {
                    actionContract.stages = UpdatedContract.stages;
                    actionContract.cycles = UpdatedContract.cycles;
                    actionContract.concepts = UpdatedContract.concepts;
                    actionContract.branches = UpdatedContract.branches;
                    actionContract.commissionConcepts = UpdatedContract.commissionConcepts;
                    actionContract.addendums = UpdatedContract.addendums;
                }

                return {
                    ...state,
                    isUpdatingOne: false,
                    successUpdatingOne: true,
                    failOnUpdatingOne: false,
                    error: undefined,
                    list: [...state.list.filter((item: Contract) => item.id !== action.contract.id), actionContract]
                };
            case 'CONTRACT_UPDATE_FAIL_ACTION':
                return {
                    ...state,
                    isUpdatingOne: false,
                    successUpdatingOne: false,
                    failOnUpdatingOne: true,
                    error: action.error
                };
            case 'CONTRACT_DELETE_REQUEST_ACTION':
                var ContractToDelete = state.list ? state.list.find((item: Contract) => item.id === action.contractId) : undefined;
                if(ContractToDelete) {
                    ContractToDelete = {...ContractToDelete, isDeleting: true, isDeletedSuccessfully: undefined, failOnDeleting: false, error: undefined};
                }
                return {
                    ...state,
                    isDeletingOne: true,
                    successDeletingOne: false,
                    failOnDeletingOne: false,
                    error: undefined,
                    list: ContractToDelete ? [...state.list.filter((item: Contract) => item.id !== action.contractId), ContractToDelete] : state.list
                };
            case 'CONTRACT_DELETE_RECEIVE_ACTION':
                var ContractDeleted = state.list ? state.list.find((item: Contract) => item.id === action.contractId) : undefined;
                if(ContractDeleted) {
                    ContractDeleted = {...ContractDeleted, isDeleting: false, isDeletedSuccessfully: true, failOnDeleting: false, error: undefined};
                }
                return {
                    ...state,
                    isDeletingOne: false,
                    successDeletingOne: true,
                    failOnDeletingOne: false,
                    error: undefined,
                    list: ContractDeleted ? [...state.list.filter((item: Contract) => item.id !== action.contractId)] : state.list.filter((item: Contract) => item.id !== action.contractId)
                };
            case 'CONTRACT_DELETE_FAIL_ACTION':
                var ContractDeletedFail = state.list ? state.list.find((item: Contract) => item.id === action.contractId) : undefined;
                if(ContractDeletedFail) {
                    ContractDeletedFail = {...ContractDeletedFail, isDeleting: false, isDeletedSuccessfully: false, failOnDeleting: true, error: action.error};
                }
                return {
                    ...state,
                    isDeletingOne: false,
                    successDeletingOne: false,
                    failOnDeletingOne: true,
                    error: action.error,
                    list: ContractDeletedFail ? [...state.list.filter((item: Contract) => item.id !== action.contractId), ContractDeletedFail] : state.list
                };
            case 'CONTRACT_CHANGE_FEEDIVISION_REQUEST_ACTION':
                return {
                    ...state,
                    isChangingFeeDivision: true,
                    FeeDivisionChangesSuccessfully: false,
                    FailChangingFeeDivision: false,
                    error: undefined
                };
            case 'CONTRACT_CHANGE_FEEDIVISION_RECEIVE_ACTION':
                var NewFeeDicisionContract = state.list ? state.list.find((item: Contract) => item.id === action.contractId) : undefined;

                if(NewFeeDicisionContract) {
                    NewFeeDicisionContract.feeDivision = action.feeDivision;
                }

                return {
                    ...state,
                    isChangingFeeDivision: false,
                    FeeDivisionChangesSuccessfully: true,
                    FailChangingFeeDivision: false,
                    error: undefined,
                    list: NewFeeDicisionContract? [...state.list.filter((item: Contract) => item.id !== action.contractId), NewFeeDicisionContract] : state.list
                };
            case 'CONTRACT_CHANGE_FEEDIVISION_FAIL_ACTION':
                return {
                    ...state,
                    isChangingFeeDivision: false,
                    FeeDivisionChangesSuccessfully: false,
                    FailChangingFeeDivision: true,
                    error: action.error
                };
            case 'CONTRACT_CLONE_REQUEST_ACTION':
                var EntityToClone = state.list ? state.list.find((item: Contract) => item.id === action.cloneInfo.contractToCloneId) : undefined;
                if(EntityToClone !== undefined) {
                    EntityToClone = {...EntityToClone, isCloning: true, isClonedSuccessfully: false, failOnCloning: false, error: undefined};
                }
                return {
                    ...state,
                    list: EntityToClone ? [...state.list.filter((item: Contract) => item.id !== action.cloneInfo.contractToCloneId), EntityToClone] : state.list,
                    error: undefined
                };
            case 'CONTRACT_CLONE_RECEIVE_ACTION':
                var EntityCloned = state.list ? state.list.find((item: Contract) => item.id === action.originalContractId) : undefined;
                if(EntityCloned !== undefined) {
                    EntityCloned = {...EntityCloned, isCloning: false, isClonedSuccessfully: true, failOnCloning: false, error: undefined};
                }
                return {
                    ...state,
                    list: EntityCloned ? [...[...state.list.filter((item: Contract) => item.id !== action.originalContractId), EntityCloned], action.contract] : [...state.list, action.contract],
                    error: undefined
                };
            case 'CONTRACT_CLONE_FAIL_ACTION':
                var EntityClonedFail = state.list ? state.list.find((item: Contract) => item.id === action.contractId) : undefined;
                if(EntityClonedFail !== undefined) {
                    EntityClonedFail = {...EntityClonedFail, isCloning: false, isClonedSuccessfully: false, failOnCloning: true, error: action.error};
                }
                return {
                    ...state,
                    list: EntityClonedFail ? [...state.list.filter((item: Contract) => item.id !== action.contractId), EntityClonedFail] : state.list,
                    error: action.error
                };
            // addendums
            case 'CONTRACT_ADDENDUMS_GETBYCONTRACTID_REQUEST':
                
                CurrentContract = state.list ? state.list.find((item: Contract) => item.id === action.contractId) : undefined;
                
                if(CurrentContract !== undefined) {
                    CurrentContract = {
                        ...CurrentContract, 
                        isGettingContractAddendums: true, 
                        isGettingContractAddendumsSuccess: false, 
                        FailGettingContractAddendums: false, 
                        ErrorGettingContractAddendums: undefined
                    };
                }

                return {
                    ...state,
                    list: CurrentContract ? [...state.list.filter((item: Contract) => item.id !== action.contractId), CurrentContract] : state.list,
                    error: undefined
                };
            case 'CONTRACT_ADDENDUMS_GETBYCONTRACTID_SUCCESS':
                
                CurrentContract = state.list ? state.list.find((item: Contract) => item.id === action.contractId) : undefined;
                               
                if(CurrentContract !== undefined) {

                    if(CurrentContract.addendums?.length) {
                        CurrentContract.addendums = action.addendums?.map((addendum: ContractAddendum) => {
                            var auxAddendum = CurrentContract?.addendums?.find((item: ContractAddendum) => item.id === addendum.id);

                            if(auxAddendum) {
                                auxAddendum = {...addendum,
                                stages: auxAddendum.stages,
                                cycles: auxAddendum.cycles,
                                concepts: auxAddendum.concepts,
                                branches: auxAddendum.branches};
                            }

                            return auxAddendum || addendum
                        });
                    } else {
                        CurrentContract.addendums = action.addendums;
                    }
                   
                    CurrentContract = {
                        ...CurrentContract, 
                        isGettingContractAddendums: false, 
                        isGettingContractAddendumsSuccess: true, 
                        FailGettingContractAddendums: false, 
                        ErrorGettingContractAddendums: undefined,
                    };
                }

                return {
                    ...state,
                    list: CurrentContract ? [...state.list.filter((item: Contract) => item.id !== CurrentContract!.id), CurrentContract] : state.list,
                    error: undefined
                };
            case 'CONTRACT_ADDENDUMS_GETBYCONTRACTID_FAILURE':
                
                CurrentContract = state.list ? state.list.find((item: Contract) => item.id === action.contractId) : undefined;

                if(CurrentContract !== undefined) {
                    CurrentContract = {
                        ...CurrentContract, 
                        isGettingContractAddendums: false, 
                        isGettingContractAddendumsSuccess: false, 
                        FailGettingContractAddendums: true, 
                        ErrorGettingContractAddendums: action.error
                    };
                }

                return {
                    ...state,
                    list: CurrentContract ? [...state.list.filter((item: Contract) => item.id !== action.contractId), CurrentContract] : state.list,
                    error: action.error
                };
            case 'CONTRACT_ADDENDUM_GETBYID_REQUEST':
                CurrentContract = state.list ? state.list.find((item: Contract) => item.id === action.contractId) : undefined;
                
                if(CurrentContract) {

                    var CurrentAddendum = CurrentContract.addendums ? CurrentContract.addendums.find((item: ContractAddendum) => item.id === action.addendumId) : undefined;

                    if(CurrentAddendum) {
                        CurrentAddendum = {
                            ...CurrentAddendum, 
                            isUpdating: true, 
                            isUpdatedSuccessfully: false, 
                            failOnUpdating: false, 
                            error: undefined
                        };

                        CurrentContract.addendums = [...CurrentContract.addendums.filter((item: ContractAddendum) => item.id !== action.addendumId), CurrentAddendum];
                    }

                    CurrentContract = {
                        ...CurrentContract, 
                        isUpdating: true, 
                        isGettingContractAddendumsSuccess: false, 
                        FailGettingContractAddendums: false, 
                        error: undefined
                    };
                }
                return {
                    ...state,
                    list: CurrentContract ? [...state.list.filter((item: Contract) => item.id !== CurrentContract!.id), CurrentContract] : state.list,
                };
            case 'CONTRACT_ADDENDUM_GETBYID_SUCCESS':

                CurrentContract = state.list ? state.list.find((item: Contract) => item.id === action.addendum.contractId) : undefined;
                
                if(CurrentContract) {

                    const CurrentAddendum = {...CurrentContract.addendums ? CurrentContract.addendums.find((item: ContractAddendum) => item.id === action.addendum.id) : undefined, ...action.addendum};

                    CurrentContract.addendums = CurrentContract.addendums ? 
                        [...CurrentContract.addendums.filter((item: ContractAddendum) => item.id !== action.addendum.id), action.addendum] 
                        : [action.addendum];

                    CurrentContract = {
                        ...CurrentContract, 
                        isGettingContractAddendums: false, 
                        isGettingContractAddendumsSuccess: true, 
                        FailGettingContractAddendums: false, 
                        error: undefined
                    };
                }
                return {
                    ...state,
                    list: CurrentContract ? [...state.list.filter((item: Contract) => item.id !== CurrentContract!.id), CurrentContract] : state.list,
                };  
            case 'CONTRACT_ADDENDUM_GETBYID_FAILURE':
                CurrentContract = state.list ? state.list.find((item: Contract) => item.id === action.contractId) : undefined;
                
                if(CurrentContract) {
                    
                    var CurrentAddendum = CurrentContract.addendums ? CurrentContract.addendums.find((item: ContractAddendum) => item.id === action.addendumId) : undefined;

                    if(CurrentAddendum) {
                        CurrentAddendum = {
                            ...CurrentAddendum, 
                            isUpdating: false, 
                            isUpdatedSuccessfully: false, 
                            failOnUpdating: true, 
                            error: action.error
                        };

                        CurrentContract.addendums = [...CurrentContract.addendums.filter((item: ContractAddendum) => item.id !== action.addendumId), CurrentAddendum];
                    }
                }
                
                return {
                    ...state,
                    list: CurrentContract ? [...state.list.filter((item: Contract) => item.id !== CurrentContract!.id), CurrentContract] : state.list,
                    error: action.error
                };     
            case 'CONTRACT_ADDENDUM_CREATE_SUCCESS':
                CurrentContract = state.list ? state.list.find((item: Contract) => item.id === action.addendum.contractId) : undefined;
                
                if(CurrentContract) {
                    CurrentContract.addendums = CurrentContract.addendums ? [...CurrentContract.addendums, action.addendum] : [action.addendum];
                }

                return {
                    ...state,
                    list: CurrentContract ? [...state.list.filter((item: Contract) => item.id !== CurrentContract!.id), CurrentContract] : state.list,
                };
            case 'CONTRACT_ADDENDUM_UPDATE_REQUEST':	
                CurrentContract = state.list ? state.list.find((item: Contract) => item.id === action.addendum.contractId) : undefined;
                
                if(CurrentContract) {
                    var CurrentAddendum = CurrentContract.addendums ? CurrentContract.addendums.find((item: ContractAddendum) => item.id === action.addendum.id) : undefined;

                    console.log(CurrentAddendum);
                    
                    if(CurrentAddendum) {

                        CurrentAddendum = {...CurrentAddendum, ...action.addendum, isUpdating: true, isUpdatedSuccessfully: undefined, failOnUpdating: false, ErrorUpdating: undefined};
                    
                        CurrentContract.addendums = CurrentContract.addendums ? [...CurrentContract.addendums.filter((item: ContractAddendum) => item.id !== action.addendum.id), CurrentAddendum] : [CurrentAddendum];
                    }
                }

                return {
                    ...state,
                    list: CurrentContract ? [...state.list.filter((item: Contract) => item.id !== CurrentContract!.id), CurrentContract] : state.list,
                };
            case 'CONTRACT_ADDENDUM_UPDATE_SUCCESS':     
                CurrentContract = state.list ? state.list.find((item: Contract) => item.id === action.addendum.contractId) : undefined;
                
                if(CurrentContract) {

                    var currentaddendum = CurrentContract.addendums ? CurrentContract.addendums.find((item: ContractAddendum) => item.id === action.addendum.id) : undefined;

                    if(currentaddendum) { 
                        
                        var auxAddendum = {...action.addendum, ...currentaddendum, isUpdating: false, isUpdatedSuccessfully: true, failOnUpdating: false, ErrorUpdating: undefined};

                        auxAddendum.stages = currentaddendum.stages;
                        auxAddendum.concepts = currentaddendum.concepts;
                        auxAddendum.branches = currentaddendum.branches;
                        auxAddendum.cycles = currentaddendum.cycles;
                        
                        currentaddendum = auxAddendum;

                        CurrentContract.addendums = CurrentContract.addendums ? [...CurrentContract.addendums.filter((item: ContractAddendum) => item.id !== action.addendum.id), currentaddendum] : [currentaddendum];
                    }
                }

                return {
                    ...state,
                    list: CurrentContract ? [...state.list.filter((item: Contract) => item.id !== CurrentContract!.id), CurrentContract] : state.list,
                };
            case 'CONTRACT_ADDENDUM_UPDATE_FAILURE':
                CurrentContract = state.list ? state.list.find((item: Contract) => item.id === action.contractId) : undefined;
                
                if(CurrentContract) {
                    var CurrentAddendum = CurrentContract.addendums ? CurrentContract.addendums.find((item: ContractAddendum) => item.id === action.addendumId) : undefined;
                    
                    if(CurrentAddendum) {
                        CurrentAddendum = {...CurrentAddendum, isUpdating: false, isUpdatedSuccessfully: false, failOnUpdating: true, ErrorUpdating: action.error};
                    }
                }

                return {
                    ...state,
                    list: CurrentContract ? [...state.list.filter((item: Contract) => item.id !== CurrentContract!.id), CurrentContract] : state.list,
                };
            case 'CONTRACT_ADDENDUM_DELETE_SUCCESS':
                CurrentContract = state.list ? state.list.find((item: Contract) => item.id === action.contractId) : undefined;
                
                if(CurrentContract) {
                    CurrentContract.addendums = CurrentContract.addendums ? [...CurrentContract.addendums.filter((item: ContractAddendum) => item.id !== action.addendumId)] : [];
                }

                return {
                    ...state,
                    list: CurrentContract ? [...state.list.filter((item: Contract) => item.id !== CurrentContract!.id), CurrentContract] : state.list,
                };
            case 'CONTRACT_ADDENDUM_CLONE_SUCCESS':
                CurrentContract = state.list ? state.list.find((item: Contract) => item.id === action.contractId) : undefined;

                if(CurrentContract) {
                    CurrentContract.addendums = CurrentContract.addendums ? [...CurrentContract.addendums, action.addendum] : [action.addendum];
                }

                return {
                    ...state,
                    list: CurrentContract ? [...state.list.filter((item: Contract) => item.id !== CurrentContract!.id), CurrentContract] : state.list,
                };
            // Stages
            case 'CONTRACTSTAGE_GET_ALL_BY_CONTRACTID_RECEIVE_ACTION':
                var contractStageAllGetted = state.list ? state.list.find((item: Contract) => item.id === action.contractId) : undefined;
                return {
                    ...state,
                    list: contractStageAllGetted ? [...state.list.filter((item: Contract) => item.id !== contractStageAllGetted!.id), {...contractStageAllGetted, stages: action.contractStages }]: state.list,
                };
            case 'CONTRACTSTAGE_UPDATE_ALL_BY_CONTRACTID_RECEIVE_ACTION':
                var contractStageUdated = state.list ? state.list.find((item: Contract) => item.id === action.contractId) : undefined;
                return {
                    ...state,
                    list: contractStageUdated ? [...state.list.filter((item: Contract) => item.id !== contractStageUdated!.id), {...contractStageUdated, stages: action.contractStages}] : state.list,
                };
            case 'CONTRACTSTAGE_GET_BY_ID_RECEIVE_ACTION':
                var contractStageGetById = state.list ? state.list.find((item: Contract) => item.id === action.contractStage.contractId) : undefined;
                return {
                    ...state,
                    list: contractStageGetById ? [...state.list.filter((item: Contract) => item.id !== contractStageGetById!.id), 
                        {...contractStageGetById, 
                            stages: contractStageGetById.stages ? 
                            [...contractStageGetById.stages.filter((item: any) => item.id !== action.contractStage.id), action.contractStage]
                            : [action.contractStage]}]
                        : state.list
                };
            case 'CONTRACTSTAGE_ADD_RECEIVE_ACTION':
                CurrentContract = state.list ? state.list.find((item: Contract) => item.id === action.contractStage.contractId) : undefined;

                if(CurrentContract) {
                    
                    var CurrentAddendum = CurrentContract.addendums?.find((item: ContractAddendum) => item.id === action.contractStage.contractAddendumId);

                    if(CurrentAddendum) {
                        CurrentAddendum = {...CurrentAddendum, stages: [...CurrentAddendum.stages || [], action.contractStage]};

                        CurrentContract.addendums = [...(CurrentContract.addendums || []).filter((addendum: ContractAddendum) => addendum.id !== CurrentAddendum?.id), CurrentAddendum];
                    }  
                }

                return {
                    ...state,
                    list: CurrentContract ? [...state.list.filter((item: Contract) => item.id !== CurrentContract!.id), CurrentContract] : state.list
                };
            case 'CONTRACTSTAGE_UPDATE_REQUEST_ACTION':
            
                CurrentContract = state.list ? state.list.find((item: Contract) => item.id === action.contractStage.contractId) : undefined;

                if(CurrentContract) {

                    var CurrentAddendum = CurrentContract.addendums?.find((item: ContractAddendum) => item.id === action.contractStage.contractAddendumId);

                    if(CurrentAddendum) {
                        
                        var currentStage = CurrentAddendum.stages?.find((item: ContractStage) => item.id === action.contractStage.id);

                        if(currentStage) {
                            
                            currentStage = {
                                ...currentStage,
                                isUpdating: true,
                                isUpdatedSuccessfully: undefined, 
                                failOnUpdating: false,
                                errorOnUpdating: undefined
                            };

                            CurrentAddendum.stages = [...(CurrentAddendum.stages || []).filter((stage: ContractStage) => stage.id !== currentStage!.id), currentStage]; 
                        }

                        CurrentContract.addendums = [...(CurrentContract.addendums || []).filter((addendum: ContractAddendum) => addendum.id !== CurrentAddendum?.id), CurrentAddendum];
                    }
                }

                return {
                    ...state,
                    list: CurrentContract ? [...state.list.filter((item: Contract) => item.id !== CurrentContract!.id), CurrentContract] : state.list
                };
            case 'CONTRACTSTAGE_UPDATE_RECEIVE_ACTION':
                
                CurrentContract = state.list ? state.list.find((item: Contract) => item.id === action.contractStage.contractId) : undefined;

                if(CurrentContract) {

                    var CurrentAddendum = CurrentContract.addendums?.find((item: ContractAddendum) => item.id === action.contractStage.contractAddendumId);

                    if(CurrentAddendum) {
                        
                        var currentStage = CurrentAddendum.stages?.find((item: ContractStage) => item.id === action.contractStage.id);
                        
                        if(currentStage) {

                            currentStage = {...currentStage, ...action.contractStage};
                            
                            currentStage = {
                                ...currentStage,
                                isUpdating: false,
                                isUpdatedSuccessfully: true, 
                                failOnUpdating: false,
                                errorOnUpdating: undefined
                            };

                            CurrentAddendum.stages = [...(CurrentAddendum.stages || []).filter((stage: ContractStage) => stage.id !== currentStage!.id), currentStage]; 

                        }

                        CurrentContract.addendums = [...(CurrentContract.addendums || []).filter((addendum: ContractAddendum) => addendum.id !== CurrentAddendum?.id), CurrentAddendum];
                    }

                }
                
                return {
                    ...state,
                    list: CurrentContract ? [...state.list.filter((item: Contract) => item.id !== CurrentContract!.id), CurrentContract] : state.list
                };
            case 'CONTRACTSTAGE_DELETE_RECEIVE_ACTION':
                CurrentContract = state.list ? state.list.find((item: Contract) => item.id === action.contractId) : undefined;
                
                if(CurrentContract) {
                    const CurrentAddendum = CurrentContract.addendums?.find((item: ContractAddendum) => item.id === action.contractAddendumId);

                    if(CurrentAddendum) {

                        CurrentAddendum.stages = CurrentAddendum.stages ? [...CurrentAddendum.stages.filter((item: ContractStage) => item.id !== action.contractStageId)] : [];

                    }
                }

                return {
                    ...state,
                    list: CurrentContract ? [...state.list.filter((item: Contract) => item.id !== CurrentContract!.id), CurrentContract] : state.list
                };
            // cycles
            case 'CONTRACTSTAGECYCLE_ADD_RECEIVE_ACTION':
                CurrentContract = state.list ? state.list.find((item: Contract) => item.id === action.contractStageCycle.contractId) : undefined;

                if(CurrentContract) {

                    const CurrentAddendum = CurrentContract.addendums?.find((item: ContractAddendum) => item.id === action.contractStageCycle.contractAddendumId);

                    if(CurrentAddendum) {

                        CurrentAddendum.cycles = [...(CurrentAddendum.cycles || []).filter((cycle: StageCycle) => cycle.id !== action.contractStageCycle.id), action.contractStageCycle];

                        CurrentContract.addendums = [...(CurrentContract.addendums || []).filter((addendum: ContractAddendum) => addendum.id !== CurrentAddendum?.id), CurrentAddendum ];
                    }

                }

                return {
                    ...state,
                    list: CurrentContract ? [...state.list.filter((item: Contract) => item.id !== CurrentContract!.id), CurrentContract] : state.list
                };
            case 'CONTRACTSTAGECYCLE_UPDATE_REQUEST_ACTION':
                CurrentContract = state.list ? state.list.find((item: Contract) => item.id === action.contractStageCycle.contractId) : undefined;

                if( CurrentContract ) {
                    
                    const CurrentAddendum = CurrentContract.addendums?.find((item: ContractAddendum) => item.id === action.contractStageCycle.contractAddendumId);

                    if(CurrentAddendum) {

                        var currentCycle = CurrentAddendum.cycles?.find((item: StageCycle) => item.id === action.contractStageCycle.id);

                        if(currentCycle) {
                            currentCycle = {
                                ...currentCycle,
                                isUpdating: true,
                                isUpdatedSuccessfully: undefined, 
                                failOnUpdating: false,
                                errorOnUpdating: undefined
                            };

                            CurrentAddendum.cycles = [...(CurrentAddendum.cycles || []).filter((cycle: StageCycle) => cycle.id !== currentCycle!.id), currentCycle];
                        }

                        CurrentContract.addendums = [...(CurrentContract.addendums || []).filter((addendum: ContractAddendum) => addendum.id !== CurrentAddendum?.id), CurrentAddendum];
                    }
                } 

                return {
                    ...state,
                    list: CurrentContract ? [...state.list.filter((item: Contract) => item.id !== CurrentContract!.id), CurrentContract] : state.list
                };
            case 'CONTRACTSTAGECYCLE_UPDATE_RECEIVE_ACTION':
            
                CurrentContract = state.list ? state.list.find((item: Contract) => item.id === action.contractStageCycle.contractId) : undefined;

                if(CurrentContract) {

                    const CurrentAddendum = CurrentContract.addendums?.find((item: ContractAddendum) => item.id === action.contractStageCycle.contractAddendumId);

                    if(CurrentAddendum) {

                        var currentCycle = CurrentAddendum.cycles?.find((item: StageCycle) => item.id === action.contractStageCycle.id);

                        if(currentCycle) {

                            currentCycle = {...currentCycle, ...action.contractStageCycle};

                            currentCycle = {
                                ...currentCycle,
                                isUpdating: false,
                                isUpdatedSuccessfully: true, 
                                failOnUpdating: false,
                                errorOnUpdating: undefined
                            };

                            CurrentAddendum.cycles = [...(CurrentAddendum.cycles || []).filter((cycle: StageCycle) => cycle.id !== currentCycle!.id), currentCycle]; 

                        }

                        CurrentContract.addendums = [...(CurrentContract.addendums || []).filter((addendum: ContractAddendum) => addendum.id !== CurrentAddendum?.id), CurrentAddendum];
                    }
                }

                return {
                    ...state,
                    list: CurrentContract ? [...state.list.filter((item: Contract) => item.id !== CurrentContract!.id), CurrentContract] : state.list
                };
            case 'CONTRACTSTAGECYCLE_DELETE_RECEIVE_ACTION':
                CurrentContract = state.list ? state.list.find((item: Contract) => item.id === action.contractId) : undefined;
                
                if(CurrentContract) {
                    const CurrentAddendum = CurrentContract.addendums?.find((item: ContractAddendum) => item.id === action.contractAddendumId);

                    if(CurrentAddendum) {
                        CurrentAddendum.cycles = CurrentAddendum.cycles ? [...CurrentAddendum.cycles.filter((item: StageCycle) => item.id !== action.contractStageCycleId)] : [];
                    }
                }

                return {
                    ...state,
                    list: CurrentContract ? [...state.list.filter((item: Contract) => item.id !== CurrentContract!.id), CurrentContract] : state.list
                };
            case 'CONTRACTSTAGECYCLE_GET_ALL_BY_CONTRACTID_RECEIVE_ACTION':
                var contractCycleAllGetted = state.list ? state.list.find((item: Contract) => item.id === action.contractId) : undefined;
                return {
                    ...state,
                    list: contractCycleAllGetted ? [...state.list.filter((item: Contract) => item.id !== contractCycleAllGetted!.id), {...contractCycleAllGetted, cycles: action.contractStageCycles}] : state.list
                };
            case 'CONTRACTSTAGECYCLE_UPDATE_ALL_BY_CONTRACTID_RECEIVE_ACTION':
                var contractCycleAllUpdated = state.list ? state.list.find((item: Contract) => item.id === action.contractId) : undefined;
                return {
                    ...state,
                    list: contractCycleAllUpdated ? [...state.list.filter((item: Contract) => item.id !== contractCycleAllUpdated!.id), {...contractCycleAllUpdated, cycles: action.contractStageCycles}] : state.list
                };
            // concepts
            case 'CONTRACTCONCEPT_ADD_RECEIVE_ACTION':

                CurrentContract = state.list ? state.list.find((item: Contract) => item.id === action.contractConcept.contractId) : undefined;
                
                if(CurrentContract) {

                    var CurrentAddendum = CurrentContract.addendums?.find((item: ContractAddendum) => item.id === action.contractConcept.contractAddendumId);

                    if(CurrentAddendum) {

                        CurrentAddendum.concepts = [...CurrentAddendum.concepts || [], action.contractConcept];

                        CurrentContract.addendums = [...(CurrentContract.addendums || []).filter((addendum: ContractAddendum) => addendum.id !== CurrentAddendum?.id), CurrentAddendum];   
                    
                    }
                }

                return {
                    ...state,
                    list: CurrentContract ? [...state.list.filter((item: Contract) => item.id !== CurrentContract!.id) || [], CurrentContract] : state.list || []
                };
            case 'CONTRACTCONCEPT_UPDATE_RECEIVE_ACTION':
                
                CurrentContract = state.list ? state.list.find((item: Contract) => item.id === action.contractConcept.contractId) : undefined;
            
                if(CurrentContract) {

                    var CurrentAddendum = CurrentContract.addendums?.find((item: ContractAddendum) => item.id === action.contractConcept.contractAddendumId);

                    if(CurrentAddendum) {

                        var CurrentConcept = CurrentAddendum.concepts?.find((item: ContractConcept) => item.id === action.contractConcept.id);

                        if(CurrentConcept) {

                            var UpdatedConcept = action.contractConcept;

                            UpdatedConcept.paymentSchedule = CurrentConcept.paymentSchedule;
                            UpdatedConcept.concept = CurrentConcept.concept;
                            

                            CurrentAddendum.concepts = [...(CurrentAddendum.concepts || []).filter((concept: ContractConcept) => concept.id !== UpdatedConcept!.id), UpdatedConcept];

                            CurrentContract.addendums = [...(CurrentContract.addendums || []).filter((addendum: ContractAddendum) => addendum.id !== CurrentAddendum?.id), CurrentAddendum];
                        
                        } else {

                            CurrentAddendum.concepts = [...(CurrentAddendum.concepts || []), action.contractConcept];

                            CurrentContract.addendums = [...(CurrentContract.addendums || []).filter((addendum: ContractAddendum) => addendum.id !== CurrentAddendum?.id), CurrentAddendum];

                        }

                    }
                }

                return {
                    ...state,
                    list: CurrentContract ? [...state.list.filter((item: Contract) => item.id !== CurrentContract!.id), CurrentContract] : state.list
                };
            case 'CONTRACTCONCEPT_DELETE_RECEIVE_ACTION':
                
                CurrentContract = state.list ? state.list.find((item: Contract) => item.id === action.contractId) : undefined;

                if(CurrentContract) {

                    var CurrentAddendum = CurrentContract.addendums?.find((item: ContractAddendum) => item.id === action.addendumId);

                    if(CurrentAddendum) {

                        CurrentAddendum.concepts = CurrentAddendum.concepts ? [...CurrentAddendum.concepts.filter((item: ContractConcept) => item.id !== action.contractConceptId)] : [];

                        CurrentContract.addendums = [...(CurrentContract.addendums || []).filter((addendum: ContractAddendum) => addendum.id !== CurrentAddendum?.id), CurrentAddendum];
                    }
                }

                return {
                    ...state,
                    list: CurrentContract ? [...state.list.filter((item: Contract) => item.id !== CurrentContract!.id), CurrentContract] : state.list
                };
            case 'CONTRACTCONCEPT_GET_ALL_BY_CONTRACTID_RECEIVE_ACTION':
                var contractConceptAllGetted = state.list ? state.list.find((item: Contract) => item.id === action.contractId) : undefined;
                return {
                    ...state,
                    list: contractConceptAllGetted ? [...state.list.filter((item: Contract) => item.id !== contractConceptAllGetted!.id), 
                    {...contractConceptAllGetted, concepts: action.contractConcepts}] : state.list
                };
            case 'CONTRACTCONCEPT_UPDATE_ALL_BY_CONTRACTID_RECEIVE_ACTION':
                var contractConceptAllUpdated = state.list ? state.list.find((item: Contract) => item.id === action.contractId) : undefined;
                return {
                    ...state,
                    list: contractConceptAllUpdated ? [...state.list.filter((item: Contract) => item.id !== contractConceptAllUpdated!.id), 
                        {...contractConceptAllUpdated, concepts: action.contractConcepts}] : state.list
                };              
            // payments
            case 'CONTRACTCONCEPTPAYMENTSCHEDULE_ADD_RECEIVE_ACTION':
                CurrentContract = state.list ? state.list.find((item: Contract) => item.id === action.contractId) : undefined;

                if(CurrentContract) {
                        
                    var CurrentAddendum = CurrentContract.addendums?.find((item: ContractAddendum) => item.id === action.contactAddendumId);

                    if(CurrentAddendum) {

                        var CurrentConcept = CurrentAddendum.concepts?.find((item: ContractConcept) => item.id === action.contractConceptPaymentSchedule.contractConceptId);

                        if(CurrentConcept) {

                            CurrentConcept.paymentSchedule = CurrentConcept.paymentSchedule ? [...CurrentConcept.paymentSchedule, action.contractConceptPaymentSchedule] : [action.contractConceptPaymentSchedule];

                            CurrentAddendum.concepts = [...(CurrentAddendum.concepts || []).filter((concept: ContractConcept) => concept.id !== CurrentConcept?.id), CurrentConcept];

                            CurrentContract.addendums = [...(CurrentContract.addendums || []).filter((addendum: ContractAddendum) => addendum.id !== CurrentAddendum?.id), CurrentAddendum];
                        }
                    }
                }

                return {
                    ...state,
                    list: CurrentContract ? [...state.list.filter((item: Contract) => item.id !== CurrentContract?.id), CurrentContract] : state.list
                };
            case 'CONTRACTCONCEPTPAYMENTSCHEDULE_UPDATE_REQUEST_ACTION':
                CurrentContract = state.list ? state.list.find((item: Contract) => item.id === action.contractId) : undefined;

                if(CurrentContract) {
                        
                    var CurrentAddendum = CurrentContract.addendums?.find((item: ContractAddendum) => item.id === action.contractAddendumId);

                    if(CurrentAddendum) {

                        var CurrentConcept = CurrentAddendum.concepts?.find((item: ContractConcept) => item.id === action.contractConceptId);

                        if(CurrentConcept) {

                            var CurrentConceptPaymentSchedule = CurrentConcept.paymentSchedule?.find((item: ContractConceptPaymentSchedule) => item.id === action.contractConceptPaymentSchedule.id);

                            if(CurrentConceptPaymentSchedule) {

                                CurrentConceptPaymentSchedule = {
                                    ...CurrentConceptPaymentSchedule,
                                    isUpdating: true,
                                    isUpdatedSuccessfully: undefined, 
                                    failOnUpdating: false,
                                    errorOnUpdating: undefined
                                };

                                CurrentConcept.paymentSchedule = [...(CurrentConcept.paymentSchedule || []).filter((paymentSchedule: ContractConceptPaymentSchedule) => paymentSchedule.id !== CurrentConceptPaymentSchedule!.id), CurrentConceptPaymentSchedule];

                                CurrentAddendum.concepts = [...(CurrentAddendum.concepts || []).filter((concept: ContractConcept) => concept.id !== CurrentConcept?.id), CurrentConcept];

                                CurrentContract.addendums = [...(CurrentContract.addendums || []).filter((addendum: ContractAddendum) => addendum.id !== CurrentAddendum?.id), CurrentAddendum];
                            }
                        }
                    }
                }

                return {
                    ...state,
                    list: CurrentContract ? [...state.list.filter((item: Contract) => item.id !== CurrentContract?.id), CurrentContract] : state.list
                };
            case 'CONTRACTCONCEPTPAYMENTSCHEDULE_UPDATE_RECEIVE_ACTION':
                CurrentContract = state.list ? state.list.find((item: Contract) => item.id === action.contractId) : undefined;

                if(CurrentContract) {
                        
                    var CurrentAddendum = CurrentContract.addendums?.find((item: ContractAddendum) => item.id === action.contractAddendumId);

                    if(CurrentAddendum) {

                        var CurrentConcept = CurrentAddendum.concepts?.find((item: ContractConcept) => item.id === action.contractConceptId);

                        if(CurrentConcept) {

                            var CurrentConceptPaymentSchedule: ContractConceptPaymentSchedule | undefined  = action.contractConceptPaymentSchedule;

                            CurrentConceptPaymentSchedule.isUpdatedSuccessfully = true;

                            CurrentConcept.paymentSchedule = CurrentConcept.paymentSchedule ? [...CurrentConcept.paymentSchedule.filter((item: ContractConceptPaymentSchedule) => item.id !== action.contractConceptPaymentSchedule.id), action.contractConceptPaymentSchedule] : [action.contractConceptPaymentSchedule];
                            
                            CurrentAddendum.concepts = [...(CurrentAddendum.concepts || []).filter((concept: ContractConcept) => concept.id !== CurrentConcept?.id), CurrentConcept];

                            CurrentContract.addendums = [...(CurrentContract.addendums || []).filter((addendum: ContractAddendum) => addendum.id !== CurrentAddendum?.id), CurrentAddendum];
                        }
                    }
                }

                return {
                    ...state,
                    list: CurrentContract ? [...state.list.filter((item: Contract) => item.id !== CurrentContract?.id), CurrentContract] : state.list
                };
            case 'CONTRACTCONCEPTPAYMENTSCHEDULE_GET_ALL_BY_CONTRACTCONCEPTID_RECEIVE_ACTION':
                
                CurrentContract = state.list ? state.list.find((item: Contract) => item.id === action.contractId) : undefined;
                
                if(CurrentContract) {

                    var CurrentAddendum = CurrentContract.addendums?.find((item: ContractAddendum) => item.id === action.contractAddendumId);

                    if(CurrentAddendum) {

                        var CurrentConcept = CurrentAddendum.concepts?.find((item: ContractConcept) => item.id === action.contractConceptId);

                        if (CurrentConcept) {

                            CurrentConcept.paymentSchedule = action.contractConceptPaymentSchedules;

                            CurrentAddendum.concepts = [...(CurrentAddendum.concepts || []).filter((concept: ContractConcept) => concept.id !== CurrentConcept?.id), CurrentConcept];

                            CurrentContract.addendums = [...(CurrentContract.addendums || []).filter((addendum: ContractAddendum) => addendum.id !== CurrentAddendum?.id), CurrentAddendum];

                        }

                    }

                }

                return {
                    ...state,
                    list: CurrentContract ? [...state.list.filter((item: Contract) => item.id !== CurrentContract?.id), CurrentContract] : state.list
                };
            case 'CONTRACTCONCEPTPAYMENTSCHEDULE_UPDATE_ALL_BY_CONTRACTCONCEPTID_RECEIVE_ACTION':
                var contractUpdateAll = state.list ? state.list.find((item: Contract) => item.id === action.contractId) : undefined;
                var contractConceptUpdateAll = contractUpdateAll?.concepts?.find((item: ContractConcept) => item.id === action.contractConceptId);
                var ContractConceptsFiteredUpdateAll = contractUpdateAll?.concepts?.filter((item: ContractConcept) => item.id !== action.contractConceptId);
                var NewContractConceptListUpdateAll = ContractConceptsFiteredUpdateAll ? [...ContractConceptsFiteredUpdateAll, {...contractConceptUpdateAll, paymentSchedule: action.contractConceptPaymentSchedules}] : [...[], {...contractConceptUpdateAll, paymentSchedule: action.contractConceptPaymentSchedules}];
                var NewcontractUpdateAll = {...contractUpdateAll, concepts: NewContractConceptListUpdateAll} as Contract;
                return {
                    ...state,
                    list: contractUpdateAll ? [...state.list.filter((item: Contract) => item.id !== NewcontractUpdateAll.id), NewcontractUpdateAll] : state.list
                };
            case 'CONTRACTCONCEPTPAYMENTSCHEDULE_DELETE_RECEIVE_ACTION':
                CurrentContract = state.list ? state.list?.find((item: Contract) => item.id === action.contractId) : undefined;

                if(CurrentContract) {

                    var CurrentAddendum = CurrentContract.addendums?.find((item: ContractAddendum) => item.id === action.contractAddendumId);

                    if(CurrentAddendum) {

                        var CurrentConcept = CurrentAddendum.concepts?.find((item: ContractConcept) => item.id === action.contractConceptId);

                        if(CurrentConcept) {

                            CurrentConcept.paymentSchedule = CurrentConcept.paymentSchedule ? [...CurrentConcept.paymentSchedule.filter((item: ContractConceptPaymentSchedule) => item.id !== action.contractConceptPaymentScheduleId)] : [];

                            CurrentAddendum.concepts = [...(CurrentAddendum.concepts || []).filter((concept: ContractConcept) => concept.id !== CurrentConcept?.id), CurrentConcept];

                            CurrentContract.addendums = [...(CurrentContract.addendums || []).filter((addendum: ContractAddendum) => addendum.id !== CurrentAddendum?.id), CurrentAddendum];
                        }
                    }
                }

                return {
                    ...state,
                    list: CurrentContract ? [...state.list.filter((item: Contract) => item.id !== CurrentContract?.id), CurrentContract] : state.list
                };               
            // concepts
            case 'CONTRACTCONCEPTCONCEPT_ADD_RECEIVE_ACTION':
                var auxContractAdd = state.list ? state.list?.find((item: Contract) => item.id === action.contractId) : undefined;
                if (auxContractAdd) {
                   var currentContactConceptAdd = auxContractAdd?.concepts?.find((concepts: ContractConcept) =>  concepts.id === action.contractConceptConcept.contractConceptId);
                   if(currentContactConceptAdd) {
                    currentContactConceptAdd.concepts?.push(action.contractConceptConcept);
                    auxContractAdd.concepts = [...auxContractAdd?.concepts?.filter((item: ContractConcept) => item.id !== currentContactConceptAdd?.id), currentContactConceptAdd];
                   }
                }
                return {
                    ...state,
                    list: auxContractAdd ? [...state.list.filter((item: Contract) => item.id !== auxContractAdd?.id), auxContractAdd] : state.list
                };
            case 'CONTRACTCONCEPTCONCEPT_UPDATE_RECEIVE_ACTION':
                var auxContractUpdate = state.list ? state.list.find((item: Contract) => item.id === action.contractId) : undefined;
                if (auxContractUpdate) {
                     var currentContactConceptUpdate = auxContractUpdate.concepts.find((concepts: ContractConcept) =>  concepts.id === action.contractConceptConcept.contractConceptId);
                        if(currentContactConceptUpdate) {
                            currentContactConceptUpdate.concepts?.push(action.contractConceptConcept);
                            auxContractUpdate.concepts = [...auxContractUpdate.concepts?.filter((item: ContractConcept) => item.id !== currentContactConceptUpdate?.id), currentContactConceptUpdate];
                        }
                }
                return {
                    ...state,
                    list: auxContractUpdate ? [...state.list.filter((item: Contract) => item.id !== auxContractUpdate?.id), auxContractUpdate] : state.list
                };
            case 'CONTRACTCONCEPTCONCEPT_GET_ALL_BY_CONTRACTCONCEPTID_RECEIVE_ACTION':
                CurrentContract = state.list ? state.list.find((item: Contract) => item.id === action.contractId) : undefined;

                if(CurrentContract) {
                    var CurrentAddendum = CurrentContract.addendums?.find((item: ContractAddendum) => item.id === action.addendumId);

                    if(CurrentAddendum) {

                        var CurrentConcept = CurrentAddendum.concepts?.find((item: ContractConcept) => item.id === action.contractConceptId);

                        if(CurrentConcept) {

                            CurrentConcept.concepts = action.contractConceptConcepts;

                            CurrentAddendum.concepts = [...(CurrentAddendum.concepts || []).filter((concept: ContractConcept) => concept.id !== CurrentConcept?.id), CurrentConcept];

                            CurrentContract.addendums = [...(CurrentContract.addendums || []).filter((addendum: ContractAddendum) => addendum.id !== CurrentAddendum?.id), CurrentAddendum];

                        }

                    }

                }

                return {
                    ...state,
                    list: CurrentContract ? [...state.list.filter((item: Contract) => item.id !== CurrentContract?.id), CurrentContract] : state.list
                };


            case 'CONTRACTCONCEPTCONCEPT_UPDATE_ALL_BY_CONTRACTCONCEPTID_RECEIVE_ACTION':
                var auxContractUpdateAll = state.list ? state.list.find((item: Contract) => item.id === action.contractId) : undefined;
                if (auxContractUpdateAll) {
                    var currentContactConceptUpdateAll = auxContractUpdateAll.concepts.find((concepts: ContractConcept) =>  concepts.id === action.contractConceptId);
                    if(currentContactConceptUpdateAll) {
                        currentContactConceptUpdateAll.concepts = action.contractConceptConcepts;
                        auxContractUpdateAll.concepts = [...auxContractUpdateAll.concepts?.filter((item: ContractConcept) => item.id !== currentContactConceptUpdateAll?.id), currentContactConceptUpdateAll].sort(Functions.DynamicSort('contractNumber'));
                    }
                }
                return {
                    ...state,
                    list: (auxContractUpdateAll ? [...state.list.filter((item: Contract) => item.id !== auxContractUpdateAll?.id), auxContractUpdateAll] : state.list).sort(Functions.DynamicSort('contractNumber'))
                };
            case 'CONTRACTCONCEPTCONCEPT_DELETE_RECEIVE_ACTION':
                CurrentContract = state.list ? state.list.find((item: Contract) => item.id === action.contractId) : undefined;

                if(CurrentContract) {
                    
                    var CurrentAddendum = CurrentContract.addendums?.find((item: ContractAddendum) => item.id === action.contractAddendumId);

                    if(CurrentAddendum) {
                        var CurrentConcept = CurrentAddendum.concepts?.find((item: ContractConcept) => item.id === action.contractConceptId);

                        if(CurrentConcept) {
                            CurrentConcept.concepts = CurrentConcept.concepts ? [...CurrentConcept.concepts.filter((item: ContractConceptConcept) => item.id !== action.contractConceptConceptId)] : [];

                            CurrentAddendum.concepts = [...(CurrentAddendum.concepts || []).filter((concept: ContractConcept) => concept.id !== CurrentConcept?.id), CurrentConcept];

                            CurrentContract.addendums = [...(CurrentContract.addendums || []).filter((addendum: ContractAddendum) => addendum.id !== CurrentAddendum?.id), CurrentAddendum];
                        }
                    }
                }

                return {
                    ...state,
                    list: CurrentContract ? [...state.list.filter((item: Contract) => item.id !== CurrentContract?.id), CurrentContract] : state.list
                };

            case 'CONTRACTBRANCH_GET_ALL_BY_CONTRACTID_RECEIVE_ACTION':
                var contractBranch = state.list ? state.list.find((item: Contract) => item.id === action.contractId) : undefined;
                return {
                    ...state,
                    list: (contractBranch ? [...state.list.filter((item: Contract) => item.id !== contractBranch!.id), {...contractBranch, branches: action.contractBranches}] : state.list).sort(Functions.DynamicSort('contractNumber'))
                };
            case 'CONTRACTBRANCH_UPDATE_ALL_BY_CONTRACTID_RECEIVE_ACTION':
                var contractBranchUpdate = state.list ? state.list.find((item: Contract) => item.id === action.contractId) : undefined;
                return {
                    ...state,
                    list: (contractBranchUpdate ? [...state.list.filter((item: Contract) => item.id !== contractBranchUpdate!.id), {...contractBranchUpdate, branches: action.contractBranches}] : state.list).sort(Functions.DynamicSort('contractNumber'))
                };
            case 'CONTRACTBRANCH_GET_BY_ID_RECEIVE_ACTION':
                var contractBranchGet = state.list ? state.list.find((item: Contract) => item.id === action.contractBranch.contractId) : undefined;
                return {
                    ...state,
                    list: (contractBranchGet ? [...state.list.filter((item: Contract) => item.id !== contractBranchGet!.id), {...contractBranchGet, branches: [...contractBranchGet?.branches?.filter((item: any) => item.id !== action.contractBranch.id), action.contractBranch]}] : state.list).sort(Functions.DynamicSort('contractNumber'))
                };
            case 'CONTRACTBRANCH_CREATE_RECEIVE_ACTION':
                var contractBranchCreate = state.list ? state.list.find((item: Contract) => item.id === action.contractBranch.contractId) : undefined;
                return {
                    ...state,
                    list: (contractBranchCreate ? [...state.list.filter((item: Contract) => item.id !== contractBranchCreate!.id), {...contractBranchCreate, branches: [...contractBranchCreate?.branches, action.contractBranch]}] : state.list).sort(Functions.DynamicSort('contractNumber'))
                };
            case 'CONTRACTBRANCH_UPDATE_RECEIVE_ACTION':
                var contractBranchUpdate = state.list ? state.list.find((item: Contract) => item.id === action.contractBranch.contractId) : undefined;
                return {
                    ...state,
                    list: (contractBranchUpdate ? [...state.list.filter((item: Contract) => item.id !== contractBranchUpdate!.id), {...contractBranchUpdate, branches: [...contractBranchUpdate?.branches?.filter((item: any) => item.id !== action.contractBranch.id), action.contractBranch]}] : state.list).sort(Functions.DynamicSort('contractNumber'))
                };
            case 'CONTRACTBRANCH_DELETE_RECEIVE_ACTION':
                var contractBranchDelete = state.list ? state.list.find((item: Contract) => item.id === action.contractId) : undefined;
                return {
                    ...state,
                    list: (contractBranchDelete ? [...state.list.filter((item: Contract) => item.id !== contractBranchDelete!.id), {...contractBranchDelete, branches: [...contractBranchDelete?.branches?.filter((item: any) => item.id !== action.id)]}] : state.list).sort(Functions.DynamicSort('contractNumber'))
                };
            case 'PRACTITIONER_CONTRACTS_DELETE_RECEIVE_ACTION':
                var contractDisassociateFromPractitioner = state.list ? state.list.find((item: Contract) => item.id === action.contractId) : undefined;
                if(contractDisassociateFromPractitioner) {
                    contractDisassociateFromPractitioner.practitioners = contractDisassociateFromPractitioner.practitioners?.filter((item: ContractPractitionerAssociation) => item.id !== action.practitionerContractId);
                }
                return {
                    ...state,
                    list: (contractDisassociateFromPractitioner ? [...state.list.filter((item: Contract) => item.id !== contractDisassociateFromPractitioner!.id), contractDisassociateFromPractitioner] : state.list).sort(Functions.DynamicSort('contractNumber'))
                };
            case 'PRACTITIONER_CONTRACTS_ADD_RECEIVE_ACTION':
                var contractAssociateToPractitioner = state.list ? state.list.find((item: Contract) => item.id === action.practitionerContract.contractId) : undefined;
                if(contractAssociateToPractitioner) {
                    contractAssociateToPractitioner.practitioners = [...contractAssociateToPractitioner.practitioners!, action.practitionerContract];
                }
                return {
                    ...state,
                    list: (contractAssociateToPractitioner ? [...state.list.filter((item: Contract) => item.id !== contractAssociateToPractitioner!.id), contractAssociateToPractitioner] : state.list).sort(Functions.DynamicSort('contractNumber'))
                };
            case 'CONTRACT_DISASSOCIATE_FROM_PROTOCOL_RECEIVE_ACTION':
                var contractDisassociateFromProtocol = state.list ? state.list.find((item: Contract) => item.id === action.contractId) : undefined;
                if(contractDisassociateFromProtocol) {
                    contractDisassociateFromProtocol.protocol = contractDisassociateFromProtocol.protocol?.filter((item: ContractProtocolAssociation) => item.protocolId !== action.ProtocolId);
                }
                return {
                    ...state,
                    list: (contractDisassociateFromProtocol ? [...state.list.filter((item: Contract) => item.id !== contractDisassociateFromProtocol!.id), contractDisassociateFromProtocol] : state.list).sort(Functions.DynamicSort('contractNumber'))
                };
            
            case 'CONTRACT_ASSOCIATE_TO_PROTOCOL_RECEIVE_ACTION':
                var contractAssociateToProtocol = state.list ? state.list.find((item: Contract) => item.id === action.contractProtocolAssociation.contractId) : undefined;
                if(contractAssociateToProtocol) {
                    contractAssociateToProtocol.protocol = [...contractAssociateToProtocol.protocol!, action.contractProtocolAssociation];
                }
                return {
                    ...state,
                    list: (contractAssociateToProtocol ? [...state.list.filter((item: Contract) => item.id !== contractAssociateToProtocol!.id), contractAssociateToProtocol] : state.list).sort(Functions.DynamicSort('contractNumber'))
                };
            case 'CONTRACT_COMMISSION_CONCEPT_GET_ALL_SUCCESS':
                var contractCommissionConcepts = state.list ? state.list.find((item: Contract) => item.id === action.contractId) : undefined;
                if(contractCommissionConcepts) {
                    contractCommissionConcepts.commissionConcepts = action.contractCommissionConcepts;
                }
                return {
                    ...state,
                    list: (contractCommissionConcepts ? [...state.list.filter((item: Contract) => item.id !== contractCommissionConcepts!.id), contractCommissionConcepts] : state.list).sort(Functions.DynamicSort('contractNumber'))
                };
            case 'CONTRACT_COMMISSION_CONCEPT_UPDATE_SUCCESS':
                var contractCommissionConceptUpdate = state.list ? state.list.find((item: Contract) => item.id === action.contractCommissionConcept.contractId) : undefined;
                if(contractCommissionConceptUpdate) {
                    contractCommissionConceptUpdate.commissionConcepts = [...contractCommissionConceptUpdate.commissionConcepts!.filter((item: ContractCommissionConcept) => item.id !== action.contractCommissionConcept.id), action.contractCommissionConcept];
                }
                return {
                    ...state,
                    list: (contractCommissionConceptUpdate ? [...state.list.filter((item: Contract) => item.id !== contractCommissionConceptUpdate!.id), contractCommissionConceptUpdate] : state.list).sort(Functions.DynamicSort('contractNumber'))
                };
            case 'CONTRACT_COMMISSION_CONCEPT_CREATE_SUCCESS':
                var contractCommissionConceptCreate = state.list ? state.list.find((item: Contract) => item.id === action.contractCommissionConcept.contractId) : undefined;
                if(contractCommissionConceptCreate) {
                    contractCommissionConceptCreate.commissionConcepts = [...contractCommissionConceptCreate.commissionConcepts!, action.contractCommissionConcept];
                }
                return {
                    ...state,
                    list: (contractCommissionConceptCreate ? [...state.list.filter((item: Contract) => item.id !== contractCommissionConceptCreate!.id), contractCommissionConceptCreate] : state.list).sort(Functions.DynamicSort('contractNumber'))
                };
            case 'CONTRACT_COMMISSION_CONCEPT_DELETE_SUCCESS':
                var contractCommissionConceptDelete = state.list ? state.list.find((item: Contract) => item.id === action.contractId) : undefined;
                if(contractCommissionConceptDelete) {
                    contractCommissionConceptDelete.commissionConcepts = contractCommissionConceptDelete.commissionConcepts?.filter((item: ContractCommissionConcept) => item.id !== action.contractCommissionConceptId);
                }

                return {
                    ...state,
                    list: (contractCommissionConceptDelete ? [...state.list.filter((item: Contract) => item.id !== contractCommissionConceptDelete!.id), contractCommissionConceptDelete] : state.list).sort(Functions.DynamicSort('contractNumber'))
                };
            case 'CONTRACT_COMMISSION_AGENT_GET_ALL_SUCCESS':
                var contractCommissionAgents = state.list ? state.list.find((item: Contract) => item.id === action.contractId) : undefined;
                if(contractCommissionAgents) {
                    contractCommissionAgents.commissionAgents = action.contractCommissionAgents;
                }
                return {
                    ...state,
                    list: (contractCommissionAgents ? [...state.list.filter((item: Contract) => item.id !== contractCommissionAgents!.id), contractCommissionAgents] : state.list)
                };
            case 'CONTRACT_COMMISSION_AGENT_GET_BY_ID_SUCCESS':
                var contractCommissionAgentGet = state.list ? state.list?.find((item: Contract) => item.id === action.contractCommissionAgent.contractId) : undefined;
                if(contractCommissionAgentGet) {
                    contractCommissionAgentGet.commissionAgents = contractCommissionAgentGet.commissionAgents ? [...contractCommissionAgentGet.commissionAgents.filter((item: ContractCommissionAgent) => item.id !== action.contractCommissionAgent.id), action.contractCommissionAgent] : [action.contractCommissionAgent];
                }
                return {
                    ...state,
                    list: (contractCommissionAgentGet ? [...state.list.filter((item: Contract) => item.id !== contractCommissionAgentGet!.id), contractCommissionAgentGet] : state.list).sort(Functions.DynamicSort('contractNumber'))
                };
            case 'CONTRACT_COMMISSION_AGENT_UPDATE_SUCCESS':
                var contractCommissionAgentUpdate = state.list ? state.list.find((item: Contract) => item.id === action.contractCommissionAgent.contractId) : undefined;
                if(contractCommissionAgentUpdate) {
                    contractCommissionAgentUpdate.commissionAgents = [...contractCommissionAgentUpdate.commissionAgents!.filter((item: ContractCommissionAgent) => item.id !== action.contractCommissionAgent.id) || [], action.contractCommissionAgent];
                }
                return {
                    ...state,
                    list: (contractCommissionAgentUpdate ? [...state.list.filter((item: Contract) => item.id !== contractCommissionAgentUpdate!.id), contractCommissionAgentUpdate] : state.list).sort(Functions.DynamicSort('contractNumber'))
                };
            case 'CONTRACT_COMMISSION_AGENT_CREATE_SUCCESS':
                var contractCommissionAgentCreate = state.list ? state.list.find((item: Contract) => item.id === action.contractCommissionAgent.contractId) : undefined;
                if(contractCommissionAgentCreate) {
                    contractCommissionAgentCreate.commissionAgents = [...contractCommissionAgentCreate.commissionAgents || [], action.contractCommissionAgent];
                }
                return {
                    ...state,
                    list: (contractCommissionAgentCreate ? [...state.list.filter((item: Contract) => item.id !== contractCommissionAgentCreate!.id), contractCommissionAgentCreate] : state.list).sort(Functions.DynamicSort('contractNumber'))
                };
            // commission agents of associated contract
            case 'CONTRACT_COMMISSION_AGENT_GET_ALL_BY_ASSOCIATED_CONTRACT_ID_REQUEST':
                var contractCommissionAgentAssociatedGetAllRequest = state.list ? state.list.find((item: Contract) => item.id === action.contractId) : undefined;
                if(contractCommissionAgentAssociatedGetAllRequest) {
                    contractCommissionAgentAssociatedGetAllRequest = {...contractCommissionAgentAssociatedGetAllRequest, isGettingCommissionAgentContractAssociation: true, GettingCommissionAgentContractAssociationSuccess: false, FailGettingCommissionAgentContractAssociation: false, error: undefined};
                }
                return {
                    ...state,
                    list: (contractCommissionAgentAssociatedGetAllRequest ? [...state.list.filter((item: Contract) => item.id !== contractCommissionAgentAssociatedGetAllRequest!.id), contractCommissionAgentAssociatedGetAllRequest] : state.list)
                };
            case 'CONTRACT_COMMISSION_AGENT_GET_ALL_BY_ASSOCIATED_CONTRACT_ID_SUCCESS':

                CurrentContract = state.list ? state.list.find((item: Contract) => item.id === action.contractId) : undefined;

                if(CurrentContract) {
                    
                    CurrentContract = {...CurrentContract, commissionAgents: action.contractCommissionAgents, isGettingCommissionAgentContractAssociation: false, GettingCommissionAgentContractAssociationSuccess: true, FailGettingCommissionAgentContractAssociation: false, error: undefined};
                    CurrentContract.commissionAgents = action.contractCommissionAgents;
                }

                return {
                    ...state,
                    list: CurrentContract ? [...state.list.filter((item: Contract) => item.id !== CurrentContract?.id), CurrentContract] : state.list
                };
            case 'CONTRACT_COMMISSION_AGENT_GET_ALL_BY_ASSOCIATED_CONTRACT_ID_FAILURE':
                var contractCommissionAgentAssociatedGetAllFail = state.list ? state.list.find((item: Contract) => item.id === action.contractId) : undefined;
                if(contractCommissionAgentAssociatedGetAllFail) {
                    contractCommissionAgentAssociatedGetAllFail = {...contractCommissionAgentAssociatedGetAllFail, isGettingCommissionAgentContractAssociation: false, GettingCommissionAgentContractAssociationSuccess: false, FailGettingCommissionAgentContractAssociation: true, ErrorGettingCommissionAgentContractAssociation: action.error};
                }
                return {
                    ...state,
                    list: (contractCommissionAgentAssociatedGetAllFail ? [...state.list.filter((item: Contract) => item.id !== contractCommissionAgentAssociatedGetAllFail!.id), contractCommissionAgentAssociatedGetAllFail] : state.list)
                };
            // commission agente concept 
            case 'CONTRACT_COMMISSION_AGENT_CONCEPT_GET_ALL_BY_COMMISSION_AGENT_ID_SUCCESS':
                var contractCommissionAgentGetConcepts = state.list ? state.list.find((item: Contract) => item.id === action.contractid) : undefined;
                var commissionAgentGetConcepts = contractCommissionAgentGetConcepts?.commissionAgents?.find((agent: ContractCommissionAgent) => agent.id === action.commissionAgentId);
                if(contractCommissionAgentGetConcepts && commissionAgentGetConcepts) {
                    commissionAgentGetConcepts.Concepts = action.contractCommissionAgentConcepts;
                    contractCommissionAgentGetConcepts.commissionAgents = [...contractCommissionAgentGetConcepts?.commissionAgents?.filter((item: ContractCommissionAgent) => item.id !== action.commissionAgentId) || [], commissionAgentGetConcepts];
                }
                
                return {
                    ...state,
                    list: (contractCommissionAgentGetConcepts ? [...state.list.filter((item: Contract) => item.id !== contractCommissionAgentGetConcepts!.id), contractCommissionAgentGetConcepts] : state.list)
                };
            case 'CONTRACT_COMMISSION_AGENT_CONCEPT_UPDATE_SUCCESS':
                var contractCommissionAgentUpdateConcept = state.list ? state.list.find((item: Contract) => item.id === action.contractCommissionAgentConcept.contractId) : undefined;
                var commissionAgentUpdateConcept = contractCommissionAgentUpdateConcept?.commissionAgents?.find((agent: ContractCommissionAgent) => agent.id === action.contractCommissionAgentConcept.commissionAgentId);
                if(contractCommissionAgentUpdateConcept && commissionAgentUpdateConcept) {
                    const NewListConcepts = commissionAgentUpdateConcept?.Concepts ? [...commissionAgentUpdateConcept?.Concepts?.filter((item: ContractCommissionAgentConcept) => item.id !== action.contractCommissionAgentConcept.id), action.contractCommissionAgentConcept] : [action.contractCommissionAgentConcept];
                    commissionAgentUpdateConcept.Concepts = NewListConcepts;
                    contractCommissionAgentUpdateConcept.commissionAgents = [...contractCommissionAgentUpdateConcept?.commissionAgents?.filter((item: ContractCommissionAgent) => item.id !== action.contractCommissionAgentConcept.commissionAgentId), commissionAgentUpdateConcept];
                }
                return {
                    ...state,
                    list: (contractCommissionAgentUpdateConcept ? [...state.list.filter((item: Contract) => item.id !== contractCommissionAgentUpdateConcept!.id), contractCommissionAgentUpdateConcept] : state.list)
                };
            case 'CONTRACT_COMMISSION_AGENT_CONCEPT_CREATE_SUCCESS':
                var contractCommissionAgentCreateConcept = state.list ? state.list?.find((item: Contract) => item.id === action.contractCommissionAgentConcept.contractId) : undefined;
                var commissionAgentCreateConcept = contractCommissionAgentCreateConcept?.commissionAgents?.find((agent: ContractCommissionAgent) => agent.id === action.contractCommissionAgentConcept.commissionAgentId);
                if(contractCommissionAgentCreateConcept && commissionAgentCreateConcept) {
                    commissionAgentCreateConcept.Concepts = [...commissionAgentCreateConcept?.Concepts || [], action.contractCommissionAgentConcept];
                    contractCommissionAgentCreateConcept.commissionAgents = [...contractCommissionAgentCreateConcept?.commissionAgents?.filter((item: ContractCommissionAgent) => item.id !== action.contractCommissionAgentConcept.commissionAgentId), commissionAgentCreateConcept];
                }
                return {
                    ...state,
                    list: (contractCommissionAgentCreateConcept ? [...state.list.filter((item: Contract) => item.id !== contractCommissionAgentCreateConcept!.id), contractCommissionAgentCreateConcept] : state.list)
                };
            case 'CONTRACT_COMMISSION_AGENT_CONCEPT_DELETE_SUCCESS':
                var contractCommissionAgentDeleteConcept = state.list ? state.list.find((item: Contract) => item.id === action.contractId) : undefined;
                var commissionAgentDeleteConcept = contractCommissionAgentDeleteConcept?.commissionAgents?.find((agent: ContractCommissionAgent) => agent.id === action.contractCommissionAgentConceptId);
                if(contractCommissionAgentDeleteConcept && commissionAgentDeleteConcept) {
                    commissionAgentDeleteConcept.Concepts = commissionAgentDeleteConcept.Concepts?.filter((item: ContractCommissionAgentConcept) => item.id !== action.contractCommissionAgentConceptId);
                    contractCommissionAgentDeleteConcept.commissionAgents = [...contractCommissionAgentDeleteConcept?.commissionAgents?.filter((item: ContractCommissionAgent) => item.id !== action.contractCommissionAgentConceptId), commissionAgentDeleteConcept];
                }
                return {
                    ...state,
                    list: (contractCommissionAgentDeleteConcept ? [...state.list.filter((item: Contract) => item.id !== contractCommissionAgentDeleteConcept!.id), contractCommissionAgentDeleteConcept] : state.list)
                };
            // stages
            case 'CONTRACTSTAGE_GET_ALL_BY_ADDENDUMID_REQUEST_ACTION':
                CurrentContract = state.list ? state.list.find((item: Contract) => item.id === action.contractId) : undefined;
                
                if (CurrentContract) {

                    let CurrentAddendum = CurrentContract.addendums?.find((item: ContractAddendum) => item.id === action.addendumId);

                    if(CurrentAddendum) {
                        
                        CurrentAddendum = {
                            ...CurrentAddendum, 
                            isGettingContractStages: true,
                            isGettingContractStagesSuccess: false,
                            FailGettingContractStages: false,
                            ErrorGettingContractStages: undefined
                        };

                        CurrentContract.addendums = CurrentContract.addendums ? [...CurrentContract.addendums.filter((item: ContractAddendum) => item.id !== action.addendumId), CurrentAddendum] : [CurrentAddendum];
                    }
                }

                return {
                    ...state,
                    list: (CurrentContract ? [...state.list.filter((item: Contract) => item.id !== CurrentContract!.id), CurrentContract] : state.list)
                };
            case 'CONTRACTSTAGE_GET_ALL_BY_ADDENDUMID_RECEIVE_ACTION':
                CurrentContract = state.list ? state.list.find((item: Contract) => item.id === action.contractId) : undefined;
                
                if (CurrentContract) {

                    let CurrentAddendum = CurrentContract.addendums?.find((item: ContractAddendum) => item.id === action.addendumId);

                    if(CurrentAddendum) {
                        
                        CurrentAddendum = {
                            ...CurrentAddendum, 
                            isGettingContractStages: false,
                            isGettingContractStagesSuccess: true,
                            FailGettingContractStages: false,
                            ErrorGettingContractStages: undefined,
                            stages: action.contractStages
                        };
                        
                        CurrentContract.addendums = CurrentContract.addendums ? [...CurrentContract.addendums.filter((item: ContractAddendum) => item.id !== action.addendumId), CurrentAddendum] : [CurrentAddendum];

                    }

                }

                return {
                    ...state,
                    list: (CurrentContract ? [...state.list.filter((item: Contract) => item.id !== CurrentContract!.id), CurrentContract] : state.list)
                };
            case 'CONTRACTSTAGE_GET_ALL_BY_ADDENDUMID_FAIL_ACTION':
                CurrentContract = state.list ? state.list.find((item: Contract) => item.id === action.contractId) : undefined;
                
                if (CurrentContract) {

                    var CurrentAddendum = CurrentContract.addendums?.find((item: ContractAddendum) => item.id === action.addendumId);

                    if(CurrentAddendum) {
                        
                        CurrentAddendum = {
                            ...CurrentAddendum, 
                            isGettingContractStages: false,
                            isGettingContractStagesSuccess: false,
                            FailGettingContractStages: true,
                            ErrorGettingContractStages: action.error
                        };

                        CurrentContract.addendums = [...CurrentContract.addendums?.filter((item: ContractAddendum) => item.id !== action.addendumId), CurrentAddendum];
                    }

                }

                return {
                    ...state,
                    list: (CurrentContract ? [...state.list.filter((item: Contract) => item.id !== CurrentContract!.id), CurrentContract] : state.list)
                };
            // cycles
            case 'CONTRACTSTAGECYCLE_GET_ALL_BY_ADDENDUMID_REQUEST_ACTION':
                CurrentContract = state.list ? state.list.find((item: Contract) => item.id === action.contractId) : undefined;
                
                if (CurrentContract) {

                    var CurrentAddendum = CurrentContract.addendums?.find((item: ContractAddendum) => item.id === action.addendumId);

                    if(CurrentAddendum) {
                        
                        CurrentAddendum = {
                            ...CurrentAddendum, 
                            isGettingContractCycles: true,
                            isGettingContractCyclesSuccess: false,
                            FailGettingContractCycles: false,
                            ErrorGettingContractCycles: undefined
                        };

                        CurrentContract.addendums = [...CurrentContract.addendums?.filter((item: ContractAddendum) => item.id !== action.addendumId), CurrentAddendum];
                    }
                }

                return {
                    ...state,
                    list: (CurrentContract ? [...state.list.filter((item: Contract) => item.id !== CurrentContract!.id), CurrentContract] : state.list)
                };
            case 'CONTRACTSTAGECYCLE_GET_ALL_BY_ADDENDUMID_RECEIVE_ACTION':
                CurrentContract = state.list ? state.list.find((item: Contract) => item.id === action.contractId) : undefined;
                
                if (CurrentContract) {

                    var CurrentAddendum = CurrentContract.addendums?.find((item: ContractAddendum) => item.id === action.addendumId);

                    if(CurrentAddendum) {
                        
                        CurrentAddendum = {
                            ...CurrentAddendum, 
                            isGettingContractCycles: false,
                            isGettingContractCyclesSuccess: true,
                            FailGettingContractCycles: false,
                            ErrorGettingContractCycles: undefined,
                            cycles: action.contractStageCycles
                        };
                        
                        CurrentContract.addendums = [...CurrentContract.addendums?.filter((item: ContractAddendum) => item.id !== action.addendumId), CurrentAddendum];

                    }

                }

                return {
                    ...state,
                    list: (CurrentContract ? [...state.list.filter((item: Contract) => item.id !== CurrentContract!.id), CurrentContract] : state.list)
                };
            case 'CONTRACTSTAGECYCLE_GET_ALL_BY_ADDENDUMID_FAIL_ACTION':
                CurrentContract = state.list ? state.list.find((item: Contract) => item.id === action.contractId) : undefined;
                
                if (CurrentContract) {

                    var CurrentAddendum = CurrentContract.addendums?.find((item: ContractAddendum) => item.id === action.addendumId);

                    if(CurrentAddendum) {
                        
                        CurrentAddendum = {
                            ...CurrentAddendum, 
                            isGettingContractCycles: false,
                            isGettingContractCyclesSuccess: false,
                            FailGettingContractCycles: true,
                            ErrorGettingContractCycles: action.error
                        };

                        CurrentContract.addendums = [...CurrentContract.addendums?.filter((item: ContractAddendum) => item.id !== action.addendumId), CurrentAddendum];
                    }

                }

                return {
                    ...state,
                    list: (CurrentContract ? [...state.list.filter((item: Contract) => item.id !== CurrentContract!.id), CurrentContract] : state.list)
                };
            // branches
            case 'CONTRACTBRANCH_GET_ALL_BY_ADDENDUMID_REQUEST_ACTION':
                CurrentContract = state.list ? state.list.find((item: Contract) => item.id === action.contractId) : undefined;
                
                if (CurrentContract) {

                    var CurrentAddendum = CurrentContract.addendums?.find((item: ContractAddendum) => item.id === action.addendumId);

                    if(CurrentAddendum) {
                        
                        CurrentAddendum = {
                            ...CurrentAddendum, 
                            isGettingContractBranches: true,
                            isGettingContractBranchesSuccess: false,
                            FailGettingContractBranches: false,
                            ErrorGettingContractBranches: undefined
                        };

                        CurrentContract.addendums = [...CurrentContract.addendums?.filter((item: ContractAddendum) => item.id !== action.addendumId), CurrentAddendum];
                    }
                }

                return {
                    ...state,
                    list: (CurrentContract ? [...state.list.filter((item: Contract) => item.id !== CurrentContract!.id), CurrentContract] : state.list)
                };
            case 'CONTRACTBRANCH_GET_ALL_BY_ADDENDUMID_RECEIVE_ACTION':
                CurrentContract = state.list ? state.list.find((item: Contract) => item.id === action.contractId) : undefined;
                
                if (CurrentContract) {

                    var CurrentAddendum = CurrentContract.addendums?.find((item: ContractAddendum) => item.id === action.addendumId);

                    if(CurrentAddendum) {
                        
                        CurrentAddendum = {
                            ...CurrentAddendum, 
                            isGettingContractBranches: false,
                            isGettingContractBranchesSuccess: true,
                            FailGettingContractBranches: false,
                            ErrorGettingContractBranches: undefined,
                            branches: action.contractBranches
                        };
                        
                        CurrentContract.addendums = [...CurrentContract.addendums?.filter((item: ContractAddendum) => item.id !== action.addendumId), CurrentAddendum];

                    }

                }

                return {
                    ...state,
                    list: (CurrentContract ? [...state.list.filter((item: Contract) => item.id !== CurrentContract!.id), CurrentContract] : state.list)
                };
            case 'CONTRACTBRANCH_GET_ALL_BY_ADDENDUMID_ERROR_ACTION':
                CurrentContract = state.list ? state.list.find((item: Contract) => item.id === action.contractId) : undefined;
                
                if (CurrentContract) {

                    var CurrentAddendum = CurrentContract.addendums?.find((item: ContractAddendum) => item.id === action.addendumId);

                    if(CurrentAddendum) {
                        
                        CurrentAddendum = {
                            ...CurrentAddendum, 
                            isGettingContractBranches: false,
                            isGettingContractBranchesSuccess: false,
                            FailGettingContractBranches: true,
                            ErrorGettingContractBranches: action.error
                        };

                        CurrentContract.addendums = [...CurrentContract.addendums?.filter((item: ContractAddendum) => item.id !== action.addendumId), CurrentAddendum];
                    }

                }

                return {
                    ...state,
                    list: (CurrentContract ? [...state.list.filter((item: Contract) => item.id !== CurrentContract!.id), CurrentContract] : state.list)
                };
            // concepts
            case 'CONTRACTCONCEPT_GET_ALL_BY_ADDENDUMID_REQUEST_ACTION':
                CurrentContract = state.list ? state.list.find((item: Contract) => item.id === action.contractId) : undefined;
                
                if (CurrentContract) {

                    var CurrentAddendum = CurrentContract.addendums?.find((item: ContractAddendum) => item.id === action.addendumId);

                    if(CurrentAddendum) {
                        
                        CurrentAddendum = {
                            ...CurrentAddendum, 
                            isGettingContractConcepts: true,
                            isGettingContractConceptsSuccess: false,
                            FailGettingContractConcepts: false,
                            ErrorGettingContractConcepts: undefined
                        };

                        CurrentContract.addendums = [...CurrentContract.addendums?.filter((item: ContractAddendum) => item.id !== action.addendumId), CurrentAddendum];
                    }
                }

                return {
                    ...state,
                    list: (CurrentContract ? [...state.list.filter((item: Contract) => item.id !== CurrentContract!.id), CurrentContract] : state.list)
                };
            case 'CONTRACTCONCEPT_GET_ALL_BY_ADDENDUMID_RECEIVE_ACTION':
                CurrentContract = state.list ? state.list.find((item: Contract) => item.id === action.contractId) : undefined;
                
                if (CurrentContract) {

                    var CurrentAddendum = CurrentContract.addendums?.find((item: ContractAddendum) => item.id === action.addendumId);

                    if(CurrentAddendum) {
                        
                        CurrentAddendum = {
                            ...CurrentAddendum, 
                            isGettingContractConcepts: false,
                            isGettingContractConceptsSuccess: true,
                            FailGettingContractConcepts: false,
                            ErrorGettingContractConcepts: undefined,
                            concepts: action.contractConcepts
                        };
                        
                        CurrentContract.addendums = [...CurrentContract.addendums?.filter((item: ContractAddendum) => item.id !== action.addendumId), CurrentAddendum];

                    }

                }

                return {
                    ...state,
                    list: (CurrentContract ? [...state.list.filter((item: Contract) => item.id !== CurrentContract!.id), CurrentContract] : state.list)
                };
            case 'CONTRACTCONCEPT_GET_ALL_BY_ADDENDUMID_FAIL_ACTION':
                CurrentContract = state.list ? state.list.find((item: Contract) => item.id === action.contractId) : undefined;
                
                if (CurrentContract) {

                    var CurrentAddendum = CurrentContract.addendums?.find((item: ContractAddendum) => item.id === action.addendumId);

                    if(CurrentAddendum) {
                        
                        CurrentAddendum = {
                            ...CurrentAddendum, 
                            isGettingContractConcepts: false,
                            isGettingContractConceptsSuccess: false,
                            FailGettingContractConcepts: true,
                            ErrorGettingContractConcepts: action.error
                        };

                        CurrentContract.addendums = [...CurrentContract.addendums?.filter((item: ContractAddendum) => item.id !== action.addendumId), CurrentAddendum];
                    }

                }

                return {
                    ...state,
                    list: (CurrentContract ? [...state.list.filter((item: Contract) => item.id !== CurrentContract!.id), CurrentContract] : state.list)
                };
            
            // contract access
            case 'CONTRACT_ACCESS_GETALL_REQUEST':
                CurrentContract = state.list ? state.list.find((item: Contract) => item.id === action.contractId) : undefined;
                if(CurrentContract) {
                    CurrentContract = {...CurrentContract, isGettingContractAccess: true, isGettingContractAccessSuccess: false, FailGettingContractAccess: false, ErrorGettingContractAccess: undefined};
                }
                return {
                    ...state,
                    list: (CurrentContract ? [...state.list.filter((item: Contract) => item.id !== CurrentContract!.id), CurrentContract] : state.list)
                };
            case 'CONTRACT_ACCESS_GETALL_SUCCESS':
                CurrentContract = state.list ? state.list.find((item: Contract) => item.id === action.constractId) : undefined;
                if(CurrentContract) {
                    CurrentContract = {...CurrentContract, access: action.contractAccess, isGettingContractAccess: false, isGettingContractAccessSuccess: true, FailGettingContractAccess: false, ErrorGettingContractAccess: undefined};
                }



                return {
                    ...state,
                    list: (CurrentContract ? [...state.list.filter((item: Contract) => item.id !== CurrentContract!.id), CurrentContract] : state.list)
                };
            case 'CONTRACT_ACCESS_GETALL_FAILURE':
                CurrentContract = state.list ? state.list.find((item: Contract) => item.id === action.contractId) : undefined;
                if(CurrentContract) {
                    CurrentContract = {...CurrentContract, isGettingContractAccess: false, isGettingContractAccessSuccess: false, FailGettingContractAccess: true, ErrorGettingContractAccess: action.error};
                }
                return {
                    ...state,
                    list: (CurrentContract ? [...state.list.filter((item: Contract) => item.id !== CurrentContract!.id), CurrentContract] : state.list)
                };
            case 'CONTRACT_ACCESS_CREATE_SUCCESS':
                CurrentContract = state.list ? state.list.find((item: Contract) => item.id === action.contractAccess.contractID) : undefined;

                if(CurrentContract) {
                    CurrentContract = {...CurrentContract, access: [...(CurrentContract.access || []).filter((access: ContractAccess) => access.id !== action.contractAccess.id) ,action.contractAccess]};
                }
                return {
                    ...state,
                    list: (CurrentContract ? [...state.list.filter((item: Contract) => item.id !== CurrentContract!.id), CurrentContract] : state.list)
                };
            case 'CONTRACT_ACCESS_UPDATE_REQUEST':
                CurrentContract = state.list ? state.list.find((item: Contract) => item.id === action.contractAccess.contractID) : undefined;

                if(CurrentContract) {
                    CurrentContract = {...CurrentContract, isUpdatingContractAccess: true, isUpdatingContractAccessSuccess: false, FailUpdatingContractAccess: false, ErrorUpdatingContractAccess: undefined};
                }
                return {
                    ...state,
                    list: (CurrentContract ? [...state.list.filter((item: Contract) => item.id !== CurrentContract!.id), CurrentContract] : state.list)
                };
            
            case 'CONTRACT_ACCESS_UPDATE_SUCCESS':
                CurrentContract = state.list ? state.list.find((item: Contract) => item.id === action.contractAccess.contractID) : undefined;
                if(CurrentContract) {
                    CurrentContract = {...CurrentContract,
                        isUpdatingContractAccess: false,
                        isUpdatingContractAccessSuccess: true,
                        FailUpdatingContractAccess: false,
                        ErrorUpdatingContractAccess: undefined, 
                        access: [...(CurrentContract.access || []).filter((access: ContractAccess) => access.id !== action.contractAccess.id) ,action.contractAccess]};
                }
                return {
                    ...state,
                    list: (CurrentContract ? [...state.list.filter((item: Contract) => item.id !== CurrentContract!.id), CurrentContract] : state.list)
                };
            case 'CONTRACT_ACCESS_GETBYID_SUCCESS':
                CurrentContract = state.list ? state.list.find((item: Contract) => item.id === action.contractAccess.contractID) : undefined;
                if(CurrentContract) {
                    CurrentContract = {...CurrentContract, access: [...(CurrentContract.access || []).filter((access: ContractAccess) => access.id === action.contractAccess.id) ,action.contractAccess]};
                }
                return {
                    ...state,
                    list: (CurrentContract ? [...state.list.filter((item: Contract) => item.id !== CurrentContract!.id), CurrentContract] : state.list)
                };   
            case 'CONTRACT_ACCESS_DELETE_REQUEST':
                CurrentContract = state.list ? state.list.find((item: Contract) => item.id === action.contractId) : undefined;
                if(CurrentContract) {
                    CurrentContract = {...CurrentContract, isDeletingContractAccess: true, isDeletingContractAccessSuccess: false, FailDeletingContractAccess: false, ErrorDeletingContractAccess: undefined};
                }
                return {
                    ...state,
                    list: (CurrentContract ? [...state.list.filter((item: Contract) => item.id !== CurrentContract!.id), CurrentContract] : state.list)
                };         
            case 'CONTRACT_ACCESS_DELETE_SUCCESS':
                CurrentContract = state.list ? state.list.find((item: Contract) => item.id === action.constractId) : undefined;
                
                if(CurrentContract) {
                    CurrentContract = {...CurrentContract, 
                        isDeletingContractAccess: false,
                        isDeletingContractAccessSuccess: true,
                        FailDeletingContractAccess: false,
                        ErrorDeletingContractAccess: undefined, 
                        access: [...(CurrentContract.access || []).filter((access: ContractAccess) => access.id !== action.contractAccessId)]};
                }
                return {
                    ...state,
                    list: (CurrentContract ? [...state.list.filter((item: Contract) => item.id !== CurrentContract!.id), CurrentContract] : state.list)
                };
            default:
                return state;
        }
    }
