// store 
import * as ProtocolsStore from '../../../redux/middleware/MedicalProtocolMiddleware';
import * as ProtocolConsumptionStore from '../../../redux/middleware/ProtocolConsumptionMiddleware';

// state
import { AppState } from '../../../redux/reducers';

// models
import { Protocol } from '../../../models/Entities/Protocols/Protocol';
import { ProtocolConsumptionTotal } from '../../../models/Entities/Protocols/ProtocolConsumptionTotal';


//#region Props

interface IProps {
    ProtocolId: number;
    status: number;
}

interface ConnectedProps {
    isLoading: boolean;
    successLoading: boolean;
    error: string | null;
    Totals: ProtocolConsumptionTotal[];
    protocol: Protocol | null;
}

interface MedicalServicesDispatchProps {
    GetMedicalProtocolById: typeof ProtocolsStore.actionCreators.GetMedicalProtocolById;
    GetTotalsByStatus: typeof ProtocolConsumptionStore.ActionCreators.GetTotalsByStatus;
}

export type Props = IProps & ConnectedProps & MedicalServicesDispatchProps;

export const mapStateToProps = (state: AppState, ownProps: IProps) => {

    const AuxProtocol: Protocol | null =  state.MedicalProtocols?.list?.find((protocol: Protocol) => protocol.id === ownProps.ProtocolId) || null;

    return {
        isLoading: state.MedicalProtocols?.isLoadingTotals || false,
        successLoading: state.MedicalProtocols?.LoadingTotalsSuccess || undefined,
        error: state.MedicalProtocols?.ErrorGettingTotals || null,
        Totals: AuxProtocol?.AmountTotals?.filter((total: ProtocolConsumptionTotal) => total.status === ownProps.status ) || [],
        protocol: AuxProtocol
    }
};

export const mapDispatchToProps = {
    ...ProtocolsStore.actionCreators,
    ...ProtocolConsumptionStore.ActionCreators,
};


export const GetStatusDescription = (status: number) => {

    /*
        NotSettled = 0,
        Pending = 1,
        Payee = 2,
        Settled = 3,
        Rejected = 4,
        Ignored = 5,
        ToSettle = 6
     */

    switch (status) {
        case 0:
            return 'Pendiente de liquidación';
        case 1:
            return 'Con saldo pendiente';
        case 2:
            return 'Pagado';
        case 3:
            return 'Liquidado';
        case 4:
            return 'Rechazado';
        case 5:
            return 'Ignorado';
        case 6:
            return 'Para liquidar';
        default:
            return 'Unknown';
    }
}