import {
  IBasePickerSuggestionsProps,
  IPersonaProps,
  ValidationState
} from '@fluentui/react';

//store
import * as ProviderStore from '../../../../redux/middleware/ProviderMiddleware';

//models
import { ErrorType } from '../../../../models/HttpError';
import { AppState } from '../../../../redux/reducers';
import { Provider } from '../../../../models/Entities/Provider/Provider';

interface IProps {
  MultiselectEnable: boolean;
  onChangeSelection?: (
    selectedProviders: Provider[] | undefined
  ) => void;
  ProviderSelected: number[] | undefined;
}

interface ConnectedProps {
  isLoading: boolean;
  isUpdating: boolean;
  isUpdatedSuccessfully: boolean;
  isLoadingSuccessfully: boolean;
  failOnUpdating: boolean;
  failOnLoading: boolean;
  error: ErrorType | undefined;
  Providers: Provider[];
}

interface DispatchProps {
  GetAllProviders: typeof ProviderStore.actionCreators.GetAllProviders;
}

export type Props = IProps & ConnectedProps & DispatchProps;

export const mapStateToProps = (state: AppState, ownProps: IProps) => ({
  isLoading: state.ContractConcept?.isLoadingAll,
  isUpdating: state.ContractConcept?.isLoadingAll,
  isUpdatedSuccessfully: state.ContractConcept?.successLoadingAll,
  isLoadingSuccessfully: state.ContractConcept?.successLoadingAll,
  failOnUpdating: state.ContractConcept?.failOnLoadingAll,
  failOnLoading: state.ContractConcept?.failOnLoadingAll,
  isDeleting: state.ContractConcept?.isDeletingOne,
  isDeletedSuccessfully: state.ContractConcept?.successDeletingOne,
  failOnDeleting: state.ContractConcept?.failOnDeletingOne,
  error: state.ContractConcept?.error,
  Providers: state.Provider?.list
});

export const mapDispatchToProps = ({
  ...ProviderStore.actionCreators
});

export const suggestionProps: IBasePickerSuggestionsProps = {
  suggestionsHeaderText: 'Médicos sugeridos',
  mostRecentlyUsedHeaderText: 'Médicos sugeridos',
  noResultsFoundText: 'No se econtraron médicos',
  loadingText: 'buscando...',
  showRemoveButtons: true,
  suggestionsAvailableAlertText: 'Médicos sugeridos disponibles',
  suggestionsContainerAriaLabel: 'Médicos sugeridos'
};

export function doesTextStartWith(text: string, filterText: string): boolean {
  return text.toLowerCase().includes(filterText.toLowerCase());
}

export function removeDuplicates(
  personas: IPersonaProps[],
  possibleDupes: IPersonaProps[]
) {
  return personas.filter(
    (persona) => !listContainsPersona(persona, possibleDupes)
  );
}

function listContainsPersona(
  persona: IPersonaProps,
  personas: IPersonaProps[]
) {
  if (!personas || !personas.length || personas.length === 0) {
    return false;
  }
  return personas.filter((item) => item.text === persona.text).length > 0;
}

export function getTextFromItem(persona: IPersonaProps): string {
  return persona.text as string;
}

export function validateInput(input: string): ValidationState {
  if (input.indexOf('@') !== -1) {
    return ValidationState.valid;
  } else if (input.length > 1) {
    return ValidationState.warning;
  } else {
    return ValidationState.invalid;
  }
}

