export type CurrencyType = {
  key: string;
  value: string;
}


export const currencyOptions: CurrencyType[] = [
  { key: '', value: '' },
  { key: 'ARS', value: '$ARS (Pesos Argentinos)' },
  { key: 'EUR', value: '€ (Euros)' },
  { key: 'USD', value: 'U$D (Dolares)' },
  { key: 'UYU', value: '$UYU (Pesos Uruguayos)'},
  { key: 'BRL', value: 'R$ (Reales Brasileños)'},
  { key: 'CLP', value: '$CLP (Pesos Chilenos)'},

];
