import * as React from 'react';
import { IPersonaProps } from '@fluentui/react/lib/Persona';
import { NormalPeoplePicker } from '@fluentui/react/lib/Pickers';

// ownFunctions
import * as ownFunctions from './PractitionerSelectorFunctions';
import * as commonFunctions from '../../../../functions/common';


// models
import { Practitioner } from '../../../../models/Entities/Practitioners/Practitioner';
import { connect } from 'react-redux';
import { Avatar, Field, Tag, TagPicker, TagPickerControl, TagPickerGroup, TagPickerInput, TagPickerList, TagPickerOption, TagPickerProps } from '@fluentui/react-components';
import { useEffect, useState } from 'react';
import { use } from 'i18next';

const PractitionerSelectorComponent = (
  props: ownFunctions.Props
) => {
  const [mostRecentlyUsed, setMostRecentlyUsed] = React.useState<
    IPersonaProps[]
  >([]);
  const [peopleList, setPeopleList] = React.useState<IPersonaProps[]>([]);

  const picker = React.useRef(null);

  const onChangeSelection = (items?: IPersonaProps[] | undefined) => {
    if (props.onChangeSelection) {
      props.onChangeSelection(items?.map((practitioner: IPersonaProps) => {
        return {
          name: practitioner.text,
          userId: practitioner.key,
          fullName: practitioner.text,
          email: practitioner.tertiaryText,
          userName: practitioner.text,
          registrationNumber: Number(practitioner.optionalText),
          speciality: practitioner.secondaryText,
          photo: ''
        } as Practitioner;
      }));
    }
  };

  const onFilterChanged = (
    filterText: string,
    currentPersonas?: IPersonaProps[],
    limitResults?: number
  ): IPersonaProps[] => {
    if (filterText) {
      let filteredPersonas: IPersonaProps[] = filterPersonasByText(filterText);
      let practitionersList = ownFunctions.removeDuplicates(
        filteredPersonas,
        currentPersonas || []
      );
      practitionersList = limitResults
        ? practitionersList.slice(0, limitResults)
        : practitionersList;
      return practitionersList || [];
    } else {
      return [];
    }
  };

  const filterPersonasByText = (
    filterText: string
  ): IPersonaProps[] => {
    return props.Practitioners?.map((item: Practitioner) => {
      return {
        key: item.userId,
        imageInitials: commonFunctions.getNameInitials(item.fullName),
        text: item.fullName,
        secondaryText: item.speciality
      } as IPersonaProps;
    })
      .filter((item: IPersonaProps) =>
        ownFunctions.doesTextStartWith(item.text as string, filterText)
      );
  };

   const [InternalProtocolPractitionerSelected, setInternalProtocolPractitionerSelected] = useState<Practitioner[]>([]);

   const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

    useEffect(() => {
      props.onChangeSelection && props.onChangeSelection(InternalProtocolPractitionerSelected);
    }, [InternalProtocolPractitionerSelected]);
  
    const onOptionSelect: TagPickerProps["onOptionSelect"] = (e, data) => {
      if (data.value === "no-options") {
        setSelectedOptions([]);
        setInternalProtocolPractitionerSelected([]);
        return;
      }
      if (props.MultiselectEnable) {
        setSelectedOptions(data.selectedOptions);
        setInternalProtocolPractitionerSelected(props.Practitioners?.filter((practitioner: Practitioner) => data.selectedOptions.includes(practitioner.fullName)));
      } else {
        setSelectedOptions(props.MultiselectEnable ? [data.value] : (selectedOptions.includes(data.value) ? [] : [data.value]));
        setInternalProtocolPractitionerSelected([props.Practitioners?.find((practitioner: Practitioner) => practitioner.fullName === data.value) as Practitioner]);
      }
    };
  
    const tagPickerOptions = props.Practitioners?.map((practitioner) => practitioner.fullName)?.filter(
      (patient: string) => !selectedOptions.includes(patient)
    );

  return (
    <div>
      {/* <NormalPeoplePicker
        onResolveSuggestions={onFilterChanged}
        getTextFromItem={ownFunctions.getTextFromItem}
        pickerSuggestionsProps={ownFunctions.suggestionProps}
        onEmptyResolveSuggestions={
          (selectedItems?: IPersonaProps[] | undefined) => props.Practitioners?.map((item: Practitioner) => {
            return {
              key: item.userId,
              imageInitials: commonFunctions.getNameInitials(item.fullName),
              text: item.fullName,
              secondaryText: item.speciality
            } as IPersonaProps;
          })
        }
        className={'ms-PeoplePicker'}
        key={'normal'}
        onValidateInput={ownFunctions.validateInput}
        selectionAriaLabel={'Selected contacts'}
        removeButtonAriaLabel={'Remove'}
        componentRef={picker}
        itemLimit={props.selectionLimit === 0 ? undefined : props.selectionLimit}
        //onInputChange={onInputChange}
        onChange={onChangeSelection}
      /> */}
      <Field label="Seleccione un profesional" style={{ maxWidth: 400 }}>
        <TagPicker
          onOptionSelect={onOptionSelect}
          selectedOptions={selectedOptions}
        >
          <TagPickerControl>
            <TagPickerGroup aria-label="Profesionales Seleccionados">
              {selectedOptions.map((option) => (
                <Tag
                  key={option}
                  shape="rounded"
                  value={option}
                >
                  {option}
                </Tag>
              ))}
            </TagPickerGroup>
            <TagPickerInput aria-label="Seleccione un profesional" />
          </TagPickerControl>
          <TagPickerList>
            {tagPickerOptions?.length > 0 ? (
              tagPickerOptions?.map((option) => (
                <TagPickerOption
                media={
                  <Avatar
                    shape="circular"
                    aria-hidden
                    name={option}
                    color="colorful"
                  />
                }
                  value={option}
                  key={option}
                >
                  {option}
                </TagPickerOption>
              ))
            ) : (
              <TagPickerOption value="no-options">
                No hay conceptos disponibles
              </TagPickerOption>
            )}
          </TagPickerList>
        </TagPicker>
      </Field>
    </div>
  );
};

export default connect(
  ownFunctions.mapStateToProps,
  ownFunctions.mapDispatchToProps
)(PractitionerSelectorComponent as any);
