import {
  Label,
  Pivot,
  PivotItem,
  PrimaryButton,
  Separator,
  Text
} from '@fluentui/react';
import { useEffect, useState } from 'react';
import { AgreementsPriceListComponent } from '../../components/PriceList/AgreementPricelist/AgreementsPriceListComponent';
import { MedicalInsuranceAgreementsDetails } from '../../components/MedicalInsuranceAgreement/MedicalInsuranceAgreementDetails/MedicalInsuranceAgreementsDetails';
import { PriceListHeaderComponent } from '../../components/PriceList/PriceListHeader/PriceListHeaderComponent';
import PricesListComponent from '../../components/PriceList/PricesList/PricesListComponent';
import { useSelector, connect } from 'react-redux';
import { AppState } from '../../redux/reducers';
import { PriceListInfoComponent } from '../../components/PriceList/PriceListInfo/PriceListInfoComponent';
import { useBoolean } from '@fluentui/react-hooks';
import { PriceListItemFormComponent } from '../../components/PriceList/PricelistItemForm/PriceListItemFormComponent';
import { MedicalInsuranceAgreementsPriceList } from '../../models/Entities/MedicalInsuranceAgreements/MedicalInsuranceAgreementsPriceList';
import { PriceDetails } from '../../models/Entities/PriceList/PriceDetail';
import AgreementServiceListComponent from '../../components/AgreementServices/AgreementServiceList/AgreementServiceListComponent';

// styles
import './AgreementDetailsPage.css';

// functions
import * as ownFunctions from './AgreementDetailsPageFunctions';
import WithRouter from '../../commons/component/withRouterCustom';
import { PageHeader } from '../../commons/component/PageHeader/PageHeader';
import SettlementListComponent from '../../components/Settlements/SettlementList/SettlementList.Component';
import PaymentAccountDetailsComponent from '../../components/PaymentAccount/PaymentAccountDetails/PaymentAccountDetails.Component';

////i18Next
import { useTranslation, Trans } from 'react-i18next';

export const MedicalAgreementsDetailsPage = (props: ownFunctions.Props) => {
  const [searchTextCriteria, setSearchTextCriteria] = useState<string>();
  const [selectedPriceList, setSelectedPriceList] =
    useState<MedicalInsuranceAgreementsPriceList>();

  const [UpdateMassivePriceIsActive, setUpdateMassivePriceIsActive] =
    useState<boolean>(false);

    //useTranslation()
    const [t] = useTranslation(); 


  const PriceListState = useSelector((state: AppState) => state.PriceList);

  const PriceListDetailsState = useSelector(
    (state: AppState) => state.PriceDetails
  );

  const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] =
    useBoolean(false);
  const [priceToEdit, setPriceToEdit] = useState<PriceDetails>();

  useEffect(() => {
    if (props.AgreementId) props.GetAgreementById(props.AgreementId);
    if (props.MedicalInsuranceId)
      props.GetMedicalInsuranceById(props.MedicalInsuranceId);
  }, [props.AgreementId, props.MedicalInsuranceId]);

  useEffect(() => {
    if (props.PriceListDeleted) setSelectedPriceList(undefined);
  }, [props.PriceListDeleted]);

  // events
  const HandleEditPriceEvent = (price: PriceDetails) => {
    setPriceToEdit(price);
    openPanel();
  };

  const HandleSearchServiceCriteriaChange = (criteria: string | undefined) => {
    setSearchTextCriteria(criteria);
  };

  const SelectPriceListHandler = (
    priceList: MedicalInsuranceAgreementsPriceList
  ) => {
    setSelectedPriceList(priceList);
  };

  const handleClosePanel = () => {
    setPriceToEdit(undefined);
    dismissPanel();
  };

  const handleAddPriceClick = () => {
    openPanel();
  };

  const handleDeletePriceList = (
    AgreementId: number,
    MedicalAgreementPriceListId: number
  ) => {
    props.DeletePriceListById(AgreementId, MedicalAgreementPriceListId);
  };

  const handleCancelDeletePriceList = () => {
    props.ResetPriceListDeleteState();
  };

  const handleClonePriceList = (AgreementPriceListId: number) => {
    props.ClonePriceList(AgreementPriceListId);
  };

  const handleAddListClick = () => {
    if (props.Agreement) {
      props.AddPriceList(
        props.AgreementId,
        t('agreementsDetailsPage.addPriceList.newPriceList'),
        t('agreementsDetailsPage.addPriceList.namePriceList') 
      );
    }
  };

  return props.Agreement ? (
    <>
      <PageHeader
        title={props.MedicalInsurance.businessName}
        subtitle={props.MedicalInsurance.title}
      />
      {/* <div className="header">
        {props.MedicalInsurance && (
          <h2 className="" onClick={GoBack}>
            {props.MedicalInsurance.businessName || ''} |{' '}
            {props.MedicalInsurance.title || ''}
            <Label>{props.MedicalInsurance.taxName || ''}</Label>
          </h2>
        )}
      </div> */}
      <div>
        <div className="Section">
          <MedicalInsuranceAgreementsDetails agreement={props.Agreement} />
        </div>
        <div className="Section">
          <Pivot>
            <PivotItem headerText={t('agreementsDetailsPage.agreementsDetailsItem.recongnizedServices')}>
              <AgreementServiceListComponent
                AgreementId={Number(props.AgreementId)}
              />
            </PivotItem>
            <PivotItem
              headerText={t('agreementsDetailsPage.agreementsDetailsItem.pricesLists')} 
              headerButtonProps={{
                'data-order': 1,
                'data-title': 'Price Lists'
              }}
            >
              <div className="SideLayoutPage">
                <div>
                  <div>
                    <PrimaryButton
                      secondaryText={t('agreementsDetailsPage.buttons.addPricesLists')}
                      onClick={handleAddListClick}
                      text={
                        props.isAddingPriceList
                          ? t('agreementsDetailsPage.addingPriceList.adding') 
                          : t('agreementsDetailsPage.addingPriceList.addPriceList') 
                      }
                      iconProps={
                        props.isAddingPriceList
                          ? ownFunctions.CreatingIcon
                          : ownFunctions.AddIcon
                      }
                      className="actionButton"
                      disabled={props.isAddingPriceList}
                    />
                    <Separator />
                    {props.Agreement && (
                      <>
                        <AgreementsPriceListComponent
                          priceList={props.PriceLists || []}
                          onSelectPriceList={SelectPriceListHandler}
                        />
                      </>
                    )}
                  </div>
                </div>
                <div>
                  {selectedPriceList && (
                    <>
                      <PriceListHeaderComponent
                        PriceList={selectedPriceList}
                        onAddMedicalService={handleAddPriceClick}
                        onSearchService={HandleSearchServiceCriteriaChange}
                        onDeletePriceList={(PriceListId: number) =>
                          handleDeletePriceList(props.AgreementId, PriceListId)
                        }
                        onCancelDeletePriceList={handleCancelDeletePriceList}
                        onClonePriceList={handleClonePriceList}
                        onMassiveUpdateChange={(isActive: boolean) =>
                          setUpdateMassivePriceIsActive(isActive)
                        }
                        isDeletingExecution={props.isDeletingPriceList}
                        FailOnDeleting={props.failOnDeletePriceList}
                        ErrorOnDeleting={props.DeletePriceListError}
                      />
                      <Separator />
                      <div className="priceListDetailsContent">
                        <div>
                          <PricesListComponent
                            PriceListId={selectedPriceList.priceList.id}
                            onEditPrice={HandleEditPriceEvent}
                            ServiceFilterCriteria={searchTextCriteria}
                            PriceMassiveEditionIsActive={UpdateMassivePriceIsActive} 
                            showPrice={true} 
                            showRemotePrice={true} 
                            showPreScreaningPrice={false}
                            showFailPrice={false} 
                            showRandomPrice={false} 
                            showAdditionalPaymentPrice={true}
                          />
                        </div>
                        <div className="Section infoPanel">
                          <PriceListInfoComponent
                            AgreementPriceList={selectedPriceList}
                          />
                        </div>
                      </div>
                    </>
                  )}
                  {PriceListState && !selectedPriceList?.agreementId && (
                    <div className="NoData">
                      <h3>{t('agreementsDetailsPage.agreementsDetailsSubTitle')}</h3>
                    </div>
                  )}
                </div>
              </div>
            </PivotItem>
            <PivotItem headerText={t('agreementsDetailsPage.agreementsDetailsItem.liquidations')}>
              <SettlementListComponent MedicalInsuranceId={props.MedicalInsuranceId} MedicalAgreementId={props.AgreementId} />
            </PivotItem>
            {/* <PivotItem headerText={t('agreementsDetailsPage.agreementsDetailsItem.accountStatus')}>
              <PaymentAccountDetailsComponent />
            </PivotItem> */}
          </Pivot>
        </div>

        {selectedPriceList && selectedPriceList.priceList && (
          <PriceListItemFormComponent
            isOpen={isOpen}
            dismissPanel={handleClosePanel}
            openPanel={openPanel}
            PriceListId={selectedPriceList.priceList.id}
            PriceDetails={priceToEdit}
            showPrice={true}
            showRemotePrice={true}
            showFailPrice={false}
            showPreScreeningPrice={false}
            showRandomPrice={false}
            showAdditionalPaymentPrice={true}
          />
        )}
      </div>
    </>
  ) : (
    <div></div>
  );
};

export default WithRouter(
  connect(
    ownFunctions.mapStateToProps,
    ownFunctions.mapDispatchToProps
  )(MedicalAgreementsDetailsPage as any)
);
