// stores 
import * as ProtocolConsumptionStore from '../../../redux/middleware/ProtocolConsumptionMiddleware';
import * as ProtocolStore from '../../../redux/middleware/MedicalProtocolMiddleware';

// state
import { AppState } from '../../../redux/reducers';
import { ProtocolConsumption } from '../../../models/Entities/Protocols/ProtocolConsumption';
import { Protocol } from '../../../models/Entities/Protocols/Protocol';
import { Practitioner } from '../../../models/Entities/Practitioners/Practitioner';

//#region Props

interface IProps {
    ProtocolId: number;
    ProtocolConsumptionId: string | null;
    isOpen: boolean;
    onClose: () => void;
}

interface ConnectedProps {
    // loading
    isLoading: boolean;
    successLoading: boolean | undefined;
    failLoading: boolean;
    error: TypeError | null;

    // changing concept
    isChangingConcept: boolean;
    successChangingConcept: boolean | undefined;
    failChangingConcept: boolean;
    errorChangingConcept: TypeError | null;

    // changing producer 
    isChangingProducer: boolean;
    successChangingProducer: boolean | undefined;
    failChangingProducer: boolean;
    errorChangingProducer: TypeError | null;

    // changing provider
    isChangingProvider: boolean;
    successChangingProvider: boolean | undefined;
    failChangingProvider: boolean;
    errorChangingProvider: TypeError | null;

    // changing medical service
    isChangingMedicalService: boolean;
    successChangingMedicalService: boolean | undefined;
    failChangingMedicalService: boolean;
    errorChangingMedicalService: TypeError | null;

    Consumption: ProtocolConsumption | null;
    ContractId: number | undefined;
    GetPersonByIdentifier: (identifier: string) => Practitioner | undefined;
}

interface MedicalServicesDispatchProps {
    GetProtocolConsumptionById: typeof ProtocolConsumptionStore.ActionCreators.GetProtocolConsumptionById;
    CreateProtocolConsumption: typeof ProtocolConsumptionStore.ActionCreators.CreateProtocolConsumption;
    UpdateProtocolConsumption: typeof ProtocolConsumptionStore.ActionCreators.UpdateProtocolConsumption;
    DeleteProtocolConsumption: typeof ProtocolConsumptionStore.ActionCreators.DeleteProtocolConsumption;
    ChangeContractConcept: typeof ProtocolConsumptionStore.ActionCreators.ChangeContractConcept;
    ChangeProducer: typeof ProtocolConsumptionStore.ActionCreators.ChangeProducer;
    ChangeProvider: typeof ProtocolConsumptionStore.ActionCreators.ChangeProvider;
    ChangeMedicalService: typeof ProtocolConsumptionStore.ActionCreators.ChangeMedicalService;
    GetAllProtocolContractAssociations: typeof ProtocolStore.actionCreators.GetAllProtocolContractAssociations;
}

export type Props = IProps & ConnectedProps & MedicalServicesDispatchProps;

export const mapStateToProps = (state: AppState, ownProps: IProps) => {

    const practitioners = state.Practitioner?.list || [];
    
    const AuxProtocol: Protocol| null =  state.MedicalProtocols?.list?.find((protocol: Protocol) => protocol.id === ownProps.ProtocolId) || null;

    const AuxConsumption: ProtocolConsumption | null = AuxProtocol?.consumptions?.find((consumption: ProtocolConsumption) => consumption.id === ownProps.ProtocolConsumptionId) || null;

    const AuxContractId: number | undefined = AuxProtocol?.contractAssociation?.contractId ?? undefined;

    return {
        // loading
        isLoading: state.MedicalProtocols?.isLoadingOneConsumption || false,
        successLoading: state.MedicalProtocols?.LoadingOneConsumptionSuccess || undefined,
        failLoading: state.MedicalProtocols?.FailGettingOneConsumption || null,
        error: state.MedicalProtocols?.ErrorGettingOneConsumption || null,
        
        // changing concept
        isChangingConcept: AuxConsumption?.isConcepAssociationChanging ?? false,
        successChangingConcept: AuxConsumption?.conceptAssociationChanged ?? undefined,
        failChangingConcept: AuxConsumption?.failonConceptAssociationChange ?? false,
        errorChangingConcept: AuxConsumption?.ErrorOnConceptAssociationChange ?? null,

        // changing producer
        isChangingProducer: AuxConsumption?.isProducerChanging ?? false,
        successChangingProducer: AuxConsumption?.producerChanged ?? undefined,
        failChangingProducer: AuxConsumption?.failonProducerChange ?? false,
        errorChangingProducer: AuxConsumption?.ErrorOnProducerChange ?? null,

        // changing provider
        isChangingProvider: AuxConsumption?.isProviderChanging ?? false,
        successChangingProvider: AuxConsumption?.providerChanged ?? undefined,
        failChangingProvider: AuxConsumption?.failonProviderChange ?? false,
        errorChangingProvider: AuxConsumption?.ErrorOnProviderChange ?? null,

        // changing medical service
        isChangingMedicalService: AuxConsumption?.isMedicalServiceChanging ?? false,
        successChangingMedicalService: AuxConsumption?.medicalServiceChanged ?? undefined,
        failChangingMedicalService: AuxConsumption?.failonMedicalServiceChange ?? false,
        errorChangingMedicalService: AuxConsumption?.ErrorOnMedicalServiceChange,
        
        Consumption: AuxConsumption || null,
        ContractId: AuxContractId,
        GetPersonByIdentifier: (identifier: string) => GetPersonByIdentifier(practitioners, identifier)
    }
};

export const mapDispatchToProps = {
    ...ProtocolConsumptionStore.ActionCreators,
    ...ProtocolStore.actionCreators
};

export const GetStatusDescription = (status: number) => {

    /*
        NotSettled = 0,
        Pending = 1,
        Payee = 2,
        Settled = 3,
        Rejected = 4,
        Ignored = 5,
     */

    switch (status) {
        case 0:
            return 'Pendiente de liquidación';
        case 1:
            return 'Con saldo pendiente';
        case 2:
            return 'Pagado';
        case 3:
            return 'Liquidado';
        case 4:
            return 'Rechazado';
        case 5:
            return 'Ignorado';
        default:
            return 'Unknown';
    }
};

// get person by identifier
const GetPersonByIdentifier = (persons: Practitioner[], identifier: string): Practitioner | undefined => {
  return persons.find((person: Practitioner) => person.userId === identifier);
}