import { Provider } from '../../models/Entities/Provider/Provider';
import { ErrorType } from '../../models/HttpError';
import { CommonState } from '../common/state/commonState';

export interface ProviderState extends CommonState<Provider> {
  // update all
  isUpdatingAll: boolean;
  UpdatingAllSuccess: undefined | boolean;
  FailUpdatingAll: boolean;

  // consumption
  isLoadingConsumptions: boolean;
  LoadingConsumptionsSuccess: undefined | boolean;
  FailGettingConsumptions: boolean;
  ErrorLoadingConsumptions: ErrorType | undefined;

}

export const ProviderUnloadState: any = {
  // loading all
  isLoadingAll: false,
  isLoadingAllSuccess: undefined,
  FailGettingAll: false,

  // update all
  isUpdatingAll: false,
  UpdatingAllSuccess: undefined,
  FailUpdatingAll: false,

  // loading one
  isLoadingOne: false,
  isLoadingOneSuccess: undefined,
  FailGettingOne: false,

  // saving
  isSaving: false,
  isSavingSuccess: undefined,
  FailSaving: false,

  // adding
  isAdding: false,
  isAddingSuccess: undefined,
  FailAdding: false,

  isFiltered: false,
  filterCriteria: undefined,

  list: [],
  Selected: undefined,
  Error: undefined,

  // consumption
  isLoadingConsumptions: false,
  LoadingConsumptionsSuccess: undefined,
  FailGettingConsumptions: false,
  ErrorLoadingConsumptions: undefined,
};
