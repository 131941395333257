import { AppThunkAction } from '../reducers';

// Actions
import * as Actions from '../actions';

// Services
import * as service from '../../services/ProtocolConsumptionSyncProcess.Service';

// Models
import { ProtocolConsumptionSyncProcess } from '../../models/Entities/Protocols/ProtocolConsumptionSyncProcess';
import { ErrorType } from '../../models/HttpError';

type KnownAction =
    | Actions.ProtocolConsumptionSyncProcess.ProtocolConsumptionSyncProcessGetAllByProtocolIdRequestAction
    | Actions.ProtocolConsumptionSyncProcess.ProtocolConsumptionSyncProcessGetAllByProtocolIdSuccessAction
    | Actions.ProtocolConsumptionSyncProcess.ProtocolConsumptionSyncProcessGetAllByProtocolIdFailureAction
    | Actions.ProtocolConsumptionSyncProcess.ProtocolConsumptionSyncProcessGetByIdRequestAction
    | Actions.ProtocolConsumptionSyncProcess.ProtocolConsumptionSyncProcessGetByIdSuccessAction
    | Actions.ProtocolConsumptionSyncProcess.ProtocolConsumptionSyncProcessGetByIdFailureAction
    | Actions.ProtocolConsumptionSyncProcess.ProtocolConsumptionSyncProcessGetLastByProtocolIdRequestAction
    | Actions.ProtocolConsumptionSyncProcess.ProtocolConsumptionSyncProcessGetLastByProtocolIdSuccessAction
    | Actions.ProtocolConsumptionSyncProcess.ProtocolConsumptionSyncProcessGetLastByProtocolIdFailureAction
    | Actions.ProtocolConsumptionSyncProcess.ProtocolConsumptionSyncProcessCreateRequestAction
    | Actions.ProtocolConsumptionSyncProcess.ProtocolConsumptionSyncProcessCreateSuccessAction
    | Actions.ProtocolConsumptionSyncProcess.ProtocolConsumptionSyncProcessCreateFailureAction
    | Actions.ProtocolConsumptionSyncProcess.ProtocolConsumptionSyncProcessUpdateRequestAction
    | Actions.ProtocolConsumptionSyncProcess.ProtocolConsumptionSyncProcessUpdateSuccessAction
    | Actions.ProtocolConsumptionSyncProcess.ProtocolConsumptionSyncProcessUpdateFailureAction
    | Actions.ProtocolConsumptionSyncProcess.ProtocolConsumptionSyncProcessDeleteRequestAction
    | Actions.ProtocolConsumptionSyncProcess.ProtocolConsumptionSyncProcessDeleteSuccessAction
    | Actions.ProtocolConsumptionSyncProcess.ProtocolConsumptionSyncProcessDeleteFailureAction;

export const ActionCreators = {
    GetAllProtocolConsumptionSyncProcessByProtocolId:
        (protocolId: number): AppThunkAction<KnownAction> =>
            (dispatch, getState) => {
                const appState = getState();
                var token: string = '';

                if (appState.oidc?.user?.access_token === undefined) {
                    dispatch({
                        type: 'PROTOCOLCONSUMPTIONSYNCPROCESS_GETALL_BYPROTOCOLID_FAILURE',
                        protocolId: protocolId,
                        error: {
                            ErrorCode: 401,
                            ErrorMessage: 'Not authorized',
                            Errors: []
                        }
                    });
                    return;
                } else {
                    token = appState.oidc.user.access_token;
                };

                if (appState) {
                    service.GetProtocolConsumptionSyncProcess(protocolId, token)
                        .then((list: ProtocolConsumptionSyncProcess[]) => {
                            dispatch({
                                type: 'PROTOCOLCONSUMPTIONSYNCPROCESS_GETALL_BYPROTOCOLID_SUCCESS',
                                protocolId: protocolId,
                                processes: list
                            });
                        })
                        .catch((error: ErrorType) => {
                            dispatch({
                                type: 'PROTOCOLCONSUMPTIONSYNCPROCESS_GETALL_BYPROTOCOLID_FAILURE',
                                protocolId: protocolId,
                                error: error
                            });
                        });
                }
            },

    GetProtocolConsumptionSyncProcessById:
        (protocolId: number, syncProcessId: string): AppThunkAction<KnownAction> =>
            (dispatch, getState) => {
                const appState = getState();
                var token: string = '';

                if (appState.oidc?.user?.access_token === undefined) {
                    dispatch({
                        type: 'PROTOCOLCONSUMPTIONSYNCPROCESS_GET_BYID_FAILURE',
                        protocolId: protocolId,
                        syncProcessId: syncProcessId,
                        error: {
                            ErrorCode: 401,
                            ErrorMessage: 'Not authorized',
                            Errors: []
                        }
                    });
                    return;
                } else {
                    token = appState.oidc.user.access_token;
                };

                if (appState) {
                    service.GetProtocolConsumptionSyncProcessById(syncProcessId, token)
                        .then((process: ProtocolConsumptionSyncProcess) => {
                            dispatch({
                                type: 'PROTOCOLCONSUMPTIONSYNCPROCESS_GET_BYID_SUCCESS',
                                process: process
                            });
                        })
                        .catch((error: ErrorType) => {
                            dispatch({
                                type: 'PROTOCOLCONSUMPTIONSYNCPROCESS_GET_BYID_FAILURE',
                                protocolId: protocolId,
                                syncProcessId: syncProcessId,
                                error: error
                            });
                        }
                        );
                }
            },
    GetLastProtocolConsumptionSyncProcessByProtocolId:
        (protocolId: number): AppThunkAction<KnownAction> =>
            (dispatch, getState) => {
                const appState = getState();
                var token: string = '';

                if (appState.oidc?.user?.access_token === undefined) {
                    dispatch({
                        type: 'PROTOCOLCONSUMPTIONSYNCPROCESS_GETLAST_BYPROTOCOLID_FAILURE',
                        protocolId: protocolId,
                        error: {
                            ErrorCode: 401,
                            ErrorMessage: 'Not authorized',
                            Errors: []
                        }
                    });
                    return;
                } else {
                    token = appState.oidc.user.access_token;
                };

                dispatch({
                    type: 'PROTOCOLCONSUMPTIONSYNCPROCESS_GETLAST_BYPROTOCOLID_REQUEST',
                    protocolId: protocolId,
                });

                if (appState) {
                    service.GetLastProtocolConsumptionSyncProcess(protocolId, token)
                        .then((process: ProtocolConsumptionSyncProcess) => {
                            dispatch({
                                type: 'PROTOCOLCONSUMPTIONSYNCPROCESS_GETLAST_BYPROTOCOLID_SUCCESS',
                                protocolId: protocolId,
                                process: process
                            });
                        })
                        .catch((error: ErrorType) => {
                            dispatch({
                                type: 'PROTOCOLCONSUMPTIONSYNCPROCESS_GETLAST_BYPROTOCOLID_FAILURE',
                                protocolId: protocolId,
                                error: error
                            });
                        });
                }
            },
    CreateProtocolConsumptionSyncProcess:
        (protocolId: number): AppThunkAction<KnownAction> =>
            (dispatch, getState) => {
                const appState = getState();
                var token: string = '';

                if (appState.oidc?.user?.access_token === undefined) {
                    dispatch({
                        type: 'PROTOCOLCONSUMPTIONSYNCPROCESS_CREATE_FAILURE',
                        protocolId: protocolId,
                        error: {
                            ErrorCode: 401,
                            ErrorMessage: 'Not authorized',
                            Errors: []
                        }
                    });
                    return;
                } else {
                    token = appState.oidc.user.access_token;
                };

                if (appState) {
                    dispatch({
                        type: 'PROTOCOLCONSUMPTIONSYNCPROCESS_CREATE_REQUEST',
                        protocolId: protocolId
                    });

                    service.AddProtocolConsumptionSyncProcess(protocolId, token)
                        .then((process: ProtocolConsumptionSyncProcess) => {
                            dispatch({
                                type: 'PROTOCOLCONSUMPTIONSYNCPROCESS_CREATE_SUCCESS',
                                process: process
                            });
                        })
                        .catch((error: ErrorType) => {
                            dispatch({
                                type: 'PROTOCOLCONSUMPTIONSYNCPROCESS_CREATE_FAILURE',
                                protocolId: protocolId,
                                error: error
                            });
                        });
                }
            },
    UpdateProtocolConsumptionSyncProcess:
        (process: ProtocolConsumptionSyncProcess): AppThunkAction<KnownAction> =>
            (dispatch, getState) => {
                const appState = getState();
                var token: string = '';

                if (appState.oidc?.user?.access_token === undefined) {
                    dispatch({
                        type: 'PROTOCOLCONSUMPTIONSYNCPROCESS_UPDATE_FAILURE',
                        error: {
                            ErrorCode: 401,
                            ErrorMessage: 'Not authorized',
                            Errors: []
                        }
                    });
                    return;
                } else {
                    token = appState.oidc.user.access_token;
                };

                if (appState) {
                    dispatch({
                        type: 'PROTOCOLCONSUMPTIONSYNCPROCESS_UPDATE_REQUEST',
                        process: process
                    });

                    service.UpdateProtocolConsumptionSyncProcess(token, process)
                        .then((process: ProtocolConsumptionSyncProcess) => {
                            dispatch({
                                type: 'PROTOCOLCONSUMPTIONSYNCPROCESS_UPDATE_SUCCESS',
                                process: process
                            });
                        })
                        .catch((error: ErrorType) => {
                            dispatch({
                                type: 'PROTOCOLCONSUMPTIONSYNCPROCESS_UPDATE_FAILURE',
                                error: error
                            });
                        });
                }
            },
    DeleteProtocolConsumptionSyncProcess:
        (protocolId: number, id: string): AppThunkAction<KnownAction> =>
            (dispatch, getState) => {
                const appState = getState();
                var token: string = '';

                if (appState.oidc?.user?.access_token === undefined) {
                    dispatch({
                        type: 'PROTOCOLCONSUMPTIONSYNCPROCESS_DELETE_FAILURE',
                        protocolId: protocolId,
                        id: id,
                        error: {
                            ErrorCode: 401,
                            ErrorMessage: 'Not authorized',
                            Errors: []
                        }
                    });
                    return;
                } else {
                    token = appState.oidc.user.access_token;
                };

                if (appState) {
                    dispatch({
                        type: 'PROTOCOLCONSUMPTIONSYNCPROCESS_DELETE_REQUEST',
                        protocolId: protocolId,
                        id: id
                    });

                    service.DeleteProtocolConsumptionSyncProcess(id, token)
                        .then(() => {
                            dispatch({
                                type: 'PROTOCOLCONSUMPTIONSYNCPROCESS_DELETE_SUCCESS',
                                protocolId: protocolId,
                                id: id
                            });
                        })
                        .catch((error: ErrorType) => {
                            dispatch({
                                type: 'PROTOCOLCONSUMPTIONSYNCPROCESS_DELETE_FAILURE',
                                protocolId: protocolId,
                                id: id,
                                error: error
                            });
                        });
                }
            }
};

