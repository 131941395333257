import { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { Field, Label, Tag, TagPicker, TagPickerControl, TagPickerGroup, TagPickerInput, TagPickerList, TagPickerOption, TagPickerProps } from '@fluentui/react-components';

// own functions
import * as ownFunctions from './ProtocolConsumptionStatusSelector.Functions';
import { IConsumptionStatusProps } from './ProtocolConsumptionStatusSelector.Functions';


const ProtocolConsumptionStatusSelectorComponent = (props: ownFunctions.Props) => {

   
  const [InternalStatusSelected, setInternalStatusSelected] = useState<IConsumptionStatusProps[]>([]);

  useEffect(() => {
      props.onSelectedStatus(InternalStatusSelected);    
  }, [InternalStatusSelected]);

  useEffect(() => {
    if(props.Status?.length > 0 && (props.statusSelected ?? []).length > 0){
           
      const selectedStatus: IConsumptionStatusProps[] = props.Status?.filter((status: IConsumptionStatusProps) => props.statusSelected?.includes(status.id)) ?? [];
      const selectedStrings: string[] = selectedStatus.map((status: IConsumptionStatusProps) => status.Name) ?? [];
      setSelectedOptions(selectedStrings);
      setInternalStatusSelected(selectedStatus);
    }
  }, []);

  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

  const onOptionSelect: TagPickerProps["onOptionSelect"] = (e, data) => {
    if (data.value === "no-options") {
      setSelectedOptions([]);
      setInternalStatusSelected([]);
      return;
    }
    setSelectedOptions(data.selectedOptions);
    setInternalStatusSelected(props.Status?.filter((status: IConsumptionStatusProps ) => data.selectedOptions.includes(status.Name)));
  };

  const tagPickerOptions = props.Status?.map((status) => status.Name)?.filter(
    (status: string) => !selectedOptions.includes(status)
  );

  return (
      <Field label="Seleccione un estado" style={{ maxWidth: 400 }}>
        <TagPicker
          onOptionSelect={onOptionSelect}
          selectedOptions={selectedOptions}
        >
          <TagPickerControl>
            <TagPickerGroup aria-label="Estados seleccionados">
              {selectedOptions.map((option) => (
                <Tag
                  key={option}
                  shape="rounded"
                  media={
                    <div className={'status-mark ' + ownFunctions.GetStatusClass(option).class}></div>
                  }
                  value={option}
                >
                  {option}
                </Tag>
              ))}
            </TagPickerGroup>
            <TagPickerInput aria-label="Seleccione un estado" />
          </TagPickerControl>
          <TagPickerList>
            {tagPickerOptions?.length > 0 ? (
              tagPickerOptions?.map((option) => (
                <TagPickerOption
                  value={option}
                  key={option}
                  media={
                    <div className={'status-mark ' + ownFunctions.GetStatusClass(option).class}></div>
                  }
                >
                  {option}
                </TagPickerOption>
              ))
            ) : (
              <TagPickerOption value="no-options">
                No hay conceptos disponibles
              </TagPickerOption>
            )}
          </TagPickerList>
        </TagPicker>
        {props.noteText && <Label>{props.noteText}</Label>}
      </Field>
  );
};

export default connect(
  ownFunctions.mapStateToProps,
  ownFunctions.mapDispatchToProps
)(ProtocolConsumptionStatusSelectorComponent as any);
