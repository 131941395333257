import { FontSizes, Label, Separator, Text } from "@fluentui/react";
import { ContractStage } from "../../../../models/Entities/Contracts/ContractStage";
import { ConfirmationButtonComponent } from "../../../../commons/component/ConfirmationButton/ConfirmationButtonComponent";
import { ErrorType } from "../../../../models/HttpError";

//i18Next
import { useTranslation } from 'react-i18next';

// styles
import './StageList.Styles.css';
import { Badge } from "@fluentui/react-components";
import { Money24Filled, MoneyHand24Filled, MoneyHand24Regular } from "@fluentui/react-icons";


export interface IProps {
    Stage: ContractStage;
    index: number;
    isDeleting: boolean;
    failOnDeleting: boolean;
    error: ErrorType | undefined;
    onEdit?: (stage: ContractStage) => void;
    onDelete?: (stage: ContractStage) => void;
}


export const StageItemComponent = (props: IProps) => {

    // actions
    const onSelectStage = (stage: ContractStage) => {
        if (props.onEdit) {
            props.onEdit(stage);
        }
    }

    const onDeleteStage = (stage: ContractStage) => {
        if (props.onDelete) {
            props.onDelete(stage);
        }
    }

    //useTranslation()
    const [t] = useTranslation();

    return (

        <div className="StageListItem" key={props.Stage.id} onClick={() => onSelectStage(props.Stage)}>
            <div className="StageIndex">
                <Text style={{ fontSize: FontSizes.mini }}>{t('stageItem.props.visit')} {props.index + 1}</Text>
            </div>
            <div className="StageListItemContent">
                <div>
                    <Label style={{ fontSize: FontSizes.size14 }}>{props.Stage.stageName}</Label>
                    {props.Stage.comments && <Text>|&nbsp;{props.Stage.comments}</Text>}
                </div>
                <div>
                    {props.Stage.markAsPayable && <><MoneyHand24Regular style={{color: "#067bb5"}} />&nbsp;<Separator vertical/></>}
                    {props.Stage.cycle && <small>{props.Stage.cycle?.cycleName}</small>}
                    {props.Stage.isOptional && <Label style={{ fontSize: FontSizes.size14 }} className="StageBadge">{t('stageItem.props.optional')}</Label>}
                    {props.Stage.isOnSiteMandatory ? <Label style={{ fontSize: FontSizes.size14 }} className="StageBadge">{t('stageItem.props.inPerson')}</Label> : <Label className="StageBadge">{t('stageItem.props.remote')}</Label>}
                </div>
            </div>
            <div className="StageListItemOptions">

            </div>
            <div className="danger">
                <ConfirmationButtonComponent
                    ButtonId={"deletebranch" + props.Stage.id}
                    ButtonIconName={"Delete"}
                    ButtonText={""}
                    ButtonCssClass={""}
                    ConfirmationButtonText={t('stageItem.confirmationButton.deleteButton')}
                    ConfirmationButtonCssClass={""}
                    CancelButtonText={t('stageItem.confirmationButton.cancelButton')}
                    CancelButtonCssClass={""}
                    Title={t('stageItem.confirmationButton.deleteVisit')}
                    Description={""}
                    ElementTextHeader={t('stageItem.confirmationButton.deleteVisitInformation') + props.Stage.stageName}
                    ElementTextInformation={t('stageItem.confirmationButton.operationMessage')}
                    onConfirmation={() =>
                        onDeleteStage(props.Stage)
                    }
                    onCancel={function (): void {
                        throw new Error('Function not implemented.');
                    }}
                    isOperationExecuting={
                        props.isDeleting
                    }
                    isOperationExecutedWithError={
                        props.failOnDeleting
                    }
                    OperationError={
                        props.error
                    }
                />
            </div>
        </div>

    );
};