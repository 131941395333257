import React from 'react';
import { connect } from 'react-redux';

// styles
import './ConsumptionDetailMenu.Styles.css';

// import functions 
import * as ComponentFunctions from './ConsumptionDetailMenu.Functions';
import { Button } from '@fluentui/react-components';
import { Separator } from '@fluentui/react';
import { ChevronDoubleRight20Regular, ChevronRight20Regular, ChevronRight24Filled } from '@fluentui/react-icons';



const ConsumptionDetailMenu = (props: ComponentFunctions.Props) => {
    return (
        <div className="consumption-detail-menu-container">
            <Button  onClick={() => undefined}>Editar</Button>
            <Separator vertical />
            <Button  onClick={() => undefined} icon={<ChevronRight20Regular />}>Ignorar</Button>
            <Button  onClick={() => undefined} icon={<ChevronDoubleRight20Regular />}>Ignorar Todos</Button>
            
        </div>
    );
};

export default connect (
    ComponentFunctions.mapStateToProps,
    ComponentFunctions.mapDispatchToProps
) (ConsumptionDetailMenu as any);