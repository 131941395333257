import { connect } from 'react-redux';

// component functions
import * as ComponentFunctions from './ConsumptionTotal.Functions';
import { ProtocolConsumptionTotal } from '../../../models/Entities/Protocols/ProtocolConsumptionTotal';
import { useEffect } from 'react';

import './ConsumptionTotal.Styles.css';
import { Label } from '@fluentui/react-components';

const ProtocolTotalsComponent = (props: ComponentFunctions.Props) => {

    useEffect(() => {
        props.GetTotalsByStatus(props.ProtocolId, props.status);
    }, [props.protocol != null]);

    return (
        <div className='protocolTotals'>
            <Label>{ComponentFunctions.GetStatusDescription(props.status)}</Label>
            {props.Totals && props.Totals.map((total: ProtocolConsumptionTotal) => 
                <div>
                    <h3>{total.totalAmount?.toLocaleString('es-AR', { style: 'currency', currency: total.currencyCode?.toUpperCase() }) || '0.00'}</h3>
                </div>
            )}
            {!props.Totals || props.Totals.length === 0 && <h3>US$ 0.00</h3>}
        </div>
    )

};

export default connect (
    ComponentFunctions.mapStateToProps,
    ComponentFunctions.mapDispatchToProps
) (ProtocolTotalsComponent as any);