import React, { FormEvent, useEffect, useState } from "react";
import { Panel, PanelType, Dropdown, TextField, Checkbox, Separator, DefaultButton, PrimaryButton, SpinnerSize, Label, MessageBarType } from "@fluentui/react";
import { connect } from "react-redux";
import { useTranslation, Trans } from "react-i18next";


// functions 
import * as ComponentFunctions from './PractitionerProtocolConceptForm.Functions';

// components
import ContractConceptSelectorComponent from "../../../../commons/component/Inputs/ContractConceptSelector/ContractConceptSelector.Component";

// models
import { ContractConcept } from "../../../../models/Entities/Contracts/ContractConcept";
import { IValueOriginOption } from "../../../Contracts/Concept/ConceptForm/ConceptForm.Functions";
import { CurrencySelectorComponent } from "../../../../commons/component/Inputs/CurrencySelector/CurrencySelectorComponent";
import MedicalServiceSelectorComponent from "../../../../commons/component/Inputs/MedicalServiceSelector/MedicalServiceSelectorComponent";
import { AvailableMedicalService } from "../../../../models/Entities/MedicalServices/AvailableMedicalService";
import { InfoLabel, Spinner } from "@fluentui/react-components";
import { NewContractCommissionAgentConcept } from "../../../../models/Entities/Contracts/NewContractCommissionAgentConcept";
import { ContractCommissionAgentConcept } from "../../../../models/Entities/Contracts/ContractCommissionAgentConcept";
import { ErrorMessageComponent } from "../../../Error/ErrorMessageComponent";
import { MessageComponent } from "../../../../commons/component/MessageComponent";
import { use } from "i18next";


interface FormValidation {
    success: boolean;
    errors: string[];
}


const PractitionerProtocolConceptForm = (props: ComponentFunctions.Props) => {

    const [submittedForm, setSubmittedForm] = useState<boolean>(false);
    const [FormValidationStatus, setFormValidationStatus] = useState<
        FormValidation | undefined
    >();

    const { t } = useTranslation();

    const [conceptSelected, setConceptSelected] = useState<ContractConcept>();
    const [valueOriginOptionSelected, setValueOriginOptionSelected] = useState<IValueOriginOption>(
        props.CommissionAgentConcept?.valuationType ? 
        ComponentFunctions.GetValueOriginOptionByKey(props.CommissionAgentConcept?.valuationType)
         : ComponentFunctions.ValueOriginOptions[0]);
    const [currencySelected, setCurrencySelected] = useState<string>(props.CommissionAgentConcept?.currencyCode || props.Contract?.contractCurrency);
    const [medicalServiceSelected, setMedicalServiceSelected] = useState<AvailableMedicalService>(props.MedicalService);
    const [takeOnlyItWasDoneByHimself, setTakeOnlyItWasDoneByHimself] = useState<boolean>(props.CommissionAgentConcept?.takeOnlyItWasDoneByHimself || false);

    // values
    const [value, setValue] = useState<number>(props.CommissionAgentConcept?.value || 0);
    const [remoteValue, setRemoteValue] = useState<number>(props.CommissionAgentConcept?.remoteValue || 0);
    const [additionalValue, setAdditionalValue] = useState<number>(props.CommissionAgentConcept?.additionalValue || 0);
    const [failValue, setFailValue] = useState<number>(props.CommissionAgentConcept?.failValue || 0);

    useEffect(() => {
        if(props.SavingSuccessfully){
            props.dismissPanel();
        }
    }, [props.SavingSuccessfully]);

    const ValidateForm = (): boolean => {
        let validationResult: boolean = true;

        let FormValidation: FormValidation = { success: true, errors: [] };
        setFormValidationStatus(FormValidation);

        if (!conceptSelected) {
            FormValidation.errors.push('Seleccione un concepto');
            validationResult = false;
        }

        if (!valueOriginOptionSelected) {
            FormValidation.errors.push('Seleccione un tipo de valorización');
            validationResult = false;
        }

        if (valueOriginOptionSelected?.isFixedPrice && !currencySelected) {
            FormValidation.errors.push('Seleccione una moneda');
            validationResult = false;
        }

        if (!valueOriginOptionSelected?.isPriceList && !valueOriginOptionSelected?.isPercentage) {
            if (value === null || value === undefined) {
                FormValidation.errors.push('Ingrese un valor');
                validationResult = false;
            }
            if (remoteValue === null || remoteValue === undefined) {
                FormValidation.errors.push('Ingrese un valor remoto');
                validationResult = false;
            }
            if (additionalValue === null || additionalValue === undefined) {
                FormValidation.errors.push('Ingrese un valor adicional');
                validationResult = false;
            }
            if (failValue === null || failValue === undefined) {
                FormValidation.errors.push('Ingrese un valor de falla');
                validationResult = false;
            }
        }

        if (!medicalServiceSelected && valueOriginOptionSelected?.isPriceList) {
            FormValidation.errors.push('Seleccione un servicio médico');
            validationResult = false;
        }

        if (valueOriginOptionSelected?.isPercentage && (value) > 100) {
            FormValidation.errors.push('El valor no puede ser mayor a 100%');
            validationResult = false;
        }

        FormValidation.success = validationResult;
        setFormValidationStatus(FormValidation);
        return validationResult;
    };

    // events
    const HandleCancelForm = () => {
        props.dismissPanel();
    }

    // events
    const HandleSaveForm = () => {

        if (ValidateForm()) {
            var currentCommissionAgentConcept: NewContractCommissionAgentConcept | ContractCommissionAgentConcept | undefined;
            if (props.CommissionAgentConceptId) {
                currentCommissionAgentConcept = {
                    id: props.CommissionAgentConceptId,
                    commissionAgentId: props.CommissionAgentId,
                    contractId: props.ContractId,
                    conceptId: conceptSelected?.id || 0,
                    valuationType: Number(valueOriginOptionSelected?.key) || 0,
                    currencyCode: currencySelected,
                    value: value,
                    remoteValue: remoteValue,
                    additionalValue: additionalValue,
                    failValue: failValue,
                    medicalServiceId: medicalServiceSelected?.id || 0,
                    takeOnlyItWasDoneByHimself: takeOnlyItWasDoneByHimself
                } as ContractCommissionAgentConcept;

                props.UpdateContractCommissionAgentConcept(currentCommissionAgentConcept as ContractCommissionAgentConcept);
            }
            else {
                currentCommissionAgentConcept = {
                    commissionAgentId: props.CommissionAgentId,
                    contractId: props.ContractId,
                    conceptId: conceptSelected?.id || 0,
                    valuationType: Number(valueOriginOptionSelected?.key) || 0,
                    currencyCode: currencySelected,
                    value: value,
                    remoteValue: remoteValue,
                    additionalValue: additionalValue,
                    failValue: failValue,
                    medicalServiceId: medicalServiceSelected?.id || 0,
                    takeOnlyItWasDoneByHimself: takeOnlyItWasDoneByHimself
                } as NewContractCommissionAgentConcept;

                props.CreateContractCommissionAgentConcept(currentCommissionAgentConcept as NewContractCommissionAgentConcept);
            }
        }
    }

    const FormField = (
        <>
            <ContractConceptSelectorComponent
                onSelectedContractConcept={(contractConcept: ContractConcept[]) => {setConceptSelected(contractConcept[0])}}
                ContractConceptsSelected={[props.CommissionAgentConcept?.conceptId]}
                MultiselectEnable={false}
                noteText={'Seleccione el concepto a tomar en cuenta en la liquidación del profesional'}
                ContractId={props.ContractId}
                referralDate={undefined}
            />
            <Dropdown
                label={t('conceptForm.formFields.valorization')}
                options={ComponentFunctions.ValueOriginOptions.map((option: IValueOriginOption) => {
                    return { ...option, ...{ ...option, text: t(option.text) } } as IValueOriginOption;
                })}
                defaultSelectedKey={valueOriginOptionSelected?.key}
                onChange={(event: any, option: any) => { console.log('option', option as IValueOriginOption); setValueOriginOptionSelected(option as IValueOriginOption) }}
            />
            {valueOriginOptionSelected?.isFixedPrice &&
                <>
                    <CurrencySelectorComponent
                        defaultSelected={currencySelected || ''}
                        onCurrencyChange={(currency: string) => setCurrencySelected(currency)}
                        errorMessage={undefined}
                        label={t('settlementDetailForm.formData.currency')}
                        isRequired={true}
                    />
                </>
            }
            {!valueOriginOptionSelected?.isPriceList &&
                <>
                    <TextField
                        label={t('conceptForm.formFields.value')}
                        value={value.toString()}
                        type={'number'}
                        onFocusCapture={(event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => event.currentTarget.select()}
                        onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => setValue(Number(newValue) || 0)}
                        suffix={valueOriginOptionSelected?.isFixedPrice ? currencySelected : valueOriginOptionSelected?.isPercentage ? '%' : undefined}
                    />
                    {valueOriginOptionSelected?.isFixedPrice &&
                        <>
                            <TextField
                                label={'Valor Remoto'}
                                value={remoteValue.toString()}
                                type={'number'}
                                onFocusCapture={(event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => event.currentTarget.select()}
                                onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => setRemoteValue(Number(newValue) || 0)}
                                suffix={valueOriginOptionSelected?.isFixedPrice ? currencySelected : valueOriginOptionSelected?.isPercentage ? '%' : undefined}
                            />
                            <TextField
                                label={'Valor Adicional'}
                                onFocusCapture={(event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => event.currentTarget.select()}
                                value={additionalValue.toString()}
                                type={'number'}

                                onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => setAdditionalValue(Number(newValue) || 0)}
                                suffix={valueOriginOptionSelected?.isFixedPrice ? currencySelected : valueOriginOptionSelected?.isPercentage ? '%' : undefined}
                            />
                            <TextField
                                label={'Valor SCR / Falla'}
                                value={failValue.toString()}
                                type={'number'}
                                onFocusCapture={(event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => event.currentTarget.select()}
                                onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => setFailValue(Number(newValue) || 0)}
                                suffix={valueOriginOptionSelected?.isFixedPrice ? currencySelected : valueOriginOptionSelected?.isPercentage ? '%' : undefined}
                            />
                        </>
                    }

                </>
            }
            {valueOriginOptionSelected?.isPriceList &&
                <>
                    <InfoLabel
                        info={
                            <>
                                Para asociar este concepto a una lista de precios, tiene que estar vinculado a un servicio médico
                            </>
                        }
                        {...props}
                    >
                        <MedicalServiceSelectorComponent
                            onSelectedServices={(services: AvailableMedicalService[]) => setMedicalServiceSelected(services[0] || undefined)}
                            noteText={'Seleccione la practica medica a la que quiere vincular el concepto a liquidar'}
                            MedicalServicesSelectedIds={[props.CommissionAgentConcept?.medicalServiceId]}
                            MultiselectEnable={false} />
                    </InfoLabel>
                </>
            }
            <Separator />
            <Checkbox
                label={'Solo tomar en cuenta si fue realizado por el mismo profesional'}
                checked={takeOnlyItWasDoneByHimself}
                onChange={(ev?: FormEvent<HTMLElement | HTMLInputElement> | undefined, checked?: boolean | undefined) => setTakeOnlyItWasDoneByHimself(checked || false)}
            />
        </>
    );

    const FormValidationStatusMessage = (FormValidationStatus?.errors?.length ? FormValidationStatus?.errors?.length > 0 : false) && (
        <>
            <MessageComponent
                message={"Verifique los datos ingresados"}
                type={MessageBarType.error}
                subMessage="Se encontraron errores en los datos ingresados. Por favor verifique los campos y vuelva a intentar."
                itemList={FormValidationStatus?.errors || []}
            />
        </>
    );

    const SaveErrorStatusMessage = (props.FailOnSaving && props.ErrorOnSaving) && (
        <>
            <MessageComponent
                 message={"Ocurrío un error al guardar los datos. Por favor intente nuevamente."}
                 type={MessageBarType.error}
                 subMessage={props.ErrorOnSaving.ErrorMessage}
                 itemList={props.ErrorOnSaving?.Errors || []}
            />
        </>
    );

    // footer
    const onRenderFooterContent = () => {
        return (
            <>
                <div className=''>
                    <DefaultButton onClick={HandleCancelForm} className="OtherOption">
                        <Trans i18nKey={'conceptForm.footer.cancelButton'} />
                    </DefaultButton>
                    <PrimaryButton
                        onClick={HandleSaveForm}
                        disabled={props.isSaving}
                        className="ConfirmAction"
                    >
                        <>{props.isSaving && <><Spinner appearance="inverted" size="extra-tiny" />&nbsp;</>}<span><Trans i18nKey={'conceptForm.footer.saveButton'} /></span></>
                    </PrimaryButton>
                </div>
            </>
        );
    };


    return (
        <Panel
            headerText={'Concepto a liquidar'}
            type={PanelType.smallFixedFar}
            isLightDismiss={true}
            isOpen={props.isOpen}
            onDismiss={props.dismissPanel}
            onRenderFooter={undefined}
            isFooterAtBottom={true}
            onRenderFooterContent={onRenderFooterContent}
            
        >
            {FormValidationStatusMessage}
            {SaveErrorStatusMessage}
            {FormField}
        </Panel>
    );
}

export default connect(
    ComponentFunctions.mapStateToProps,
    (ComponentFunctions.mapDispatchToProps)
)(PractitionerProtocolConceptForm as any);

