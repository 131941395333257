import React from 'react';
import { connect } from 'react-redux';
import { Badge, Button, Label, Subtitle1, Subtitle2 } from '@fluentui/react-components';


// functions 
import * as ComponentFunctions from './ConsumptionToSettle.Functions';

// styles
import './ConsumptionToSettle.Styles.css';

// components
import SettlementFormComponent from '../../Settlements/SettlementForm/Settlement.Form.Component';
import { useBoolean } from '@fluentui/react-hooks';
import { Separator } from '@fluentui/react';



const ConsumptionToSettleComponent = (props: ComponentFunctions.Props) => {

    const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false);

    const handleClosePanel = () => {
        dismissPanel();
    };

    const Forms = (
        <SettlementFormComponent
            isOpen={isOpen}
            dismissPanel={handleClosePanel}
            openPanel={openPanel}
            ContractId={undefined}
            PractitionerId={undefined}
            ProtocolId={props.ProtocolId}
            MedicalAgreementId={undefined}
            MedicalInsuranceId={undefined}
        />
    )

    return (
        <div className='ConsumptionToSettleContainer'>
            <div>
                {props.TotalByCurrency?.map((total, index) =>
                    <div>
                        <Label>Total</Label>
                        <Subtitle1>{total.Total?.toLocaleString('es-AR', { style: 'currency', currency: total.Currency.toUpperCase() }) ?? '0.00'}</Subtitle1>
                        <Separator />
                        <Subtitle2>División de honorarios</Subtitle2>
                        <div>
                            <div>
                                <Label>Centro medico</Label>
                                <strong>{total.TotalOwner?.toLocaleString('es-AR', { style: 'currency', currency: total.Currency.toUpperCase() }) ?? '0.00'}</strong>
                            </div>
                            <div>
                                <Label>Investigador Principal</Label>
                                <strong>{total.TotalTeam?.toLocaleString('es-AR', { style: 'currency', currency: total.Currency.toUpperCase() }) ?? '0.00'}</strong>
                            </div>
                        </div>


                    </div>
                )}
            </div>
            <div>
                {props.Totals?.map((total, index) =>
                    <div>
                        <Label>{total.ConceptName}</Label>
                        <Subtitle1>{total.Total?.toLocaleString('es-AR', { style: 'currency', currency: total.CurrencyValue.toUpperCase() }) ?? '0.00'}</Subtitle1>

                        <Separator />
                        <Subtitle2>División de honorarios</Subtitle2>
                        <div>
                            <div>
                                <Label>Centro medico</Label>
                                <strong>{total.TotalOwner?.toLocaleString('es-AR', { style: 'currency', currency: total.CurrencyValue.toUpperCase() }) ?? '0.00'}</strong>
                            </div>
                            <div>
                                <Label>Investigador Principal</Label>
                                <strong>{total.TotalTeam?.toLocaleString('es-AR', { style: 'currency', currency: total.CurrencyValue.toUpperCase() }) ?? '0.00'}</strong>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <div>
                <Button onClick={openPanel}>Generar Liquidación&nbsp;<Badge>{props.Consumptions.length}</Badge></Button>
            </div>
            {Forms}
        </div>
    );
}

export default connect(
    ComponentFunctions.mapStateToProps,
    ComponentFunctions.mapDispatchToProps
)(ConsumptionToSettleComponent as any);