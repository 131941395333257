// stores 
import * as ProtocolConsumptionStore from '../../../../redux/middleware/ProtocolConsumptionMiddleware';


// state
import { AppState } from '../../../../redux/reducers';
import { ProtocolConsumption } from '../../../../models/Entities/Protocols/ProtocolConsumption';
import { Protocol } from '../../../../models/Entities/Protocols/Protocol';
import { ErrorType } from '../../../../models/HttpError';


//#region Props

interface IProps {
    ProtocolId: number;
    SelectedConsumptions: ProtocolConsumption[];
}

interface ConnectedProps {
    isChangingStatus: boolean;
    isAddingConsumption: boolean;
    AddedConsumption: boolean | undefined;
    FailOnAddingConsumption: boolean;
    ErrorOnAddingConsumption: ErrorType | null;
}

interface MedicalServicesDispatchProps {
    ChangeStatus: typeof ProtocolConsumptionStore.ActionCreators.ChangeStatus;
}

export type Props = IProps & ConnectedProps & MedicalServicesDispatchProps;

export const mapStateToProps = (state: AppState, ownProps: IProps) => {

    const CurrentProtocol: Protocol | null = state.MedicalProtocols?.list?.find((protocol: Protocol) => protocol.id === ownProps.ProtocolId) || null;
    
    return {
        isChangingStatus: CurrentProtocol?.consumptions?.some((consumption: ProtocolConsumption) => 
            consumption.ischangingStatus === true
        ) || false,
        isAddingConsumption: state.MedicalProtocols?.isCreatingConsumption || false,
        AddedConsumption: state.MedicalProtocols?.CreatingConsumptionSuccess || undefined,
        FailOnAddingConsumption: state.MedicalProtocols?.FailCreatingConsumption || false,
        ErrorOnAddingConsumption: state.MedicalProtocols?.ErrorCreatingConsumption || null
        
    }
};

export const mapDispatchToProps = {
    ...ProtocolConsumptionStore.ActionCreators
};


