import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Label, Text, Button, DrawerBody, DrawerFooter, DrawerHeader, DrawerHeaderTitle, OverlayDrawer, Title3, Subtitle2, Subtitle1, Avatar, Table, InfoLabel, MessageBar, MessageBarBody, MessageBarTitle, Title2, Checkbox, CheckboxProps, Spinner } from '@fluentui/react-components';
import { Dismiss24Regular } from '@fluentui/react-icons';
import moment from 'moment';

// component functions
import * as ComponentFunctions from './ConsumptionDetails.Functions';

// styles
import './ConsumptionDetails.Styles.css';
import { Check, Separator } from '@fluentui/react';
import ConsumptionListComponent from '../ConsumptionList/ConsumptionList.Component';
import ContractConceptSelectorComponent from '../../../commons/component/Inputs/ContractConceptSelector/ContractConceptSelector.Component';
import { ContractConcept } from '../../../models/Entities/Contracts/ContractConcept';
import PractitionerSelectorComponent from '../../../commons/component/Inputs/PractitionerSelector/PractitionerSelectorComponent';
import { Practitioner } from '../../../models/Entities/Practitioners/Practitioner';
import { Provider } from '../../../models/Entities/Provider/Provider';
import { AvailableMedicalService } from '../../../models/Entities/MedicalServices/AvailableMedicalService';

// components
import ConsumptionDetailMenuComponent from './ConsumptionDetailsMenu/ConsumptionDetailMenu.Component';


const PanelInfo = (props: ComponentFunctions.Props) => {

    const [changeConceptOptionActive, setChangeConceptOptionActive] = useState(false);
    const [changeProducerOptionActive, setChangeProducerOptionActive] = useState(false);
    const [changeProviderOptionActive, setChangeProviderOptionActive] = useState(false);
    const [changeMedicalServiceOptionActive, setChangeMedicalServiceOptionActive] = useState(false);
    const [ChangeAll, setChangeAll] = useState<boolean>(false);

    const [newConceptAssociation, setNewConceptAssociation] = useState<ContractConcept>();
    const [newProducer, setNewProducer] = useState<Practitioner>();
    const [newProvider, setNewProvider] = useState<Provider>();
    const [newMedicalService, setNewMedicalService] = useState<AvailableMedicalService>();

    useEffect(() => {
        if (props.Consumption?.protocolId) {
            props.GetAllProtocolContractAssociations(props.Consumption?.protocolId);
        }
    }, [props.Consumption?.protocolId]);

    useEffect(() => {
        setChangeConceptOptionActive(props.isChangingConcept);
        setChangeAll(false);
    }, [props.isChangingConcept]);

    useEffect(() => {
        setChangeProducerOptionActive(props.isChangingProducer);
    }, [props.isChangingProducer]);

    useEffect(() => {
        setChangeProviderOptionActive(props.isChangingProvider);
    }, [props.isChangingProvider]);

    useEffect(() => {
        setChangeMedicalServiceOptionActive(props.isChangingMedicalService);
    }, [props.isChangingMedicalService]);

    const HandleChangeConcept = () => {
        if (props.ProtocolId && props.Consumption?.id && newConceptAssociation?.id) {
            props.ChangeContractConcept(props.ProtocolId, props.Consumption?.id, newConceptAssociation.id, ChangeAll);
        }
    };

    const HandleChangeProducer = () => {
        if (props.ProtocolId && props.Consumption?.id && newProducer?.userId) {
            props.ChangeProducer(props.ProtocolId, props.Consumption?.id, newProducer.userId);
        }
    };

    const HandleChangeProvider = () => {
        if (props.ProtocolId && props.Consumption?.id && newProvider?.person.personIdentifier) {
            props.ChangeProvider(props.ProtocolId, props.Consumption?.id, newProvider?.person.personIdentifier);
        }
    };

    const HandleChangeMedicalService = () => {
        if (props.ProtocolId && props.Consumption?.id && newMedicalService?.id) {
            props.ChangeMedicalService(props.ProtocolId, props.Consumption?.id, newMedicalService?.id);
        }
    };

    return (
        <div>
            <h3>Detalle de consumo</h3>
            <ConsumptionDetailMenuComponent ProtocolId={props.ProtocolId} ProtocolConsumptionId={props.ProtocolConsumptionId} />
            <div className='consumptionDetails'>
                <Label>Paciente</Label>
                <Text>{props.Consumption?.patientNumberInProtocol}</Text>

                <Label>Fecha de realización</Label>
                <Text>{moment(props.Consumption?.serviceDueDate).format('DD/MM/YYYY hh:mm a')}</Text>

                <Label>Estado</Label>
                <Text>{ComponentFunctions.GetStatusDescription(props.Consumption!.status)}</Text>

                <Label>Realizado por</Label>
                <div className='RelatedConceptContainer'>
                    {!changeProducerOptionActive && <div className='consumptionPerson'>
                        {props.Consumption?.producer && <div>
                            <Avatar
                                aria-label={props.Consumption?.producer}
                                name={props.GetPersonByIdentifier(props.Consumption?.producer)?.fullName}
                                color="colorful"
                            />
                            <div>
                                <Text>{props.GetPersonByIdentifier(props.Consumption?.producer)?.fullName}</Text>
                                <Label>{props.GetPersonByIdentifier(props.Consumption?.producer)?.speciality}</Label>
                            </div>
                        </div>}
                        <div>
                            <Button onClick={() => setChangeProducerOptionActive(true)}>{props.Consumption?.producer ? 'Cambiar quien realizo este concepto' : 'informar quien realizo este concepto'}</Button>
                        </div>
                    </div>}
                    {changeProducerOptionActive &&
                        <div>
                            {props.failChangingProducer && <MessageBar key={props.Consumption?.id} intent={"error"}>
                                <MessageBarBody>
                                    <MessageBarTitle>Error</MessageBarTitle>
                                    {props.errorChangingProducer}
                                </MessageBarBody>
                            </MessageBar>}
                            <PractitionerSelectorComponent MultiselectEnable={false} SelectedPractitioners={
                                newProducer ? [newProducer.userId] : props.Consumption?.producer ? [props.Consumption?.producer] : []
                            } onChangeSelection={(selectedPractitioner: Practitioner[] | undefined) => selectedPractitioner?.length ? setNewProducer(selectedPractitioner[0]) : setNewProducer(undefined)} />
                            <div>
                                <Button disabled={props.isChangingProducer || newProducer === undefined} onClick={HandleChangeProducer}>
                                    {props.isChangingProducer ? <Spinner size="extra-tiny" label="" /> : 'Confirmar'}
                                </Button>
                                <Button disabled={props.isChangingProducer} onClick={() => setChangeProducerOptionActive(false)}>Cancelar</Button>
                            </div>
                        </div>
                    }
                </div>
            </div>

            <Separator />
            <div className='RelatedConceptContainer'>
                {!changeConceptOptionActive && <div>
                    <Label>Concepto asociado</Label>
                    <Text>{props.Consumption?.conceptDescription ?? 'No asociado a ningun concepto'}</Text>
                    <div>
                        <Button onClick={() => setChangeConceptOptionActive(true)}>{props.Consumption?.contractConceptId ? 'Cambiar concepto' : 'Asociar concepto'}</Button>
                    </div>
                </div>}
                {changeConceptOptionActive && props.ContractId && <div>
                    {props.failChangingConcept && <MessageBar key={props.Consumption?.id} intent={"error"}>
                        <MessageBarBody>
                            <MessageBarTitle>Asociacion de concepto</MessageBarTitle>
                            {props.errorChangingConcept}
                        </MessageBarBody>
                    </MessageBar>}
                    <ContractConceptSelectorComponent
                        ContractId={props.ContractId}
                        referralDate={props.Consumption?.serviceDueDate}
                        onSelectedContractConcept={(contract: ContractConcept[]) => setNewConceptAssociation(contract[0])}
                        ContractConceptsSelected={props.Consumption?.contractConceptId ? [props.Consumption?.conceptDescription] : undefined}
                        MultiselectEnable={false}
                    />
                    <Checkbox
                        checked={ChangeAll}
                        onChange={(ev, data) => data.checked === true ? setChangeAll(data.checked) : setChangeAll(false)}
                        label="Aplicar a todos los consumos de este tipo"
                    />
                    <div>
                        <Button disabled={props.isChangingConcept || newConceptAssociation === undefined} onClick={HandleChangeConcept}>
                            {props.isChangingConcept ? <Spinner size="extra-tiny" label="" /> : 'Confirmar'}
                        </Button>
                        <Button disabled={props.isChangingConcept} onClick={() => setChangeConceptOptionActive(false)}>Cancelar</Button>
                    </div>
                </div>}
            </div>
            <Separator />
            <div className='consumptionGrandTotal'>
                <div>
                    <Label>TOTAL A LIQUIDAR</Label>
                    <Text>{props.Consumption?.totalAmount?.toLocaleString('es-AR', { style: 'currency', currency: props.Consumption?.currencyCode?.toUpperCase() }) ?? '0.00'}</Text>
                </div>
                {props.Consumption?.haveFeeDivision && <>
                    <Separator />
                    <div>
                        <div>
                            <Label>División de honorarios</Label>
                        </div>
                        <div>
                            <Text><small>Centro </small> {(props.Consumption?.conceptValues?.reduce(
                                (acc, concept) => acc + concept.ownerAmount, 0
                            ) + props.Consumption?.ownerAmountWithholdingTotal).toLocaleString('es-AR', { style: 'currency', currency: props.Consumption?.currencyCode?.toUpperCase() }) ?? '0.00'}
                            </Text>
                            <Text><small>Equipo médico </small> {(props.Consumption?.conceptValues?.reduce(
                                (acc, concept) => acc + concept.teamAmount, 0
                            ) + props.Consumption?.teamAmountWithholdingTotal).toLocaleString('es-AR', { style: 'currency', currency: props.Consumption?.currencyCode?.toUpperCase() }) ?? '0.00'}</Text>
                        </div>
                    </div>
                    <div>
                        <MessageBar key={props.Consumption?.id} intent={'info'} style={{ width: '100%' }}>
                            <MessageBarBody>
                                <MessageBarTitle>WithHolding</MessageBarTitle>
                                Ya se ha descontando el monto ({props.Consumption?.withHolingPercentage + '%'}) &nbsp;{props.Consumption?.withHolingAmount.toLocaleString('es-AR', { style: 'currency', currency: props.Consumption?.currencyCode?.toUpperCase() }) ?? '0.00'} definido por contrato
                            </MessageBarBody>
                        </MessageBar>
                    </div>
                </>}

            </div>
            <table className='values'>
                <thead>
                    <tr>
                        <th>Concepto</th>
                        <th>Valor unitario</th>
                        <th>Coef.</th>
                        <th>Cantidad a liquidar</th>
                        <th>Total por Cantidad</th>
                        <th>Porcentaje</th>
                        <th colSpan={2}>División de honorarios</th>
                        <th>Importe</th>
                    </tr>
                    <tr>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th>Centro</th>
                        <th>Equipo médico</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>

                    <tr>
                        <th>

                            {props.Consumption?.haveOverheadIncluded && <InfoLabel
                                info={
                                    <>
                                        Se le descuenta overhead incluido ({props.Consumption?.overheadPercentage + '%'})&nbsp;{props.Consumption?.overheadAmount?.toLocaleString('es-AR', { style: 'currency', currency: props.Consumption?.currencyCode?.toUpperCase() }) ?? '0.00'}
                                    </>
                                }
                                {...props}
                            >
                                Valor unitarios del concepto
                            </InfoLabel>}
                            {!props.Consumption?.haveOverheadIncluded && 'Valor unitarios del concepto'}
                        </th>
                        <td>{props.Consumption?.unitAmount?.toLocaleString('es-AR', { style: 'currency', currency: props.Consumption?.currencyCode?.toUpperCase() }) ?? '0.00'}<br />
                            <small style={{ color: 'blue' }}>overhead incluido:{props.Consumption?.overheadAmount?.toLocaleString('es-AR', { style: 'currency', currency: props.Consumption?.currencyCode?.toUpperCase() }) ?? '0.00'}</small>
                        </td>
                        <td>{props.Consumption?.coefficientApplied}</td>
                        <td>{props.Consumption?.quantity}</td>
                        <td>{props.Consumption?.amount?.toLocaleString('es-AR', { style: 'currency', currency: props.Consumption?.currencyCode?.toUpperCase() }) ?? '0.00'}</td>
                        <td></td>
                        <td>({props.Consumption?.feeDivisionPercentage === 0 ? '100%' : props.Consumption?.feeDivisionPercentage + '%'})&nbsp;{props.Consumption?.ownerAmount?.toLocaleString('es-AR', { style: 'currency', currency: props.Consumption?.currencyCode?.toUpperCase() }) ?? '0.00'} </td>
                        <td>({props.Consumption?.teamDivisionPercentage + '%'})&nbsp;{props.Consumption?.teamAmount?.toLocaleString('es-AR', { style: 'currency', currency: props.Consumption?.currencyCode?.toUpperCase() }) ?? '0.00'}</td>
                        <td><strong>{props.Consumption?.amount?.toLocaleString('es-AR', { style: 'currency', currency: props.Consumption?.currencyCode?.toUpperCase() }) ?? '0.00'}</strong></td>
                    </tr>
                    <tr>
                        <th>WithHolding</th>
                        <td>{props.Consumption?.withHolingAmount.toLocaleString('es-AR', { style: 'currency', currency: props.Consumption?.currencyCode?.toUpperCase() }) ?? '0.00'}</td>
                        <td>1</td>
                        <td></td>
                        <td></td>
                        <td>{props.Consumption?.withHolingPercentage + '%'}</td>
                        <td>({props.Consumption?.feeDivisionPercentage + '%'})&nbsp;{props.Consumption?.ownerAmountWithholding?.toLocaleString('es-AR', { style: 'currency', currency: props.Consumption?.currencyCode?.toUpperCase() }) ?? '0.00'} </td>
                        <td>({props.Consumption?.teamDivisionPercentage + '%'})&nbsp;{props.Consumption?.teamAmountWithholding?.toLocaleString('es-AR', { style: 'currency', currency: props.Consumption?.currencyCode?.toUpperCase() }) ?? '0.00'}</td>
                        <td><strong>{props.Consumption?.withHolingAmount.toLocaleString('es-AR', { style: 'currency', currency: props.Consumption?.currencyCode?.toUpperCase() }) ?? '0.00'}</strong></td>
                    </tr>
                    {(props.Consumption?.conceptValues?.length ?? 0) > 0 && <><tr>
                        <td colSpan={8}>Montos Adicionales</td>
                    </tr>
                        {props.Consumption?.conceptValues?.map((concept) => {
                            return (
                                <tr key={concept.id}>
                                    <th>{concept.contractConceptName}</th>
                                    <td>({concept.percentValue + '%'})&nbsp;{concept.value?.toLocaleString('es-AR', { style: 'currency', currency: props.Consumption?.currencyCode?.toUpperCase() }) ?? '0.00'}</td>
                                    <td>1</td>
                                    <td>1</td>
                                    <td>{concept.value?.toLocaleString('es-AR', { style: 'currency', currency: props.Consumption?.currencyCode?.toUpperCase() }) ?? '0.00'}</td>
                                    <td>{concept.percentValue + '%'}</td>
                                    <td>({(concept.feeDivisionPercentage === 0 ? 100 : concept.feeDivisionPercentage) + '%'})&nbsp;{concept.ownerAmount?.toLocaleString('es-AR', { style: 'currency', currency: props.Consumption?.currencyCode?.toUpperCase() }) ?? '0.00'}</td>
                                    <td>({(concept.teamDivisionPercentage) + '%'})&nbsp;{concept.teamAmount?.toLocaleString('es-AR', { style: 'currency', currency: props.Consumption?.currencyCode?.toUpperCase() }) ?? '0.00'}</td>
                                    <td><strong>{concept.value?.toLocaleString('es-AR', { style: 'currency', currency: props.Consumption?.currencyCode?.toUpperCase() }) ?? '0.00'}</strong></td>
                                </tr>
                            )
                        })}
                    </>}
                    <td colSpan={8}>Costos</td>
                    <tr>
                        <th>Costo calculado</th>
                        <td>{props.Consumption?.costAmount.toLocaleString('es-AR', { style: 'currency', currency: props.Consumption?.costCurrencyCode?.toUpperCase() ?? 'ARS' }) ?? '0.00'}</td>
                        <td>1</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td><strong>{props.Consumption?.costAmount.toLocaleString('es-AR', { style: 'currency', currency: props.Consumption?.costCurrencyCode?.toUpperCase() ?? 'ARS' }) ?? '0.00'}</strong></td>
                    </tr>
                </tbody>
            </table>
            <div>
                <Separator />
                <Title3>Conceptos relacionados</Title3>
                <ConsumptionListComponent ProtocolId={props.ProtocolId} ParentId={props.Consumption?.parentInstanceId} ProducerId={undefined} ProviderId={undefined} />
            </div>
        </div>
    )
};


const ConsumptionDetailsCompoment = (props: ComponentFunctions.Props) => {

    const HandleCancelForm = () => {
        props.onClose();
    };

    const Footer = (
        <DrawerFooter>
            <Button onClick={HandleCancelForm} >Cerrar</Button>
        </DrawerFooter>
    );

    return (
        <div>
            <OverlayDrawer
                position={'end'}
                open={props.isOpen}
                size='large'
            >
                <DrawerHeader>
                    <DrawerHeaderTitle
                        action={
                            <Button
                                appearance="subtle"
                                aria-label="Close"
                                icon={<Dismiss24Regular />}
                                onClick={() => HandleCancelForm()}
                            />
                        }
                    >
                        <div className='consumptionHeader'>
                            <Subtitle1 style={{ textTransform: 'capitalize' }}>{props.Consumption?.serviceName}</Subtitle1>
                            <Text>{props.Consumption?.stageName}</Text>
                        </div>


                    </DrawerHeaderTitle>
                </DrawerHeader>
                <DrawerBody className='ScrollShadow'>
                    {props.Consumption && <PanelInfo {...props} />}
                </DrawerBody>
                {Footer}
            </OverlayDrawer>
        </div>
    );

}

export default connect(
    ComponentFunctions.mapStateToProps,
    ComponentFunctions.mapDispatchToProps
)(ConsumptionDetailsCompoment as any);

