
//functions
import * as Functions from '../functions/common';

// models
import { ProtocolConsumptionSyncProcess } from '../models/Entities/Protocols/ProtocolConsumptionSyncProcess';
import { IResult } from '../models/HttpResult';

var BaseURL = process.env.REACT_APP_BILLER_API_GATEWAY + 'protocols/protocols/';

// get all sync process by protocol id
export const GetProtocolConsumptionSyncProcess = async (
    protocolId: number,
    token: string
): Promise<ProtocolConsumptionSyncProcess[]> => {
    let RequestURL: string = BaseURL + protocolId + '/consumptions/sync';
    try {
        const Response: ProtocolConsumptionSyncProcess[] = await fetch(RequestURL, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            },
            method: 'GET'
        })
        .then((response) => {
            if (!response.ok) {
            throw response;
            } else {
            return response.json() as Promise<IResult<ProtocolConsumptionSyncProcess[]>>;
            }
        })
        .then((data: IResult<ProtocolConsumptionSyncProcess[]>) => {
            return data.data;
        })
        .catch((error: any) =>
            error.text().then((body: any) => {
            throw Functions.HttpErrorHandler(body, error);
            })
        );

        return Response;
    } catch (error: any) {
        throw error;
    }
};

// get protocol consumption sync process by id

export const GetProtocolConsumptionSyncProcessById = async (
    token: string,
    id: string
): Promise<ProtocolConsumptionSyncProcess> => {
    let RequestURL: string = BaseURL + '/consumptions/sync/' + id;
    try {
        const Response: ProtocolConsumptionSyncProcess = await fetch(RequestURL, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
        },
        method: 'GET'
        })
        .then((response) => {
            if (!response.ok) {
            throw response;
            } else {
            return response.json() as Promise<IResult<ProtocolConsumptionSyncProcess>>;
            }
        })
        .then((data: IResult<ProtocolConsumptionSyncProcess>) => {
            return data.data;
        })
        .catch((error: any) =>
            error.text().then((body: any) => {
            throw Functions.HttpErrorHandler(body, error);
            })
        );

        return Response;
    } catch (error: any) {
        throw error;
    }
};

// get last protocol consumption sync process

export const GetLastProtocolConsumptionSyncProcess = async (
    protocolId: number,
    token: string
): Promise<ProtocolConsumptionSyncProcess> => {
    let RequestURL: string = BaseURL + protocolId + '/consumptions/sync/last';
    try {
        const Response: ProtocolConsumptionSyncProcess = await fetch(RequestURL, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
        },
        method: 'GET'
        })
        .then((response) => {
            if (!response.ok) {
            throw response;
            } else {
            return response.json() as Promise<IResult<ProtocolConsumptionSyncProcess>>;
            }
        })
        .then((data: IResult<ProtocolConsumptionSyncProcess>) => {
            return data.data;
        })
        .catch((error: any) =>
            error.text().then((body: any) => {
            throw Functions.HttpErrorHandler(body, error);
            })
        );

        return Response;
    } catch (error: any) {
        throw error;
    }
};

// Add protocol consumption sync process

export const AddProtocolConsumptionSyncProcess = async (
    protocolId: number,
    token: string
): Promise<ProtocolConsumptionSyncProcess> => {
    let RequestURL: string = BaseURL + 'consumptions/sync';
    try {
        const Response: ProtocolConsumptionSyncProcess = await fetch(RequestURL, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
        },
        method: 'POST',
        body: JSON.stringify({protocolId: protocolId})  
        })
        .then((response) => {
            if (!response.ok) {
            throw response;
            } else {
            return response.json() as Promise<IResult<ProtocolConsumptionSyncProcess>>;
            }
        })
        .then((data: IResult<ProtocolConsumptionSyncProcess>) => {
            return data.data;
        })
        .catch((error: any) =>
            error.text().then((body: any) => {
            throw Functions.HttpErrorHandler(body, error);
            })
        );

        return Response;
    } catch (error: any) {
        throw error;
    }
};

// update protocol consumption sync process

export const UpdateProtocolConsumptionSyncProcess = async (
    token: string,
    SyncProcess: ProtocolConsumptionSyncProcess
): Promise<ProtocolConsumptionSyncProcess> => {
    let RequestURL: string = BaseURL + '/consumptions/sync/';
    try {
        const Response: ProtocolConsumptionSyncProcess = await fetch(RequestURL, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
        },
        body: JSON.stringify(SyncProcess),
        method: 'PUT'
        })
        .then((response) => {
            if (!response.ok) {
            throw response;
            } else {
            return response.json() as Promise<IResult<ProtocolConsumptionSyncProcess>>;
            }
        })
        .then((data: IResult<ProtocolConsumptionSyncProcess>) => {
            return data.data;
        })
        .catch((error: any) =>
            error.text().then((body: any) => {
            throw Functions.HttpErrorHandler(body, error);
            })
        );

        return Response;
    } catch (error: any) {
        throw error;
    }
};

// delete protocol consumption sync process

export const DeleteProtocolConsumptionSyncProcess = async (
    token: string,
    id: string
): Promise<ProtocolConsumptionSyncProcess> => {
    let RequestURL: string = BaseURL + '/consumptions/sync/' + id;
    try {
        const Response: ProtocolConsumptionSyncProcess = await fetch(RequestURL, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
        },
        method: 'DELETE'
        })
        .then((response) => {
            if (!response.ok) {
            throw response;
            } else {
            return response.json() as Promise<IResult<ProtocolConsumptionSyncProcess>>;
            }
        })
        .then((data: IResult<ProtocolConsumptionSyncProcess>) => {
            return data.data;
        })
        .catch((error: any) =>
            error.text().then((body: any) => {
            throw Functions.HttpErrorHandler(body, error);
            })
        );

        return Response;
    } catch (error: any) {
        throw error;
    }
};

