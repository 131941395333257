import React, { ChangeEvent, useCallback } from 'react';
import {
    DefaultButton,
    FontWeights,
    getTheme,
    IIconProps,
    IStackProps,
    mergeStyleSets,
    MessageBar,
    MessageBarType,
    Panel,
    PanelType,
    PrimaryButton,
    Separator,
    TextField
} from '@fluentui/react';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ErrorMessageComponent } from '../../../Error/ErrorMessageComponent';

// styles
import './StageForm.Styles.css';

// component functions
import * as ComponentFunctions from './StageForm.Functions';
import { ContractStage } from '../../../../models/Entities/Contracts/ContractStage';
import { NewContractStage } from '../../../../models/Entities/Contracts/NewContractStage';
import CycleSelectorComponent from '../../../../commons/component/Inputs/CyclesSelector/CycleSelectorComponent';
import { StageCycle } from '../../../../models/Entities/Contracts/StageCycle';

////i18Next
import { useTranslation, Trans } from 'react-i18next';
import { Field, Input, InputOnChangeData, InputProps, Spinner, Switch, SwitchOnChangeData, SwitchProps, Text, Textarea, TextareaOnChangeData, TextareaProps } from '@fluentui/react-components';
import { Info24Filled, InfoFilled, SparkleFilled } from '@fluentui/react-icons';


interface FormValidation {
    success: boolean;
    errors: string[];
}

export const ContractStageFormComponent = (props: ComponentFunctions.Props) => {

    const [submittedForm, setSubmittedForm] = useState<boolean>(false);
    const [FormValidationStatus, setFormValidationStatus] = useState<
        FormValidation | undefined
    >();

    const [stageName, setStageName] = useState<string>();
    const [comments, setComments] = useState<string>();
    const [isOptional, setIsOptional] = useState<boolean>(false);
    const [isOnSiteMandatory, setIsOnSiteMandatory] = useState<boolean>(false);
    const [cycleId, setCycleId] = useState<number | undefined | null>();
    const [failSelectionEnabled, setFailSelectionEnabled] = useState<boolean>(false);
    const [stageOrder, setStageOrder] = useState<number>();
    const [emrProtocolVisitIdRelated, setEmrProtocolVisitIdRelated] = useState<number>();
    const [markAsSettleable, setMarkAsSettleable] = useState<boolean>(true);
    const [markAsPayable, setMarkAsPayable] = useState<boolean>(true);

    //useTranslation()
    const [t] = useTranslation();

    //load necesary data
    useEffect(() => {
        props.GetContractById(props.ContractId);
        if (props.ContractStageId) props.GetContractStageById(props.ContractStageId);
    }, []);

    // save control
    useEffect(() => {
        if (submittedForm && FormValidationStatus?.success && props.isSavedSuccessfully) {
            ResetForm();
            props.dismissPanel();
        }
    }, [submittedForm, props.isSavedSuccessfully]);


    useEffect(() => {
        if (props.Stage) {
            setStageName(props.Stage.stageName);
            setComments(props.Stage.comments);
            setIsOptional(props.Stage.isOptional);
            setIsOnSiteMandatory(props.Stage.isOnSiteMandatory);
            setCycleId(props.Stage.cycleId);
            setFailSelectionEnabled(props.Stage.failSelectionEnabled);
            setStageOrder(props.Stage.stageOrder);
            setEmrProtocolVisitIdRelated(props.Stage.emrProtocolVisitIdRelated);
            setMarkAsPayable(props.Stage.markAsPayable);
            setMarkAsSettleable(props.Stage.markAsSettleable);
        }
    }, [props.ContractStageId]);

    const ResetForm = () => {
        setSubmittedForm(false);
        setStageName('');
        setComments('');
        setIsOptional(false);
        setIsOnSiteMandatory(false);
        setCycleId(undefined);
        setFailSelectionEnabled(false);
        setStageOrder(props.TotalStages);
        setEmrProtocolVisitIdRelated(undefined);
        setMarkAsPayable(true);
        setMarkAsSettleable(true);
    };

    // Events
    const onChangeStageNameTextFieldValue: InputProps["onChange"] = (
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: InputOnChangeData
    ) => {
        newValue ? setStageName(newValue.value) : setStageName('');
    };

    const onChangeOrderTextFieldValue: InputProps["onChange"] = (
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: InputOnChangeData
    ) => {
        newValue ? setStageOrder(parseInt(newValue.value)) : setStageOrder(0);
    };

    const onChangeCommentsTextFieldValue: TextareaProps["onChange"] = (
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: TextareaOnChangeData
    ) => {
        newValue ? setComments(newValue.value) : setComments('');
    };

    const onChangeIsOptionalToggleValue: SwitchProps["onChange"] = (ev: ChangeEvent<HTMLInputElement>, checked: SwitchOnChangeData) => {
        checked.checked ? setIsOptional(true) : setIsOptional(false);
    };

    const onChangeIsOnSiteMandatoryToggleValue: SwitchProps["onChange"] = (
        ev: ChangeEvent<HTMLInputElement>,
        checked: SwitchOnChangeData
    ) => {
        checked.checked ? setIsOnSiteMandatory(true) : setIsOnSiteMandatory(false);
    };

    const onChangeCycleIdTextFieldValue = (cycles: StageCycle[]) => {
        cycles.length > 0 ? setCycleId(cycles[0]?.id) : setCycleId(undefined);
    };

    const onChangeFailSelectionEnabledToggleValue: SwitchProps["onChange"] = (
        ev: ChangeEvent<HTMLInputElement>,
        checked: SwitchOnChangeData
    ) => {
        checked.checked ? setFailSelectionEnabled(true) : setFailSelectionEnabled(false);
    }

    const onChangeEmrProtocolVisitIdRelatedTextFieldValue = (
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string
    ) => {
        newValue ? setEmrProtocolVisitIdRelated(parseInt(newValue)) : setEmrProtocolVisitIdRelated(0);
    };

    const onChangeMarkAsSettleableToggleValue: SwitchProps["onChange"] = (
        ev: ChangeEvent<HTMLInputElement>,
        checked: SwitchOnChangeData
    ) => {
        checked.checked ? setMarkAsSettleable(true) : setMarkAsSettleable(false);
    };

    const onChangeMarkAsPayableToggleValue: SwitchProps["onChange"] = (
        ev: ChangeEvent<HTMLInputElement>,
        checked: SwitchOnChangeData
    ) => {
        checked.checked ? setMarkAsPayable(true) : setMarkAsPayable(false);
    };

    const HandleSaveForm = () => {
        setSubmittedForm(true);
        if (ValidateForm()) {
            let CurrentValues: ContractStage | NewContractStage;
            if (props.Stage) {
                CurrentValues = {
                    id: props.Stage.id,
                    contractId: props.Stage.contractId,
                    contractAddendumId: props.ContractAddendumId,
                    stageName: stageName!,
                    comments: comments ?? '',
                    isOptional: isOptional,
                    isOnSiteMandatory: isOnSiteMandatory,
                    failSelectionEnabled: failSelectionEnabled,
                    cycleId: cycleId!,
                    stageOrder: stageOrder!,
                    emrProtocolVisitIdRelated: emrProtocolVisitIdRelated,
                    internalCrossVersionId: props.Stage.internalCrossVersionId,
                    markAsSettleable: markAsSettleable,
                    markAsPayable: markAsPayable
                };
                props.UpdateContractStage(CurrentValues as ContractStage);

            } else {
                CurrentValues = {
                    contractId: props.ContractId,
                    contractAddendumId: props.ContractAddendumId,
                    stageName: stageName!,
                    comments: comments ?? '',
                    isOptional: isOptional,
                    isOnSiteMandatory: isOnSiteMandatory,
                    failSelectionEnabled: failSelectionEnabled,
                    cycleId: cycleId!,
                    stageOrder: stageOrder!,
                    emrProtocolVisitIdRelated: emrProtocolVisitIdRelated,
                    markAsSettleable: markAsSettleable,
                    markAsPayable: markAsPayable
                };
                props.AddContractStage(CurrentValues);
            }
        }
    };

    const HandleCancelForm = () => {
        ResetForm();
        props.dismissPanel();
    };

    // footer
    const onRenderFooterContent = () => {
        return (
            <div className={contentStyles.footer}>
                <DefaultButton disabled={props.isSaving} onClick={HandleCancelForm} className="OtherOption">
                    <Trans i18nKey={'stageForm.footer.cancelButton'} />
                </DefaultButton>
                <PrimaryButton
                    onClick={HandleSaveForm}
                    styles={buttonStyles}
                    className="ConfirmAction"
                    disabled={props.isSaving}
                >
                    {props.isSaving && <><Spinner size="extra-tiny" />&nbsp;</>}<Trans i18nKey={'stageForm.footer.saveButton'} />
                </PrimaryButton>
            </div>
        );
    };

    const formFields = (
        <div {...columnProps}>
            <Field required label={t('stageForm.props.stageName')}
                validationState={!submittedForm ? "none" : !stageName ? "error" : "success"}
                validationMessage={submittedForm && !stageName ? t('stageForm.props.requiredName') : ''}
            >
                <Input value={stageName} onChange={onChangeStageNameTextFieldValue} />
            </Field>
            <CycleSelectorComponent
                ContractId={props.ContractId}
                ContractAddendumId={props.ContractAddendumId}
                onSelectedCycles={onChangeCycleIdTextFieldValue}
                CyclesSelected={props.Stage?.cycleId ? [props.Stage.cycleId] : []}
                MultiselectEnable={false}
            />
            <Field required label={t('stageForm.props.order')}>
                <Input
                    type="number"
                    defaultValue={stageOrder?.toString() ?? (props.TotalStages).toString()}
                    onChange={onChangeOrderTextFieldValue}
                />
            </Field>
            <Field className='ToggleInline'
                validationState="none"
                validationMessageIcon={<InfoFilled />}
                validationMessage="Indica si la visita es opcional u obligatoria">
                
                    <Switch
                        label={t('stageForm.props.optional')}
                        labelPosition="before"
                        checked={isOptional}
                        onChange={onChangeIsOptionalToggleValue}
                    />
                
            </Field>
            <Field className='ToggleInline'
                validationState="none"
                validationMessageIcon={<InfoFilled />}
                validationMessage="Indica si la visita se realiza de forma presencial o remota">
                
                    <Switch
                        label={t('stageForm.props.inPerson')}
                        labelPosition="before"
                        checked={isOnSiteMandatory}
                        onChange={onChangeIsOnSiteMandatoryToggleValue}
                    />
                
            </Field>
            <Field className='ToggleInline'
                validationState="none"
                validationMessageIcon={<InfoFilled />}
                validationMessage="Indica si en esta visita es posible que ocurra un fallo de selección">
                
                    <Switch
                        label={t('stageForm.props.enableSelectionFailure')}
                        labelPosition="before"
                        checked={failSelectionEnabled}
                        onChange={onChangeFailSelectionEnabledToggleValue}
                    />
                
            </Field>
            <Field className='ToggleInline'
                validationState="none"
                validationMessageIcon={<InfoFilled />}
                validationMessage="Indica si esta visita es tenida en cuenta para el pago al equipo de investigación">
                
                    <Switch
                        label={t('stageForm.props.isPayable')}
                        labelPosition="before"
                        checked={markAsPayable}
                        onChange={onChangeMarkAsPayableToggleValue}
                    />
                
            </Field>
            <Separator />
            <Field label={t('stageForm.props.comments')}>
                <Textarea value={comments} onChange={onChangeCommentsTextFieldValue} />
            </Field>
        </div>

    );

    // form validation

    const ValidateForm = (): boolean => {
        let validationResult: boolean = true;

        let FormValidation: FormValidation = { success: true, errors: [] };
        setFormValidationStatus(FormValidation);

        // validate Visit name
        if (!stageName) {
            FormValidation.errors.push(t('stageForm.props.requiredName'));
            validationResult = false;
        }

        // validate Order
        if (!stageOrder) {
            FormValidation.errors.push(t('stageForm.props.requiredOrder'));
            validationResult = false;
        }

        FormValidation.success = validationResult;
        setFormValidationStatus(FormValidation);

        return validationResult;
    };

    return (
        <Panel
            isOpen={props.isOpen}
            type={PanelType.smallFixedFar}
            headerText={t('stageForm.panel.visitInformation')}
            onDismiss={props.dismissPanel}
            closeButtonAriaLabel="Close"
            onRenderFooter={onRenderFooterContent}
            isFooterAtBottom={true}
        >
            {submittedForm && !FormValidationStatus?.success && (
                <MessageBar messageBarType={MessageBarType.error} isMultiline={true}>
                    {t('stageForm.panel.reviewInformation')}
                    <ul>
                        {FormValidationStatus?.errors.map(
                            (Error: string, index: number) => (
                                <li key={index}>{Error}</li>
                            )
                        )}
                    </ul>
                </MessageBar>
            )}
            <div className={contentStyles.body}>
                {props.failOnSaving &&
                    props.error && (
                        <ErrorMessageComponent Error={props.error} />
                    )}
                {formFields}
            </div>
        </Panel>
    );
};

export default connect(
    ComponentFunctions.mapStateToProps,
    ComponentFunctions.mapDispatchToProps
)(ContractStageFormComponent as any);

const buttonStyles = { root: { marginRight: 8, class: 'ConfirmAction' } };

const columnProps: Partial<IStackProps> = {
    tokens: { childrenGap: 15 }
    // styles: { root: { width: 150 } }
};

const theme = getTheme();
const contentStyles = mergeStyleSets({
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
        borderRadius: 10
    },
    header: [
        theme.fonts.mediumPlus,
        {
            flex: '1 1 auto',
            color: theme.palette.neutralPrimary,
            display: 'flex',
            alignItems: 'center',
            fontWeight: FontWeights.semibold,
            padding: '0px 12px 12px 12px',
            backgroundColor: 'lightgray',
            borderRadius: '10px 10px 0 0'
        }
    ],
    body: {
        flex: '4 4 auto',
        overflowY: 'auto',
        selectors: {
            'p:first-child': { marginTop: 0 },
            'p:last-child': { marginBottom: 0 }
        },
        padding: '0 1em 0 0',
        maxHeight: '85vh'
    },
    footer: {
        //flex: '4 4 auto',
        padding: '0 24px 24px 24px'
    }
});

const cancelIcon: IIconProps = { iconName: 'Cancel' };

const iconButtonStyles = {
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px'
    },
    rootHovered: {
        color: theme.palette.neutralDark
    }
};
