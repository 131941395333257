
import React, { useState } from 'react';
// Component Funtions
import { Badge, Button, Spinner } from '@fluentui/react-components';
import * as ComponnentFunctions from './ConsumptionList.Menu.Functions';

// Styles
import './ConsumptionList.Menu.Styles.css';
import { connect } from 'react-redux';
import { Add24Filled, ClipboardArrowRight24Regular, ClipboardCheckmark24Regular, ClipboardClock24Regular, Payment24Regular, PulseSquare24Regular } from '@fluentui/react-icons';
import { MdOutlineHideImage } from 'react-icons/md';
import ConsumptionFormComponent from '../../ConsumptionForm/Consumption.Form.Component';



/*
    status
    -----------------------------
    NotSettled = 0,
    Pending = 1,
    Payee = 2,
    Settled = 3,
    Rejected = 4,
    Ignored = 5,
    ToSettle = 6
 */


const ConsumptionListMenuComponent = (props: ComponnentFunctions.Props) => {

    // create or edit form
    const [isConsumptionFormOpen, setIsConsumptionFormOpen] = useState(false);

    const onConsumptionFormDismiss = () => {
        setIsConsumptionFormOpen(false);
    }

    const onCLickMarkAsPaid = () => {
        props.SelectedConsumptions.forEach(element => {
            props.ChangeStatus(props.ProtocolId, element.id, 2, false);
        });
    };

    const onCLickMarkToSettled = () => {
        props.SelectedConsumptions.forEach(element => {
            props.ChangeStatus(props.ProtocolId, element.id, 6, false);
        });
    };

    const onClickMarkAsRejected = () => {
        props.SelectedConsumptions.forEach(element => {
            props.ChangeStatus(props.ProtocolId, element.id, 4, false);
        });
    }

    const onClickMarkAsPending = () => {
        props.SelectedConsumptions.forEach(element => {
            props.ChangeStatus(props.ProtocolId, element.id, 0, false);
        });
    }

    const onClickMarkAsIgnored = () => {
        props.SelectedConsumptions.forEach(element => {
            props.ChangeStatus(props.ProtocolId, element.id, 5, false);
        });
    }

    const Forms = (
            <>
                {props.ProtocolId && <ConsumptionFormComponent
                    ProtocolId={props.ProtocolId}
                    isOpen={isConsumptionFormOpen}
                    onClose={onConsumptionFormDismiss}
                />}
            </>
        );

    return (
        <div className='menuContainer'>
            <Button appearance='primary' icon={props.isAddingConsumption ? <Spinner size="extra-tiny" /> : <Add24Filled />} onClick={() => setIsConsumptionFormOpen(true)}>Agregar Concepto</Button>
            <Button disabled={props.SelectedConsumptions?.length === 0} appearance='secondary' icon={props.isChangingStatus ? <Spinner size="extra-tiny" /> : <Payment24Regular />} onClick={onCLickMarkAsPaid}>Marcar como cobrado&nbsp;{props.SelectedConsumptions?.length > 0 && <Badge size='small' appearance="filled">{props.SelectedConsumptions?.length}</Badge>}</Button>
            <Button disabled={props.SelectedConsumptions?.length === 0} appearance='secondary' icon={props.isChangingStatus ? <Spinner size="extra-tiny" /> : <ClipboardCheckmark24Regular />} onClick={onCLickMarkToSettled}>Marcar para liquidar&nbsp;{props.SelectedConsumptions?.length > 0 && <Badge size='small' appearance="filled">{props.SelectedConsumptions?.length}</Badge>}</Button>
            <Button disabled={props.SelectedConsumptions?.length === 0} appearance='secondary' icon={props.isChangingStatus ? <Spinner size="extra-tiny" /> : <ClipboardArrowRight24Regular />} onClick={onClickMarkAsRejected}>Marcar como rechazados&nbsp;{props.SelectedConsumptions?.length > 0 && <Badge size='small' appearance="filled">{props.SelectedConsumptions?.length}</Badge>}</Button>
            <Button disabled={props.SelectedConsumptions?.length === 0} appearance='secondary' icon={props.isChangingStatus ? <Spinner size="extra-tiny" /> : <ClipboardClock24Regular />} onClick={onClickMarkAsPending}>Marcar como pendiente&nbsp;{props.SelectedConsumptions?.length > 0 && <Badge size='small' appearance="filled">{props.SelectedConsumptions?.length}</Badge>}</Button>
            <Button disabled={props.SelectedConsumptions?.length === 0} appearance='secondary' icon={props.isChangingStatus ? <Spinner size="extra-tiny" /> : <MdOutlineHideImage />} onClick={onClickMarkAsIgnored}>Marcar como ignorado&nbsp;{props.SelectedConsumptions?.length > 0 && <Badge size='small' appearance="filled">{props.SelectedConsumptions?.length}</Badge>}</Button>
            {Forms}
        </div>
    );
}

export default connect(
    ComponnentFunctions.mapStateToProps,
    ComponnentFunctions.mapDispatchToProps
)(ConsumptionListMenuComponent as any);