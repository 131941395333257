import * as React from 'react';

// ownFunctions
import * as Functions from './ProviderSelectorFunctions';
import { Provider } from '../../../../models/Entities/Provider/Provider';
import { Person } from '../../../../models/Entities/Person/Person';
import { IPersonaProps } from '@fluentui/react';
import { Avatar, Field, Tag, TagPicker, TagPickerControl, TagPickerGroup, TagPickerInput, TagPickerList, TagPickerOption, TagPickerProps } from '@fluentui/react-components';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { use } from 'i18next';

const ProviderSelectorComponent = (props: Functions.Props) => {
  const [mostRecentlyUsed, setMostRecentlyUsed] = React.useState<
    IPersonaProps[]
  >([]);
  const [peopleList, setPeopleList] = React.useState<IPersonaProps[]>([]);

  useEffect(() => {
    props.GetAllProviders(1, 1000, '');
  }, []);

  const onChangeSelection = (items?: IPersonaProps[] | undefined) => {
    if (props.onChangeSelection) {
      items?.map((provider: IPersonaProps) => {
        return {
          person: {
            businessName: provider.text,
            id: provider.key,
          } as Person,
          id: provider.key,
        } as Provider;
      });
    }
  };

  const onFilterChanged = (
    filterText: string,
    currentProvider?: IPersonaProps[],
    limitResults?: number
  ): IPersonaProps[] | Promise<IPersonaProps[]> => {
    // if (filterText) {
    //   let filteredSponsor: Promise<IPersonaProps[]> =
    //     filterPersonasByText(filterText);

    //   return filteredSponsor.then((providers: IPersonaProps[]) => {
    //     let ProviderList = removeDuplicates(providers, currentProvider || []);
    //     ProviderList = limitResults
    //       ? ProviderList.slice(0, limitResults)
    //       : ProviderList;
    //     return ProviderList || [];
    //   });
    // } else {
    //   return [];
    // }

    return [];
  };

  const filterPersonasByText = (
    filterText: string
  ): IPersonaProps[] => {
    // return ProviderService.GetProviders(1, 1000, '')
    //   .then((data: Provider[]) => {
    //     return data
    //       .map((item: Provider) => {
    //         return {
    //           key: item.id,
    //           imageInitials: CommonFunctions.getNameInitials(item.fullName),
    //           text: item.fullName,
    //           secondaryText: 'Proveedor'
    //         } as IPersonaProps;
    //       })
    //       .filter((item: IPersonaProps) =>
    //         doesTextStartWith(item.text as string, filterText)
    //       );
    //   })
    //   .catch((error: any) => {
    //     return [] as IPersonaProps[];
    //   });

    return [] as IPersonaProps[];

  };

  const [InternalProtocolPatientsSelected, setInternalProtocolPatientsSelected] = useState<Provider[]>([]);

  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

  const onOptionSelect: TagPickerProps["onOptionSelect"] = (e, data) => {
    if (data.value === "no-options") {
      setSelectedOptions([]);
      setInternalProtocolPatientsSelected([]);
      return;
    }
    if (props.MultiselectEnable) {
      setSelectedOptions(data.selectedOptions);
      setInternalProtocolPatientsSelected(props.Providers?.filter((provider: Provider) => data.selectedOptions.includes(provider.person.businessName)));
    } else {
      setSelectedOptions(props.MultiselectEnable ? [data.value] : (selectedOptions.includes(data.value) ? [] : [data.value]));
      setInternalProtocolPatientsSelected([props.Providers?.find((provider: Provider) => provider.person.businessName === data.value) as Provider]);
    }
  };

  const tagPickerOptions = props.Providers?.map((provider) => provider.person.businessName)?.filter(
    (provider: string) => !selectedOptions.includes(provider)
  );

  return (
    <div>
      <Field label="Seleccione un proveedor" style={{ maxWidth: 400 }}>
        <TagPicker
          onOptionSelect={onOptionSelect}
          selectedOptions={selectedOptions}
        >
          <TagPickerControl>
            <TagPickerGroup aria-label="Proveedores Seleccionados">
              {selectedOptions.map((option) => (
                <Tag
                  key={option}
                  media={
                    <Avatar
                      shape="circular"
                      aria-hidden
                      name={option}
                      color="colorful"
                    />
                  }
                  value={option}
                >
                  {option}
                </Tag>
              ))}
            </TagPickerGroup>
            <TagPickerInput aria-label="Seleccione un proveedor" />
          </TagPickerControl>
          <TagPickerList>
            {tagPickerOptions?.length > 0 ? (
              tagPickerOptions?.map((option) => (
                <TagPickerOption
                  media={
                    <Avatar
                      shape="circular"
                      aria-hidden
                      name={option}
                      color="colorful"
                    />
                  }
                  value={option}
                  key={option}
                >
                  {option}
                </TagPickerOption>
              ))
            ) : (
              <TagPickerOption value="no-options">
                No hay conceptos disponibles
              </TagPickerOption>
            )}
          </TagPickerList>
        </TagPicker>
      </Field>
    </div>
  );
};

export default connect (
  Functions.mapStateToProps,
  Functions.mapDispatchToProps
) (ProviderSelectorComponent as any);
