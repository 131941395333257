
// component function
import { connect } from 'react-redux';
import * as ComponentFunctions from './ConsumptionList.Functions';
import { useEffect, useState } from 'react';
import { Avatar, Button, createTableColumn, TableCellLayout, TableColumnDefinition, Label, DataGrid, DataGridHeader, DataGridRow, DataGridHeaderCell, DataGridBody, DataGridCell, Accordion, AccordionItem, AccordionHeader, AccordionPanel, DataGridProps, TableRowId, Spinner, Checkbox, Badge, Input, InputOnChangeData, SearchBox } from '@fluentui/react-components';
import { ProtocolConsumption } from '../../../models/Entities/Protocols/ProtocolConsumption';
import { DeleteRegular, EditRegular } from '@fluentui/react-icons';
import moment, { max } from 'moment';

import './ConsumptionList.Styles.css';
import ConsumptionSyncProcessComponent from '../ConsumptionSyncProcess/ConsumptionSyncProcess.Component';
import { Separator } from '@fluentui/react';
import ProtocolPatientSelectorComponent from '../../../commons/component/Inputs/ProtocolPatientSelector/ProtocolPatientSelector.Component';
import { ProtocolPatient } from '../../../models/Entities/Protocols/ProtocolPatient';
import ContractConceptSelectorComponent from '../../../commons/component/Inputs/ContractConceptSelector/ContractConceptSelector.Component';
import { ContractConcept } from '../../../models/Entities/Contracts/ContractConcept';
import { CurrencySelectorComponent } from '../../../commons/component/Inputs/CurrencySelector/CurrencySelectorComponent';

// components
import ConsumptionListMenuComponent from './ConsumptionList.Menu/ConsumptionList.Menu.Component';

// forms
import ConsumptionDetailsCompoment from '../ConsumptionDetails/ConsumptionDetails.Component';
import ConsumptionFormComponent from '../ConsumptionForm/Consumption.Form.Component';
import ContractStagesSelectorComponent from '../../../commons/component/Inputs/ContractStagesSelector/ContractStages.Selector.Component';
import { ContractStage } from '../../../models/Entities/Contracts/ContractStage';
import PractitionerSelectorComponent from '../../../commons/component/Inputs/PractitionerSelector/PractitionerSelectorComponent';
import { Practitioner } from '../../../models/Entities/Practitioners/Practitioner';
import ProtocolConsumptionStatusSelectorComponent from '../../../commons/component/Inputs/ProtocolConsumptionStatusSelector/ProtocolConsumptionStatusSelector.Component';
import { IConsumptionStatusProps } from '../../../commons/component/Inputs/ProtocolConsumptionStatusSelector/ProtocolConsumptionStatusSelector.Functions';
import ConsumptionToSettleComponent from '../ConsumptionToSettle/ConsumptionToSettle.Component';


const ConsumtionListComponent = (props: ComponentFunctions.Props) => {

    const [selectedPatients, setSelectedPatients] = useState<ProtocolPatient[]>([]);
    const [selectedContractConcepts, setSelectedContractConcepts] = useState<ContractConcept[]>([]);
    const [selectedCurrency, setSelectedCurrency] = useState<string>('');
    const [selectedContractStages, setSelectedContractStages] = useState<ContractStage[]>([]);
    const [selectedPractitioners, setSelectedPractitioners] = useState<Practitioner[]>([]);
    const [selectedStatus, setSelectedStatus] = useState<IConsumptionStatusProps[]>([]);
    const [filterText, setFilterText] = useState<string>();

    const [selectedConsumption, setSelectedConsumption] = useState<ProtocolConsumption | null>(null);

    // Row Selection
    const [selectedRows, setSelectedRows] = useState(new Set<TableRowId>());

    // show consumption with amount in zero
    const [showConsumptionWithAmountInZero, setShowConsumptionWithAmountInZero] = useState<boolean>((props.ParentId || props.ProviderId) ? true : false);

    // show consumption ignored
    const [showConsumptionIgnored, setShowConsumptionIgnored] = useState<boolean>(props.ParentId ? true : false);

    const onSelectionChange: DataGridProps["onSelectionChange"] = (e, data) => {
        setSelectedRows(data.selectedItems);
        console.log('filas seleccionadas', data.selectedItems);
    };

    const onDeleteConsumption = (consumption: ProtocolConsumption) => {
       props.ProtocolId && props.DeleteProtocolConsumption(props.ProtocolId, consumption.id);
    }

    // information panel
    const [isInformationPanelOpen, setIsInformationPanelOpen] = useState(false);
    // create or edit form
    const [isConsumptionFormOpen, setIsConsumptionFormOpen] = useState(false);

    // on close form
    const onInformationPanelDismiss = () => {
        setSelectedConsumption(null);
        setIsInformationPanelOpen(false);
    }

    // on close form
    const onConsumptionFormDismiss = () => {
        setSelectedConsumption(null);
        setIsConsumptionFormOpen(false);
    }

    useEffect(() => {
        if (props.protocol?.id && props.ParentId === undefined) {
            props.GetAllPractitioners(1, 1000, undefined);
            props.GetAllProtocolConsumptionsByProtocol(props.protocol?.id);
            props.GetAllProtocolContractAssociations(props.protocol?.id);
        }
    }, [props.protocol?.id]);

    useEffect(() => {
        if (props.CurrentProvider?.id) {
            props.GetProviderById(props.CurrentProvider?.id);
            props.GetAllMedicalProtocols();
        }
    }, [props.CurrentProvider?.id]);

    useEffect(() => {
        if (props.CurrentProvider?.person?.personIdentifier && props.ParentId === undefined) {
            props.GetProviderConsumptions(props.CurrentProvider?.id, props.CurrentProvider?.person?.personIdentifier);
        }
    }, [props.CurrentProvider?.person?.personIdentifier]);

    useEffect(() => {
        if (props.ParentId === undefined) {
            if (props.protocol?.contractAssociation?.contractId) {
                props.GetContractById(props.protocol?.contractAssociation?.contractId);
            }
        }
    }, [props.protocol?.contractAssociation?.contractId]);


    // on edit payment
    const onEditDebt = (consumption: ProtocolConsumption) => {
        setSelectedConsumption(consumption);
        setIsInformationPanelOpen(true);
    }

    // on delete payment
    const onDeleteDebt = (consumption: ProtocolConsumption) => {
        //props.DeletePayment(payment.id);
    }


    const columns: (TableColumnDefinition<ProtocolConsumption> | false)[] = [
        createTableColumn<ProtocolConsumption>({
            columnId: "status",
            compare: (a, b) => {
                return props.ProviderId ? a.costStatus - b.costStatus : a.status - b.status;
            },
            renderHeaderCell: () => {
                return "Estado";
            },
            renderCell: (item: ProtocolConsumption) => {
                return (
                    <TableCellLayout>
                        {item.ischangingStatus ?
                            <Spinner size="extra-tiny" />
                            : <div className={'status-mark ' + ComponentFunctions.GetStatusDescription(props.ProviderId ? item.costStatus : item.status).class}></div>
                        }
                    </TableCellLayout>
                );
            },
        }),
        props.ProviderId !== undefined &&
        createTableColumn<ProtocolConsumption>({
            columnId: "protocol",
            compare: (a, b) => {
                return a.protocolId - b.protocolId;
            },
            renderHeaderCell: () => {
                return "Protocolo";
            },
            renderCell: (item: ProtocolConsumption) => {
                return (
                    <TableCellLayout>
                        <Label>{props.GetProtocolById(item.protocolId)?.title}</Label>
                    </TableCellLayout>
                );
            },
        }),
        props.ParentId === undefined &&
        createTableColumn<ProtocolConsumption>({
            columnId: "stage",
            compare: (a, b) => {
                return a.stageName?.localeCompare(b.stageName);
            },
            renderHeaderCell: () => {
                return "Visita";
            },
            renderCell: (item: ProtocolConsumption) => {
                return (
                    <TableCellLayout>
                        <Label>{item.stageName}</Label>
                    </TableCellLayout>
                );
            },
        }),
        props.ParentId === undefined &&
        createTableColumn<ProtocolConsumption>({
            columnId: "patient",
            compare: (a, b) => {
                return a.patientNumberInProtocol?.localeCompare(b.patientNumberInProtocol);
            },
            renderHeaderCell: () => {
                return "Paciente";
            },
            renderCell: (item: ProtocolConsumption) => {
                return (
                    <TableCellLayout>
                        <Label>{item.patientNumberInProtocol}</Label>
                    </TableCellLayout>
                );
            },
        }),
        createTableColumn<ProtocolConsumption>({
            columnId: "concept",
            compare: (a, b) => {
                return a.serviceName.localeCompare(b.serviceName);
            },
            renderHeaderCell: () => {
                return "Concepto";
            },
            renderCell: (item: ProtocolConsumption) => {
                return (
                    <TableCellLayout>
                        <Label>{item.serviceName}</Label>
                        {/* <Label>{item.condition}</Label>
                        <Label>{item.typeOfService}</Label> */}
                        {item.markedAsItemized && <Badge>Itemizable</Badge>}
                        {item.markedAsRefundable && <Badge>Reembolsable</Badge>}
                    </TableCellLayout>
                );
            },
        }),
        createTableColumn<ProtocolConsumption>({
            columnId: "duedate",
            compare: (a, b) => {
                return new Date(a.serviceDueDate).getTime() - new Date(b.serviceDueDate).getTime();
            },
            renderHeaderCell: () => {
                return "Fecha realización";
            },
            renderCell: (item: ProtocolConsumption) => {
                return (
                    <TableCellLayout>
                        {item.serviceDueDate && moment(item.serviceDueDate).format("DD/MM/YYYY hh:mm a")}
                    </TableCellLayout>
                );
            },
        }),
        props.ProtocolId !== undefined && 
        createTableColumn<ProtocolConsumption>({
            columnId: "producer",
            // compare: (a, b) => {
            //     return props.GetPersonByIdentifier(a.producer)?.fullName!.localeCompare(props.GetPersonByIdentifier(b.producer)!.fullName!);
            // },
            renderHeaderCell: () => {
                return "Realizado por";
            },
            renderCell: (item: ProtocolConsumption) => {
                return item.producer && (
                    <TableCellLayout media={(
                        <Avatar
                            aria-label={item.producer}
                            name={props.GetPersonByIdentifier(item.producer)?.fullName}
                            color="colorful"
                        />
                    )}>
                        {props.GetPersonByIdentifier(item.producer)?.fullName}
                    </TableCellLayout>
                );
            },
        }),
        // createTableColumn<ProtocolConsumption>({
        //     columnId: "unitAmount",
        //     compare: (a, b) => {
        //         return (a.unitAmount - b.unitAmount);
        //     },
        //     renderHeaderCell: () => {
        //         return "Monto unitario";
        //     },
        //     renderCell: (item: ProtocolConsumption) => {
        //         return (
        //             <TableCellLayout>
        //                 <Text>{item.unitAmount?.toLocaleString('es-AR', { style: 'currency', currency: item.currencyCode?.toUpperCase() }) || '0.00'}</Text>
        //             </TableCellLayout>
        //         )
        //     },
        // }),
        // createTableColumn<ProtocolConsumption>({
        //     columnId: "Amount",
        //     compare: (a, b) => {
        //         return props.CurrentProvider ? (a.costAmount - b.costAmount) : (a.unitAmount - b.unitAmount);
        //     },
        //     renderHeaderCell: () => {
        //         return "Monto";
        //     },
        //     renderCell: (item: ProtocolConsumption) => {
        //         return (
        //             <TableCellLayout>
        //                 <strong>{props.ProviderId ? item.costAmount?.toLocaleString('es-AR', { style: 'currency', currency: item.costCurrencyCode?.toUpperCase() ?? "ARS" }) ?? '0.00' : item.amount?.toLocaleString('es-AR', { style: 'currency', currency: item.currencyCode?.toUpperCase() }) ?? '0.00'}</strong>
        //             </TableCellLayout>
        //         )
        //     },
        // }),
        // createTableColumn<ProtocolConsumption>({
        //     columnId: "addingsAmount",
        //     compare: (a, b) => {
        //         return (a.totalValues - b.totalValues);
        //     },
        //     renderHeaderCell: () => {
        //         return "Montos adicionales";
        //     },
        //     renderCell: (item: ProtocolConsumption) => {
        //         return (
        //             <TableCellLayout>
        //                 <strong>{item.totalValues?.toLocaleString('es-AR', { style: 'currency', currency: item.currencyCode?.toUpperCase() }) || '0.00'}</strong>
        //             </TableCellLayout>
        //         )
        //     },
        // }),
        // createTableColumn<ProtocolConsumption>({
        //     columnId: "withholdingAmount",
        //     compare: (a, b) => {
        //         return (a.withHolingAmount - b.withHolingAmount);
        //     },
        //     renderHeaderCell: () => {
        //         return "withholding";
        //     },
        //     renderCell: (item: ProtocolConsumption) => {
        //         return (
        //             <TableCellLayout>
        //                 <Text>{item.withHolingAmount?.toLocaleString('es-AR', { style: 'currency', currency: item.currencyCode?.toUpperCase() }) || '0.00'} | ({item.withHolingPercentage + '%'})</Text>
        //             </TableCellLayout>
        //         )
        //     },
        // }),
        createTableColumn<ProtocolConsumption>({
            columnId: "totalAmount",
            compare: (a, b) => {
                return (a.totalAmount - b.totalAmount);
            },
            renderHeaderCell: () => {
                return "Monto total";
            },
            renderCell: (item: ProtocolConsumption) => {
                return (
                    <TableCellLayout>
                        <strong>{item.totalAmount?.toLocaleString('es-AR', { style: 'currency', currency: item.currencyCode?.toUpperCase() }) || '0.00'}</strong>
                    </TableCellLayout>
                )
            },
        }),
        // createTableColumn<ProtocolConsumption>({
        //     columnId: "Settled",
        //     compare: (a, b) => {
        //         return (a.payeeAmount - b.payeeAmount);
        //     },
        //     renderHeaderCell: () => {
        //         return "Pagado";
        //     },
        //     renderCell: (item: ProtocolConsumption) => {
        //         return (
        //             <TableCellLayout>
        //                 <strong>{item.payeeAmount?.toLocaleString('es-AR', { style: 'currency', currency: item.currencyCode?.toUpperCase() }) || '0.00'}</strong>
        //             </TableCellLayout>
        //         )
        //     },
        // }),
        createTableColumn<ProtocolConsumption>({
            columnId: "pending",
            compare: (a, b) => {
                return (a.pendingAmount - b.pendingAmount);
            },
            renderHeaderCell: () => {
                return "Pendiente";
            },
            renderCell: (item: ProtocolConsumption) => {
                return (
                    <TableCellLayout>
                        <strong>{item.pendingAmount?.toLocaleString('es-AR', { style: 'currency', currency: item.currencyCode?.toUpperCase() }) || '0.00'}</strong>
                    </TableCellLayout>
                )
            },
        }),
        createTableColumn<ProtocolConsumption>({
            columnId: "actions",
            renderHeaderCell: () => {
                return "Actions";
            },
            renderCell: (item: ProtocolConsumption) => {
                return (
                    <>
                        <Button appearance="subtle" aria-label="Edit" icon={<EditRegular />} onClick={(event) => { event.stopPropagation(); onEditDebt(item) }} />
                        {![1,2,3,6].includes(item.status) && <Button appearance="subtle" aria-label="Delete" icon={<DeleteRegular />} onClick={(event) => { event.stopPropagation(); onDeleteConsumption(item) }} />}
                    </>
                );
            },
        }),
    ];

    const columnSizingOptions = {
        status: {
            defaultWidth: 20,
            idealWidth: 20,
        },
        stage: {
            defaultWidth: 130,
            idealWidth: 130,
        },
        patient: {
            defaultWidth: 180,
            idealWidth: 180,
        },
        concept: {
            defaultWidth: 180,
            idealWidth: 180,
        },
        duedate: {
            defaultWidth: 150,
            idealWidth: 150,
        },
        producer: {
            defaultWidth: 150,
            idealWidth: 150,
        },
        Amount: {
            defaultWidth: 100,
            idealWidth: 100,
        },
        actions: {
            defaultWidth: 100,
            idealWidth: 100,
            maxWidth: 50,
        },

    };

    const Forms = (
        <>
            {selectedConsumption && <ConsumptionDetailsCompoment
                ProtocolId={selectedConsumption?.protocolId}
                ProtocolConsumptionId={selectedConsumption?.id}
                isOpen={isInformationPanelOpen}
                onClose={onInformationPanelDismiss} />}
            {props.ProtocolId && <ConsumptionFormComponent
                ProtocolId={props.ProtocolId}
                isOpen={isConsumptionFormOpen}
                onClose={onConsumptionFormDismiss}
            />}
        </>
    );


    return (
        <>
            {props.ProtocolId && !props.ParentId && <ConsumptionSyncProcessComponent ProtocolId={props.ProtocolId} />}
            {props.ProtocolId && props.haveConsumptionsToSettle && <ConsumptionToSettleComponent ProtocolId={props.ProtocolId} status={6} />}
            {props.ProtocolId && !props.ParentId && <> <Separator />
                <Accordion collapsible className='ConsumptionFiltersContainer'>
                    <AccordionItem value="1">
                        <AccordionHeader size="medium" key={props.ParentId}>Filtros</AccordionHeader>
                        <AccordionPanel>
                            {props.contract && <>
                                <SearchBox style={{width: '100%'}} value={filterText}    onChange={(ev: any, data: InputOnChangeData) => setFilterText(data.value) }/>
                                <Separator />
                                <div className='ConsumptionFilters'>

                                    <div>
                                        <ProtocolPatientSelectorComponent
                                            ProtocolId={props.ProtocolId}
                                            onSelectedProtocolPatient={(patients: ProtocolPatient[]) => { patients ? setSelectedPatients(patients) : setSelectedPatients([]) }}
                                            ProtocolPatientsSelectedNumber={selectedPatients?.map((pat: ProtocolPatient) => { return pat.numberInProtocol })}
                                            MultiselectEnable={true}
                                        />
                                    </div>
                                    <div>
                                        <ContractConceptSelectorComponent
                                            ContractId={props.contract.id}
                                            referralDate={undefined}
                                            onSelectedContractConcept={(contractConcepts: ContractConcept[]) => setSelectedContractConcepts(contractConcepts)}
                                            ContractConceptsSelected={selectedContractConcepts?.map((contractConcept: ContractConcept) => contractConcept.id)}
                                            MultiselectEnable={true}
                                        />
                                    </div>
                                    <div>
                                        <CurrencySelectorComponent
                                            errorMessage={undefined}
                                            defaultSelected={selectedCurrency}
                                            label={'Seleccione una moneda'}
                                            onCurrencyChange={(currency: string) => currency ? setSelectedCurrency(currency) : setSelectedCurrency('')}
                                            disabled={false}
                                            isRequired={false}
                                        />
                                    </div>
                                    <div>
                                        <ContractStagesSelectorComponent
                                            ContractId={props.contract.id}
                                            onSelectedContractStage={
                                                (stages: ContractStage[]) => {
                                                    stages !== undefined || stages?.[0] !== undefined ? setSelectedContractStages(stages) : setSelectedContractStages([])
                                                }
                                            }
                                            ContractStagesSelected={selectedContractStages?.map((contractStage: ContractStage) => contractStage.id)}
                                            MultiselectEnable={true} />
                                    </div>
                                    <div>
                                        <PractitionerSelectorComponent
                                            onChangeSelection={(practitioners: Practitioner[] | undefined) => { practitioners !== undefined || practitioners?.[0] !== undefined ? setSelectedPractitioners(practitioners) : setSelectedPractitioners([]) }}
                                            MultiselectEnable={true}
                                            SelectedPractitioners={selectedPractitioners?.map((practitioner: Practitioner) => practitioner.userId) || []}
                                        />
                                    </div>
                                    <div>
                                        <ProtocolConsumptionStatusSelectorComponent 
                                            ContractId={0} 
                                            onSelectedStatus={
                                                (status: IConsumptionStatusProps[]) => {
                                                    status !== undefined || status?.[0] !== undefined ? setSelectedStatus(status) : setSelectedStatus([])
                                                }
                                            } 
                                            statusSelected={
                                                selectedStatus?.map((status: IConsumptionStatusProps) => status.id)
                                            } 
                                            MultiselectEnable={true} 
                                        />
                                    </div>
                                </div>
                                <Separator />
                                <div className='ConsumptionMarks'>
                                    <Checkbox
                                        checked={showConsumptionWithAmountInZero}
                                        onChange={(ev, data) => data.checked ? setShowConsumptionWithAmountInZero(true) : setShowConsumptionWithAmountInZero(false)}
                                        label="Mostrar consumos con monto en cero"
                                    />
                                    <Checkbox
                                        checked={showConsumptionIgnored}
                                        onChange={(ev, data) => data.checked ? setShowConsumptionIgnored(true) : setShowConsumptionIgnored(false)}
                                        label="Mostrar consumos ignorados"
                                    />
                                </div>
                            </>
                            }
                        </AccordionPanel>
                    </AccordionItem>
                </Accordion></>}
            {props.ProtocolId && <ConsumptionListMenuComponent ProtocolId={props.ProtocolId} SelectedConsumptions={props.Consumptions?.filter((item: ProtocolConsumption) => Array.from(selectedRows).includes(item.id))} />}
            <div className="ConsumptionListContainer">
                <DataGrid
                    items={props.Consumptions?.filter((consumption: ProtocolConsumption) => {
                        return (selectedPatients.length === 0 || selectedPatients.map((patient: ProtocolPatient) => patient.numberInProtocol).includes(consumption.patientNumberInProtocol))
                        && (selectedContractConcepts.length === 0 || selectedContractConcepts.map((contractConcept: ContractConcept) => contractConcept?.concept?.conceptName).includes(consumption.conceptDescription))
                        && (selectedCurrency === undefined || selectedCurrency === null || selectedCurrency === '' || consumption.currencyCode === selectedCurrency)
                        && (showConsumptionWithAmountInZero || consumption.amount > 0)
                        && (showConsumptionIgnored || consumption.status !== 5)
                        && (selectedContractStages.length === 0 || selectedContractStages.map((contractStage: ContractStage) => contractStage.stageName).includes(consumption.stageName))
                        && (selectedPractitioners.length === 0 || (consumption.producer !== undefined && selectedPractitioners.map((practitioner: Practitioner) => practitioner.userId).includes(consumption.producer)))
                        && (selectedStatus.length === 0 || selectedStatus.map((status: IConsumptionStatusProps) => status.id).includes(consumption.status))
                        && (filterText === undefined || filterText === null || filterText === '' || consumption.serviceName.toLowerCase().includes(filterText.toLowerCase()))
                    }) || []}
                    columns={columns.filter(Boolean) as TableColumnDefinition<ProtocolConsumption>[]}
                    sortable
                    selectionMode="multiselect"
                    selectedItems={selectedRows}
                    onSelectionChange={onSelectionChange}
                    getRowId={(item) => item.id}
                    resizableColumns={false}
                    columnSizingOptions={columnSizingOptions}
                    focusMode="composite"
                >
                    <DataGridHeader>
                        <DataGridRow
                            selectionCell={{
                                checkboxIndicator: { "aria-label": "Select all rows" },
                            }}
                        >
                            {({ renderHeaderCell }) => (
                                <DataGridHeaderCell>{renderHeaderCell()}</DataGridHeaderCell>
                            )}
                        </DataGridRow>
                    </DataGridHeader>
                    <DataGridBody<ProtocolConsumption>>
                        {({ item, rowId }) => (
                            <DataGridRow<ProtocolConsumption> className={ComponentFunctions.GetRowClassByStatus(item.status)}
                                key={rowId}
                                selectionCell={{
                                    checkboxIndicator: { "aria-label": "Select row" },
                                }}
                            >
                                {({ renderCell }) => (
                                    <DataGridCell onClick={(e: any) => {
                                        e.stopPropagation();
                                        setSelectedConsumption(item);
                                        setIsInformationPanelOpen(true);
                                    }}>{renderCell(item)}</DataGridCell>
                                )}
                            </DataGridRow>
                        )}
                    </DataGridBody>
                </DataGrid>
            </div>
            {Forms}
        </>

    );
};

export default connect(
    ComponentFunctions.mapStateToProps,
    ComponentFunctions.mapDispatchToProps
)(ConsumtionListComponent as any);